import React from 'react';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import useOrientation from '../../general/useOrientation';

function TypeOfIssue({ text, value, maxValue }) {
  const percentage = isMobile? (value / maxValue) * 100 : (value / maxValue) * 85;
  const orientation = useOrientation();

  const containerStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start', // Align left
    margin: '0.5vw 1vw', // Adjusted margin for tighter spacing
    marginBottom: '-0.3vw',
  };

  const progressContainerStyle = {
    display: 'flex',
    flexDirection: 'row', // Align elements horizontally
    alignItems: 'center', // Center elements vertically
  };

  const progressBarStyle = {
    height: isMobile? `1vh`:'0.5vw',
    width: isMobile? `${parseInt(percentage) - 10}%` : `${percentage}%`,
    backgroundColor: '#3498db',
    borderRadius: '5px',
  };

  const valueStyle = {
    marginLeft: '0.5vw',
  };
  if (orientation === 'landscape')
  return (
    <>
    <>
    <div className="row" style={containerStyle}>
      <div className="col-md-3">
        <p style={{ margin: '0',fontSize: '1vw', fontFamily:'Blender Pro'  }}>{text} &nbsp; </p>
      </div>
      <div className="col-md-9" style={progressContainerStyle}>
        <div style={progressBarStyle}></div>
        <div style={valueStyle}>{value}</div>
      </div>
    </div>
    </>
    </>
  );
  if (orientation === 'portrait')
  return(
    <>
    <div className="row" style={containerStyle}>
      <div className="col-md-3">
        <p style={{ margin: '0',fontSize: '2vh', fontFamily:'Blender Pro'  }}>{text} &nbsp; </p>
      </div>
      <div className="col-md-9" style={progressContainerStyle}>
        <div style={progressBarStyle}></div>
        <div style={valueStyle}>{value}</div>
      </div>
    </div>
    </>
  )
}

export default TypeOfIssue;
