import React from 'react'
import { useEffect,useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderPage from '../headerPage/HeaderPage';
import {OpenNewWindow,Edit} from 'iconoir-react'
import Cookies from 'universal-cookie'; 
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
import axios from 'axios' 
import FaqView from './FaqView';
import useOrientation from '../../general/useOrientation';

const cookies = new Cookies();
const MySwal = withReactContent(Swal)
const sessionData = cookies.get('sessionData', { path: '/' })
function FaqHome({changeNavState}) {
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait') { isThisPortrait = true;}
  const navigate = useNavigate(); 
  const { id } = useParams();  
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [showOverlay, setShowOverlay] = useState(false);
  const [viewFaq, setViewFaq] = useState(false);
  const [faqData, setFaqData] = useState({});
  useEffect(() => {
    changeNavState('Help');
    getData();
  }, [])

  const closePopup = (() => {
    setShowOverlay(false);
    setViewFaq(false);
  })

  const openFaqPopup = ((data) => {
    setFaqData(data);
    setShowOverlay(true);
    setViewFaq(true);
  })
  
  const openPrivacyPolicy = (() => {
    navigate("/privacyPolicy")
  })

  const buttonAction = (() => {
    navigate("/faq/add")
  })

  const editFaq = ((id) => {
    navigate("/faq/edit/" + id)
  })
    
    
  const getData = () => { 
    setIsLoading(true)
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    const config = {headers:{['x-access-token']: sessionToken}};

    var dbpath = process.env.REACT_APP_PATH_DESTINATION + "/faqList";   
    axios.get( dbpath, config)
    .then(res => { 
        console.log("res.data.data faqList logging")
        console.log(res.data.data);
        if (res.data.status == "200"){
            setData(res.data.data);
            setIsLoading(false)
            if (id){
                for (let i = 0; i < res.data.data.length ; i++){
                    if (res.data.data[i].id == id){
                        setFaqData(res.data.data[i]);
                        setShowOverlay(true);
                        setViewFaq(true);
                        break;
                    }
                }
            }
        } else {
            setIsLoading(true)
            //maybe error log / alert here somehow
        }
    })
  }

  return ( <>
    <div onClick={closePopup} className={showOverlay ? 'divOverlay' : ''}> </div>
    
    {viewFaq && (
      <FaqView data={faqData} closePopup={closePopup} />
    )}

    <HeaderPage
        title1={"Help"}
        title2={"Tutorial & Feedback"}
        loadingState={isLoading}
        buttonText={(sessionData.int_ext == 1 && sessionData.apps_roles_id < 3)? "Create New" : ""}
        buttonAction={(sessionData.int_ext == 1 && sessionData.apps_roles_id < 3)? buttonAction : ""}
    />

    <div style={{marginLeft:isThisPortrait?"0vw":'0vw',marginRight:isThisPortrait?"0vw":'1vw', marginTop:'2vw', display:isThisPortrait?'flex':'', justifyContent: isThisPortrait?'center':''}}>
        <img 
            style={{width:isThisPortrait? '95%': '100%', height:isThisPortrait?'':''}}
            src={isThisPortrait? process.env.REACT_APP_FAQ_BG_DESTINATION + 'bannerfaqMobile.png' : process.env.REACT_APP_FAQ_BG_DESTINATION + 'bannerfaq.png'}
        />
    </div>
    <br/><br/>
    <div className='row' style={{fontFamily:"Blender Pro", width:isThisPortrait?'95%':'', marginLeft:isThisPortrait?'2.5vw':'', marginBottom:isThisPortrait?'':'2vw'}}>
        <div className='col-md-6' style={{fontFamily:'Blender Pro'}}>
            <p style={{color:'#A3AEB7', marginBottom:'-1vw', fontSize:'17px'}}>Tutorial</p>
            <h4 style={{fontFamily:"Blender Pro", fontSize:'17px'}}>Learn how to use the essential features</h4>

            {data && data.length > 0 && (
                <div style={{marginTop:'2vw'}}>
                {data.map((item, index) => (
                    <div key={index} className="cursorPointer" onClick={() => openFaqPopup(item)}>
                    <h5 style={{ color: '#0296D2' , marginTop:isThisPortrait?'2vh':'1vw',fontFamily:"Blender Pro", fontSize:'17px'}}>
                      {item.title} {sessionData.int_ext == 1 && sessionData.apps_roles_id < 3 && (
                        item.int_ext_id === 1 ? " Internal" : " External"
                      )}
                      &nbsp;
                      {sessionData.int_ext == 1 && sessionData.apps_roles_id < 3 && (
                        <Edit onClick={() => editFaq(item.id)}/>
                      )}
                    </h5>
                    </div>
                ))}
                </div>
            )}
        </div>
       
        <div className='col-md-6' style={{fontFamily:'Blender Pro'}}>
        {isThisPortrait && (
          <><br/><br/></>
        )}
            <p style={{color:'#A3AEB7', marginBottom:isThisPortrait?'2vh':'-1vw',fontFamily:"Blender Pro", fontSize:'17px'}}>Feedback</p>
            <h4 style={{fontFamily:"Blender Pro", fontSize:'17px'}}>Have any feedback? Let us know!</h4>
            <button style={{padding:'10px', marginTop:'1vw'}} onClick={() => {const formUrl =
                sessionData.int_ext == 2
                    ? 'https://forms.office.com/Pages/ResponsePage.aspx?id=yJHxX27ETEGHXmgzRnoFVDTWvSqeXA9CrzQTNXpsl_tUOEMwQ09GS1pISkVJMUtPMUxZVUlJTTQ2Sy4u'
                    : 'https://forms.office.com/Pages/ResponsePage.aspx?id=yJHxX27ETEGHXmgzRnoFVDTWvSqeXA9CrzQTNXpsl_tUNEs1MzU3TzdYOElFTkxJUjc5V0VXMDVEMy4u';
                window.open(formUrl, '_blank');}} className='buttonBlue'>
                <OpenNewWindow /> Open Feedback Form
            </button>
            <br/><br/> 
            <div style={{marginTop:'0vw', marginBottom:isThisPortrait?'1.5vh':''}}>
                <div className="cursorPointer" onClick={() => openPrivacyPolicy()}>
                <h5 style={{ color: '#0296D2' , marginTop:'1vw',fontFamily:"Blender Pro", fontSize:'17px'}}>View BeFa Helpdesk Privacy Policy</h5>
                </div>
            </div>
            <br/><br/>
        </div>

    </div>
    </>
  )
}

export default FaqHome