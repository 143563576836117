import { Radius } from 'iconoir-react';
import StatusImage from './StatusImage'
import React, { useState, useEffect } from 'react';
import {Star} from 'iconoir-react';
import TimeStatus from './TimeStatus';
import CountUp from '../../ticketList/CountUp';
import CountDown from '../../ticketList/CountDown';
import Cookies from 'universal-cookie';
import useOrientation from '../../../general/useOrientation';
const cookies = new Cookies();

function StatusTicketDetail({dueAt, typeString, star, extended, countdown, data, displayType, refresh}) {
  const [dueString, setDueString] = useState("");
  const [status, setStatus] = useState("")
  const [mrgnTop, setMrgnTop] = useState("-3.3vw")
  const [pureView, setPureView] = useState(false)
  const sessionData = cookies.get('sessionData', { path: '/' })
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait') { isThisPortrait = true;}
  const formatDate = (date) => {
    const createdAt = new Date(date);
    const hours = createdAt.getHours().toString().padStart(2, '0');
    const minutes = createdAt.getMinutes().toString().padStart(2, '0');
    const day = createdAt.getDate().toString().padStart(2, '0');
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[createdAt.getMonth()];
    const year = createdAt.getFullYear();
    return `${hours}.${minutes} on ${day} ${month} ${year}`;
  };

  useEffect(() => {
    //status 
    if ((data.status_id == 2 && displayType != "1" && displayType != "0") && data.status_overdue=="overdue"){
      setStatus("Overdue")
    } else {
      setStatus(typeString);
    }

    //override status 1
    if (displayType == "1" || displayType == "0"){
      if (data.status_id == 5 || data.status_id == 6)
        setStatus("On Progress")
    }
    var firstStr = "";
    if (data.status_id == 1 ){
      firstStr = "Accept"
    } else if (data.status_id == 6){
      firstStr = "Forward Response"
    } else if (data.status_id == 2 || data.status_id == 5){
      firstStr = "Resolve"
    }  else if (data.status_id == 3 ){
      firstStr = "Close"
    }  else if (data.status_id == 4){
      if (data.closed_auto == 1){
        firstStr = "Closed by System"
      } else {
        firstStr = "Closed by Creator"
      }
    }  else if (data.status_id == 7){
      firstStr = "Unpublished Draft"
    } else {
      firstStr = ""
    }

    if (data.status_id == 7) {
      setDueString(firstStr)
    } else if (data.status_id == 4) {
      const closedAt = formatDate(data.closed_at);
      let dueStr = `${firstStr} at ${closedAt} ` 
      setDueString(dueStr);
    } else {
      let dueStr = `${firstStr} is due at ${dueAt} ` 
      setDueString(dueStr);
    }

    //override the display type 1
    if (displayType == "1" || displayType == "0") {
      if (typeString === 'Waiting' || typeString === 'On Progress' || typeString === 'Resolved' || typeString === 'Done' || typeString === 'Forwarded' ) {
        setMrgnTop("-2.5vw");
        setPureView(true);
      } else {
        setMrgnTop("-3.3vw");
        setPureView(false);
      }
    } else {
      setMrgnTop("-3.3vw");
      setPureView(false);
    }
  }, [data, mrgnTop, refresh])
  if (orientation === 'landscape')
  return (
    <>
    <div>
      <StatusImage displayType={displayType} type={typeString} status_overdue={data.status_overdue}/> 
    
      <p style={{fontSize:'1.5vw', marginTop:mrgnTop, marginLeft:'4.3vw',fontFamily:'Blender Pro'}}>
          <b>{status}</b>
      </p>
      {!pureView && (
        <p style={{fontSize:'1vw', marginTop:'-1.5vw', marginLeft:'4.3vw',fontFamily:'Blender Pro'}}>
          {dueString}
        </p>
      )}

      <div style={{ float: 'right', marginTop: '-4vw' }}>
        { star && (
          <>
          <div className='countdownStarRating'>
          {star} <Star style={{fill:'CurrentColor', width:'1vw', marginTop:"-0.2vw", color:"#FFFFFF"}} />
          </div>
          <div style={{width:'1vw', display: 'inline-block',}}></div>
          </>
        )}
        {extended == 1 && displayType != 1  && (displayType != 0 || sessionData.int_ext == "1" )&& (
          <>
          <div className='countdownExtended'>
          Extended
          </div>
          <div style={{width:'1vw', display: 'inline-block',}}></div>
          </>
        )}
        {displayType == 1 && (
          <div className='countdownClassUp'>
          <CountUp data={countdown} status={typeString}/>
          </div>
        )}
        {(displayType == 2 || displayType == 3) && data.status_overdue == "not overdue" && (
          <div className='countdownClassDown'>
          <CountDown data={data.due_at} status={typeString}/>
          </div>
        )}
        {(displayType == 2 || displayType == 3) && data.status_overdue == "overdue" && (
          <div className='countdownClassUpOverdue'>
          <CountUp data={countdown} status={typeString}/>
          </div>
        )}
        
      </div>
      {data.forwarded === 1 ? (
          // Render a different TimeStatus component if data.forwarded is 1
          <TimeStatus createdAt={data.created_at} acceptedAt={data.forwarded_at} doneAt={data.resolved_at} closedAt={data.closed_at} />
        ) : (
          // Default TimeStatus component if data.forwarded is not 1
          <TimeStatus createdAt={data.created_at} acceptedAt={data.accepted_at} doneAt={data.resolved_at} closedAt={data.closed_at} />
        )}
    </div> 
    </>
  )

  if (orientation === 'portrait')
  return (
    <>
    <div>
      <StatusImage displayType={displayType} type={typeString} status_overdue={data.status_overdue}/> 
    
      <p style={{fontSize:'3vh', marginTop:'-3.8vh', marginLeft:'4.5vh',fontFamily:'Blender Pro'}}>
          <b>{status}</b>
      </p>
      
      <div style={{ float: 'right', marginTop: '-7.2vh' , marginRight:'-8vw'}}>
        { star && (
          <>
          <div className='countdownStarRatingMobile'>
          {star} <Star style={{fill:'CurrentColor', width:'1.5vh', marginTop:"-0.2vw", color:"#FFFFFF"}} />
          </div>
          <div style={{width:'1.5vh', display: 'inline-block',}}></div>
          </>
        )}
        {extended == 1 && (displayType != 0 || sessionData.int_ext == "1" )&& (
          <>
          <div className='countdownExtended' style={{padding:'0.5vh 2vw 0.5vh 2vw'}}>
          Extended
          </div>
          <div style={{width:'1.5vh',  display: 'inline-block'}}></div>
          </>
        )}
        {displayType == 1 && (
          <div className='countdownClassUp' style={{padding:'0.5vh 2vw 0.5vh 2vw'}}>
          <CountUp data={countdown} status={typeString}/>
          </div>
        )}
        {(displayType == 2 || displayType == 3) && data.status_overdue == "not overdue" && (
          <div className='countdownClassDown' style={{padding:'0.5vh 2vw 0.5vh 2vw'}}>
          <CountDown data={data.due_at} status={typeString}/>
          </div>
        )}
        {(displayType == 2 || displayType == 3) && data.status_overdue == "overdue" && (
          <div className='countdownClassUpOverdue' style={{padding:'0.5vh 2vw 0.5vh 2vw'}}>
          <CountUp data={countdown} status={typeString}/>
          </div>
        )}
        
      </div>
      
    </div> 
    </>
  )
}

export default StatusTicketDetail