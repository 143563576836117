import React from 'react'
import { useEffect,useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Cookies from 'universal-cookie'; 
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
import axios from 'axios' 
import UploadComp from '../createTicket/UploadComp';
import useOrientation from '../../general/useOrientation';

const cookies = new Cookies();
const MySwal = withReactContent(Swal)
const sessionData = cookies.get('sessionData', { path: '/' })
function FaqAddEdit({addEdit, changeNavState}) {
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait') { isThisPortrait = true;}
  const navigate = useNavigate(); 
  const { id } = useParams();  
  const [draft, setDraft] = useState('0');
  const [title, setTitle] = useState('');
  const [order, setOrder] = useState('');
  const [target, setTarget] = useState('1');
  const [dbshow, setDbshow] = useState('0');
  const [refreshDropdown, setRefreshDropdown] = useState(false);
  const [oldAttachmentContent, setOldAttachmentContent] = useState('');
  const [oldAttachmentBG, setOldAttachmentBG] = useState('');
  const [uploadedFilesContent, setUploadedFilesContent] = useState([]);
  const [uploadedFilesBG, setUploadedFilesBG] = useState([]);
  
  useEffect(() => {
    changeNavState('Help');
    if (addEdit == "edit"){
        getData();
    }
  }, [])
  const handleTitleChange = (e) => {
    setTitle(e.target.value); 
  };
  const handleOrderChange = (e) => {
    setOrder(e.target.value); 
  };
  const handleTargetChange = (e) => {
    setTarget(e.target.value); 
  };
  const handleShowDashboardChange = (e) => {
    setDbshow(e.target.value); 
  };
  const handleChangeOldAtchContent = (value) => {
    setOldAttachmentContent(value);
  }
  const handleChangeOldAtchBG = (value) => {
    setOldAttachmentBG(value);
  }

    // Callback function to handle file and metadata on change status
    const handleFileChangeContent = (meta, file, status) => {
        if (status === 'done') {
            // File has been successfully uploaded
            setUploadedFilesContent((prevFiles) => [...prevFiles, { meta, file }]);
        } else if (status === 'removed') {
            // File has been removed
            setUploadedFilesContent((prevFiles) =>
            prevFiles.filter((uploadedFile) => uploadedFile.file !== file)
            );
        }
        // Add more conditions based on your requirements

        console.log("upload files logging",uploadedFilesContent);
    };
    
    // Callback function to handle file and metadata on change status
    const handleFileChangeBG = (meta, file, status) => {
        if (status === 'done') {
            // File has been successfully uploaded
            setUploadedFilesBG((prevFiles) => [...prevFiles, { meta, file }]);
        } else if (status === 'removed') {
            // File has been removed
            setUploadedFilesBG((prevFiles) =>
            prevFiles.filter((uploadedFile) => uploadedFile.file !== file)
            );
        }
        // Add more conditions based on your requirements
        console.log("upload files logging",uploadedFilesBG);
    };

    
  const handleAction = () => { 
    var sessionToken = cookies.get('sessionToken', { path: '/' });
    const config = { headers: { 'x-access-token': sessionToken } };
    let apiPath = process.env.REACT_APP_PATH_DESTINATION;
    if (addEdit == "edit"){
      apiPath += "/faqEdit";
    } else {
      apiPath += "/faqAdd";
    }
  
    // Create a FormData object
    const formData = new FormData();
    let lengthFile = 0;
    let whichChanged = 1;
    // Append the uploaded files to the FormData object
    uploadedFilesContent.forEach((file) => {
      if (uploadedFilesContent.length === 0) {
        //no file
      } else {
        lengthFile += 1;
        formData.append('files',file.file);
      }
    });
    uploadedFilesBG.forEach((file) => {
      if (uploadedFilesBG.length === 0) {
        //no file
      } else {
        lengthFile += 1;
        formData.append('files',file.file);
        if (lengthFile === 1){
          whichChanged = 2;
        } 
        //appending. if file is length 2 then only the second file changed. 
        //if which changed is 1 - means only first file is changed. limiting the loop process in BE 
      }
    });
  
    // Append other data to the FormData object
    formData.append('title', title);
    formData.append('order', order);
    formData.append('int_ext', target);
    formData.append('dashboard', dbshow);
    formData.append('id', id);
    formData.append('whichChanged', whichChanged);
    //alert(apiPath);
    axios.post(apiPath, formData, config)
      .then(res => {
        console.log(res.data);
        if (res.data.status === "200") {
          MySwal.fire({
            title: <strong>Success</strong>,
            html: <p>Data Edited </p>
          })
        } else {
          MySwal.fire({
            title: <strong>Error!</strong>,
            html: <p>Error BE Edit Data </p>
          })
        }
      })
      .catch(error => {
        MySwal.fire({
          title: <strong>Error!</strong>,
          html: <p>Error Axios or path </p>
        })
      });
  }

  const verifyDelete = () => {
    MySwal.fire({
      title: <strong>Are you sure to Delete?</strong>,
      html: <p>This action cannot be undone!</p>,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete();
      }
    });
  };

  const handleDelete = () => {
    var sessionToken = cookies.get('sessionToken', { path: '/' });
    const config = { headers: { 'x-access-token': sessionToken } };
    
    let apiPath = process.env.REACT_APP_PATH_DESTINATION + "/faqDelete/" + id;

    axios.get(apiPath, config)
      .then(res => {
        console.log(res.data);
        if (res.data.status === "200") {
          MySwal.fire({
            title: <strong>Success</strong>,
            html: <p>Data Deleted, please go back to FAQ List </p>
          })
        } else {
          MySwal.fire({
            title: <strong>Error!</strong>,
            html: <p>Error BE Delete Data </p>
          })
        }
      })
      .catch(error => {
        MySwal.fire({
          title: <strong>Error!</strong>,
          html: <p>Error Axios or path </p>
        })
      });
  }

  const getData = () => { 
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    const config = {headers:{['x-access-token']: sessionToken}};

    var dbpath = process.env.REACT_APP_PATH_DESTINATION + "/faqListId/"+id;   
    axios.get( dbpath, config)
    .then(res => { 
        console.log("res.data.data faqList logging")
        console.log(res.data.data);
        if (res.data.status == "200"){
            setTitle(res.data.data.title)
            setOrder(res.data.data.order)
            setTarget(res.data.data.int_ext_id);
            setDbshow(res.data.data.dashboard);
            setDraft("1");
            setOldAttachmentContent(res.data.data.content);
            setOldAttachmentBG(res.data.data.background);
        } else {
            //maybe error log / alert here somehow
        }
    })
  }

  return (<div style={{width:isThisPortrait?'95%':'', marginLeft:isThisPortrait?'2.5vw':''}}>
    <h2>Faq Add Edit</h2>
    <br/>
    <h3>FAQ Title</h3>
    <input
        placeholder='Input Fields'
        className={isThisPortrait?'inputTextMobile':'inputText'}
        type='text'
        style={{ marginTop: '-2.5vw' }}
        value={title}
        onChange={handleTitleChange}
    />

    <h3>FAQ Order</h3>
    <input
        placeholder='Input Fields'
        className={isThisPortrait?'inputTextMobile':'inputText'}
        type='number'
        style={{ marginTop: '-2.5vw' }}
        value={order}
        onChange={handleOrderChange}
    />

    <h3>FAQ Target</h3>
    <select
        className={isThisPortrait?'inputTextMobile':'inputText'}
        style={{ marginTop: '-2.5vw' }}
        value={target}
        onChange={handleTargetChange}
    >
        <option value={1}>Internal</option>
        <option value={2}>External</option>
    </select> 

    <h3>FAQ Show in Dashboard (max 3 in dashboard for internal & external)</h3>
    <select
        className={isThisPortrait?'inputTextMobile':'inputText'}
        style={{ marginTop: '-2.5vw' }}
        value={dbshow}
        onChange={handleShowDashboardChange}
    >
        <option value={0}>Don't show in Dashboard</option>
        <option value={1}>Show in Dashboard</option>
    </select>
 

    <h3>FAQ Content (one image only)</h3>
    <UploadComp onChangeStatus={handleFileChangeContent} refresh={refreshDropdown} atch={oldAttachmentContent} setAtchString={handleChangeOldAtchContent} isDraft={draft} isFaq={true}/>

    <h3>FAQ BG (for dashboard) (add random image if not needed for dashboard - 1 only)</h3>
    <UploadComp onChangeStatus={handleFileChangeBG} refresh={refreshDropdown} atch={oldAttachmentBG} setAtchString={handleChangeOldAtchBG} isDraft={draft} isFaq={true}/>
    
    <br/><br/>
    <button style={{backgroundColor:'red'}} className="btn btnSubmitTicket" type="button" onClick={verifyDelete}>
      Delete
    </button>
    &nbsp;&nbsp;&nbsp;
    <button className="btn  btnSubmitTicket" type="button" onClick={handleAction}>
      Submit
    </button>
    <br/><br/>
    </div>
  )
}

export default FaqAddEdit