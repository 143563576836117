import React, { useEffect, useState } from 'react'
import HeaderPage from '../headerPage/HeaderPage';
import ConfigIcons from './ConfigIcons';
import {PageFlip,UserStar,SoundMin,Mail,Group,TimeZone,ChatBubbleWarning,Bell,DatabaseSettings,MoreHorizCircle} from 'iconoir-react';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
function Configuration({changeNavState}) {
  var sessionData = cookies.get('sessionData', { path: '/' })
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    changeNavState('Configurations');
  }, [])

  return (
    <>
    <HeaderPage
        title1={"Configurations"}
        title2={"Configurations"}
        loadingState={isLoading}
    />
    <div style={{marginTop:'4vw', marginRight:'1vw'}} className='row'>
      {sessionData.int_ext == 1 && parseInt(sessionData.apps_roles_id) < 3 &&( <>
        <ConfigIcons Icon={PageFlip} name={"Ticket"} link={"/configuration/ticket"}/>
        </>
      )}
      {sessionData.int_ext == 1 && parseInt(sessionData.apps_roles_id) < 4 &&( <>
        <ConfigIcons Icon={UserStar} name={"Tenant"} link={"/configuration/tenant"}/>  
        </>
      )}
      {sessionData.int_ext == 1 && parseInt(sessionData.apps_roles_id) < 3 &&( <>
        <ConfigIcons Icon={SoundMin} name={"Announcement"} link={"/configuration/announcement"}/>
        {/* 
        <ConfigIcons Icon={Mail} name={"Email"} link={"/dashboard"}/>
        <ConfigIcons Icon={TimeZone} name={"Submit Hour"} link={"/dashboard"}/>
        <ConfigIcons Icon={DatabaseSettings} name={"Storage File Limit"} link={"/dashboard"}/>
        
        <ConfigIcons Icon={MoreHorizCircle} name={"Others"} link={"/dashboard"}/>
        */}
        </>
      )}
      </div>
    </>
  )
}

export default Configuration