import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import useOrientation from '../../../general/useOrientation';
const cookies = new Cookies();

function MobileDetailTabbing({selectedTab, handleTabClick}) {
  var sessionData = cookies.get('sessionData', { path: '/' })
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait') { isThisPortrait = true;}
  if (orientation === 'portrait')
  return (
    <div className='row' style={{ position:'relative', zIndex:'2',borderBottom: '1px solid #E8E9EB', width: '112%', marginTop: '-2vh'}}>
      <div style={{width:'50%'}} className={`tabbingMobileDetail ${selectedTab === 'Conversation' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Conversation')}>
        Conversation
      </div>
      <div style={{width:'50%'}} className={`tabbingMobileDetail ${selectedTab === 'Details' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Details')}>
        Details
      </div>
    </div>
  )
  else 
  return (<></>)

  
}

export default MobileDetailTabbing;