import React, { useState, useEffect } from 'react';
import useOrientation from '../../../general/useOrientation';

function OtherAttachment({ key,id, type, source, handlePdfClick, fileName , clearHandler,isDraft="0"}) {
  const [displayedType, setDisplayedType] = useState(type || 'other');
  const marginTopStyle = Math.floor((id - 1) / 4) * 11 + 'vw';
  const marginTopStyleM = Math.floor((id - 1) / 3) * 28 + 'vw';
  const fileExtension = fileName.split('.').pop();

  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait')
    isThisPortrait = true;

  useEffect(() => {
    // Update displayedType whenever the 'type' prop changes
    setDisplayedType(type || 'other');
  }, [type]);

  const imageSource = `/dist/img/filesIcon/${displayedType}.png`;

  return (
    <div className={isThisPortrait?'col-4 cursorPointer':'col-md-3 cursorPointer'} style={{marginTop:isThisPortrait?'-4vw':'-2vw'}}>
      <div
        style={{
          width: isThisPortrait? '25vw' : '9.375vw',
          height: isThisPortrait? '25vw' :'9.375vw',
          position: 'absolute',
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          border: '2px solid #E8E9EB',
          marginTop: isThisPortrait?marginTopStyleM:marginTopStyle
        }}
        onClick={() => handlePdfClick(source)}
      >
        <img style={{ color: '#E8E9EB', width: isThisPortrait?'5vh' :'2vw', height: isThisPortrait?'5vh' :'2vw' }} src={imageSource} alt="icon" />
        <p style={{ margin: '1vw 1vw 0vw 1vw', fontSize: '15px' }}>
        {fileName.split('.').slice(0, -1).join('.').substring(0, 10)}{fileName.length > 10 ? '...' : ''}
        </p>
        <p style={{ fontSize: '15px' }}>.{fileExtension}</p>
      </div>
      {isDraft =="1" &&(
        <button style={{marginLeft:isThisPortrait?'0vh' :'2vw',marginTop:isThisPortrait?'17vh' : '10.5vw', display:'flex', alignItems:'center',justifyContent:'center'}} className="btn btnSubmitTicketMobile" type="button" onClick={() => clearHandler(fileName)}>
        Remove
        </button>
      )}
    </div>
  );
}

export default OtherAttachment;
