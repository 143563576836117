import React from 'react'; import axios from 'axios' ;
import ContentHeader from '../../content/ContentHeader'; import { useState, useEffect} from 'react';
import Cookies from 'universal-cookie'; 
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
import BootstrapTable from 'react-bootstrap-table-next'
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import NoPage from '../NoPage';
const cookies = new Cookies();
const MySwal = withReactContent(Swal)
const { SearchBar } = Search;

const actionFormatter = (data,row) => {
  if (data <= 15){
    return <>(cannot edit/delete)</>
  } else {   
    return <>
    <a title="edit" href={`/editAPD/`+ data} className="btn btn-warning">
      <i className="fa fa-edit" aria-hidden="true"></i>
    </a> &nbsp;
    <button title="delete" onClick={() => handleDelete(data)} className="btn btn-warning">
      <i className="fa fa-trash"></i>
    </button>
    </>
  }  
}
  
const handleDelete = (id) => {
  var sessionToken = cookies.get('sessionToken', { path: '/' })
  var path = process.env.REACT_APP_PATH_DESTINATION + "/deleteApprovalDetail/" +id;   
  const config = {headers:{['x-access-token']: sessionToken}};
  axios.get(path , config)
  .then(function (response) {
    if (response.data.status == '200')
      MySwal.fire({
        title: <strong>Success!</strong>,
        html: <p>{response.data.message}</p>,
        icon: 'success'
      }).then(() => {
        window.location.reload(true);
      })
    else 
      MySwal.fire({
        title: <strong>Failed!</strong>,
        html: <p>{response.data.message}</p>,
        icon: 'error'
      }).then(() => {
        window.location.reload(true);
      })
  })
  .catch(function (error) {
      console.log(error);
  });
}

  const columns = [
    {
      text: 'No.',
      dataField: 'sl.no',
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1;
      },
      sort: true,
    },
    {
      text: 'Approval Detail Name',
      dataField: 'approval_detail_name',
      sort:true
    },
    {
      text: 'Approval To',
      dataField: 'fullname',
      sort:true
    },
    {
      text: 'Action',
      dataField: 'id',
      sort:true,
      formatter: actionFormatter
    }
  
  ];

  const options = {
    sortIndicator: true,
    bordered: false,
    hideSizePerPage: true,
    sizePerPage: 8,
    page: 1
  };
  
  const searchProps = {
    delay: 800,
    placeholder: "search table...",
    className: "searchRight"
  };
  
  const defaultSorted = [
    {
      dataField: "order",
      order: "desc"
    }
  ];
function ApprovalDetailList() {
  const [dataa, setDataa] = useState([]);
  useEffect(() => {
    getData();
  }, [])
  
  const getData = () => {
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    axios.interceptors.request.use(config => {config.headers['x-access-token'] = sessionToken;return config;},error => {return Promise.reject(error);});
    axios.get(`${process.env.REACT_APP_PATH_DESTINATION}/approvalDetailList`) 
      .then(res => {
        console.log(res.data.data);
        setDataa(res.data.data);
      })
      .catch(err => {
        console.log(err);
      })
  }    
  var data = cookies.get('sessionData', { path: '/' })
  if (data.apps_roles_id > 3) {
    return ( <NoPage />)
  }
  return (
    <>
        <ContentHeader title="Approver Settings"/> 
        <section className="content">
        <div className="container-fluid">
            <div className="row"> 
              <div className="col-md-12">
              
                <ToolkitProvider
                  keyField="id"
                  data={dataa}
                  columns={columns}
                  bootstrap4={true}
                  search={searchProps}
                >
                  {props => (
                    <div>
                      <div style={{width: '200px', float:'left'}}>
                      <a className="btn btn-primary" href="addNewAPD" role="button">Add New Approval</a>
                      </div>
                      <div style={{width: '200px', float:'right'}}>
                      <table>
                        <tbody>
                        <tr>
                          <td><i className="fa fa-search" aria-hidden="true"></i></td>
                          <td><SearchBar {...props.searchProps} {...searchProps} /></td>
                        </tr>
                        </tbody>
                      </table>
                      </div>
                      
                      <BootstrapTable
                        {...props.baseProps}
                        striped 
                        hover
                        pagination={paginationFactory({
                          sizePerPage: 25 
                        })}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default ApprovalDetailList