import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import {Cancel, SendDiagonal,WarningCircle} from 'iconoir-react';
import TicketDropdown from '../../createTicket/TicketDropdown';
import ReactQuill from 'react-quill';
import LoadingSpinner from '../../../general/LoadingSpinner';
import OverlaySubmit from '../../../general/OverlaySubmit';
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
import axios from 'axios' ; import Cookies from 'universal-cookie';
import OverlayPopup from '../../../general/OverlayPopup';
import SwitchOnOff from '../../../general/SwitchOnOff';
import DatePickerAnc from './DatePickerAnc';
import useOrientation from '../../../general/useOrientation';

const cookies = new Cookies();
const MySwal = withReactContent(Swal)

function AnnouncementCreate({data,isEdit=false,closePopup,show,hide}) {
  const navigate = useNavigate();
  const [intExt, setIntExt] = useState("");
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [cat, setCat] = useState("");
  const [extGroup, setExtGroup] = useState("");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [popup, setPopup] = useState(false);
  const [popupImageUrl, setPopupImageUrl] = useState("");
  const [errSubmitText, setErrSubmitText] = useState('');
  const [usePublish, setUsePublish] = useState(false);
  const [startPublish, setStartPublish] = useState('');
  const [endPublish, setEndPublish] = useState('');
  const [createDiv, setCreateDiv] = useState(true);
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait') { isThisPortrait = true;}
  useEffect(() => {
    if (isEdit) { 
      setIntExt(data.internal);
      setTitle(data.title);
      setDesc(data.konten);
      setCat(data.announce_kategori_id);
      setExtGroup(data.external_group_id);
      setStartPublish(data.start_publish);
      setEndPublish(data.end_publish);
      //console.log("data =", data);
      //alert("data.startPublish = " + data.start_publish )
      if (data.start_publish && String(data.start_publish) !== "" && String(data.start_publish) !== "0000-00-00 00:00:00"){
        setUsePublish(true);
      }
    }
  }, [data,isEdit])

  // Set a maximum size limit for 'desc' including base64-encoded images (in kilobytes)
  const maxDescSizeKB = process.env.REACT_APP_MAX_INLINE_IMAGE_SIZE; // Adjust the limit according to your requirements

  // Function to estimate the size of a base64 string in kilobytes
  const getBase64Size = (base64String) => {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
    const byteSize = base64.length / 4 * 3 - padding.length;
    return byteSize / 1024; // Convert to kilobytes
  };

  function isDescEmpty(desc) {
    // Remove content inside angle brackets
    const strippedContent = desc.replace(/<[^>]*>/g, '').trim();
  
    // Check if the stripped content is empty
    return strippedContent === '';
  }

  const pickDropdownIntExt = (val) => {
    setIntExt(val.value);
  }

  const pickDropdownCat = (val) => {
    setCat(val.value);
  }

  const pickDropdownExtGroup = (val) => {
    let tmp = "";
    for(let i = 0 ; i < val.length; i++){
      tmp += val[i].value;
      if (i < val.length - 1) {
        tmp += ",";
      }
    }
    setExtGroup(tmp);
  }

  const handleDescChange = (value) => {
    setDesc(value);
  }

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'header': [1, 2, 3, 4, 5, 6] }],
      [{ 'align': [] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['link', 'image'],
    ],
  };
   
  const createAnc = () => { 
    const descSizeKB = getBase64Size(desc);
    
    if (descSizeKB > maxDescSizeKB) {
      hide();
      setCreateDiv(false);
      MySwal.fire({
        title: '<strong>Image is too big!</strong>',
        html: `<p>Image inside in the body text is too big. Please reduce the image size or use attach file. Max image size is : ${maxDescSizeKB} kb </p>`,
      }).then((result) => {
        show();
        setCreateDiv(true);
      });
      return;
    }

    var sessionToken = cookies.get('sessionToken', { path: '/' });
    const config = { headers: { 'x-access-token': sessionToken } };
    var apiPath = String(process.env.REACT_APP_PATH_DESTINATION);
    if (isEdit === true) apiPath += "/editAnnouncement" 
    else apiPath += "/addAnnouncement"
    // Create a FormData object
    const formData = new FormData();
  
    // Append other data to the FormData object
    formData.append('intExt', intExt);
    formData.append('category', cat);
    formData.append('extGroup', extGroup);
    formData.append('title', title);
    formData.append('konten', desc);
    formData.append('usePublish', usePublish);
    formData.append('startPublish', startPublish);
    formData.append('endPublish', endPublish);
    if (isEdit === true) formData.append('id', data.id);

    var emptyString = "";

    if (intExt == ''){
      if (emptyString != ''){emptyString += ", "}
      emptyString += "Target";
    }
    if (extGroup == '' && intExt == 2){
      if (emptyString != ''){emptyString += ", "}
      emptyString += "External Group";
    }
    if (cat == ''){
      if (emptyString != ''){emptyString += ", "}
      emptyString += "Category";
    }
    if (title == ''){
      if (emptyString != ''){emptyString += ", "}
      emptyString += "Subject";
    }
    if (desc == '' || isDescEmpty(desc)){
      if (emptyString != ''){emptyString += ", "}
      emptyString += "Description";
    }
    if (usePublish == true && (startPublish == '0000-00-00 00:00:00' || startPublish == '')){
      if (emptyString != ''){emptyString += ", "}
      emptyString += "Start Publish Date";
    }
    if (usePublish == true && (endPublish == '0000-00-00 00:00:00' || endPublish == '')){
      if (emptyString != ''){emptyString += ", "}
      emptyString += "End Publish Date";
    }
    if (emptyString !== "") {
      emptyString += " cannot be empty." 
      setErrSubmitText(emptyString);
      setLoadingSubmit(false)
      return
    }
    axios.post(apiPath, formData, config)
      .then(res => {
        console.log(res.data);
        if (res.data.status === "200") {
          setPopupImageUrl("/SuccessMessage.png");
          setPopup(true);
          setLoadingSubmit(false)
        } else {
          setPopupImageUrl("/FailMessage.png");
          setPopup(true);
          setLoadingSubmit(false)
        }
      })
      .catch(error => {
        console.error("Error uploading files:", error);
        setPopupImageUrl("/FailMessage.png");
        setPopup(true);
        setLoadingSubmit(false)
      });

  }
  const togglePublish = () => { 
    setUsePublish(!usePublish)
  }
  const pickPublishStartDate = (val) => {
    val = new Date(val)  
    const year = val.getFullYear();
    const month = val.getMonth() + 1;
    const day = val.getDate();

    // Constructing the yyyy-mm-dd format
    const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
    setStartPublish(formattedDate);
  };

  const pickPublishEndDate = (val) => {
    val = new Date(val)  
    const year = val.getFullYear();
    const month = val.getMonth() + 1;
    const day = val.getDate();

    // Constructing the yyyy-mm-dd format
    const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
    setEndPublish(formattedDate);
  };
  const deleteAnc = () => {
    closePopup();
    setLoadingDelete(true)
    MySwal.fire({
      title: '<strong>Warning</strong>',
      html: `<p>Confirm to Delete Announcement ${data.title}? You cannot undo this action</p>`,
      icon: 'warning',
      width:'50vw',
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: 'Back',
      confirmButtonText: 'Proceed',
    }).then(result => {
      if (result.isConfirmed) {
          var sessionToken = cookies.get('sessionToken', { path: '/' });
          const config = { headers: { 'x-access-token': sessionToken } };
          const apiPath = process.env.REACT_APP_PATH_DESTINATION + "/deleteAnnouncement";
          const formData = {
              id: data.id
          }
          axios.post(apiPath, formData, config)
          .then(res => {
            MySwal.fire({
              title: <strong>Success!</strong>,
              html: <p>Announcement is Deleted.</p>
            })
            setLoadingDelete(false)
          })
          .catch(err => {
              console.log(err);
                  MySwal.fire({
                  title: <strong>Error!</strong>,
                  html: <p>{err}</p>
              })
              setLoadingDelete(false)
          })
      } else {
          MySwal.close()
          setLoadingDelete(false)
      }
  })
  }
  const handlePopupClose = () => {
    if (popupImageUrl === "/SuccessMessage.png" || popupImageUrl === "/SuccessDraft.png" ) {
      navigate("/dashboard")
    } else {
      setPopup(false);
    }
  };

  return (<>
    {createDiv && (
      <div >
      {popup && (
        <OverlayPopup
          imageUrl={popupImageUrl}
          message={popupImageUrl}
          onClose={handlePopupClose}
        />
      )}
      {(loadingSubmit || loadingDelete) && (
        <OverlaySubmit />
      )}
      <div className={isThisPortrait?"popupContentMobile":"popupContent"} style={{width:isThisPortrait?"90vw": '640px', marginTop:'auto', height:isThisPortrait?'80vh':''}}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <p style={{fontSize:'20px'}}><b>Add Announcement</b></p>
          <Cancel onClick={closePopup} className="cursorPointer" style={{marginTop:'-20px'}}/>
        </div>
        <TicketDropdown defValue={isEdit? intExt : ""} pickDropdown={pickDropdownIntExt} type={6} title="Target" link='/intExt' />
        <TicketDropdown defValue={isEdit? cat : ""}pickDropdown={pickDropdownCat} type={2} title="Category" link='/categoryList' />
        {intExt == 2 && (
          <TicketDropdown defValue={isEdit? extGroup : ""} isMulti={true} pickDropdown={pickDropdownExtGroup} type={7} title="External Group" link='/extGroup' />
        )}
        <p style={{ marginTop: '1vw',fontFamily:'Blender Pro' }}>Subject</p>
          <input
            placeholder='Input Fields'
            className={isThisPortrait?'inputTextMobile':'inputText'}
            type='text'
            style={{ marginTop: '-2.5vw' }}
            value={title}
            onChange={handleTitleChange}
          />
          <br />
          <br />
          <p style={{fontFamily:'Blender Pro'}} >Description</p>
          <div>
            <ReactQuill
              value={desc}
              onChange={handleDescChange}
              modules={modules}
              className='inputText'
              style={{minHeight:'10vw'}}
            />
          </div>
          <div style={{fontFamily:'Blender Pro', marginTop:'1vw'}}>
            Announcement Time Range &nbsp; <SwitchOnOff isSwitchOn={usePublish} click={togglePublish}/>
          </div>
          {usePublish && (
            <div className={isThisPortrait?'':'row'} style={{marginTop:'1vw', fontFamily:'Blender Pro'}}>
              {isThisPortrait && (
                <><br/></>
              )}
              <div className='col-md-6' style={{fontSize:'15px'}}>
                Start Publish Date <DatePickerAnc defValue={startPublish} setTime={pickPublishStartDate}/>
              </div>
              {isThisPortrait && (
                <><br/></>
              )}
              <div className='col-md-6' style={{fontSize:'15px'}}>
                End Publish Date <DatePickerAnc defValue={endPublish} setTime={pickPublishEndDate}/>
              </div>
            </div>
          )}

          {errSubmitText && (
            <div style={{marginLeft: '0vw',marginTop:'1vw',marginBottom:'0vw'}}>
              <p style={{fontSize:'1.2vw'}}>
              <WarningCircle width="16px" color="red" />
                &nbsp;&nbsp;&nbsp;
                <font color="red">{errSubmitText}</font>
              </p>
            </div>
          )}
          <div style={{float:'right', marginTop: '1vh'}}>
            {isEdit && (
              <>
                <a style={{fontSize:'15px'}} className="draftClass cursorPointer" onClick={deleteAnc}>
                  {loadingDelete && (
                    <LoadingSpinner />  
                  )} &nbsp; Delete
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </>
            )}
            <button className="btn btnSubmitTicket" type="button" onClick={createAnc}>
              {loadingSubmit ? (
                <LoadingSpinner /> 
              ) : (
                <SendDiagonal /> 
              )} Publish
              
            </button>
          </div>
      </div>
      </div>
    )}
    </>
  )
}

export default AnnouncementCreate