import React from 'react';
import 'react-form-builder2/dist/app.css';
import Builder from './Builder';
import BuilderRO from './BuilderRO';
import TableGen from './TableGen';

function FormToHtmlDouble({ levelFiller, formData, dataValue, rulesValue, onFileUpload, onReupload }  ) {
  try {rulesValue = JSON.parse(rulesValue)} catch {rulesValue = {}};
  var formDataParsed;
  var dataValueParsed;
  try {
    formDataParsed = JSON.parse(formData);
    //console.log("datavalue logging");console.log(dataValue);
    dataValueParsed = dataValue && dataValue.length > 0 ? JSON.parse(dataValue) : [];
  } catch { return (<> Form is empty. Please contact your administrator <br/> <br/> </>);};
  
  const formElements = formDataParsed.map((element, index) => (
    
    <div key={element.id}>
      {(() => {
        try {
          //const value = rulesValue["select_" + index];
          //console.log("index logging = " + index);
          //console.log("rulesValue[] logging = " + rulesValue["select_" + index]);
          if (rulesValue["select_" + index] === undefined) {
            rulesValue["select_" + index] = 0;
          }
        } catch (error) {
          rulesValue["select_" + index] = "0";
        }


        if (rulesValue["select_" + index] == levelFiller){
          //console.log("log index " + index + " enter if, means editable");
          switch (element.element) {
            case 'Header':
              return Builder.renderHeader(element);
            case 'TextInput':
              return Builder.renderTextInput(element, dataValueParsed[element.field_name] || '');
            case 'Checkboxes':
              return Builder.renderCheckboxes(element, dataValueParsed);
            case 'Label':
              return Builder.renderLabel(element);
            case 'Paragraph':
              return Builder.renderParagraph(element);
            case 'LineBreak':
              return Builder.renderLineBreak();
            case 'Dropdown':
              return Builder.renderDropdown(element, dataValueParsed[element.field_name] || '');
            case 'Tags':
              return Builder.renderTags(element, dataValueParsed[element.field_name] || '');
            case 'RadioButtons':
              return Builder.renderRadioButtons(element, dataValueParsed[element.field_name] || '');
            case 'EmailInput':
              return Builder.renderEmailInput(element, dataValueParsed[element.field_name] || '');
            case 'NumberInput':
              return Builder.renderNumberInput(element, dataValueParsed[element.field_name] || '');
            case 'PhoneNumber':
              return Builder.renderPhoneNumber(element, dataValueParsed[element.field_name] || '');
            case 'TextArea':
              return Builder.renderTextArea(element, dataValueParsed[element.field_name] || '');
            case 'Image':
            case 'Camera':
              return Builder.renderImage(element, dataValueParsed[element.field_name] || '', dataValueParsed['loc_'+element.field_name] || '', dataValueParsed, onReupload, onFileUpload);
            case 'Rating':
              return Builder.renderRating(element, dataValueParsed[element.field_name] || '');
            case 'DatePicker':
              return Builder.renderDatePicker(element, dataValueParsed[element.field_name] || '');
            case 'Signature':
              return <Builder.Signature label={element.label} name={element.id} width={400} heigth={200} initialValue={dataValueParsed[element.id]}/>;
            case 'HyperLink':
              return Builder.renderHyperLink(element);
            case 'Download':
            case 'FileUpload':
              if (typeof dataValueParsed[element.field_name] !== "string") 
                dataValueParsed[element.field_name] = ""; // Set it to an empty string
              return Builder.renderDownload(element, dataValueParsed[element.field_name] || '', dataValueParsed['loc_'+element.field_name] || '', dataValueParsed, onReupload, onFileUpload);
            case 'Range':
              return Builder.renderRange(element, dataValueParsed[element.field_name] || '');
            case 'TwoColumnRow':
            case 'ThreeColumnRow' :
            case 'MultiColumnRow' :
              return <TableGen element={element} defaultValue={dataValueParsed[element.id]} />
            default:
              return null;
          }
        } else {
          //console.log("log index " + index + " enter else , means NON editable");
          switch (element.element) {
            case 'Header':
              return BuilderRO.renderHeader(element);
            case 'TextInput':
              return BuilderRO.renderTextInput(element, dataValueParsed[element.field_name] || '');
            case 'Checkboxes':
              return BuilderRO.renderCheckboxes(element, dataValueParsed);
            case 'Label':
              return BuilderRO.renderLabel(element);
            case 'Paragraph':
              return BuilderRO.renderParagraph(element);
            case 'LineBreak':
              return BuilderRO.renderLineBreak();
            case 'Dropdown':
              return BuilderRO.renderDropdown(element, dataValueParsed[element.field_name] || '');
            case 'Tags':
              return BuilderRO.renderTags(element, dataValueParsed[element.field_name] || '');
            case 'RadioButtons':
              return BuilderRO.renderRadioButtons(element, dataValueParsed[element.field_name] || '');
            case 'EmailInput':
              return BuilderRO.renderEmailInput(element, dataValueParsed[element.field_name] || '');
            case 'NumberInput':
              return BuilderRO.renderNumberInput(element, dataValueParsed[element.field_name] || '');
            case 'PhoneNumber':
              return BuilderRO.renderPhoneNumber(element, dataValueParsed[element.field_name] || '');
            case 'TextArea':
              return BuilderRO.renderTextArea(element, dataValueParsed[element.field_name] || '');
            case 'Image':
            case 'Camera':
              return BuilderRO.renderImage(element, dataValueParsed[element.field_name] || '', dataValueParsed['loc_'+element.field_name] || '', dataValueParsed);
            case 'Rating':
              return BuilderRO.renderRating(element, dataValueParsed[element.field_name] || '');
            case 'DatePicker':
              return BuilderRO.renderDatePicker(element, dataValueParsed[element.field_name] || '');
            case 'Signature':
              return <BuilderRO.Signature label={element.label} name={element.id} width={400} heigth={200} initialValue={dataValueParsed[element.id]}/>;
            case 'HyperLink':
              return BuilderRO.renderHyperLink(element);
            case 'Download':
            case 'FileUpload':
              return BuilderRO.renderDownload(element, dataValueParsed[element.field_name] || '', dataValueParsed['loc_'+element.field_name] || '', dataValueParsed);
            case 'Range':
              return BuilderRO.renderRange(element, dataValueParsed[element.field_name] || '');
            case 'TwoColumnRow':
            case 'ThreeColumnRow' :
            case 'MultiColumnRow' :
              return <TableGen element={element} defaultValue={dataValueParsed[element.id]} />
            default:
              return null;
          }
        }

      })()}
    </div>
  ));
  return (<>{formElements}</>);
  
  
}

export default FormToHtmlDouble;