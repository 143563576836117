import React from 'react'; import { useState, useEffect} from 'react';
import Submenu from './../submenu/Submenu'
import Cookies from 'universal-cookie';
import { LogOut as LogoutIcon, HomeSimpleDoor, PageFlip, Page, SoundMin, ReportColumns, HelpCircle, Settings, Binocular } from 'iconoir-react';
import NavbarHeader from './NavbarHeader';
import { MobileView } from 'react-device-detect';
import useOrientation from '../general/useOrientation';
export default function Menubar({navState,changeNavState}) {
  const cookies = new Cookies();
  const orientation = useOrientation();
  //console.log("menubar start logging")
  var data = cookies.get('sessionData', { path: '/' })
  var username = "-";
  if (data) username = data.fullname
  if (data.apps_roles_id == 1 || data.apps_roles_id == 2) { //superadmin
    return (
      <> 
        <aside className="main-sidebar leftSidebar" >
          <NavbarHeader />
          <div className="sidebar" style={{marginLeft:'1vw'}}>
            <nav className="mt-2">
              <ul className="nav nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <Submenu name="Dashboard" Icon={HomeSimpleDoor} link="/dashboard" state={navState} clickAction={changeNavState}/>
                <Submenu name="Ticket" Icon={PageFlip} link="/ticketList" state={navState} clickAction={changeNavState}/>
                <Submenu name="Document" Icon={Page} link="/documentList" state={navState} clickAction={changeNavState}/>
                <Submenu name="Announcement" Icon={SoundMin} link="/announcement" state={navState} clickAction={changeNavState}/>
                <Submenu name="Report" Icon={ReportColumns} link="/reports" state={navState} clickAction={changeNavState}/>
                <Submenu name="Help" Icon={HelpCircle} link="/faq" state={navState} clickAction={changeNavState}/>
                <Submenu name="Configurations" Icon={Settings} link="/configuration" state={navState} clickAction={changeNavState}/>
                <Submenu name="Audit Trail" Icon={Binocular} link="/audit" state={navState} clickAction={changeNavState}/>
                {orientation === 'portrait' && (
                  <Submenu name="Logout" Icon={LogoutIcon} link="/logout" state={navState} clickAction={changeNavState}/>
                )} 
              </ul>
            </nav>
          </div>
        </aside>
      </>
      )
  } else if (data.apps_roles_id == 3) { //cs / admin
    return (
      <> 
      <aside className="main-sidebar leftSidebar">
          <NavbarHeader />
          <div className="sidebar">
            <nav className="mt-2">
              <ul className="nav nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <Submenu name="Dashboard" Icon={HomeSimpleDoor} link="/dashboard"  state={navState} clickAction={changeNavState}/>
                <Submenu name="Ticket" Icon={PageFlip} link="/ticketList" state={navState} clickAction={changeNavState}/>
                <Submenu name="Document" Icon={Page} link="/documentList" state={navState} clickAction={changeNavState}/>
                <Submenu name="Announcement" Icon={SoundMin} link="/announcement" state={navState} clickAction={changeNavState}/>
                <Submenu name="Report" Icon={ReportColumns} link="/reports" state={navState} clickAction={changeNavState}/>
                <Submenu name="Help" Icon={HelpCircle} link="/faq" state={navState} clickAction={changeNavState}/>
                <Submenu name="Configurations" Icon={Settings} link="/configuration" state={navState} clickAction={changeNavState}/>
                {orientation === 'portrait' && (
                  <Submenu name="Logout" Icon={LogoutIcon} link="/logout" state={navState} clickAction={changeNavState}/>
                )} 
              </ul>
            </nav>
          </div>
        </aside>
      </>
      )
  } else if (data.apps_roles_id == 4) { //internal 
    return (
  <> 
    <aside className="main-sidebar leftSidebar">
      <NavbarHeader />
      <div className="sidebar">
        <nav className="mt-2">
          <ul className="nav nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
              <Submenu name="Dashboard" Icon={HomeSimpleDoor} link="/dashboard" state={navState} clickAction={changeNavState}/>
              <Submenu name="Ticket" Icon={PageFlip} link="/ticketList" state={navState} clickAction={changeNavState}/>
              <Submenu name="Document" Icon={Page} link="/documentList" state={navState} clickAction={changeNavState}/>
              <Submenu name="Announcement" Icon={SoundMin} link="/announcement" state={navState} clickAction={changeNavState}/>
              <Submenu name="Report" Icon={ReportColumns} link="/reports" state={navState} clickAction={changeNavState}/>
              <Submenu name="Help" Icon={HelpCircle} link="/faq" state={navState} clickAction={changeNavState}/>
              {orientation === 'portrait' && (
                <Submenu name="Logout" Icon={LogoutIcon} link="/logout" state={navState} clickAction={changeNavState}/>
              )} 
          </ul>
        </nav>
      </div>
    </aside>
  </>
      )
  } else  { //external
    return (
  <> 
    <aside className="main-sidebar leftSidebar">
      <NavbarHeader isPopup={true}/>
      <div className="sidebar">
        <nav className="mt-2">
          <ul className="nav nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
              <Submenu name="Dashboard" Icon={HomeSimpleDoor} link="/dashboard" state={navState} clickAction={changeNavState}/>
              <Submenu name="Ticket" Icon={PageFlip} link="/ticketList" state={navState} clickAction={changeNavState}/>
              <Submenu name="Document" Icon={Page} link="/documentList" state={navState} clickAction={changeNavState}/>
              <Submenu name="Announcement" Icon={SoundMin} link="/announcement" state={navState} clickAction={changeNavState}/>
              <Submenu name="Help" Icon={HelpCircle} link="/faq" state={navState} clickAction={changeNavState}/>
              {orientation === 'portrait' && (
                <Submenu name="Logout" Icon={LogoutIcon} link="/logout" state={navState} clickAction={changeNavState}/>
              )}
          </ul>
        </nav>
      </div>
    </aside>
  </>
      )
  }
}
