import React, { useState , useEffect } from 'react';
import '../../../scripts/custom.css';
import { WarningCircle } from 'iconoir-react';
import useOrientation from '../../general/useOrientation';
function ResetComp({isLoading, changeScreen, handleSubmit, forgotpassEmail, setForgotPassEmail, login, errorMsg}) {
  // Add state variables to track form submission and email field
  const [submittedEmail, setSubmittedEmail] = useState(false);
  const orientation = useOrientation();
  useEffect(() => {
    setSubmittedEmail(true);
  }, [])
  // Function to handle form submission
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (forgotpassEmail.length == 0) {
      setSubmittedEmail(false);
      return;
    } else {
      setSubmittedEmail(true);
    }
    handleSubmit(e)
  };
  if (orientation === 'landscape')
  return (
    <div className="">
    <div className="row" style={{maxHeight: '100vh', maxWidth:'100vw'}}  >
      <div className="col-sm-6 col-md-6">
        <div className="hold-transition login-page" style={{ backgroundColor: 'white' }}>
          <img src="/dist/img/logo.png" className="rounded" alt="logo" style={{ width: '16vw', height: 'auto', position: 'absolute', top: '0', left: '0' , padding:'2%',marginLeft:'-1.5vw'}} />
          {login}
            <div className="login-box">
              <div className="" style={{paddingTop:"20px"}}>
                <div className="card-body login-card-body">
                <div className="login-logo" style={{textAlign: 'left'}}>
                <p style={{fontFamily:'Blender Pro',color:'#404041', marginBottom:'2vw', fontSize:'',fontWeight:'500'}}><b>Reset Password </b></p>
                </div>
                  <form onSubmit={handleFormSubmit}>
                  <div className="bottomPad" ><b>Email</b> <br/></div>
                    <div className="input-group mb-3">
                      <input  type="text" className="form-control hdInput" placeholder="Input Email" name="email" autoComplete="off" value={forgotpassEmail} onChange={(e) => setForgotPassEmail(e.target.value)}/>
                    </div>
                   {/* Display email error message only if submitted and email is empty */}
                    {!submittedEmail && (
                      <div style={{ textAlign: 'left', width: '100%', marginTop: '-10px' }}>
                        <p>
                        <WarningCircle width="16px" color="red" />
                          &nbsp;&nbsp;&nbsp;
                          <font color="red">Email is required</font>
                        </p>
                        <div style={{ paddingBottom: '3px' }}></div>
                      </div>
                    )}
                    <div style={{ textAlign: 'left', width: '100%' }}>
                      {errorMsg.length > 0 && (
                        <div className="input-group mb-3 font-befa">
                          <a className="form-control hdErrorInput">
                            <WarningCircle width="16px" color="red" /> {errorMsg} {/*Please Input valid email and password*/}
                          </a>
                        </div>
                      )}
                      <div style={{ paddingBottom: "3px" }} ></div>
                    </div>
                    <br/>
                    <div className="row">
                      <div className="col-12">
                        <button type="submit" className="btn btn-primary btn-block font-befa" style={{ borderRadius: "8px", backgroundColor: "#0296D2"}} >Send Request</button>
                      </div>
                    </div>
                    <br/>
                  </form>
                  <div style={{ textAlign: 'center', width: '100%' }}>
                    <a onClick={changeScreen}>Cancel</a>
                  </div>
                  <div className="col-md-6 custom-footer">
                    <p style={{fontSize:'1vw'}}>By signing in you understand and acknowledge <a href="/privacyPolicy">Privacy Policy</a></p>
                  </div>
                </div>
              </div>
              <div id="requirement" />
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-6" style={{ backgroundImage: `url("/dist/img/loginpage.jpg")`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
          {/* Content inside the div */}
        </div>
    </div>
  </div>
  )

  if (orientation === 'portrait')
  return (
    <div className="">
    <div className="row" style={{maxHeight: '100vh', maxWidth:'100vw'}}  >
      <div className="col-sm-6 col-md-6">
        <div className="hold-transition login-page" style={{ backgroundColor: 'white' }}>
          <img src="/dist/img/logo.png" className="rounded" alt="logo" style={{ width: 'auto', height: '15vh', position: 'absolute', top: '0', left: '0' , padding:'2%',marginLeft:'-2vh', marginTop:'5vh'}} />
          {login}
            <div className="login-box">
              <div className="" style={{paddingTop:"20px"}}>
                <div className="card-body login-card-body">
                <div className="login-logo" style={{textAlign: 'left'}}>
                <p style={{fontFamily:'Blender Pro',color:'#404041', marginBottom:'2vw', fontSize:'',fontWeight:'500'}}><b>Reset Password </b></p>
                   <br/>
                </div>
                  <form onSubmit={handleFormSubmit}>
                  <div className="bottomPad" ><b>Email</b> <br/></div>
                    <div className="input-group mb-3">
                      <input  type="text" className="form-control hdInput" placeholder="Input Email" name="email" autoComplete="off" value={forgotpassEmail} onChange={(e) => setForgotPassEmail(e.target.value)}/>
                    </div>
                   {/* Display email error message only if submitted and email is empty */}
                    {!submittedEmail && (
                      <div style={{ textAlign: 'left', width: '100%', marginTop: '-10px' }}>
                        <p>
                        <WarningCircle width="16px" color="red" />
                          &nbsp;&nbsp;&nbsp;
                          <font color="red">Email is required</font>
                        </p>
                        <div style={{ paddingBottom: '3px' }}></div>
                      </div>
                    )}

                    <div style={{ textAlign: 'left', width: '100%' }}>
                      {errorMsg.length > 0 && (
                        <div className="input-group mb-3 font-befa">
                          <a className="form-control hdErrorInput">
                            <WarningCircle width="16px" color="red" /> {errorMsg} {/*Please Input valid email and password*/}
                          </a>
                        </div>
                      )}
                      <div style={{ paddingBottom: "3px" }} ></div>
                    </div>

                    <br/>
                    <div className="row">
                      <div className="col-12">
                        <button type="submit" className="btn btn-primary btn-block font-befa" style={{ borderRadius: "8px", backgroundColor: "#0296D2", height : "5vh"}} >Send Request</button>
                      </div>
                    </div>
                    <br/>
                    
                  </form>
                  <div style={{ textAlign: 'center', width: '100%' }}>
                    <a onClick={changeScreen}>Cancel</a>
                  </div>
                  <div className="col-md-6 custom-footer">
                    <p style={{fontSize:'1.8vh'}}>By signing in you understand and acknowledge <a href="/privacyPolicy">Privacy Policy</a></p>
                  </div>
                </div>
              </div>
              <div id="requirement" />
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-6" style={{ backgroundImage: `url("/dist/img/loginpage.jpg")`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
          {/* Content inside the div */}
        </div>
    </div>
  </div>
  )

}

export default ResetComp