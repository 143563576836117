import React from 'react';
import useOrientation from './useOrientation';

const OverlayPopup = ({ imageUrl, message, onClose }) => {
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait')
    isThisPortrait = true;
  return (
    <div className="popup-overlay" onClick={onClose}>
      <img style={{height:isThisPortrait?'250px':'20vw'}} className={isThisPortrait?"popup-img-mobile":"popup-img"} src={imageUrl} alt={message} />
    </div>
  );
};

export default OverlayPopup;
