import React, { useState, useEffect } from 'react';
import useOrientation from '../../../general/useOrientation';

function TimeAgo({ time, right }) {
  const [timeStr, setTimeStr] = useState('');
  const [timeStrM, setTimeStrM] = useState('');
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait') { isThisPortrait = true;}

  const getTimeAgo = (timeDiff) => {
    if (timeDiff < 60) {
      return 'few seconds ago';
    } else if (timeDiff >= 60 && timeDiff < 3600) {
      const mins = Math.floor(timeDiff / 60);
      return mins === 1 ? '1 min ago' : `${mins} mins ago`;
    } else if (timeDiff >= 3600 && timeDiff < 86400) {
      const hours = Math.floor(timeDiff / 3600);
      return hours === 1 ? '1 hour ago' : `${hours} hours ago`;
    } else if (timeDiff >= 86400 && timeDiff < 2592000) {
      const days = Math.floor(timeDiff / 86400);
      return days === 1 ? '1 day ago' : `${days} days ago`;
    } else if (timeDiff >= 2592000 && timeDiff < 31536000) {
      const months = Math.floor(timeDiff / 2592000);
      return months === 1 ? '1 month ago' : `${months} months ago`;
    } else {
      const years = Math.floor(timeDiff / 31536000);
      return years === 1 ? '1 year ago' : `${years} years ago`;
    }
  };
  
  const getTimeAgoM = (timeDiff) => {
    if (timeDiff < 60) {
      return 'moments ago';
    } else if (timeDiff >= 60 && timeDiff < 3600) {
      const mins = Math.floor(timeDiff / 60);
      return mins === 1 ? '1 min ago' : `${mins} mins ago`;
    } else if (timeDiff >= 3600 && timeDiff < 86400) {
      const hours = Math.floor(timeDiff / 3600);
      return hours === 1 ? '1 hr ago' : `${hours} hrs ago`;
    } else if (timeDiff >= 86400 && timeDiff < 2592000) {
      const days = Math.floor(timeDiff / 86400);
      return days === 1 ? '1 day ago' : `${days} days ago`;
    } else if (timeDiff >= 2592000 && timeDiff < 31536000) {
      const months = Math.floor(timeDiff / 2592000);
      return months === 1 ? '1 mth ago' : `${months} mths ago`;
    } else {
      const years = Math.floor(timeDiff / 31536000);
      return years === 1 ? '1 yr ago' : `${years} yrs ago`;
    }
  };

  if (!right){
    right = "-4vw"
  }

  useEffect(() => {
    const currentTime = new Date();
    const timeDate = new Date(time);

    if (isNaN(timeDate)) {
      setTimeStr('Error');
    } else {
      const timeDifference = (currentTime - timeDate) / 1000; // Difference in seconds
      setTimeStr(getTimeAgo(timeDifference));
      setTimeStrM(getTimeAgoM(timeDifference));
    }
  }, [time]);

  if (orientation == 'landscape')
  return (
    <div style={{ position: 'absolute', top: '2.2vw', right: '0.5vw', fontSize: '1.1vw', color: '#0296D2' }}>
      {timeStr}
    </div>
  );
  
  if (orientation == 'portrait')
  return (
    <div style={{ position: 'absolute', top: '0.5vh', right: right, fontSize: '2vh', color: '#0296D2' }}>
      {timeStrM}
    </div>
  );
  
}

export default TimeAgo;
