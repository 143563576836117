import React, { Component } from 'react'; import { useState, useEffect} from 'react';
import ContentHeader from './../content/ContentHeader';import axios from 'axios' 
import Cookies from 'universal-cookie';import DashboardCard from '../content/DashboardCard';
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useNavigate } from 'react-router-dom';

const cookies = new Cookies();
function Dashboard()  {
  var data = cookies.get('sessionData', { path: '/' })
  const [dataReady, setDataReady] = useState(false);
  const [tabContents, settabContents] = useState();
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [dataa, setDataa] = useState([]);
    useEffect(() => {
        getData();
      }, [])

  const dataRequested = {
    "class" : "bg-info",
    "icon" : "fa-calendar-plus",
    "style_bgcolor" : "",
    "style_color" : "",
    "sum" : dataa.sum_requested,
    "statusText" : "Requested",
    "href" : "/dashboard/5/all/0",
    "gotext" : "View"
  }
  const dataOnProgressP = {
    "class" : "",
    "icon" : "fa-calendar",
    "style_bgcolor" : "#9c9cfc",
    "style_color" : "#ffffff",
    "sum" : dataa.sum_on_progress_p,
    "statusText" : "On Progress",
    "href" : "/dashboard/6/all/0",
    "gotext" : "View"
  }
  const dataApprovedP = {
    "class" : "bg-green",
    "icon" : "fa-calendar-check",
    "style_bgcolor" : "",
    "style_color" : "",
    "sum" : dataa.sum_approved_p,
    "statusText" : "Approved",
    "href" : "/dashboard/7/all/0",
    "gotext" : "View"
  }
  const dataRejectedP = {
    "class" : "bg-red",
    "icon" : "fa-calendar-times",
    "style_bgcolor" : "",
    "style_color" : "",
    "sum" : dataa.sum_rejected_p,
    "statusText" : "Rejected",
    "href" : "/dashboard/8/all/0",
    "gotext" : "View"
  }

const getData = () => {
  var sessionToken = cookies.get('sessionToken', { path: '/' })
  const config = {headers:{['x-access-token']: sessionToken}};
  
  //checkPersonalDashboard
  var dbpath = process.env.REACT_APP_PATH_DESTINATION + "/checkPersonalDashboard";   
  axios.get(dbpath , config)
    .then(res => { 
      console.log("res.data.data pdb logging")
      console.log(res.data);
    })

  var path = process.env.REACT_APP_PATH_DESTINATION + "/overviewDoc/" + data.user_id;   

  axios.get(path , config)
    .then(res => {
      setDataa(res.data.data);
      let newRow = {
        label: "ALL",
        content: (<>
            <div className="row" style={{ paddingTop: "5px" }}>
              <DashboardCard data={processData(res.data.data,1)} />
              <DashboardCard data={processData(res.data.data,2)} />
              <DashboardCard data={processData(res.data.data,3)} />
              <DashboardCard data={processData(res.data.data,4)} />
            </div>
          </>
        ),
      };
      var x;
      if (res.data.data.dept_sub.length > 0) {
        x = res.data.data.dept_sub.map(item => ({
          label:item.kode_department,
          content:<>
            <div className="row" style={{paddingTop:"5px"}}>
              <DashboardCard data={processData(item,1)} />
              <DashboardCard data={processData(item,2)} />          
              <DashboardCard data={processData(item,3)} />
              <DashboardCard data={processData(item,4)} />
            </div>
          </>
        }));
        x.unshift(newRow);
      } else {
        x = [newRow];
      }
      settabContents(x);
      setDataReady(true);
    })
    .catch(err => {
      console.log(err);
    })
}   

function processData(item,type)  {
  if (!item.department_id) item.department_id = "all"
  if (!item.kode_department) item.kode_department = "All"
  var dataCard;
  switch(parseInt(type)){
    case 1:
      dataCard = {
        "class" : "bg-warning",
        "icon" : "fa-tasks",
        "style_bgcolor" : "",
        "style_color" : "",
        "sum" : item.sum_pending,
        "statusText" : "Waiting Approval",
        "href" : "/dashboard/1/" + item.department_id+ "/" + item.kode_department,
        "gotext" : "View"
      }
      break;
    case 2:
      dataCard = {
        "class" : "",
        "icon" : "fa-calendar",
        "style_bgcolor" : "#9c9cfc",
        "style_color" : "#ffffff",
        "sum" : item.sum_on_progress_s,
        "statusText" : "On Progress",
        "href" : "/dashboard/2/" + item.department_id+ "/" + item.kode_department,
        "gotext" : "View"
      }
      break;
    case 3:
      dataCard = {
        "class" : "bg-green",
        "icon" : "fa-calendar-check",
        "style_bgcolor" : "",
        "style_color" : "",
        "sum" : item.sum_approved_s,
        "statusText" : "Approved",
        "href" : "/dashboard/3/" + item.department_id+ "/" + item.kode_department,
        "gotext" : "View"
      }
      break;
    case 4: 
    dataCard = {
      "class" : "bg-red",
      "icon" : "fa-calendar-times",
      "style_bgcolor" : "",
      "style_color" : "",
      "sum" : item.sum_rejected_s,
      "statusText" : "Rejected",
      "href" : "/dashboard/4/" + item.department_id+ "/" + item.kode_department,
      "gotext" : "View"
    }
      break;
  }

  return dataCard;
}
return dataReady ? (
    <>
    <ContentHeader title="View All"/> 
    <section className="content">
      <div className="container-fluid">
      <h3 style={{margin: "0px"}}>Subordinate &nbsp;</h3> 
        <Box sx={{ width: "100%" }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            {tabContents.map((tab) => (
              <Tab key={tab.label} label={tab.label} style={{ fontSize: '15px' }}/>
            ))}
          </Tabs>
          {tabContents[value].content}
        </Box>
        <br/>
        <h3>Personal</h3>
        <div className="row">
          <DashboardCard data={dataRequested} />
          <DashboardCard data={dataOnProgressP} />
          <DashboardCard data={dataApprovedP} />
          <DashboardCard data={dataRejectedP} />
        </div>
      </div>
    </section>
  </>
) : (
  <ContentHeader title="Loading..." />
);

  
}
export default Dashboard