import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CommentAdd from '../comment/CommentAdd';
import { Calendar } from 'iconoir-react';
import useOrientation from '../../../general/useOrientation';


function ExtendPick({ setExtendTime, int_or_ext_id, action, cancelHandler, sendHandler, setContent }) {
  const [endDate, setEndDate] = useState(null);
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait') { isThisPortrait = true;}
  const changeDateHandler = (date) => {
    setEndDate(date);
    setExtendTime(date);
  }
  
const CustomDatePickerInput = ({ value, onClick }) => (
  <div style={{ position: 'relative' }}>
    <input
      type="text"
      value={value}
      onClick={onClick}
      placeholder="Select Date"
      readOnly
      className={isThisPortrait?'datePickerM':'datePicker'}
      style={{ paddingRight: '30px' , fontSize:'15px'}} // Adjust the padding to accommodate the icon
    />
    <span
      style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)', cursor: 'pointer' }}
      onClick={onClick}
    >
      <Calendar size={20} color="#737E86" />
    </span>
  </div>
);
  return (
    <div>
      <div style={{ marginLeft: '4vw' }}>
        <p className="txtCreateTicket" style={{ marginTop: '2vw', fontSize:'15px' }}>
          End Date
        </p>
        <DatePicker
          selected={endDate}
          onChange={(date) => changeDateHandler(date)}
          dateFormat="d MMMM yyyy"
          customInput={<CustomDatePickerInput />} 
        />
      </div>
      <CommentAdd action={action} cancelHandler={cancelHandler} sendHandler={sendHandler} setContent={setContent} />
    </div>
  );
}

export default ExtendPick;
