import React from 'react';
import { useRef, useState, useEffect } from 'react';

class BuilderRO extends React.Component {


  static renderHeader = (element) => {
    return <h3 dangerouslySetInnerHTML={{ __html: element.content }}></h3>;
  };
  
  static renderTextInput = (element, initialValue) => {
    return (
      <div className="form-group">
        <label htmlFor={element.field_name} dangerouslySetInnerHTML={{ __html: element.label }}></label>
        <input
          type="text"
          className="form-control"
          id={element.field_name}
          name={element.field_name}
          defaultValue={initialValue || ''}
          readOnly // Added the readOnly attribute
        />
      </div>
    );
  };
  
  static renderCheckboxes = (element, initialValues) => {
    return (
      <>
      <label dangerouslySetInnerHTML={{ __html: element.label }}></label>
      <br/>
      <div className={element.inline ? 'form-check form-check-inline form-group' : 'form-group'}>
        {element.options.map((option) => (
          <div key={option.key}>
            <input
              type="checkbox"
              id={option.key}
              name={option.key}
              value={option.value}
              readOnly
              defaultChecked={initialValues && initialValues.hasOwnProperty(option.key)}
              onClick={(e) => e.preventDefault()} // Prevent checkbox from being clicked
            />
            <label htmlFor={option.key}> &nbsp;{option.text}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </label>
          </div>
        ))}
      </div>
      </>
    );
  };
  
  static renderLabel = (element) => {
    return <label dangerouslySetInnerHTML={{ __html: element.content }}>
    </label>;
  };
  
  static renderParagraph = (element) => {
    return <p dangerouslySetInnerHTML={{ __html: element.content }}></p>;
  };
  
  static renderLineBreak = () => {
    return <br />;
  };

  static renderDropdown = (element, initialValue = '') => {
    return (
      <div className="form-group">
        <label htmlFor={element.field_name} dangerouslySetInnerHTML={{ __html: element.label }}></label>
        <select
          id={element.field_name}
          name={element.field_name}
          className="form-control"
          defaultValue={initialValue}
          disabled // Added the disabled attribute
        >
          {element.options.map((option) => (
            <option key={option.key} value={option.value}>
              {option.text}
            </option>
          ))}
        </select>
      </div>
    );
  };
  
  static renderTags = (element, initialValue = '') => {
    return (
      <div className="form-group">
        <label htmlFor={element.field_name} dangerouslySetInnerHTML={{ __html: element.label }}></label>
        <input
          type="text"
          className="form-control"
          id={element.field_name}
          name={element.field_name}
          list={`${element.field_name}-list`}
          defaultValue={initialValue}
          readOnly // Added the readOnly attribute
        />
        <datalist id={`${element.field_name}-list`}>
          {element.options.map((option) => (
            <option key={option.key} value={option.value}>
              {option.text}
            </option>
          ))}
        </datalist>
      </div>
    );
  };
  
  static renderRadioButtons = (element, initialValue = '') => {
    const elementId = `radiobuttons_${element.field_name}`;
    return (
      <>
      <label dangerouslySetInnerHTML={{ __html: element.label }}></label>
      <br/>
      <div className={element.inline ? 'form-check form-check-inline form-group' : 'form-group'}>
        {element.options.map((option) => {
          const id = `${elementId}_${option.key}`;
          const checked = option.value === initialValue;
  
          return (
            <div className="form-check" key={option.key}>
              <input
                className="form-check-input"
                type="radio"
                name={element.field_name}
                id={id}
                value={option.value}
                defaultChecked={checked}
                disabled // Added the disabled attribute
              />
              <label className="form-check-label" htmlFor={id}>
                {option.text}
              </label>
            </div>
          );
        })}
      </div>
      </>
    );
  };
  
  static renderEmailInput = (element, initialValue) => {
    return (
      <div className="form-group">
        <label htmlFor={element.field_name} dangerouslySetInnerHTML={{ __html: element.label }}></label>
        <input
          type="email"
          className="form-control"
          id={element.field_name}
          name={element.field_name}
          defaultValue={initialValue}
          readOnly // Added the readOnly attribute
        />
      </div>
    );
  };
  static renderNumberInput = (element, initialValue) => {
    return (
      <div className="form-group">
        <label htmlFor={element.field_name} dangerouslySetInnerHTML={{ __html: element.label }}></label>
        <input
          type="text"
          className="form-control"
          id={element.field_name}
          name={element.field_name}
          defaultValue={initialValue}
          readOnly // Added the readOnly attribute
        />
      </div>
    );
  };
  
  static renderPhoneNumber = (element, initialValue) => {
    return (
      <div className="form-group">
        <label htmlFor={element.field_name} dangerouslySetInnerHTML={{ __html: element.label }}></label>
        <input
          type="tel"
          className="form-control"
          id={element.field_name}
          name={element.field_name}
          defaultValue={initialValue}
          readOnly // Added the readOnly attribute
        />
      </div>
    );
  };

  static renderTextArea = (element, initialValue) => {
    return (
      <div className="form-group">
         <label htmlFor={element.field_name} dangerouslySetInnerHTML={{ __html: element.label }}></label>
        <textarea
          className="form-control"
          id={element.field_name}
          name={element.field_name}
          defaultValue={initialValue}
          readOnly // Added the readOnly attribute
        />
      </div>
    );
  };
  static renderImage = (element, fileName, fileLoc, dataValueParsed) => {
    if (fileName) {
      const imageStyle =
        element.width && element.width !== '0' && element.height && element.height !== '0'
          ? { width: element.width + 'px', height: element.height + 'px' }
          : {};
      return (
        <div className="form-group">
          <img id={`${element.field_name}-preview`} alt="Preview" src={fileLoc} style={imageStyle} />
          <br />
          <button style={{ marginTop: '5px' }} className="btn btn-secondary btn-sm" disabled>Reupload</button>
          {/* Hidden input for element */}
          <input
            type="hidden"
            name="elementNames"
            value={element.field_name}
          />
          <input
            type="hidden"
            name={element.field_name}
            value={fileName}
          />
          <input
            type="hidden"
            name={`sp_` + element.field_name}
            value={dataValueParsed[`sp_` + element.field_name]}
          />
        </div>
      );
    } else {
      const imageStyle =
        element.width && element.width !== '0' && element.height && element.height !== '0'
          ? { width: element.width + 'px', height: element.height + 'px' }
          : {};
      return (
        <div className="form-group">
          <label>
            <img id={`${element.field_name}-preview`} alt="Preview" src="/no_image.JPG" style={imageStyle} />
          </label>
        </div>
      );
    }
  };
  
  static renderRating = (element, initialValue) => {
    return (
      <div className="form-group">
        <label htmlFor={element.field_name} dangerouslySetInnerHTML={{ __html: element.label }}></label>
        <div>
          {[1, 2, 3, 4, 5].map((rating) => (
            <span
              key={rating}
              className={`${element.field_name}-star`}
              style={{ fontSize: '24px', color: rating <= initialValue ? 'gold' : 'lightgray' }}
            >
              ★
            </span>
          ))}
        </div>
        <input type="hidden" id={`${element.field_name}-value`} name={element.field_name} value={initialValue} />
      </div>
    );
  };

  static renderDatePicker = (element, initialValue) => {
    return (
      <>
        <style>
          {`
            .my-input::before {
              content: none !important;
            }
          `}
        </style>
        <div className="form-group">
          <label htmlFor={element.field_name} dangerouslySetInnerHTML={{ __html: element.label }}></label>
          <input
            type="date"
            className="form-control my-input"
            id={element.field_name}
            name={element.field_name}
            defaultValue={initialValue}
            readOnly // Added the readOnly attribute
          />
        </div>
      </>
    );
  };

  static Signature = ({ label, name, width, height, initialValue }) => {
    const canvasRef = useRef(null);
    const inputRef = useRef(null);
  
    const handleMouseDown = () => {
      // Disable drawing functionality
    };
  
    const handleMouseMove = () => {
      // Disable drawing functionality
    };
  
    const handleMouseUp = () => {
      // Disable drawing functionality
    };
  
    const handleMouseOut = () => {
      // Disable drawing functionality
    };
  
    const handleClear = () => {
      // Hide the clear button
    };
  
    useEffect(() => {
      const canvas = canvasRef.current;
      const input = inputRef.current;
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.onload = () => {
        ctx.drawImage(img, 0, 0);
        input.value = canvas.toDataURL();
      };
      img.src = initialValue;
    }, [initialValue]);
  
    return (
      <div className="form-group">
        <canvas
          ref={canvasRef}
          width={width}
          height={height}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseOut={handleMouseOut}
          style={{ border: "1px solid black" }}
        ></canvas>
        <br />
        <label dangerouslySetInnerHTML={{ __html: label }}>
        </label> 
        
        <input ref={inputRef} type="hidden" name={name} />
      </div>
    );
  };

  static renderHyperLink = (element) => {
    return (
      <div className="form-group">
       <a href={element.href} dangerouslySetInnerHTML={{ __html: element.content }}></a>
      </div>
    );
  };

  static renderDownloadBAK(element, fileName, fileLoc, dataValueParsed) {
    if (fileName)
      return (
        <div className="form-group">
          <label dangerouslySetInnerHTML={{ __html: element.label }}></label>
          <br/>
          <a target="_blank" rel="noreferrer" href={fileLoc}>{fileName}</a>
        </div>
      );
    else {
      return (
        <div className="form-group">
          <label dangerouslySetInnerHTML={{ __html: element.label }}></label>
          <br/>
          <p>No file selected</p>
        </div>
      );
    }
  }
  
  static renderDownload = (element, fileName, fileLoc,dataValueParsed, onReupload, onFileUpload) => {
   const [fileNames, setFileNames] = useState({});
   try {
      if (!fileName || fileName === "") {
        return (
          <div className="form-group">
            <label dangerouslySetInnerHTML={{ __html: element.label }}></label>
            <br/>
            <p>No file selected</p>
          </div>
        );
      } else {
        return (
          <div className="form-group">
            <label dangerouslySetInnerHTML={{ __html: element.label }}></label>
            <br/>
            <a target="_blank" rel="noreferrer" href={fileLoc}>{fileName}</a>
            <br/>
            {/* Hidden input for element */}
            <input
              type="hidden"
              name="elementNames"
              value={element.field_name}
            />
            <input
              type="hidden"
              name={element.field_name}
              value={fileName}
            />
            <input
              type="hidden"
              name={`sp_`+element.field_name}
              value={dataValueParsed[`sp_`+element.field_name]}
            />
          </div>
        );
      }
   } catch {
    return (
      <div className="form-group">
        <label dangerouslySetInnerHTML={{ __html: element.label }}></label>
        <br/>
        <p>No file selected</p>
      </div>
    );
   }

      
  };
  static renderRange = (element, initialValue) => {
    let defaultValue = element.default_value;
    if (initialValue || initialValue === 0 || initialValue === '') {
      defaultValue = initialValue;
    }
    return (
      <div className="form-group">
        <label dangerouslySetInnerHTML={{ __html: element.label }}></label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: '8px' }}>{element.min_label}</span>
          <input
            type="range"
            name={element.field_name}
            min={element.min_value}
            max={element.max_value}
            step={element.step}
            defaultValue={defaultValue}
            style={{ flex: 1 }}
            disabled
          />
          <span style={{ marginLeft: '8px' }}>{element.max_label}</span>
        </div>
      </div>
    );
  };

}

export default BuilderRO;
