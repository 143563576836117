import React from 'react'
import CommentAdd from '../comment/CommentAdd';
import { useState } from 'react';
import TicketDropdown from '../../createTicket/TicketDropdown';
import TicketDropdownShow from '../TicketDropdownShow';
import useOrientation from '../../../general/useOrientation';

function ForwardAddHandler({ setDeptFwd, setCatFwd, int_or_ext_id,action,cancelHandler, sendHandler, setContent, priorityId="0"}) {
    const [linkCategory, setLinkCategory] = useState('');
    const orientation = useOrientation();
    let isThisPortrait = false;
    if (orientation === 'portrait')
      isThisPortrait = true;
    const PickDropdownDept = (val) => {
        setLinkCategory("/tiketIntCat/" + val.value);
        setDeptFwd(val);
      }
      const PickDropdownCat = (val) => {
        //console.log(val.label);
        setCatFwd(val)
      }
    if (int_or_ext_id === 1 || priorityId == 2) {
        return (
            <>
                <div style={{marginLeft: '4vw'}}>
                <br/>
                <TicketDropdownShow title="" value="0" label={isThisPortrait?"Forward: Divisi lain akan melakukan support":"Forward: Anda meneruskan ticket untuk di-support department lain"} />
                <TicketDropdown type={1} pickDropdown={PickDropdownDept} title="Department" link='/deptMgmtInt' />
                <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
                <TicketDropdown type={2} pickDropdown={PickDropdownCat} title="Category" link={linkCategory} />
                </div>
                <CommentAdd action={action} cancelHandler={cancelHandler} sendHandler={sendHandler} setContent={setContent} />
            </>
        );
    } else if (int_or_ext_id === 2) {
        return (
            <>
            <div style={{marginLeft: '4vw'}}>
                <TicketDropdownShow title="" value="0" label={isThisPortrait?"Forward: Department lain akan melakukan support":"Forward: Anda meneruskan ticket untuk di-support department lain"} />
            </div>
            <CommentAdd action={action} cancelHandler={cancelHandler} sendHandler={sendHandler} setContent={setContent} />
            </>
        );
    }
}

export default ForwardAddHandler