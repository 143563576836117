import React from 'react'; import axios from 'axios' ;
import ContentHeader from '../../content/ContentHeader'; import { useState, useEffect, useRef} from 'react';
import Cookies from 'universal-cookie'; 
import {BinMinus} from 'iconoir-react';
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
import BootstrapTable from 'react-bootstrap-table-next'
import {Search as NoirSearch, NavArrowRight } from 'iconoir-react'
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import NoPage from '../NoPage';
import { useNavigate } from 'react-router-dom';
import CountUp from './CountUp';
import { Tooltip } from 'react-tooltip';
import { isMobile } from 'react-device-detect';
import useOrientation from '../../general/useOrientation';
const cookies = new Cookies();
const MySwal = withReactContent(Swal)
const { SearchBar } = Search;

const ppFormatter = (data,row) => {
    if (data && data != 'undefined'){
      
        return <>
        <a href="#" data-tooltip-id={`tipPP${data}${row.id}`} data-tooltip-content={row.display_name}>
        <img
            src={process.env.REACT_APP_PUBLIC_DESTINATION + data}
            height='32px'
            alt="Profile Picture"
          /> 
          <Tooltip id={`tipPP${data}${row.id}`} place="right" type="dark" effect="solid" />
        </a>
        </>
    } else {
        return <> <img
        src={process.env.REACT_APP_PUBLIC_DESTINATION + '/dist/img/pp/pp.png'}
        height='32px'
        alt="Profile Picture"
      /> </>
    }
}

const dateFormatter = (data) => {
  var datee = new Date(data);
  const year = datee.getFullYear().toString().slice(-2); // Get the last 2 digits of the year
  const month = String(datee.getMonth() + 1).padStart(2, '0'); // Get the month (0-indexed) and pad with 0 if needed
  const day = String(datee.getDate()).padStart(2, '0'); // Get the day and pad with 0 if needed
  const hours = String(datee.getHours()).padStart(2, '0'); // Get the hours and pad with 0 if needed
  const minutes = String(datee.getMinutes()).padStart(2, '0'); // Get the minutes and pad with 0 if needed

  return <>{`${day}/${month}/${year} at ${hours}:${minutes}`}</>
}
    
const titleFormatter = (data,row) => {
  var dataa = "";
   if (row.tiket_referral_id != null){
    dataa = "[REF] ";
   }
   dataa += data ;
   if (dataa.length > 35) {
    dataa = dataa.substring(0, 35) + " ...";
  }

  if (dataa == ""){
    dataa = "No Subject"
  }
  return dataa
}


  
const statusFormatter = (data,row) => {
  var sessionData = cookies.get('sessionData', { path: '/' })
  var int_ext = sessionData.int_ext;
  var bgColor = '';
  var txtColor = '';
  var txt = '';

  if (data == "Waiting"){
    //if (row.status_overdue == "overdue"){
    //  bgColor = '#FFE6D6';
    //  txtColor = '#A04A11';
    //  txt = 'Overdue';
    //} else {
      bgColor = '#FFF4E0';
      txtColor = '#A3711C';
      txt = 'Waiting';
    //}
  } else if (data == "On Progress"){
    if (row.status_overdue == "overdue" && int_ext == 1){
      bgColor = '#FFE6D6';
      txtColor = '#A04A11';
      txt = 'Overdue';
    } else {
      bgColor = '#D6EBF6';
      txtColor = '#01648C';
      txt = 'On Progress';
    }
  } else if (data == "Resolved"){
    bgColor = '#DCF5F3';
    txtColor = '#00786B';
    txt = 'Resolved';
  } else if (data == "Closed"){
    bgColor = '#E8E9EB';
    txtColor = '#737E86';
    txt = 'Closed';
  } else if (data == "Forwarded" ){
    if (int_ext == 1){
      bgColor = '#E7E5F5';
      txtColor = '#403688';
      txt = 'Forwarded';
    } else {
      bgColor = '#D6EBF6';
      txtColor = '#01648C';
      txt = 'On Progress';
    }
  } else if (data == "Done" ){
    if (int_ext == 1){
      bgColor = '#E1F6FA';
      txtColor = '#0D7D94';
      txt = 'Done';
    } else {
      bgColor = '#D6EBF6';
      txtColor = '#01648C';
      txt = 'On Progress';
    }
  } else {
    bgColor = '#E8E9EB';
    txtColor = '#737E86';
    txt = data;
  }
  return <div style={{ fontSize:'1vw' ,display: 'inline-block', backgroundColor: bgColor, color: txtColor, borderRadius: '16px', textAlign: 'center' , padding:'0.2vw 0.8vw 0.2vw 0.8vw'}}>
    {txt}
  </div>
}

function TicketTableDraft({link, refresh, setLoading}) {
  const clickedColumnIndex = useRef();
  const navigate = useNavigate();
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait') { isThisPortrait = true;}
  const displayedRow = 12;
  const [dataa, setDataa] = useState([]);
  const [totlength, setTotLength] = useState(0);
  const [txtBL, setTxtBL] = useState('');
  const [currentPage, setCurrentPage] = useState(0); // Track the current page
  useEffect(() => {
    setLoading(true)
    getData(link);
  }, [link,refresh])
  useEffect(() => {
    let cpmone = parseInt(currentPage) - 1 
    let firstDigit = (parseInt(displayedRow) * parseInt(cpmone) )
    let secondDigit = parseInt(firstDigit) + parseInt(displayedRow)
    if (secondDigit > totlength) secondDigit = totlength
    let a = `${firstDigit+1} - ${secondDigit} of ${totlength} data`   ;
    setTxtBL(a);
    if (totlength === 0){
      setTxtBL();
    }
  }, [currentPage,totlength])
  const deleteFormatter = (data,row) => {
      return <BinMinus style={{color:'#0296D2'}} onClick={() => deleteDraftHandler(data)}/>
  }
  const deleteDraftHandler = (id) => {
    MySwal.fire({
      title: '<strong>Warning</strong>',
      html: `<p>Confirm to Delete? You cannot undo this action</p>`,
      icon: 'warning',
      width:isThisPortrait? '85vw' : '50vw',
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: 'Back',
      confirmButtonText: 'Proceed',
    }).then(result => {
      if (result.isConfirmed) {
        var sessionToken = cookies.get('sessionToken', { path: '/' });
        const config = { headers: { 'x-access-token': sessionToken } };
        const apiPath = process.env.REACT_APP_PATH_DESTINATION + "/deleteDraft";
        const formData = {
            id: id
        }
        axios.post(apiPath, formData, config)
        .then(res => {
            console.log("draft logging here",res.data.data);
            getData(link);
        })
        .catch(err => {
            console.log(err);
                MySwal.fire({
                title: <strong>Error!</strong>,
                html: <p>{err.response?.data?.message || "An error occurred. Please try relogging"}</p>
            })
            setLoading(false);
        })
      } else {
        MySwal.close()
      }
    })
  }

const searchProps = {
  delay: 800,
  placeholder: isThisPortrait? "search...":"search draft...",
  className: "searchRight",
  zIndex : 5,
  position: 'relative',
  height: '4px',
  fontFamily: 'Blender Pro !important'
};

      
const titleFormatterMobile = (data, row) => {
  var title = "";
  if (row.tiket_referral_id != null){
   title = "[REF] ";
  }
  title += row.title ;
  if (title.length > 35) {
    title = title.substring(0, 35) + "...";
  }
  if (title == ""){
    title = "No Subject"
  }

  let shortName = row.display_name ;
  if (shortName.length > 22) {
    shortName = shortName.substring(0, 22) + "...";
  }
    
  let bgColor = '#E8E9EB';
  let txtColor = '#737E86';
  let txt = row.status_name;

  let createdDate = "";
  let datee = new Date(row.created_at);
  const year = datee.getFullYear().toString().slice(-2); // Get the last 2 digits of the year
  const month = String(datee.getMonth() + 1).padStart(2, '0'); // Get the month (0-indexed) and pad with 0 if needed
  const day = String(datee.getDate()).padStart(2, '0'); // Get the day and pad with 0 if needed
  const hours = String(datee.getHours()).padStart(2, '0'); // Get the hours and pad with 0 if needed
  const minutes = String(datee.getMinutes()).padStart(2, '0'); // Get the minutes and pad with 0 if needed
  createdDate= `${day}/${month}/${year} at ${hours}:${minutes}`
  return (
    <>
      <div className='row' style={{marginTop:'1vh', marginLeft:'2vw', display: 'flex', alignItems: 'center'}}>
        <a onClick={() => navigate('/CreateTicketDraft/'+row.id)} data-tooltip-id={`tipPP${data}${row.id}`} data-tooltip-content={row.display_name}>
          <img
            onClick={() => navigate('/CreateTicketDraft/'+row.id)} 
            src={data && data != 'undefined'? process.env.REACT_APP_PUBLIC_DESTINATION + data : process.env.REACT_APP_PUBLIC_DESTINATION +'/dist/img/pp/pp.png'}
            height='35px'
            width='35px'
            alt="pp"
            className='img-circle'
          /> 
          <Tooltip id={`tipPP${data}${row.id}`} place="right" type="dark" effect="solid" />
        </a>
        <div onClick={() => navigate('/CreateTicketDraft/'+row.id)} style={{margin: '-0.25vh 0 0 2vw'}}>
          <span style={{fontSize:'15px'}}>{shortName}</span> <br/>
          <span style={{fontSize:'15px', fontWeight:'100'}}>{createdDate}</span>
        </div>
        <div style={{marginLeft:'auto', marginRight:'3vw'}}> {/* Set marginLeft to auto */}
          <div style={{ fontSize:'15px', backgroundColor: bgColor, color: txtColor, borderRadius: '16px', textAlign: 'center' , padding:'0.2vh 0.8vh 0.2vh 0.8vh'}}>
            {txt}
          </div>
        </div>
        <div style={{marginRight:'2vw'}}> 
          <BinMinus style={{color:'#0296D2'}} onClick={() => deleteDraftHandler(data)}/>
        </div>
      </div>
      <div onClick={() => navigate('/CreateTicketDraft/'+row.id)} className='row' style={{marginLeft:'2vw', marginTop:'1vh', display: 'flex', justifyContent: 'space-between', marginBottom:'1vh'}}> 
        <div style={{fontSize:'18px'}}>
          {title} 
        </div>
        <div>
          <NavArrowRight onClick={() => navigate('/CreateTicketDraft/'+row.id)} style={{height:'2vh'}}/>
        </div>
      </div>
    </>
  );  
};
      
  const columnsMobile = [
    {
      text: 'Creator',
      dataField: 'path_foto',
      sort: true,
      headerClasses: 'd-none',
      formatter:titleFormatterMobile,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
            clickedColumnIndex.current = columnIndex;
        },
      },
    }
  ];

    const columns = [
        {
            text: 'Creator',
            dataField: 'path_foto',
            sort: true,
            formatter:ppFormatter
        },
        {
            text: 'Subject',
            dataField: 'title',
            sort:true,
            formatter:titleFormatter
        },
        {
            text: 'Edit Date',
            dataField: 'updated_at',
            sort:true,
            formatter: dateFormatter
        },
        {
            text: 'Status',
            dataField: 'status_name',
            sort:true,
            formatter:statusFormatter
        },
        {
            text: 'Action',
            dataField: 'id',
            sort: true,
            formatter:deleteFormatter,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    clickedColumnIndex.current = columnIndex;
                },
            },
        },
    ];
  const getData = (link) => {
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    axios.interceptors.request.use(config => {config.headers['x-access-token'] = sessionToken;return config;},error => {return Promise.reject(error);});
    axios.get(`${process.env.REACT_APP_PATH_DESTINATION}/${link}`) 
      .then(res => {
        console.log("draft logging here",res.data.data);
        setDataa(res.data.data);
        setTotLength(res.data.data.length);
        setCurrentPage(1);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
            MySwal.fire({
            title: <strong>Error!</strong>,
            html: <p>{err.response?.data?.message || "An error occurred. Please try relogging"}</p>
        })
        setLoading(false);
      })
  }
  const options = {
    sortIndicator: true,
    bordered: false,
    hideSizePerPage: true, // Hide the option to change the number of rows
    sizePerPage: displayedRow, // You can set your desired default value here
    page: 1,
    showTotal: false, // Hide the total number of rows
    sizePerPageList: [] // Remove the size per page options from the dropdown
  };
  const handlePageChange = (type, { page, sizePerPage }) => {
    setCurrentPage(type); 
  };
  
  const tableRowEvents = {
    onClick: (e, row, rowIndex) => {
      //console.log('row',row); //got all the data u ever want here
      if (clickedColumnIndex.current === 0) {
        //do nothing - mobile only for this (in column)
      } else if (clickedColumnIndex.current === 4){
        //do nothing - landscape only for this (in column)
      } else {
        // Navigate to another page
        navigate('/CreateTicketDraft/'+row.id);
      }
      // Reset clickedColumnIndex
      clickedColumnIndex.current = undefined;
    },
    onMouseEnter: (e, row, rowIndex) => {
      //console.log(`enter on row with index: ${rowIndex}`);
    }
  }
  return (
    <>
    <ContentHeader title=""/> 
        <section className="content">
        <div className="container-fluid">
            <div className="row"> 
              <div className="col-md-12">
                <ToolkitProvider
                  keyField="id"
                  data={dataa}
                  columns={isThisPortrait? columnsMobile:columns}
                  bootstrap4={true}
                  search={searchProps}
                >
                  {props => (
                    <div >
                      <div style={{width:isThisPortrait? '25vw':'200px', float:'right',zIndex:-1}}>           
                      <table>
                        <tbody>
                          <tr >
                          <td>
                            <SearchBar {...props.searchProps} {...searchProps} />
                          </td>
                          </tr>
                        </tbody>
                      </table>
                      </div>
                      <BootstrapTable
                        {...props.baseProps}
                        striped 
                        hover
                        pagination={paginationFactory({ ...options, onPageChange: handlePageChange })}
                        classes="custom-table" 
                        rowClasses="custom-row"
                        rowEvents={ tableRowEvents }
                      />
                    </div>
                  )}
                </ToolkitProvider>
                <div style={{marginTop:'1vw', marginLeft:isThisPortrait?'3vw':'inherit'}}>{txtBL}</div>
              </div>
            </div>
        </div>
     </section>
    </>
  )
}

export default TicketTableDraft