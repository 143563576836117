import React from 'react';
import { BrowserView, MobileView, isMobile, isBrowser } from 'react-device-detect';
import { Link } from "react-router-dom";

function Submenu({ name, Icon, link, state, clickAction }) {
  const divStyle = name === state ? { backgroundColor: '#E8E9EB', borderRadius: '1vw', paddingRight: '-5vw', width:'95%'} : {};

  if (isBrowser)
  return (
  <>
  
    <div style={{ ...divStyle }}>
      <li className="nav-item" key={name} style={{ marginTop:'0.25vw', marginBottom: '0.25vw' }}>
        <Link to={link} onClick={() => clickAction(name)} className="nav-link" style={{ display: 'flex', alignItems: 'center' }}>
          {Icon && <Icon className={`nav-icon leftSidebarTextIcon`} style={{ color: "#737E86", width: '1.875vw' }} />}
          <p className='leftSidebarTextIcon' style={{ color: '#737E86', fontSize: "1vw", textAlign: 'center', marginLeft: '0.5vw' }}> <b>{name}</b> </p>
        </Link>
      </li>
    </div>
    
  
  </>
  );

  if (isMobile)
  return (
    <>
    <div style={{ ...divStyle }}>
      <li className="nav-item" key={name} style={{ marginTop:'0.25vh', marginBottom: '0.25vh' }}>
        <Link to={link} onClick={() => clickAction(name)} className="nav-link" style={{ display: 'flex', alignItems: 'center' }}>
          {Icon && <Icon className={`nav-icon leftSidebarTextIcon`} style={{ color: "#737E86", width: '2vh' }} />}
          <p className='leftSidebarTextIcon' style={{ color: '#737E86', fontSize: "2vh", textAlign: 'center', marginLeft: '2.5vw' }}> <b>{name}</b> </p>
        </Link>
      </li>
    </div>
  </>
  )
}

export default Submenu;
