import React from 'react'
import { BrowserView, isBrowser, isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import useOrientation from '../../general/useOrientation';
const maxContentCharLength = 80;

function Announcement({id, title, content, date, username, category }) {
  const navigate = useNavigate();
  const orientation = useOrientation();
  if (content && content.length > maxContentCharLength) {
    // Trim content to 50 characters and add "..."
    content = content.substring(0, maxContentCharLength) + "...";
  }
  const handleClick = () => {
    // Use the navigate function to navigate to google.com
    navigate('/announcement/'+title);
  };
  if (orientation === 'landscape')
  return (
  <>
    <div className="col-md-8 cursorPointer" style={{marginTop:'1vw'}} onClick={handleClick}>
    <p style={{fontSize:"1.2vw"}}> <b>{title}</b></p>
        <p style={{marginTop:'-1vw', color:'#B9BEC3', fontSize:"0.95vw", fontFamily:'Blender Pro Book'}}><b>{date} • By {username}</b></p>
    </div>
    <div onClick={handleClick} className="col-md-4 cursorPointer" style={{textAlign:"right", marginTop:'1.4vw', fontSize:'0.9vw'}}>
        <a style={{ backgroundColor: '#D6EBF6', color: '#01648C', borderRadius: '0.85vw', padding: '0.3vw 0.6vw 0.3vw 0.6vw' }}>{category}</a> 
    </div>
  </>
  )

  if (orientation === 'portrait')
  return (
  <>
    <div onClick={handleClick} className="col-md-4 cursorPointer" style={{position:'relative',textAlign:"right", fontSize:'2vh'}}>
      <a style={{ backgroundColor: '#D6EBF6', color: '#01648C', borderRadius: '0.8vh', padding: '0.3vh 2vw 0.3vh 2vw' }}>{category}</a> 
    </div>
    <div className="col-md-8 cursorPointer" style={{marginTop:'-3vh'}} onClick={handleClick}>
    <p style={{fontSize:"2vh", maxWidth:'70%'}}> <b>{title}</b></p>
      <p style={{marginTop:'-1vh', marginBottom:'3vh', color:'#B9BEC3', fontSize:"1.5vh", fontFamily:'Blender Pro Book'}}><b>{date} • By {username}</b></p>
    </div>

  </>
  )
}

export default Announcement