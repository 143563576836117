import React from 'react';
import { useState, useRef, useEffect } from 'react';
import 'react-form-builder2/dist/app.css';
import { useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
import Cookies from 'universal-cookie'; 
import axios from 'axios' ;
import ContentHeader from '../../content/ContentHeader';
import FormToHtmlDouble from './FormToHtmlDouble';
import FormToHtmlReadOnly from './FormToHtmlReadOnly';
import docDashDetails from '../docDash/docDashDetails';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import BackButton from '../../content/BackButton';
const cookies = new Cookies();
const MySwal = withReactContent(Swal)

function FormShow() {
  const {id} = useParams();
  const {backCode} = useParams(); 
  const [backLink, setbackLink] = useState([]);
  const [tblDetail, setTblDetail] = useState([]);
  const [formData, setformData] = useState([]);
  const [dataReady, setDataReady] = useState(false);
  const [dataEmpty, setDataEmpty] = useState(false);
  const [dataValue, setDataValue] = useState([]);
  const [fileObjects, setFileObjects] = useState([]);
  const [dataa, setDataa] = useState([]);//TODO: this set data is important, go getand set the data
  const [pending, setPending] = useState(false);
  const [rulesValue, setRulesValue] = useState([]);
  const [levelFiller, setLevelFiller] = useState([]);
  const [title, setTitle] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    getData();
    switch (backCode) {
      case '1':
        //Waiting Approval
        setbackLink('/dashboard/1/all/All');
        break;
      case '2':
        //Approved
        setbackLink('/dashboard/3/all/All');
        break;
      case '3':
        //Rejected
        setbackLink('/dashboard/4/all/All');
        break;
      case '4':
        //On Progress
        setbackLink('/dashboard/2/all/All');
        break;
      case '5':
        //View All
        setbackLink('/dashboard/8/all/0');
        break;
      case '6':
        //Cancelled
        setbackLink('/dashboard/5/all/0');
        break;
      default:
        //back to home as default - failsafe
        setbackLink('/dashboard');
        break;
    }

  }, [])

  const handleDownloadBak = () => {
    var docName = dataa.doc_name + ' - ' + dataa.doc_number + '.pdf';
    const element = document.getElementById('content-to-download');
  
    // Skip elements with the 'exclude-from-pdf' class
    const excludeElements = element.getElementsByClassName('exclude-from-pdf');
    const excludedElementsArray = Array.from(excludeElements);
  
    // Create a clone of the excluded elements
    const excludedElementsClone = excludedElementsArray.map((el) => el.cloneNode(true));
  
    // Remove the excluded elements from the DOM
    excludedElementsArray.forEach((el) => el.remove());
  
    html2canvas(element, { scrollY: -window.scrollY }).then((canvas) => {
      //const imgData = canvas.toDataURL('image/png');
      const imgData = canvas.toDataURL('image/png', 0.1);
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgAspectRatio = canvas.width / canvas.height;
      const pdfAspectRatio = pdfWidth / pdfHeight;
      let imgWidth = pdfWidth;
      let imgHeight = pdfWidth / imgAspectRatio;
  
      if (imgHeight > pdfHeight) {
        imgHeight = pdfHeight;
        imgWidth = pdfHeight * imgAspectRatio;
      }
  
      const marginY = 10;
      const offsetY = marginY / 25.4 * 10;
  
      pdf.addImage(imgData, 'PNG', (pdfWidth - imgWidth) / 2 + 10, offsetY, imgWidth - 20, imgHeight);
  
      // Add footer with current date and time
      var data = cookies.get('sessionData', { path: '/' })
      const now = new Date();
      const footerText = `Generated by ${data.fullname} from Paperless Approval on: ${now.toLocaleDateString()} ${now.toLocaleTimeString()}`;
      const footerX = pdfWidth - 5;
      const footerY = pdfHeight - 5; // Adjust the vertical position as needed
      pdf.setFontSize(7);
      pdf.text(footerText, footerX, footerY, { align: 'right' });
      pdf.save(docName);
  
      // After the PDF is downloaded, add the excluded elements back to the DOM with <br/> after each element
      excludedElementsClone.forEach((el, index) => {
        element.appendChild(el);
        if (index !== excludedElementsClone.length - 1) {
          const brElement = document.createElement('br');
          element.appendChild(brElement);
        }
      });
    });
  };  

  const handleDownload = () => {
    var docName = dataa.doc_name + ' - ' + dataa.doc_number + '.pdf';
    const element = document.getElementById('content-to-download');
  
    // Skip elements with the 'exclude-from-pdf' class
    const excludeElements = element.getElementsByClassName('exclude-from-pdf');
    const excludedElementsArray = Array.from(excludeElements);
  
    // Create a clone of the excluded elements
    const excludedElementsClone = excludedElementsArray.map((el) => el.cloneNode(true));
  
    // Remove the excluded elements from the DOM
    excludedElementsArray.forEach((el) => el.remove());
  
    html2canvas(element, { scrollY: -window.scrollY }).then((canvas) => {
      // Convert canvas to JPEG format with compression (0.0 to 1.0, where 1.0 is best quality)
      const imgData = canvas.toDataURL('image/jpeg',100); // Adjust quality as needed (0.0 to 1.0)
  
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgAspectRatio = canvas.width / canvas.height;
      const pdfAspectRatio = pdfWidth / pdfHeight;
      let imgWidth = pdfWidth;
      let imgHeight = pdfWidth / imgAspectRatio;
  
      if (imgHeight > pdfHeight) {
        imgHeight = pdfHeight;
        imgWidth = pdfHeight * imgAspectRatio;
      }
  
      const marginY = 10;
      const offsetY = marginY / 25.4 * 10;
  
      pdf.addImage(imgData, 'JPEG', (pdfWidth - imgWidth) / 2 + 10, offsetY, imgWidth - 20, imgHeight);
  
      // Add footer with current date and time
      var data = cookies.get('sessionData', { path: '/' })
      const now = new Date();
      const footerText = `Generated by ${data.fullname} from Paperless Approval on: ${now.toLocaleDateString()} ${now.toLocaleTimeString()}`;
      const footerX = pdfWidth - 5;
      const footerY = pdfHeight - 5; // Adjust the vertical position as needed
      pdf.setFontSize(7);
      pdf.text(footerText, footerX, footerY, { align: 'right' });
      pdf.save(docName);
  
      // After the PDF is downloaded, add the excluded elements back to the DOM with <br/> after each element
      excludedElementsClone.forEach((el, index) => {
        element.appendChild(el);
        if (index !== excludedElementsClone.length - 1) {
          const brElement = document.createElement('br');
          element.appendChild(brElement);
        }
      });
    });
  };
  
  const handleAction = (link, reason) => {
    const sessionToken = cookies.get('sessionToken', { path: '/' })
    const config = { headers: {['x-access-token']: sessionToken } }
    if (link === 'docApprove') {
        //console.log(event);
        //onSubmit(event);
        //handleDocApprove(dataa, config)
    } else {
        handleOtherAction(dataa, link, config, reason)
    }
  }
  
  const handleDocApprove = (data, config) => {
    const path = process.env.REACT_APP_PATH_DESTINATION + '/docCheck'
  
    axios.post(path, { data }, config)
      .then(response => {
        console.log(response.data);
        if (response.data.status === '301') {
          showWarningModal(response.data, data, config)
        } else {
          handleOtherAction(data, 'docApprove', config)
        }
      })
      .catch(error => {
        console.log(error);
        showErrorModal()
      })
  }
  
  const handleOtherAction = (data, link, config, reason) => {
    data.reject_reason = reason;
    const path = process.env.REACT_APP_PATH_DESTINATION + '/' + link
  
    axios.post(path, { data }, config)
      .then(response => {
        if (response.data.status === '200') {
          showSuccessModal(response.data.message)
        } else {
          showErrorModal(response.data.message)
        }
      })
      .catch(error => {
        console.log(error);
        showErrorModal()
      })
  }
        
  const showWarningModal = (response, dataa, config) => {
    console.log("showWarningModal")
    let tableData = '';
    let message = response.message;
    let data2 = response.data;
    console.log(response)
    for (let i = 0; i < data2.length; i++) {
      const updatedAt = new Date(data2[i].updated_at).toLocaleString('en-US', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });
      tableData += `<tr>
                      <td style="text-align:left;">${data2[i].doc_name}</td>
                      <td style="text-align:left;">${updatedAt}</td>
                    </tr>`;
    }
    const swalHTML = `
    <div>
        <p style="text-align:left;">${message}</p>
        <table style="width: 700px;">
        <thead>
            <tr>
            <th style="text-align:left;">Document Name</th>
            <th style="text-align:left;">Last Update</th>
            </tr>
        </thead>
        <tbody>
            ${tableData}
        </tbody>
        </table>
    </div>
    `;
  
    MySwal.fire({
        title: '<strong>Warning</strong>',
        html: swalHTML,
        icon: 'warning',
        width:'800px',
        showCancelButton: true,
        cancelButtonText: 'Back',
        confirmButtonText: 'Proceed'
      }).then(result => {
        if (result.isConfirmed) {
          // Handle the "Proceed" button click
          handleOtherAction(dataa, 'docApprove', config)  
        } else {
          MySwal.close()
        }
      })
  }
  
  const showSuccessModal = (message) => {
    MySwal.fire({
      title: '<strong>Success!</strong>',
      html: '<p>' + message + '</p>',
      icon: 'success'
    }).then(() => {
      window.location.replace("/");
    })
  }
  
  const showErrorModal = (message) => {
    MySwal.fire({
      title: '<strong>Error!</strong>',
      html: '<p>' + (message || 'Something went wrong. Please contact the administrator.') + '</p>',
      icon: 'error',
      onClose: () => {
        window.location.reload();
      }
    });
  }
        
  const getData = () => {
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    var path = process.env.REACT_APP_PATH_DESTINATION + "/viewDocOngoing/" + id;   
    const config = {headers:{['x-access-token']: sessionToken}};
    axios.get(path , config)
      .then(res => {
        //console.log("res.data.data.doc_content");
        //console.log(res.data.data.doc_content);
        if (res.data.data.doc_value) {
          setDataValue(res.data.data.doc_value);
        }
        if (res.data.data.doc_rules && String(res.data.data.doc_rules) != "{}") {
          setRulesValue(res.data.data.doc_rules);
        }
        if (res.data.data.doc_content && String(res.data.data.doc_content) != "{}") {
          console.log("enter if");
          setformData(res.data.data.doc_content);
          setDataReady(true);
        } else {
          console.log("enter else");
          setDataEmpty(true);
          setDataReady(true);
        }
        console.log("filler level = " + res.data.data.current_level)
        setLevelFiller(res.data.data.current_level)

        Swal.close()
      })
      .catch(err => {
        console.log(err);
        Swal.close()
      })

      var path2 = process.env.REACT_APP_PATH_DESTINATION + "/docDashboardDetailWithID" ;   
      axios.post(path2, {"dtID":id,"type":"" , "deptIDChosen":""} , config)
      .then(res => {
        console.log(res);
        //setting pending:
        var data = cookies.get('sessionData', { path: '/' })

        const approvalToValues = String(res.data.data[0].approval_to).split(','); // Split the string into an array
        const userId = data.user_id.toString(); // Ensure user_id is also a string
        let isPending = false;

        if (approvalToValues.length > 1){
          for (const value of approvalToValues) {
            const trimmedValue = value.replace(/\(|\)/g, '').trim(); // Remove parentheses and trim whitespace
            if (trimmedValue === userId) {
              isPending = true;
              break;
            }
          }
        } else {
          const trimmedValue = res.data.data[0].approval_to.replace(/\(|\)/g, '').trim(); 
          if (trimmedValue === userId) {
              isPending = true;
          }
        }

        if (isPending) {
          setPending(true);
        }

        setDataa(res.data.data[0]);
        console.log(res.data.data[0]);

        var tblString;
        tblString = docDashDetails.detailsFormatterInside(res.data.data[0],tblString);
        setTblDetail(tblString);
        let tmp = res.data.data[0].current_level;
        setTitle(res.data.data[0]["ad_"+tmp+"_title"]);
      })
      .catch(err => {
        console.log(err);
      })
  }    

  const onReupload = (event, fieldName) => {
    event.preventDefault();
    Swal.fire({
      title: 'Clearing Data...',
      didOpen: () => {
        Swal.showLoading()
      },
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false
    })
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    var path = process.env.REACT_APP_PATH_DESTINATION + "/reuploadDocMasterDemo/";
    var data = {
      id: id,
      fieldName: fieldName
    }   
    const config = {headers:{['x-access-token']: sessionToken}};
    axios.post(path , data={data}, config)
      .then(res => {
        console.log(res.data);
        getData();
      })
      .catch(err => {
        console.log(err);
        getData();
      })
  }

  const onSubmit = (event) => {
    event.preventDefault();
    Swal.fire({
      title: 'Uploading to Server',
      didOpen: () => {
        Swal.showLoading()
      },
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false
    })
    const form = event.target;
    
    const requestBody = new FormData(form);
    requestBody.append('id', id);

    if (!fileObjects) {
      console.log('File input is empty');
    } else {
      for (const fileObject of fileObjects) {
        //const elementNames = requestBody.getAll('elementNames');
        
        //requestBody.append('elementNames', fileObject.elementName);
        requestBody.append('file', fileObject.file);
        requestBody.append(fileObject.elementName, fileObject.file.name);
      }
    }
    for (const element of form.elements) {
      if (element.name && element.type === 'file') {
        //do nothing, alr handled above
      } else if (element.name == "elementNames") {
        const elementNames = new Set(requestBody.getAll('elementNames')); // Convert to a Set
        elementNames.add(element.value); // Add element value to the Set
        requestBody.set('elementNames', Array.from(elementNames).join(',')); // Convert back to an array and join as a single value
      } else if (element.name) {
        if (element.type === 'radio') {
          if (element.checked) {
            requestBody.set(element.name, element.value);
          }
        } else if (element.type === 'checkbox') {
          if (element.checked) {
            if (!requestBody.has(element.name)) {
              requestBody.set(element.name, [element.value]);
            } else {
              const values = requestBody.getAll(element.name);
              values.push(element.value);
              requestBody.set(element.name, values);
            }
          }
        } else {
          requestBody.set(element.name, element.value);
        }
      }
    }
    //delete dupes 
    try{
      const elementNames = requestBody.get('elementNames');
      const uniqueElementNames = [...new Set(elementNames.split(','))];
      requestBody.set('elementNames', uniqueElementNames.join(','));  
    } catch {
      //do nothing, eleemnt just doesnt exist
    }
    //adding the doc_value as a new content
    const requestBodyJSON = {};
    requestBody.forEach((value, key) => {
      requestBodyJSON[key] = value;
    });

    const newData = { ...dataa, doc_value: requestBodyJSON };
    requestBody.set('dataa', JSON.stringify(newData));
    
    const sessionToken = cookies.get('sessionToken', { path: '/' })
    const config = { headers: {['x-access-token']: sessionToken } }

    //TODO: the doc check is skipped right now
    
    const path = process.env.REACT_APP_PATH_DESTINATION + '/docApprove'
    axios.post(path, requestBody, config)
      .then(response => {
        if (response.data.status === '200') {
          showSuccessModal(response.data.message)
        } else {
          showErrorModal(response.data.message)
        }
      })
      .catch(error => {
        console.log(error);
        showErrorModal()
      })
  };

  const handleFileUpload = (event) => {
    const newFiles = event.target.files;
    const elementName = event.target.name;
    const newFileObjects = Array.from(newFiles).map(file => ({ file, elementName }));
  
    setFileObjects(prevFileObjects => {
      const index = prevFileObjects.findIndex(fileObject => fileObject.elementName === elementName);
      if (index !== -1) {
        prevFileObjects[index] = newFileObjects[0];
        return [...prevFileObjects];
      } else {
        return [...prevFileObjects, ...newFileObjects];
      }
    });
  };

  if (!dataReady) {
    return <ContentHeader title="Loading Attachment..."/>;
  } else {
    if (dataEmpty) {
      navigate('/formAdd/'+id);
    } else {
      //console.log(dataValue);
      if (pending)
      return (
        <>
          <BackButton link={backLink}/>
          <section className="content">
            <div className="container-fluid">
              <div className="" id="content-to-download"> 
              <div dangerouslySetInnerHTML={{ __html: tblDetail }} />
                <form onSubmit={(event) => onSubmit(event)} encType="multipart/form-data">
                <FormToHtmlDouble levelFiller={levelFiller} formData={formData} dataValue={dataValue} rulesValue={rulesValue} onFileUpload={handleFileUpload} onReupload={onReupload} />
                <p className="exclude-from-pdf">{title}</p>
                <div className="exclude-from-pdf" style={{ display: 'flex', alignItems: 'center' }}>
                  <button title="Approve" className="btn btn-success " style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                    <i className="fa fa-check " style={{ marginRight: '5px' }}></i>
                    <span>Approve</span>
                  </button>
                  <input
                    type="text"
                    className="form-control"
                    id="approve_notes"
                    name="approve_notes"
                    placeholder="(Optional) Enter Approve Notes"
                    required={false}
                  />
                </div>
                </form>
                <br/>
                <button title="Reject" onClick={() => 
                MySwal.fire({
                    title: <strong>Enter Reject Reason</strong>,
                    html: <input type="text" id="reject_reason" className="swal2-input" placeholder="Enter Reject Reason"></input>,
                    confirmButtonText: 'Submit',
                    focusConfirm: false,
                    preConfirm: () => {
                        const reject_reason = Swal.getPopup().querySelector('#reject_reason').value
                        if (!reject_reason ) {
                        Swal.showValidationMessage(`Please enter reject reason`)
                        }
                        return { reject_reason: reject_reason }
                    }
                }).then((result) => {
                  console.log("dataa1");
                  console.log(dataa);
                  var rejectres = result.value.reject_reason
                  handleAction("docReject", rejectres)
                })
            } 
            className="btn btn-danger exclude-from-pdf">
            <i className="fa fa-times"> Reject</i>
            </button>
                <br/>
              </div>
            <br/>
            <br/>
            <button className="btn btn-primary" onClick={handleDownload}>Download as PDF</button>
            <br/>
            </div>
          </section>
        </>
      );
      else 
      return(
        <>
        <BackButton link={backLink}/>
        <section className="content">
        <div className="container-fluid">
          <div className="" id="content-to-download" > 
          <div dangerouslySetInnerHTML={{ __html: tblDetail }} />
            <form onSubmit={(event) => onSubmit(event)} encType="multipart/form-data">
              <FormToHtmlReadOnly formData={formData} dataValue={dataValue} onFileUpload={handleFileUpload} onReupload={onReupload} />
            </form>
            <br/>
          </div>
        <br/>
        <br/>
        <button className="btn btn-primary" onClick={handleDownload}>Download as PDF</button>
        <br/>
        </div>
        
      </section>
      </>
      );
    }
  }
}

export default FormShow;