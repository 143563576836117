import React from 'react'
import useOrientation from '../../general/useOrientation';

function TicketSubmitHour({publishTime}) {
    const orientation = useOrientation();
    const formatTime = (timeString) => {
        const [hours, minutes, seconds] = String(timeString).split(':');
        return `${hours}:${minutes}`;
    };
    if (orientation === 'landscape')
    return (
    <div style={{backgroundColor:'#EAF8FF', marginLeft: '-3vw',width: '110%', height:'4.8vw', borderRadius:'8px'}}>
        <div className='row'>
            <div className='col-md-3' style={{ textAlign: 'right' }}>
                <img style={{height:'4vw', marginLeft: '0.5vw', marginTop:'0.5vw'}} src='/dist/img/submitClock.png'/>
            </div>
            <div className='col-md-9' >
               <p style={{fontFamily: 'Blender Pro', fontSize:'1.1vw', color:'#01648C', marginTop:'0.5vw'}}>Ticket Submit Hour</p>
                <div className='row' style={{marginTop:'-1vw', marginLeft:'0.2vw'}}>
                    <div className='' style={{width:'100% !important',fontFamily: 'Blender Pro',marginLeft:'-0.2vw',backgroundColor:'#FFFFFF', color:'#01648C', borderRadius:'8px', fontSize:'0.9vw', padding: '0vw 0.5vw 0vw 0.5vw'}}>
                    <b style={{color:'#01648C'}}>{formatTime(publishTime[0])} - {formatTime(publishTime[1])}</b>
                    </div> 
                    <div className='' style={{width:'100% !important',fontFamily: 'Blender Pro',marginLeft:'0.5vw',backgroundColor:'#FFFFFF', color:'#01648C', borderRadius:'8px', fontSize:'0.9vw', padding: '0vw 0.5vw 0vw 0.5vw'}}>
                    <b style={{color:'#01648C'}}>{formatTime(publishTime[2])} - {formatTime(publishTime[3])}</b>
                    </div>
                </div>
            </div>
        </div>
        <div style={{fontSize:'1vw', color:'#737E86', marginTop:'0.5vw'}}>
        Ticket yang akan di-submit di luar jam tersebut hanya bisa disimpan di draft.
        </div>
    </div>
    )
    
    if (orientation === 'portrait')
    return (
    <div style={{backgroundColor:'#EAF8FF', marginLeft: '2vw',width: '95%', height:'9vh', borderRadius:'8px', marginTop:'2vh'}}>
        <div className='row'>
            <div className='' style={{ textAlign: 'left' , marginLeft:'3vw'}}>
                <img style={{height:'7vh', marginLeft: '0.5vw', marginTop:'1vh'}} src='/dist/img/submitClock.png'/>
            </div>
            <div className='' >
               <p style={{fontFamily: 'Blender Pro', fontSize:'2vh', color:'#01648C', marginTop:'1vh'}}>Ticket Submit Hour</p>
                <div className='row' style={{marginTop:'-1.5vh', marginLeft:'0.2vw'}}>
                    <div className='' style={{width:'100% !important',fontFamily: 'Blender Pro',marginLeft:'-0.2vw',backgroundColor:'#FFFFFF', color:'#01648C', borderRadius:'8px', fontSize:'1.8vh', padding: '0.5vw 2vw 0.5vw 2vw'}}>
                    <b style={{color:'#01648C'}}>{formatTime(publishTime[0])} - {formatTime(publishTime[1])}</b>
                    </div> 
                    <div className='' style={{width:'100% !important',fontFamily: 'Blender Pro', marginLeft:'2vw',backgroundColor:'#FFFFFF', color:'#01648C', borderRadius:'8px', fontSize:'1.8vh', padding: '0.5vw 2vw 0.5vw 2vw'}}>
                    <b style={{color:'#01648C'}}>{formatTime(publishTime[2])} - {formatTime(publishTime[3])}</b>
                    </div>
                </div>
            </div>
        </div>
        <div style={{fontSize:'15px', color:'#737E86', marginTop:'1vh', width:'95%', marginLeft:'1%'}}>
        Ticket yang akan di-submit di luar jam tersebut hanya bisa disimpan di draft.
        </div>
    </div>
    )
}

export default TicketSubmitHour