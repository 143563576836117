import React, { useEffect, useState } from 'react'
import ContentHeader from '../../content/ContentHeader'
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
import Cookies from 'universal-cookie'; import axios from 'axios' ;
import DocLibraryDisplayParent from './DocLibraryDisplayParent';

const MySwal = withReactContent(Swal)
const cookies = new Cookies();
function DocumentLibrary({link, refresh, setLoading}) {
  const [docLibData, setDocLibData] = useState({});
  useEffect(() => {
    setLoading(true)
    getData(link);
  }, [link,refresh])

  const getData = (link) => {
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    axios.interceptors.request.use(config => {config.headers['x-access-token'] = sessionToken;return config;},error => {return Promise.reject(error);});
    axios.get(`${process.env.REACT_APP_PATH_DESTINATION}/${link}`) 
      .then(res => {
        console.log("doc library logging here",res.data.data);
        setDocLibData(res.data.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
            MySwal.fire({
            title: <strong>Error!</strong>,
            html: <p>{err.response?.data?.message || "An error occurred. Please try relogging"}</p>
        })
        setLoading(false);
      })
  }
  return (
    <>
      <ContentHeader title=""/> 
      <section className="content" style={{marginLeft:'-0.5vw'}}>
        <div className="container-fluid" >
          <div className='col-md-12'>
            <DocLibraryDisplayParent data={docLibData} /> 
          </div>
        </div>
      </section>
    </>
  )
}

export default DocumentLibrary