import React from 'react'; import axios from 'axios' ;
import ContentHeader from '../../content/ContentHeader'; import { useState, useEffect} from 'react';
import Cookies from 'universal-cookie'; 
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
import BootstrapTable from 'react-bootstrap-table-next'
import {Search as NoirSearch } from 'iconoir-react'
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import NoPage from '../NoPage';
import HeaderPage from '../headerPage/HeaderPage';
const cookies = new Cookies();
const MySwal = withReactContent(Swal)
const { SearchBar } = Search;

const actionFormatter = (data) => {
    if (data && data != 'undefined'){
        return <>
        <button title="change active status" onClick={() => handleViewDetail(data)} className="btn btn-warning">
            <i className="fa fa-eye"></i>
        </button>
        </>
    } else {
        return <></>
    }
}

const dateFormatter = (data) => {
    var datee= new Date(data);
    return <>{datee.toLocaleDateString() + " " + datee.toTimeString().substr(0,8)}</>
}
    
const handleViewDetail = (data) => {
    MySwal.fire({
        title: <strong>Details</strong>,
        html: <p>{data}</p>
    })
}
  
    const columns = [
      {
        text: 'Date',
        dataField: 'created_at',
        sort: true,
        formatter: dateFormatter
      },
      {
        text: 'Username',
        dataField: 'fullname',
        sort:true
      },
      {
        text: 'Action',
        dataField: 'action',
        sort:true
      },
      {
        text: 'Detail',
        dataField: 'detail',
        sort:true
      },
      {
        text: 'More Details',
        dataField: 'target',
        sort:true,
        formatter: actionFormatter
      }

    ];
  
    
    
    const searchProps = {
      delay: 800,
      placeholder: "search table...",
      className: "searchRight",
      zIndex : 5,
      position: 'relative'
    };
    
    const defaultSorted = [
      {
        dataField: "order",
        order: "desc"
      }
    ];
function Audit({changeNavState}) {
  const [dataa, setDataa] = useState([]);
  const [currentPage, setCurrentPage] = useState(0); // Track the current page
  const [totlength, setTotLength] = useState(0);
  const [txtBL, setTxtBL] = useState('');
  const [loading, setLoading] = useState(true);
  const displayedRow = 12;
  const options = {
    sortIndicator: true,
    bordered: false,
    hideSizePerPage: true, // Hide the option to change the number of rows
    sizePerPage: displayedRow, // You can set your desired default value here
    page: 1,
    showTotal: false, // Hide the total number of rows
    sizePerPageList: [] // Remove the size per page options from the dropdown
  };
  const handlePageChange = (type, { page, sizePerPage }) => {
    setCurrentPage(type); 
  };
  useEffect(() => {
      changeNavState('Audit Trail');
  }, [])
  useEffect(() => {
    getData();
  }, [])
  useEffect(() => {
    let cpmone = parseInt(currentPage) - 1 
    let firstDigit = (parseInt(displayedRow) * parseInt(cpmone) )
    let secondDigit = parseInt(firstDigit) + parseInt(displayedRow)
    if (secondDigit > totlength) secondDigit = totlength
    let a = `${firstDigit+1} - ${secondDigit} of ${totlength} data`   ;
    setTxtBL(a);
    if (totlength === 0){
      setTxtBL();
    }
  }, [currentPage,totlength])
  
  const getData = () => {
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    axios.interceptors.request.use(config => {config.headers['x-access-token'] = sessionToken;return config;},error => {return Promise.reject(error);});
    axios.get(`${process.env.REACT_APP_PATH_DESTINATION}/auditList`) 
      .then(res => {
        console.log(res.data.data);
        setDataa(res.data.data);
        setTotLength(res.data.data.length);
        setCurrentPage(1);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        MySwal.fire({
          title: <strong>Error!</strong>,
          html: <p>{err}</p>
      })
      })
  }    
  var data = cookies.get('sessionData', { path: '/' })
  if (data.apps_roles_id > 2) {
    return ( <NoPage />)
  }
  return (
    <>
      <HeaderPage
        title1={"Audit"}
        title2={"Audit List"}
        loadingState={loading}
      />

              <div className="col-md-12" style={{marginTop:'2vw'}}>
              
                <ToolkitProvider
                  keyField="id"
                  data={dataa}
                  columns={columns}
                  bootstrap4={true}
                  search={searchProps}
                >
                  {props => (
                    <div >
                      <div style={{width: '200px', float:'left', zIndex:-1}}>
                      </div>
                      <div style={{width: '200px', float:'right',zIndex:-1}}>           
                      <table>
                        <tbody>
                          <tr>
                            <td style={{ position: 'relative' }}>
                              <SearchBar {...props.searchProps} {...searchProps} />
                              <div style={{ position: 'absolute', top: '40%', right: '10px', transform: 'translateY(-50%)' }}>
                                <NoirSearch size={24} />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      </div>
                      <BootstrapTable
                        {...props.baseProps}
                        striped 
                        hover
                        pagination={paginationFactory({ ...options, onPageChange: handlePageChange })}
                        classes="custom-table" 
                        rowClasses="custom-row"
                      />
                    </div>
                  )}
                </ToolkitProvider>
                <div style={{marginTop:'1vw'}}>{txtBL}</div>
              </div>

    </>

  )
}

export default Audit