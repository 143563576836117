import React from 'react'
import Cookies from 'universal-cookie';
import CreateTicket from './CreateTicket';
import CreateTicketExternal from './CreateTicketExternal';
import { useParams } from 'react-router-dom';
const cookies = new Cookies();
function CreateTicketParent({changeNavState, isDraft = "0"}) {
    const { id } = useParams();
    var data = cookies.get('sessionData', { path: '/' })
    var int_ext = data.int_ext;
    if (int_ext == "1"){
        return (
            <CreateTicket refId={id} changeNavState={changeNavState} isDraft={isDraft}/>
        )
    } else {
        return (
            <CreateTicketExternal refId={id} changeNavState={changeNavState} isDraft={isDraft}/>
        )
    }
    
}

export default CreateTicketParent