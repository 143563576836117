import React, { useState } from 'react'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import AttachmentHandler from '../ticketDetail/attachment/AttachmentHandler';

function UploadComp({onChangeStatus,refresh,atch=null,setAtchString,isDraft="0",isFaq=false}) {
  const [isExpanded, setIsExpanded] = useState(0);
  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    console.log(`File status: ${status}`, meta, file);
    
    // Call the callback function passed from the parent component
    onChangeStatus(meta, file, status);
  };
  // Function to override the default content of the file input

  const customStyles = {
     dropzone: {
      display: 'flex',
      flexDirection: 'row',
      overflowX: 'auto', 
      overflowY: 'auto',
      borderStyle: 'dashed',
      borderWidth: '2px 2px', 
      borderColor: '#B9BEC3',
    },
    inputLabelWithFiles: {
      display: 'none', // hide the input
    },
    preview :{
      height: '120px'
    },
    inputWithFilesContent:{
      borderBotton: 'none'
    },
    inputLabel :{
      color:'#737E86',
      fontFamily:'Blender Pro',
      fontSize:'18px'
    }
  };
  
  const handleImageClick = (val) => {
    if (isExpanded == 0){
      setIsExpanded(val);
    } else {
      setIsExpanded(0);
    }
  };
  const handlePdfClick = (link) => {
    //alert(link)
     window.open(link, '_blank');
   };

  const clearAtchHandler = (val) => {
    // Split the atch string into an array using "|"
    const atchArray = atch.split('|');

    // Find the index of the provided val in the array
    const indexToRemove = atchArray.indexOf(val);

    // If the val is found, remove it and adjust "|" separators
    if (indexToRemove !== -1) {
      atchArray.splice(indexToRemove, 1);
      const updatedAtch = atchArray.join('|');

      // If there are extra "|" separators, remove them
      const finalAtch = updatedAtch.replace(/\|+/g, '|');

      // You can update the original atch string if needed
      // atch = finalAtch;

      // Return the modified atch string
      setAtchString(finalAtch);
    }
  };

  return (
    <>
      <div className={isExpanded == 0 ? '' : 'divOverlay'}  onClick={() => handleImageClick(0)}>

      </div>
      <Dropzone
        key={refresh}
        onChangeStatus={handleChangeStatus}
        accept="*"
        styles={customStyles}
        inputContent ='Drag and Drop your file here'
      />
      <div className='row' style={{ marginTop: '3vw', }}>
        {atch && String(atch).split('|').map((attachment, index) => (
          <AttachmentHandler
            key={"atch"+index} 
            id={index + 1} 
            isExpanded={isExpanded}
            source={isFaq? `${process.env.REACT_APP_FAQ_BG_DESTINATION}${attachment}` : `${process.env.REACT_APP_IMG_DESTINATION}${attachment}`}
            handleImageClick={handleImageClick}
            handlePdfClick={handlePdfClick}
            fileName={attachment}
            clearHandler={clearAtchHandler}
            isDraft={isDraft}
          />
        ))}
      </div>
    </>
  )
}

export default UploadComp