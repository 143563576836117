import React from 'react'; import { useState, useEffect} from 'react';
import { Tooltip } from 'react-tooltip'; 
import { useNavigate } from 'react-router-dom';
import useOrientation from '../../general/useOrientation';
const maxCharacter= 35;

function UrgentTicket({ data }) {
  const navigate = useNavigate();
  const orientation = useOrientation();
  const [dueText, setDueText] = useState('');
  const [ppLink, setPpLink] = useState('');
  const [truncatedTitle, setTruncatedTitle] = useState('');
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  useEffect(() => {
    //truncated Title
    if (data.title.length > maxCharacter) {
      // If the title is longer than the maximum allowed characters, truncate it and add '...'
      const truncated = data.title.substring(0, maxCharacter) + '...';
      setTruncatedTitle(truncated);
    } else {
      setTruncatedTitle(data.title);
    }

    //time
    let a = new Date(data.created_at);
    // Get the date and time components
    const hours = a.getHours();
    const minutes = a.getMinutes();
    const day = a.getDate();
    const month = monthNames[a.getMonth()];
    const year = a.getFullYear();
    
    setDueText(`Submitted at ${hours}:${minutes} on ${day} ${month} ${year}`);

    var picture = "";
    //profile picture
    switch(data.status_id){
      case 1: 
        picture = "Waiting";
        break;
      case 2: 
        picture = "OnProgress";
        break;
      case 3: 
        picture = "Resolved";
        break;
    }
    setPpLink(`/dist/img/status/${picture}.png`);

  }, [data]);

  const redirectToTicketDetail = () => {
    navigate('/ticketDetail/'+data.id);
  }

    return (
<div className="row align-items-center " style={{ marginLeft: '0.9vw', marginBottom:'1vw' }} >
  <div className="col-md-2">

    <a href="#" className="no-hover" data-tooltip-id={"statusImage"+data.id} data-tooltip-content={data.status_name}>
      <img
        src={ppLink}
        alt="Status Logo"
        className="brand-image img-circle"
        style={{ height: "3vw", width:'3vw' }} 
      />
      <Tooltip id={"statusImage"+data.id} place="left" type="dark" effect="solid" style={{marginTop:'', marginLeft:'',}}/>
    </a>
  </div>
  <div className="col-md-10 cursorPointer" style={{ textAlign: "left" }} onClick={redirectToTicketDetail}>
    <p style={{ fontSize: "1vw", fontFamily:'Blender Pro Book' }}><b>{truncatedTitle}</b></p>
    <p style={{ marginTop: '-1vw', fontSize: "0.9vw", color: '#737E86', fontFamily:'Blender Pro' }}><b>{dueText}</b></p>
  </div>
</div>

    );
}

export default UrgentTicket