import React from 'react'
import TicketTable from '../ticketList/TicketTable'
import TicketTableDraft from '../ticketList/TicketTableDraft'
import DocumentLibrary from '../documentLibrary/DocumentLibrary'

function DocTableParent({link, refresh, setLoading,filterData}) {
    if (link === "docListToMe" || link === "docListByMe"){
        return (<TicketTable filterData={filterData} link={link} refresh={refresh} setLoading={setLoading} clickLink={"docDetail"}/>)
    } else if (link === "docListByMeDraft"){
        return (<TicketTableDraft link={link} refresh={refresh} setLoading={setLoading} clickLink={"docDetail"}/>)
    } else if (link === "docLibraryList"){
        return (<DocumentLibrary link={link} refresh={refresh} setLoading={setLoading} clickLink={"docDetail"}/>)
    } else {
        return null
    }
}
export default DocTableParent