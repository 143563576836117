import React from 'react'; import axios from 'axios' ;
import ContentHeader from '../../content/ContentHeader'; import { useState, useEffect, useRef} from 'react';
import Cookies from 'universal-cookie'; 
import {Download} from 'iconoir-react';
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
import BootstrapTable from 'react-bootstrap-table-next'
import {Search as NoirSearch, NavArrowRight } from 'iconoir-react'
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import NoPage from '../NoPage';
import { useNavigate } from 'react-router-dom';
import CountUp from './CountUp';
import { Tooltip } from 'react-tooltip';
import useOrientation from '../../general/useOrientation';
const cookies = new Cookies();
const MySwal = withReactContent(Swal)
const { SearchBar } = Search;

const dateFormatter = (data) => {
  var datee = new Date(data);
  const year = datee.getFullYear().toString().slice(-2); // Get the last 2 digits of the year
  const month = String(datee.getMonth() + 1).padStart(2, '0'); // Get the month (0-indexed) and pad with 0 if needed
  const day = String(datee.getDate()).padStart(2, '0'); // Get the day and pad with 0 if needed
  const hours = String(datee.getHours()).padStart(2, '0'); // Get the hours and pad with 0 if needed
  const minutes = String(datee.getMinutes()).padStart(2, '0'); // Get the minutes and pad with 0 if needed

  return <>{`${day}/${month}/${year} at ${hours}:${minutes}`}</>
}
    
const titleFormatter = (data,row) => {
  var dataa = "";
   if (row.tiket_referral_id != null){
    dataa = "[REF] ";
   }
   dataa += data ;
   if (dataa.length > 35) {
    dataa = dataa.substring(0, 35) + " ...";
  }
  return dataa
}

const isImage = (source) => {
  const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg'];
  const fileExtension = source.slice(((source.lastIndexOf(".") - 1) >>> 0) + 2);
  return imageExtensions.includes(`.${fileExtension.toLowerCase()}`);
};

const typeFormatter = (data,row) => {
  let typeString = "";

  if (row.attachment) {
    const fileNameParts = row.attachment.split('.');
    
    // Get the last part after the last '.'
    const fileExtension = fileNameParts[fileNameParts.length - 1];

    //process types


    const allowedExtensions = ['.xls', '.xlsx', '.pdf', '.mp4', '.doc', '.docx'];
    if (isImage(row.attachment)) {
      typeString = 'image';
    } else if (allowedExtensions.includes(`.${fileExtension.toLowerCase()}`)) {
      // Determine the type based on the allowed extensions
      if (fileExtension.toLowerCase() === 'xls' || fileExtension.toLowerCase() === 'xlsx') {
        typeString = 'excel';
      } else if (fileExtension.toLowerCase() === 'pdf') {
        typeString = 'pdf';
      } else if (fileExtension.toLowerCase() === 'mp4') {
        typeString = 'video';
      } else if (fileExtension.toLowerCase() === 'doc' || fileExtension.toLowerCase() === 'docx') {
        typeString = 'word';
      }
    } else {
      typeString = 'other';
    }

    const imageSource = `/dist/img/filesIcon/${typeString}.png`;

    return <>
    <a href="#" data-tooltip-id={`tipPP`+data} data-tooltip-content={typeString}>
      <img style={{ color: '#E8E9EB', width: '2vw', height: '2vw' }} src={imageSource} alt="icon" />
      <Tooltip id={`tipPP`+data} place="right" type="dark" effect="solid" />
    </a>
   
    </>;
  } else {
    return data
  }

}

const defaultSorted = [
  {
      dataField: "created_at",
      order: "desc"
  }
];

function TicketFiles({link, refresh, setLoading, isExpanded, setIsExpanded, imagePopup, setImagePopup}) {
  const clickedColumnIndex = useRef();
  const navigate = useNavigate();
  const displayedRow = 12;
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait') { isThisPortrait = true;}
  const [dataa, setDataa] = useState([]);
  const [totlength, setTotLength] = useState(0);
  const [txtBL, setTxtBL] = useState('');
  const [currentPage, setCurrentPage] = useState(0); // Track the current page
  useEffect(() => {
    setLoading(true)
    getData(link);
  }, [link,refresh])
  useEffect(() => {
    let cpmone = parseInt(currentPage) - 1 
    let firstDigit = (parseInt(displayedRow) * parseInt(cpmone) )
    let secondDigit = parseInt(firstDigit) + parseInt(displayedRow)
    if (secondDigit > totlength) secondDigit = totlength
    let a = `${firstDigit+1} - ${secondDigit} of ${totlength} data`   ;
    setTxtBL(a);
    if (totlength === 0){
      setTxtBL();
    }
  }, [currentPage,totlength])
    const downloadFormatter = (data,row) => {
       return <Download style={{color:'#0296D2'}} onClick={() => downloadHandler(row.attachment)}/>
    }
    const downloadHandler = (link) => {
        const downloadLink = process.env.REACT_APP_IMG_DESTINATION + link;
        // Fetch the file as a blob
        fetch(downloadLink)
          .then((response) => response.blob())
          .then((blob) => {
            // Create a URL for the blob
            const blobUrl = URL.createObjectURL(blob);
      
            // Create a virtual link element
            const linkElement = document.createElement('a');
      
            // Set the href attribute to the blob URL
            linkElement.href = blobUrl;
      
            // Set the download attribute with the desired file name
            linkElement.download = link;
      
            // Append the link to the document
            document.body.appendChild(linkElement);
      
            // Trigger a click on the link to start the download
            linkElement.click();
      
            // Remove the link from the document
            document.body.removeChild(linkElement);
      
            // Revoke the blob URL to free up resources
            URL.revokeObjectURL(blobUrl);
          })
          .catch((error) => {
            console.error('Error downloading the file:', error);
          });
    }

    const searchProps = {
      delay: 800,
      placeholder: isThisPortrait? "search...":"search files...",
      className: "searchRight",
      zIndex : 5,
      position: 'relative',
      height: '4px',
      fontFamily: 'Blender Pro !important'
    };

    const titleFormatterMobile = (data, row) => {
      var title = "";  let typeString = "";
      if (row.tiket_referral_id != null){
       title = "[REF] ";
      }
      title += row.title ;
      if (title.length > 50) {
        title = title.substring(0, 50) + "...";
      }
    
      let shortName = row.attachment ;
      if (shortName.length > 35) {
        shortName = shortName.substring(0, 35) + "...";
      }

      let shortName2 = row.display_name ;
      if (shortName2.length > 30) {
        shortName2 = shortName2.substring(0, 30) + "...";
      }

      const fileNameParts = row.attachment.split('.');
      // Get the last part after the last '.'
      const fileExtension = fileNameParts[fileNameParts.length - 1];
  
      //process types
      const allowedExtensions = ['.xls', '.xlsx', '.pdf', '.mp4', '.doc', '.docx'];
      if (isImage(row.attachment)) {
        typeString = 'image';
      } else if (allowedExtensions.includes(`.${fileExtension.toLowerCase()}`)) {
        // Determine the type based on the allowed extensions
        if (fileExtension.toLowerCase() === 'xls' || fileExtension.toLowerCase() === 'xlsx') {
          typeString = 'excel';
        } else if (fileExtension.toLowerCase() === 'pdf') {
          typeString = 'pdf';
        } else if (fileExtension.toLowerCase() === 'mp4') {
          typeString = 'video';
        } else if (fileExtension.toLowerCase() === 'doc' || fileExtension.toLowerCase() === 'docx') {
          typeString = 'word';
        }
      } else {
        typeString = 'other';
      }
  
      const imageSource = `/dist/img/filesIcon/${typeString}.png`;

      let createdDate = "";
      let datee = new Date(row.created_at);
      const year = datee.getFullYear().toString().slice(-2); // Get the last 2 digits of the year
      const month = String(datee.getMonth() + 1).padStart(2, '0'); // Get the month (0-indexed) and pad with 0 if needed
      const day = String(datee.getDate()).padStart(2, '0'); // Get the day and pad with 0 if needed
      const hours = String(datee.getHours()).padStart(2, '0'); // Get the hours and pad with 0 if needed
      const minutes = String(datee.getMinutes()).padStart(2, '0'); // Get the minutes and pad with 0 if needed
      createdDate= `${day}/${month}/${year} at ${hours}:${minutes}`
      return (
        <>
          <div className='row' style={{marginTop:'1vh', marginBottom:'1vh',marginLeft:'2vw', display: 'flex', alignItems: 'center'}}>
            <a href="#" data-tooltip-id={`tipPP`+data} data-tooltip-content={typeString}>
              <img style={{ color: '#E8E9EB', width: '5vh', height: '5vh', marginLeft:'-2vw' }} src={imageSource} alt="icon" />
              <Tooltip id={`tipPP`+data} place="right" type="dark" effect="solid" />
            </a>
            <div style={{margin: '-0.25vh 0 0 2vw'}}>
              <span style={{fontSize:'18px'}}>{shortName}</span> <br/>
              <span style={{fontSize:'15px', fontWeight:'100'}}>{shortName2} • {createdDate}</span><br/>
              <span style={{fontSize:'15px'}}>{title} </span>
            </div>
            <div style={{marginLeft:'auto', marginRight:'1vw'}}> {/* Set marginLeft to auto */}
              <Download style={{color:'#0296D2'}} onClick={() => downloadHandler(row.attachment)}/>
            </div> 
          </div>
        </>
      );      
    };

    const columnsMobile = [
      {
        text: 'Creator',
        dataField: 'attachment',
        sort: true,
        headerClasses: 'd-none',
        formatter:titleFormatterMobile,
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
              clickedColumnIndex.current = columnIndex;
          },
        },
      }
    ];
  
    const columns = [
        {
            text: 'Type',
            dataField: 'dupe_id',
            sort: true,
            formatter:typeFormatter
        },
        {
            text: 'File Name',
            dataField: 'attachment',
            sort:true,
            formatter:titleFormatter
        },
        {
            text: 'Subject',
            dataField: 'title',
            sort:true,
            formatter:titleFormatter
        },
        {
            text: 'Submit Date',
            dataField: 'created_at',
            sort:true,
            formatter: dateFormatter,
        },
        {
            text: 'Ticket Creator',
            dataField: 'display_name',
            sort:true
        },
        {
            text: 'Action',
            dataField: 'id',
            sort: true,
            formatter:downloadFormatter,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    clickedColumnIndex.current = columnIndex;
                },
            },
        },
    ];
  const getData = (link) => {
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    axios.interceptors.request.use(config => {config.headers['x-access-token'] = sessionToken;return config;},error => {return Promise.reject(error);});
    axios.get(`${process.env.REACT_APP_PATH_DESTINATION}/${link}`) 
      .then(res => {
        console.log("ticket files logging here",res.data.data);
        // Merge 'komen' and 'tiket' arrays into one array
        const mergedData = [...res.data.data.komen, ...res.data.data.tiket];
        // Sort the merged data based on the 'created_date' property in descending order
        const sortedData = mergedData.sort((a, b) => {
          // Assuming 'created_date' is a string in the format 'YYYY-MM-DD'
          const dateA = new Date(a.created_date);
          const dateB = new Date(b.created_date);

          // Compare the dates in descending order
          return dateB - dateA;
        });
        // Set the merged data to the state
        //setDataa(sortedData);
        const dataWithIds = sortedData.map((item, index) => ({ ...item, idSort: index + 1 }));
        setDataa(dataWithIds);
        setTotLength(sortedData.length);
        setCurrentPage(1);
        setLoading(false);
      })
      .catch(err => {
        /*
        console.log(err);
            MySwal.fire({
            title: <strong>Error!</strong>,
            html: <p>{err}</p>
        })*/
        setLoading(false);
      })
  }
  const options = {
    sortIndicator: true,
    bordered: false,
    hideSizePerPage: true, // Hide the option to change the number of rows
    sizePerPage: displayedRow, // You can set your desired default value here
    page: 1,
    showTotal: false, // Hide the total number of rows
    sizePerPageList: [], // Remove the size per page options from the dropdown
    defaultSorted: defaultSorted,
  };
  const handlePageChange = (type, { page, sizePerPage }) => {
    setCurrentPage(type); 
  };
  
  const tableRowEvents = {
    onClick: (e, row, rowIndex) => {
      //console.log('row',row); //got all the data u ever want here
      if (clickedColumnIndex.current === 5) {
        // Do nothing
      } else {
        if(isImage(row.attachment)){
          setImagePopup(row.attachment);
          setIsExpanded(1);
        } else {
          window.open(process.env.REACT_APP_IMG_DESTINATION + row.attachment, '_blank');
        }

      }
      // Reset clickedColumnIndex
      clickedColumnIndex.current = undefined;
    },
    onMouseEnter: (e, row, rowIndex) => {
      //console.log(`enter on row with index: ${rowIndex}`);
    }
  }

  return (
    <>
    <ContentHeader title=""/> 
        <section className="content">
        <div className="container-fluid">
            <div className="row"> 
              <div className="col-md-12">
                <ToolkitProvider
                  keyField="idSort"
                  data={dataa}
                  columns={isThisPortrait?columnsMobile:columns}
                  bootstrap4={true}
                  search={searchProps}
                >
                  {props => (
                    <div >
                      <div style={{width:isThisPortrait? '25vw':'200px', float:'right',zIndex:-1, marginRight:'1.6vw' }}>           
                      <table>
                        <tbody>
                          <tr >
                          <td>
                            <SearchBar {...props.searchProps} {...searchProps} />
                          </td>
                          </tr>
                        </tbody>
                      </table>
                      </div>
                      <BootstrapTable
                        {...props.baseProps}
                        striped 
                        hover
                        pagination={paginationFactory({ ...options, onPageChange: handlePageChange })}
                        classes="custom-table" 
                        rowClasses="custom-row"
                        rowEvents={ tableRowEvents }
                        defaultSorted={defaultSorted} 
                      />
                    </div>
                  )}
                </ToolkitProvider>
                <div style={{marginTop:'1vw', marginLeft:isThisPortrait?'3vw':'inherit'}}>{txtBL}</div>
              </div>
            </div>
        </div>
     </section>
    </>

  )
}

export default TicketFiles