import React from 'react'
import FormatDate from '../../../general/FormatDate'
import { useState } from 'react';
import { useEffect } from 'react';
import useOrientation from '../../../general/useOrientation';

function ForwardDisplay({data}) {
  const [photoLocal, setPhotoLocal] = useState("/dist/img/pp.png");
  const [dnLocal, setDnLocal] = useState("None");
  const [deptfnLocal, setDeptfnLocal] = useState("None");
  const [fwdAtLocal, setfwdAtLocal] = useState("-");
  const [fwdTID, setFwdTID] = useState("-");
  const [cnt, setCnt] = useState("-");
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait') { isThisPortrait = true;}

  useEffect(() => {
    try{
      setPhotoLocal(data.fwd_data.path_foto);
      setDnLocal(data.fwd_data.display_name);
      setDeptfnLocal(data.fwd_data.dept_fullname);
      setfwdAtLocal(data.forwarded_at);
      setFwdTID(data.tiket_forward_id);
      setCnt(data.fwd_konten);
    } catch {
      //do nothing
    }
  }, [data])

  if (!data || data.displayType == 1 || data.displayType == 0 || data.forwarded != 1) {
    // You can render a fallback UI or return null
    return null;
  }

  if (orientation === 'landscape')
  return (
    <div style={{marginBottom: '2vw', height:"auto", width:"", backgroundImage: `url('/dist/img/forwardBox.png')`, backgroundSize: 'contain', borderRadius:"8px"}}>
        <div style={{ marginLeft: '1.5vw', marginTop: '0.5vw', marginRight: '2vw', borderBottom:'1px solid #E8E9EB', paddingBottom:'0.5vw' }}>
            <div style={{ marginTop: '0.5vw', position: 'relative' }} className='row'>
            {/* 
            <a href="#" className="no-hover" data-tooltip-id={"creatorComment"+comment.id} data-tooltip-content={comment.display_name}>
            */}
            <img
                src={photoLocal}
                alt="Creator Profile Picture"
                className="brand-image img-circle"
                style={{ height: "2.5vw", marginTop: '0.7vw', position: 'absolute' ,marginLeft:'0.5vw'}}
            />
            {/*
            <Tooltip id={"creatorComment"+comment.id} place="bottom" type="dark" effect="solid" style={{marginTop:'4.5vw', marginLeft:'1.5vw'}}/>
            </a>
            */}
            
            <div style={{ marginTop: '0.3vw', marginLeft: '4vw', width:'100%' }}>
                <h5 style={{ fontSize: '1.5vw', fontFamily: 'Blender Pro', marginRight:'3vw' }}>{dnLocal}</h5>
                <p style={{ marginTop: '-1vw', fontSize: '1.1vw' }}>
                Forwarded to <span style={{color:"#0296D2"}}>{deptfnLocal}</span> at <FormatDate date={fwdAtLocal}/> <br/>
                <span style={{color:"#7A6ED0"}}>Forward ticket ID : <b>{fwdTID}</b></span>
                </p>
                <div style={{ marginTop: '1vw', fontSize: '1.1vw', fontFamily: 'Blender Pro' }}>
                <div dangerouslySetInnerHTML={{ __html: cnt }}></div>
              </div>

            </div>
            <div style={{ marginTop:'-2vw' }}>

            </div>
            </div>
        </div>
    </div>
  )

  if (orientation === 'portrait')
  return (
    <div style={{marginBottom: '2vh', height:"", width:"", backgroundImage: `url('/dist/img/forwardBox.png')`, backgroundSize: 'cover', borderRadius:"8px"}}>
        <div style={{ marginLeft: '1.5vh', marginTop: '0.5vh', marginRight: '2vh', borderBottom:'1px solid #E8E9EB', paddingBottom:'0.5vh' }}>
            <div style={{ marginTop: '0.5vh', position: 'relative' }} className='row'>
            {/* 
            <a href="#" className="no-hover" data-tooltip-id={"creatorComment"+comment.id} data-tooltip-content={comment.display_name}>
            */}
            <img
                src={photoLocal}
                alt="Creator Profile Picture"
                className="brand-image img-circle"
                style={{ height: "30px", marginTop: '0.7vh', position: 'absolute' ,marginLeft:'10px'}}
            />
            {/*
            <Tooltip id={"creatorComment"+comment.id} place="bottom" type="dark" effect="solid" style={{marginTop:'4.5vw', marginLeft:'1.5vw'}}/>
            </a>
            */}
            
            <div style={{ marginTop: '10px', marginLeft: '45px', width:'100%' , paddingBottom:'1vh'}}>
                <h5 style={{ fontSize: '15px', fontFamily: 'Blender Pro', marginRight:'3vh' }}>{dnLocal}</h5>
                <p style={{ marginTop: '-1vh', fontSize: '13px' }}>
                Forwarded to <span style={{color:"#0296D2"}}>{deptfnLocal}</span> at <FormatDate date={fwdAtLocal}/> <br/>
                <span style={{color:"#7A6ED0"}}>Forward ticket ID : <b>{fwdTID}</b></span>
                </p>
                <div style={{ marginTop: '1vh', fontSize: '13px', fontFamily: 'Blender Pro' }}>
                <div dangerouslySetInnerHTML={{ __html: cnt }}></div>
              </div>

            </div>
            <div style={{ marginTop:'-2vh' }}>

            </div>
            </div>
        </div>
    </div>
  )
}

export default ForwardDisplay