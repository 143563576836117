import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import Cookies from 'universal-cookie';
import axios from 'axios' 
import useOrientation from '../../general/useOrientation';
const cookies = new Cookies();

const TicketDropdown = ({type,title,link,pickDropdown,isMulti=false,refresh,defValue="",setRefresh}) => {
  // State to store the selected option
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState();
  const myVariableRef = useRef(false);
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait')
    isThisPortrait = true;
  useEffect(() => {
    if(link === ''){
      setOptions([{
          value: "0",
          label: "Please Choose Previous Dropdown First",
      },]);
      setSelectedOption(null); 
    } else {
      setSelectedOption(null); 
      getData();
    }
  }, [link,refresh,defValue,myVariableRef.current])

  useEffect(() => {
    // Set default values after options have been updated
    if (options && defValue != '' && type != "3" && type != "7") {
      //alert("Setting default values type " + type + " defValue = " + defValue);
      const defaultOption = options.find((option) => option.value === defValue);
      if (defaultOption) {
        //alert("default option enter type " + type + " defValue = " + defValue);
        setSelectedOption(defaultOption);
        pickDropdown(defaultOption);
      } else {
        setSelectedOption(null);
      }
    } else if (options && defValue != '' && type == "7") {
      const selectedOptions = defValue
        .split(',')
        .map((value) => parseInt(value, 10)) // Assuming the values are integers

      // Check if options is defined before using filter
      if (options) {
        const defaultOptions = options.filter((option) => selectedOptions.includes(option.value));
        setSelectedOption(defaultOptions);
      }
    }
  }, [options, defValue, type]);

  // Event handler for option selection
  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    //add dropdown here to parent
    pickDropdown(selectedOption)
  };
  
  const getData = () => { 
    //console.log("get data type " + type);
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    const config = {headers:{['x-access-token']: sessionToken}};
    var dbpath = process.env.REACT_APP_PATH_DESTINATION + link;   
    axios.get( dbpath, config)
      .then(res => { 
        console.log("res.data.data dropdown")
        console.log(res.data.data);
        if (res.data.status == "200"){
          if(type == 1){
            setOptions( res.data.data.map((option) => ({
              value: option.id,
              label: option.dept_name + " - " + option.dept_fullname,
            })))
          } else if (type == 2){
            setOptions( res.data.data.map((option) => ({
              value: option.id,
              label: option.kategori_name,
            })))
          } else if (type == 3) {
            setOptions([]);
            for (var i=0; i< res.data.data.length;i++ ){
              //console.log(res.data.data[i]);
              let a = String(res.data.data[i]).split(',');
              const option = {
                value: a[0],
                label: a[1],
              };
              // Append the option to the existing options state
              setOptions((prevOptions) => [...prevOptions, option]);
            }
          } else if (type == 4){
            setOptions( res.data.data.map((option) => ({
              value: option.id,
              label: option.parent_name,
            })))
          } else if (type == 5){
            setOptions([]);
            for (var i=0; i< res.data.data.length;i++ ){
              const option = {
                value: res.data.data[i],
                label: res.data.data[i],
              };
              // Append the option to the existing options state
              setOptions((prevOptions) => [...prevOptions, option]);
            }
          } else if (type == 6){
            setOptions( res.data.data.map((option) => ({
              value: option.id,
              label: option.int_or_ext_name,
            })))
          } else if (type == 7){
            setOptions([]);
              for (var i=0; i< res.data.data.length;i++ ){
                const option = {
                  value: res.data.data[i].id,
                  label: res.data.data[i].group_name,
                };
              // Append the option to the existing options state
              setOptions((prevOptions) => [...prevOptions, option]);
            }
          }
        }  else {
          //maybe error log / alert here somehow
        }
        //setting the default value here 
        if (options && defValue != '' && isMulti == false) {
          console.log("if (defValue != '' && isMulti == false) type = " + type)
          console.log("enter def value defined type  " + type);
          // find the option with the specified value
          const defaultOption = options.find((option) => option.value == defValue);
          // if the option is found, set it as the default selected option
          if (defaultOption) {
            //console.log("enter if define value type  " + type);
            setSelectedOption(defaultOption);
            pickDropdown(defaultOption)
          } else {
            //console.log("enter else define value type  " + type);
            myVariableRef.current = true;
            setSelectedOption(null);
          }
        } else if (options && defValue != '' && type == "3" ){
          //console.log('else if (defValue != "" && type == "3" ) type =' + type)
          // For multi-select, defValue is a comma-separated string of selected values
          const defaultValues = defValue.split(',');
          // find the options with the specified values
          const defaultOptions = options.filter((option) => defaultValues.includes(option.value));
          //console.log("defaultoptions dropdown ismulti", defaultOptions);
          // if options are found, set them as the default selected options
          if (defaultOptions.length > 0) {
            setSelectedOption(defaultOptions);
            pickDropdown(defaultOptions);
          } else {
            myVariableRef.current = true;
          }
        } 
      })
  }

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: '8px',
      padding: '-2vw -5vw -2vw -5vw',
      fontSize: '15px',
      width: isThisPortrait? '100%': '95%',
      border: '0px',
      backgroundColor: '#F5F5F6',
      color: '#FFFFFF',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#0296D2' : '#fff', // Change the color here
      color: state.isSelected ? '#fff' : '#404041',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#404041',
      fontFamily : 'Blender Pro',
      fontSize:'15px'
    }),
    menu: (provided) => ({
      ...provided,
      width: isThisPortrait? '100%':'95%',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#D6EBF6', 
      borderRadius: '8px'
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#01648C', 
      fontFamily: 'Blender Pro',
      fontSize:'15px'
    }),
  };

  return (
    <>
    <div style={{marginTop: '1vw'}}>
        <p style={{fontFamily: 'Blender Pro', fontSize:'15px'}}>{title}</p>
    </div>
    <div style={{marginTop:'0.5vw'}}>
      <Select
        isMulti={isMulti}
        id="dropdown"
        value={selectedOption}
        onChange={handleSelectChange}
        options={options}
        placeholder="Select"
        styles={customStyles}
      />
    </div>
    </>
  );
};

export default TicketDropdown;
