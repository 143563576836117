import React, { useEffect, useState } from 'react'
import useOrientation from '../../../general/useOrientation';

function ImageAttachment({id,isExpanded,source,handleImageClick,fileName,clearHandler=null,isDraft="0"}) {
  const marginTopStyle = Math.floor((id - 1) / 4) * 11 + 'vw';
  const marginTopStyleM = Math.floor((id - 1) / 3) * 28 + 'vw';
  const [showImg, setShowImg] = useState(false);
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait')
    isThisPortrait = true;

  const handleEmbiggen = () => {
    handleImageClick(id);
    
    setShowImg(true);
  };

  useEffect(() => {
    if(isExpanded === 0 ){
      setShowImg(false)
    } 
  }, [isExpanded])

  return (
    <div className={isThisPortrait?'col-4':'col-md-3'} style={{marginTop:isThisPortrait?'3vw':'2vw'}}>
        <img
            src={source}
            alt={`Attachment ${id}`} 
            className={showImg === true ? ( isThisPortrait? 'expandedM':'expanded' ) : ''}
            style={{
                width: isThisPortrait? '25vw' : '9.375vw',
                height: isThisPortrait? '25vw' :'9.375vw',
                objectFit: 'cover', 
                position: 'absolute',
                borderRadius: '8px',
                marginTop: isThisPortrait?marginTopStyleM:marginTopStyle
            }}
            onClick={() => handleEmbiggen()}
        />
        <br/>
        {isDraft =="1" &&(
          <button style={{marginLeft:isThisPortrait?'0vh' :'2vw',marginTop:isThisPortrait?'15vh' : '9vw', display:'flex', alignItems:'center',justifyContent:'center'}} className="btn btnSubmitTicketMobile" type="button" onClick={() => clearHandler(fileName)}>
          Remove
        </button>
        )}
    </div>
  )
}

export default ImageAttachment