import React from 'react'; import axios from 'axios' ;
import ContentHeader from '../../content/ContentHeader'; import { useState, useEffect} from 'react';
import Cookies from 'universal-cookie'; 
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
import BootstrapTable from 'react-bootstrap-table-next'
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import NoPage from '../NoPage';
const cookies = new Cookies();
const MySwal = withReactContent(Swal)
const { SearchBar } = Search;

const actionFormatter = (data) => {
    if (data && data != 'undefined'){
        return <>
        <button title="change active status" onClick={() => handleViewDetail(data)} className="btn btn-warning">
            <i className="fa fa-eye"></i>
        </button>
        </>
    } else {
        return <></>
    }
}

const dateFormatter = (data) => {
    var datee= new Date(data);
    return <>{datee.toLocaleDateString() + " " + datee.toTimeString().substr(0,8)}</>
}
    
const handleViewDetail = (data) => {
    MySwal.fire({
        title: <strong>Details</strong>,
        html: <p>{data}</p>
    })
}
  
    const columns = [
      {
        text: 'Date',
        dataField: 'created_at',
        sort: true,
        formatter: dateFormatter
      },
      {
        text: 'Creator',
        dataField: 'fullname',
        sort:true
      },
      {
        text: 'Doc Name',
        dataField: 'doc_name',
        sort:true
      },
      {
        text: 'Doc Number',
        dataField: 'doc_number',
        sort:true
      }

    ];
  
    const options = {
      sortIndicator: true,
      bordered: false,
      hideSizePerPage: true,
      sizePerPage: 15,
      page: 1
    };
    
    const searchProps = {
      delay: 800,
      placeholder: "search table...",
      className: "searchRight"
    };
    
    const defaultSorted = [
      {
        dataField: "order",
        order: "desc"
      }
    ];
function AuditDoc() {
    const [dataa, setDataa] = useState([]);
  useEffect(() => {
    getData();
  }, [])
  
  const getData = () => {
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    axios.interceptors.request.use(config => {config.headers['x-access-token'] = sessionToken;return config;},error => {return Promise.reject(error);});
    axios.get(`${process.env.REACT_APP_PATH_DESTINATION}/auditDocList`) 
      .then(res => {
        console.log(res.data.data);
        setDataa(res.data.data);
      })
      .catch(err => {
        console.log(err);
        MySwal.fire({
          title: <strong>Error!</strong>,
          html: <p>{err}</p>
      })
      })
  }    
  var data = cookies.get('sessionData', { path: '/' })
  if (data.apps_roles_id > 2) {
    return ( <NoPage />)
  }
  return (
    <>
    <ContentHeader title="Document Audit List"/> 
        <section className="content">
        <div className="container-fluid">
            <div className="row"> 
              <div className="col-md-12">
              
                <ToolkitProvider
                  keyField="id"
                  data={dataa}
                  columns={columns}
                  bootstrap4={true}
                  search={searchProps}
                >
                  {props => (
                    <div>
                      <div style={{width: '200px', float:'left'}}>
                      </div>
                      <div style={{width: '200px', float:'right'}}>
                      <table>
                        <tbody>
                        <tr>
                          <td><i className="fa fa-search" aria-hidden="true"></i></td>
                          <td><SearchBar {...props.searchProps} {...searchProps} /></td>
                        </tr>
                        </tbody>
                      </table>
                      </div>
                      
                      <BootstrapTable
                        {...props.baseProps}
                        striped 
                        hover
                        pagination={paginationFactory({
                          sizePerPage: 25 
                        })}

                      />
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            </div>
        </div>
    </section>
    </>

  )
}

export default AuditDoc