import React from 'react'; 
import ContentHeader from './ContentHeader';
import BackButton from './BackButton';
import { useParams } from 'react-router-dom';

export default function FormExample() {
  const { type} = useParams();
  return (
    <>
    <BackButton link={'/dashboard/'+type} />
        <ContentHeader title="A Long HTML Document Example" />
<div>
  
  <div className="container">
    <div className="left-column">
      <h2>Introduction</h2>
      <p>This is a sample HTML document that demonstrates how a longer document can be structured using HTML. It includes a variety of elements such as headings, paragraphs, lists, images, and more.</p>
      <h2>History of HTML</h2>
      <p>HTML (HyperText Markup Language) is the standard markup language for creating web pages. It was created by Tim Berners-Lee, a British computer scientist, in 1990. The first version of HTML, HTML 1.0, was released in 1993. Since then, there have been many updates to the language, with the latest version being HTML5.</p>
      <p>HTML is used to create the structure and content of web pages, while CSS (Cascading Style Sheets) is used to add style and layout to web pages. JavaScript is used to add interactivity to web pages.</p>
      <h2>HTML Elements</h2>
      <p>HTML elements are the building blocks of HTML pages. Some common HTML elements include headings (h1, h2, h3, etc.), paragraphs (p), lists (ul, ol, li), images (img), links (a), and more. Each element has a specific purpose and can be customized using attributes.</p>
      <h2>HTML Attributes</h2>
      <p>HTML attributes provide additional information about HTML elements. For example, the src attribute is used to specify the source of an image in an `img` element, and the href attribute is used to specify the URL of a link in an `a` element. Attributes are always specified within the opening tag of an HTML element.</p>
    </div>
    <div className="right-column">
      <h2>HTML Lists</h2>
      <p>HTML provides several types of lists for organizing content, including unordered lists (ul), ordered lists (ol), and definition lists (dl).</p>
      <h3>Unordered List</h3>
      <ul>
        <li>Item 1</li>
        <li>Item 2</li>
        <li>Item 3</li>
      </ul>
      <h3> Ordered List</h3>
      <ol>
        <li>Item 1</li>
        <li>Item 2</li>
        <li>Item 3</li>
      </ol>
      <h3>Definition List</h3>
      <dl>
        <dt>Term 1</dt>
        <dd>Definition 1</dd>
        <dt>Term 2</dt>
        <dd>Definition 2</dd>
        <dt>Term 3</dt>
        <dd>Definition 3</dd>
      </dl>
      <h2>HTML Images</h2>
      <p>The `img` element is used to embed images in an HTML document. The `src` attribute is used to specify the source URL of the image, while the `alt` attribute is used to provide alternative text for the image in case it cannot be displayed. The following is an example of how to embed an image in HTML:</p>
      <pre>&lt;img src="image.jpg" alt="A sample image"&gt;{"\n"}{"      "}</pre>
    </div>
    <div className="clearfix" />
  </div>
</div>






    </>
  )
}
