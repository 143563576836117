import React, { createContext, useState, useEffect } from 'react';

// Create a context to store geolocation data
export const GeolocationContext = createContext(null);

export const GeolocationProvider = ({ children }) => {
  const [geolocationData, setGeolocationData] = useState({ status: 'unknown' });

  const getGeolocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setGeolocationData({ status: 'success', latitude, longitude });
        },
        (error) => {
          setGeolocationData({ status: 'rejected', message: error.message });
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 30000,
        }
      );
    } else {
      setGeolocationData({ status: 'not_supported' });
    }
  };

  // Get geolocation when the component mounts
  useEffect(() => {
    //getGeolocation();
  }, []);

  // Return the provider with geolocation data
  return (
    <GeolocationContext.Provider value={geolocationData}>
      {children}
    </GeolocationContext.Provider>
  );
};
