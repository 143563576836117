import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { WarningCircle } from 'iconoir-react';

function NewPass() {
  const { token } = useParams();
  const [errorMsg, setErrorMsg] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const handlePasswordChange = (txt) => {
    setPassword(txt);
  };

  const handleRepeatPasswordChange = (txt) => {
    setRepeatPassword(txt);
  };

  const handleSubmit = function(e) {
    e.preventDefault();
    // Check if passwords match
    if (password !== repeatPassword) {
        setErrorMsg("Passwords don't match");
        return;
    }

    axios.post(`${process.env.REACT_APP_PATH_DESTINATION}/setNewPass`, { newPass: password, token: token })
    .then(function (res) {
      console.log(res.data);
      setErrorMsg(res.data.message);
    })
    .catch(err => {
      console.log(err)
    })
  }

  return (
    <div className="">
    <div className="row" >
      <div className="col-sm-6 col-md-6">
        <div className="hold-transition login-page" style={{ backgroundColor: 'white' }}>
        <img src="/dist/img/logo.png" className="rounded" alt="logo" style={{ width: '16vw', height: 'auto', position: 'absolute', top: '0', left: '0' , padding:'2%',marginLeft:'-1.5vw'}} />
            <div className="login-box">
              <div className="" style={{paddingTop:"20px"}}>
                <div className="card-body login-card-body">
                <div className="login-logo" style={{textAlign: 'left'}}>
                  <p style={{fontFamily:'Blender Pro'}}><b>Set New Password </b></p>
                  
                </div>
                  <form onSubmit={handleSubmit}>
                   <div className="bottomPad" ><b>New Password</b> <br/></div>
                    <div className="input-group mb-3">
                      <input type="password" className="form-control hdInput" placeholder="Input New Password" name="password" autoComplete="off" value={password} onChange={(e) => handlePasswordChange(e.target.value)}/>
                    </div>
                    <div className="bottomPad" ><b>Repeat New Password</b> <br/></div>
                    <div className="input-group mb-3">
                      <input type="password" className="form-control hdInput" placeholder="Repeat New Password" name="repeatpass" autoComplete="off" value={repeatPassword} onChange={(e) => handleRepeatPasswordChange(e.target.value)}/>
                    </div>
                   
                    <div style={{ textAlign: 'left', width: '100%' }}>
                      {errorMsg.length > 0 && (
                        <div className="input-group mb-3 font-befa">
                          <a className="form-control hdErrorInput">
                            <WarningCircle width="16px" color="red" /> {errorMsg} 
                          </a>
                        </div>
                      )}
                      <div style={{ paddingBottom: "3px" }} ></div>
                    </div>

                    <br/>
                    <div className="row">
                      <div className="col-12">
                        <button type="submit" className="btn btn-primary btn-block font-befa" style={{ borderRadius: "8px", backgroundColor: "#0296D2"}} >Change Password</button>
                      </div>
                    </div>
                    <br/>
                    
                  </form>
                  
                  <div className="col-md-6 custom-footer">
                    By signing in you understand and acknowledge <a href="/privacyPolicy">Privacy Policy</a>
                  </div>
                </div>
              </div>
              <div id="requirement" />
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-6" style={{ backgroundImage: `url("/dist/img/loginpage.jpg")`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
          {/* Content inside the div */}
        </div>



    </div>
  </div>
  );
}

export default NewPass;
