import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import Cookies from 'universal-cookie';
import axios from 'axios' 
const cookies = new Cookies();

const ConfigDropdown = ({type,title,link,pickDropdown,isMulti=false,refresh,defValue=""}) => {
// State to store the selected option
const [selectedOption, setSelectedOption] = useState(null);
const [options, setOptions] = useState();
const myVariableRef = useRef(false);
useEffect(() => {
  if(link === ''){
    setOptions([{
        value: "0",
        label: "Please Choose Previous Dropdown First",
    },]);
    setSelectedOption(null); 
  } else {
    setSelectedOption(null); 
    getData();
  }
}, [link,refresh,defValue,myVariableRef.current])

useEffect(() => {
    // PART 2 STARTS HERE - setting the defaults
    if (options && defValue != '' && isMulti == false) {
      // find the option with the specified value
      const defaultOption = options.find((option) => option.value == defValue);
      // if the option is found, set it as the default selected option
      if (defaultOption) {
        // console.log("enter if define value type  " + type);
        setSelectedOption(defaultOption);
        pickDropdown(defaultOption)
      } else {
        // console.log("enter else define value type  " + type);
        myVariableRef.current = true;
        setSelectedOption(null);
      }
    } else if (options && defValue != '' && type == "3") {
      const defaultValues = defValue.split(',');
      //const defaultOptions = options.filter((option) => defaultValues.includes(option.value));
      const defaultOptions = options.filter(option => defaultValues.includes(option.value.toString()));
      // if options are found, set them as the default selected options
      if (defaultOptions.length > 0) {
        setSelectedOption(defaultOptions);
        pickDropdown(defaultOptions);
      } else {
        myVariableRef.current = true;
      }
    } /*else if (options && defValue != '' && type == '4') {
      
      const defaultValues = defValue.split(',');
      //const defaultOptions = options.filter((option) => defaultValues.includes(option.value));
      //const defaultOptions = options.filter(option => defaultValues.includes(option.value));
      const defaultOptions = options.filter(option => defaultValues.includes(option.value.toString()));
      // if options are found, set them as the default selected options
      if (defaultOptions.length > 0) {
        setSelectedOption(defaultOptions);
        pickDropdown(defaultOptions);
      } else {
        myVariableRef.current = true;
      }

      console.log("type 4 stuff");
      console.log("defaultValues",defaultValues);
      console.log("defaultOptions",defaultOptions);
      console.log("options",options);
    } */
}, [options, defValue, isMulti, type])

useEffect(() => {
  // Set default values after options have been updated
  if (options && defValue != '' && type != "3" && type != "7" && type != "10") {
    //alert("Setting default values type " + type + " defValue = " + defValue);
    const defaultOption = options.find((option) => option.value === defValue);
    if (defaultOption) {
      //alert("default option enter type " + type + " defValue = " + defValue);
      setSelectedOption(defaultOption);
      pickDropdown(defaultOption);
    } else {
      setSelectedOption(null);
    }
  } else if (options && defValue != '' && (type == "7" || type == "10")) {
    const selectedOptions = defValue
      .split(',')
      .map((value) => parseInt(value, 10)) // Assuming the values are integers

    // Check if options is defined before using filter
    if (options) {
      const defaultOptions = options.filter((option) => selectedOptions.includes(option.value));
      setSelectedOption(defaultOptions);
    }
  }
}, [options, defValue, type]);

// Event handler for option selection
const handleSelectChange = (selectedOption) => {
  setSelectedOption(selectedOption);
  //add dropdown here to parent
  pickDropdown(selectedOption)
};

const getData = async () => { 
  // PART 1 STARTS HERE
  // console.log("get data type " + type);
  var sessionToken = cookies.get('sessionToken', { path: '/' })
  const config = {headers:{['x-access-token']: sessionToken}};
  var dbpath = process.env.REACT_APP_PATH_DESTINATION + link;   

  try {
    const res = await axios.get(dbpath, config);
    if (res.data.status == "200") {
      if (type == 1) {
        setOptions(res.data.data.map((option) => ({
          value: option.id,
          label: option.kode_department + " - " + option.nama_department,
        })))
      } else if (type == 2) {
        setOptions(res.data.data.map((option) => ({
          value: option.id,
          label: option.kategori_name,
        })))
      } else if (type == 3) {
        setOptions(res.data.data.map((option) => ({
          value: option.id,
          label: option.fullname,
        })))
      } else if (type == 4) {
        //console.log("res.data.data type 4",res.data.data);
        setOptions(res.data.data.map((option) => ({
          value: option.id,
          label: option.parent_name,
        })))
      } else if (type == 5) {
        setOptions([]);
        for (var i = 0; i < res.data.data.length; i++) {
          const option = {
            value: res.data.data[i].id,
            label: res.data.data[i].tenant_blok,
          };
          setOptions((prevOptions) => [...prevOptions, option]);
        }
      } else if (type == 6) {
        setOptions(res.data.data.map((option) => ({
          value: option.id,
          label: option.int_or_ext_name,
        })))
      } else if (type == 7) {
        setOptions([]);
        for (var i = 0; i < res.data.data.length; i++) {
          const option = {
            value: res.data.data[i].id,
            label: res.data.data[i].group_name,
          };
          setOptions((prevOptions) => [...prevOptions, option]);
        }
      } else if (type == 8) {
        setOptions(res.data.data.map((option) => ({
          value: option.id,
          label: option.group_name,
        })))
      } else if (type == 9) {
        setOptions(res.data.data.map((option) => ({
          value: option.id,
          label: option.nama_tenant,
        })))
      } else if (type == 10) {
        setOptions([]);
        for (var i = 0; i < res.data.data.length; i++) {
          const option = {
            value: res.data.data[i].id,
            label: res.data.data[i].kode_department + " - " + res.data.data[i].nama_department,
          };
          setOptions((prevOptions) => [...prevOptions, option]);
        }
      }
    } else {
      // maybe error log / alert here somehow
    }

  } catch (error) {
    // Handle error here
    console.error("Error fetching data:", error);
  }
}


const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '8px',
    padding: '-2vw -5vw -2vw -5vw',
    fontSize: '15px',
    width: '95%',
    border: '0px',
    backgroundColor: '#F5F5F6',
    color: '#FFFFFF',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#0296D2' : '#fff', // Change the color here
    color: state.isSelected ? '#fff' : '#404041',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#404041',
    fontFamily : 'Blender Pro',
    fontSize:'15px'
  }),
  menu: (provided) => ({
    ...provided,
    width: '95%',
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#D6EBF6', 
    borderRadius: '8px'
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: '#01648C', 
    fontFamily: 'Blender Pro',
    fontSize:'15px'
  }),
};

return (
  <>
  <div style={{marginTop: '1vw'}}>
      <p style={{fontFamily: 'Blender Pro'}}>{title}</p>
  </div>
  <div style={{marginTop:'0.5vw',width:'75vw'}}>
    <Select
      isMulti={isMulti}
      id="dropdown"
      value={selectedOption}
      onChange={handleSelectChange}
      options={options}
      placeholder="Select"
      styles={customStyles}
    />
  </div>
  </>
);
};

export default ConfigDropdown;
