import React from 'react';
import { useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import FormBuilder from 'react-form-builder2';
import 'react-form-builder2/dist/app.css';
import Cookies from 'universal-cookie'; 
import axios from 'axios' ;
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
import ContentHeader from '../../content/ContentHeader';
import BackButton from '../../content/BackButton';
const cookies = new Cookies();
const MySwal = withReactContent(Swal)
function FormCreator() {
  const { id , docName } = useParams();
  const [dataa, setDataa] = useState([]);
  const [formData, setformData] = useState([]);
  const [dataReady, setDataReady] = useState(false);
  const onChange = (data) => {
    setDataa(data);
  };

  useEffect(() => {
    getData();
  }, [])

  const getData = () => {
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    var path = process.env.REACT_APP_PATH_DESTINATION + "/viewDocMasterDemo/" + id;   
    const config = {headers:{['x-access-token']: sessionToken}};
    axios.get(path , config)
      .then(res => {
        if (res.data.data.doc_content && String(res.data.data.doc_content) != "{}") {
          setformData(JSON.parse(res.data.data.doc_content));
          setDataReady(true);
        } else {
          setDataReady(true);
        }
        Swal.close()
      })
      .catch(err => {
        console.log(err);
        Swal.close()
      })
  } 

  const onSubmit = () => {
    const dataSend = {
      task_data : dataa.task_data,
      doc_master_id: id 
    }
    console.log(dataSend);
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    var path = process.env.REACT_APP_PATH_DESTINATION + "/editDocMasterContent";   
    const config = {headers:{['x-access-token']: sessionToken}};
    axios.post(path, {data: dataSend}, config)
    .then(function (response) {
      if (response.data.status == '200')
      MySwal.fire({
        title: <strong>Success!</strong>,
        html: <p>{response.data.message}</p>,
        icon: 'success'
      }).then(() => {
        window.location.reload(false);
      })
    else 
      MySwal.fire({
        title: <strong>Failed!</strong>,
        html: <p>{response.data.message}</p>,
        icon: 'error'
      })
    })
    .catch(function (error) {
        console.log(error);
        MySwal.fire({
          title: <strong>Server Error!</strong>,
          html: <p>Something in the server went wrong. Please contact administrator</p>,
          icon: 'error'
        })
    });
  }
  if (!dataReady) {
    return <ContentHeader title="Loading Form Data..."/>;
  } else {
    return (<>
      <BackButton link="../../documentList"/>
      <ContentHeader title={"Edit "+docName}/>
      <FormBuilder.ReactFormBuilder
        onPost={onChange}
        data={formData}
      />
      <button type="submit" onClick={onSubmit} className="btn btn-primary" style={{ display: 'block', margin: 'auto', padding: '10px'}}>Submit</button>
    </>);
  }
}

export default FormCreator;