import React from 'react'
import { useEffect,useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useOrientation from '../../general/useOrientation';
import Cookies from 'universal-cookie'; 
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
import axios from 'axios' 
import UploadComp from '../createTicket/UploadComp';

const cookies = new Cookies();
const MySwal = withReactContent(Swal)
const sessionData = cookies.get('sessionData', { path: '/' })

function DocLibAddEdit({addEdit, changeNavState}) {
  const navigate = useNavigate(); 
  const orientation = useOrientation();
  const { id } = useParams();  
  const [draft, setDraft] = useState('0');
  const [extGroup, setExtGroup] = useState([]);
  const [extGroupChosen, setExtGroupChosen] = useState('1');
  const [docLibName, setDocLibName] = useState('');
  const [refreshDropdown, setRefreshDropdown] = useState(false);
  const [oldAttachmentContent, setOldAttachmentContent] = useState('');
  const [oldAttachmentBG, setOldAttachmentBG] = useState('');
  const [uploadedFilesContent, setUploadedFilesContent] = useState([]);
  const [uploadedFilesBG, setUploadedFilesBG] = useState([]);
  
  useEffect(() => {
    changeNavState('Document');
    if (addEdit == "edit"){
        getData();
    }
    getExtGroup();
  }, [])

  const handleExtGroupChosen = (e) => {
    setExtGroupChosen(e.target.value); 
  };
  const handleDocLibName = (e) => {
    setDocLibName(e.target.value); 
  };

  const handleChangeOldAtchContent = (value) => {
    setOldAttachmentContent(value);
  }
  const handleChangeOldAtchBG = (value) => {
    setOldAttachmentBG(value);
  }

    // Callback function to handle file and metadata on change status
    const handleFileChangeContent = (meta, file, status) => {
      if (status === 'done') {
          // File has been successfully uploaded
          setUploadedFilesContent((prevFiles) => [...prevFiles, { meta, file }]);
      } else if (status === 'removed') {
          // File has been removed
          setUploadedFilesContent((prevFiles) =>
          prevFiles.filter((uploadedFile) => uploadedFile.file !== file)
          );
      }
      // Add more conditions based on your requirements

      console.log("upload files logging",uploadedFilesContent);
  };
  
  // Callback function to handle file and metadata on change status
  const handleFileChangeBG = (meta, file, status) => {
      if (status === 'done') {
          // File has been successfully uploaded
          setUploadedFilesBG((prevFiles) => [...prevFiles, { meta, file }]);
      } else if (status === 'removed') {
          // File has been removed
          setUploadedFilesBG((prevFiles) =>
          prevFiles.filter((uploadedFile) => uploadedFile.file !== file)
          );
      }
      // Add more conditions based on your requirements
      console.log("upload files logging",uploadedFilesBG);
  };

  
  const handleAction = () => { 
    var sessionToken = cookies.get('sessionToken', { path: '/' });
    const config = { headers: { 'x-access-token': sessionToken } };
    let apiPath = process.env.REACT_APP_PATH_DESTINATION;
    if (addEdit == "edit"){
      apiPath += "/docLibEdit";
    } else {
      apiPath += "/docLibAdd";
    }
  
    // Create a FormData object
    const formData = new FormData();
    let lengthFile = 0;
    let whichChanged = 1;
    // Append the uploaded files to the FormData object
    uploadedFilesBG.forEach((file) => {
      if (uploadedFilesBG.length === 0) {
        //no file
      } else {
        lengthFile += 1;
        formData.append('files',file.file);
      }
    });
    uploadedFilesContent.forEach((file) => {
      if (uploadedFilesContent.length === 0) {
        //no file
      } else {
        lengthFile += 1;
        formData.append('files',file.file);
        if (lengthFile === 1){
          whichChanged = 2;
        } 
        //appending. if file is length 2 then only the second file changed. 
        //if which changed is 1 - means only first file is changed. limiting the loop process in BE 
      }
    });
  
    // Append other data to the FormData object
    formData.append('extGroupId', extGroupChosen);
    formData.append('name', docLibName);
    formData.append('id', id);
    formData.append('whichChanged', whichChanged);
    //alert(apiPath);
    axios.post(apiPath, formData, config)
      .then(res => {
        console.log(res.data);
        if (res.data.status === "200") {
          MySwal.fire({
            title: <strong>Success</strong>,
            html: <p>Data Edited </p>
          })
        } else {
          MySwal.fire({
            title: <strong>Error!</strong>,
            html: <p>Error BE Edit Data </p>
          })
        }
      })
      .catch(error => {
        MySwal.fire({
          title: <strong>Error!</strong>,
          html: <p>Error Axios or path </p>
        })
      });
  }

  const verifyDelete = () => {
    MySwal.fire({
      title: <strong>Are you sure to Delete?</strong>,
      html: <p>This action cannot be undone!</p>,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete();
      }
    });
  };

  const handleDelete = () => {
    var sessionToken = cookies.get('sessionToken', { path: '/' });
    const config = { headers: { 'x-access-token': sessionToken } };
    
    let apiPath = process.env.REACT_APP_PATH_DESTINATION + "/docLibDelete/" + id;

    axios.get(apiPath, config)
      .then(res => {
        console.log(res.data);
        if (res.data.status === "200") {
          MySwal.fire({
            title: <strong>Success</strong>,
            html: <p>Data Deleted, please go back to Doc Lib List </p>
          })
        } else {
          MySwal.fire({
            title: <strong>Error!</strong>,
            html: <p>Error BE Delete Data </p>
          })
        }
      })
      .catch(error => {
        MySwal.fire({
          title: <strong>Error!</strong>,
          html: <p>Error Axios or path </p>
        })
      });
  }

  const getExtGroup = () => { 
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    const config = {headers:{['x-access-token']: sessionToken}};

    var dbpath = process.env.REACT_APP_PATH_DESTINATION + "/extGroup";   
    axios.get( dbpath, config)
    .then(res => { 
      if (res.data.status == "200"){
        setExtGroup(res.data.data);
      } 
    })
  }
  
  const getData = () => { 
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    const config = {headers:{['x-access-token']: sessionToken}};

    var dbpath = process.env.REACT_APP_PATH_DESTINATION + "/docLibraryListId/"+id;   
    axios.get( dbpath, config)
    .then(res => { 
        console.log("res.data.data docaddedit logging")
        console.log(res.data.data);
        if (res.data.status == "200"){
            setDocLibName(res.data.data.name);
            setExtGroupChosen(res.data.data.external_group_id);
            setDraft("1");
            setOldAttachmentContent(res.data.data.attachment);
            setOldAttachmentBG(res.data.data.cover);
        } else {
            //maybe error log / alert here somehow
        }
    })
  }
  if (orientation === 'landscape')
  return (
    <>
    <h2>Doc Lib Add Edit</h2>
    <br/>
    <h3>Doc Lib External Group</h3>
    <select
        className='inputText'
        style={{ marginTop: '-2.5vw' }}
        value={extGroupChosen}
        onChange={handleExtGroupChosen}
    >
      {extGroup.map((extGroups, index) => (
        <option key={index} value={extGroups.id}>
          {extGroups.group_name}
        </option>
      ))}
    </select> 

    <h3>Doc Lib Name</h3>
    <input
        placeholder='Input Fields'
        className='inputText'
        type='text'
        style={{ marginTop: '-2.5vw' }}
        value={docLibName}
        onChange={handleDocLibName}
    />

    <h3>Doc Lib Attachment (to be downloaded - one file only)</h3>
    <UploadComp onChangeStatus={handleFileChangeContent} refresh={refreshDropdown} atch={oldAttachmentContent} setAtchString={handleChangeOldAtchContent} isDraft={draft}/>

    <h3>FAQ Cover (one image only)</h3>
    <UploadComp onChangeStatus={handleFileChangeBG} refresh={refreshDropdown} atch={oldAttachmentBG} setAtchString={handleChangeOldAtchBG} isDraft={draft}/>
    
    <br/><br/>
    <button style={{backgroundColor:'red'}} className="btn btnSubmitTicket" type="button" onClick={verifyDelete}>
      Delete
    </button>
    &nbsp;&nbsp;&nbsp;
    <button className="btn  btnSubmitTicket" type="button" onClick={handleAction}>
      Submit
    </button>
    <br/><br/>
    </>
    )

    
  if (orientation === 'portrait')
  return (
    <div style={{width:'90%', marginLeft:'5%'}}>
    <h2 style={{fontFamily:'Blender Pro'}}>Doc Lib Add Edit</h2>
    <br/>
    <h3 style={{fontFamily:'Blender Pro'}}>Doc Lib External Group</h3>
    <select
        className='inputTextMobile'
        style={{ marginTop: '-2.5vw' }}
        value={extGroupChosen}
        onChange={handleExtGroupChosen}
    >
      {extGroup.map((extGroups, index) => (
        <option key={index} value={extGroups.id}>
          {extGroups.group_name}
        </option>
      ))}
    </select> 

    <h3 style={{fontFamily:'Blender Pro'}}>Doc Lib Name</h3>
    <input
        placeholder='Input Fields'
        className='inputTextMobile'
        type='text'
        style={{ marginTop: '-2.5vw' }}
        value={docLibName}
        onChange={handleDocLibName}
    />

    <h3 style={{fontFamily:'Blender Pro'}}>Doc Lib Attachment (to be downloaded - one file only)</h3>
    <UploadComp onChangeStatus={handleFileChangeContent} refresh={refreshDropdown} atch={oldAttachmentContent} setAtchString={handleChangeOldAtchContent} isDraft={draft}/>

    <h3 style={{fontFamily:'Blender Pro'}}>Doc Lib BG Cover (one image only, no png )</h3>
    <UploadComp onChangeStatus={handleFileChangeBG} refresh={refreshDropdown} atch={oldAttachmentBG} setAtchString={handleChangeOldAtchBG} isDraft={draft}/>
    
    <br/><br/>
    <button style={{backgroundColor:'red'}} className="btn btnSubmitTicketMobile" type="button" onClick={verifyDelete}>
      Delete
    </button>
    &nbsp;&nbsp;&nbsp;
    <button className="btn btnSubmitTicketMobile" type="button" onClick={handleAction}>
      Submit
    </button>
    <br/><br/>
    </div>
    )
}

export default DocLibAddEdit