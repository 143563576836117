import React, { Component } from 'react'

export class BackButton extends Component {
    render() {
    const link = this.props.link;
        return (
        <div className="card-body">
          <a className="btn btn-primary" href={link} role="button">Back</a>
        </div>
    )
  }
}

export default BackButton

