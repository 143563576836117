import React, { useEffect, useState } from 'react'
import ImageDocLibrary from './ImageDocLibrary';
import useOrientation from '../../general/useOrientation';

function DocLibraryDisplayParent({data}) {
  const [loopData, setLoopData] = useState(0);
  const [loopDataMobile, setLoopDataMobile] = useState(0);
  const orientation = useOrientation();
  useEffect(() => {
    setLoopData(((data.length - (data.length % 4)) / 4)+1);
    setLoopDataMobile(Math.floor(data.length / 2) + (data.length % 2));
  }, [data])

  if (orientation === 'landscape')
  return (
    <>
    {Array.isArray(data) && (() => {
      const elements = [];

      for (let i = 0; i < loopData; i++) {
        console.log("loopdata i = "+i);
        elements.push(
          <React.Fragment key={i}>
            <div className="row" style={{ marginTop: i === 0 ? '3vw':'15vw' }}>
            {data[parseInt((i*4)+0)] && (
              <div className="col-md-3" key={i+"a"}>
                <ImageDocLibrary data={data[parseInt((i*4)+0)]} order="0"/>
              </div>
            )}
            {data[parseInt((i*4)+1)] && (
              <div className="col-md-3" key={i+"b"}>
                <ImageDocLibrary data={data[parseInt((i*4)+1)]} order="1"/>
              </div>
            )}
            {data[parseInt((i*4)+2)] && (
              <div className="col-md-3" key={i+"c"}>
                <ImageDocLibrary data={data[parseInt((i*4)+2)]} order="2"/>
              </div>
            )}
            {data[parseInt((i*4)+3)] && (
              <div className="col-md-3" key={i+"d"}>
                <ImageDocLibrary data={data[parseInt((i*4)+3)]} order="3" />
              </div>
            )}
            </div>
          </React.Fragment>
        );
      }
      return elements;
    })()}
    </>
  )

  if (orientation === 'portrait')
  return (
    <>
    {Array.isArray(data) && (() => {
      const elements = [];

      for (let i = 0; i < loopDataMobile; i++) {
        elements.push(
          <React.Fragment key={i}>
            <div className="row" style={{ marginTop: i === 0 ? '3vh':'15vh' }}>
            {data[parseInt((i*2)+0)] && (
              <div className="" key={i+"a"}>
                <ImageDocLibrary data={data[parseInt((i*2)+0)]} order="0"/>
              </div>
            )}
            {data[parseInt((i*2)+1)] && (
              <div className="" key={i+"b"}>
                <ImageDocLibrary data={data[parseInt((i*2)+1)]} order="1"/>
              </div>
            )}
            </div>
          </React.Fragment>
        );
      }
      return elements;
    })()}
    </>
  )
}

export default DocLibraryDisplayParent