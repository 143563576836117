import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker';
import {Calendar} from 'iconoir-react';

const CustomDatePickerInput = ({ value, onClick }) => (
    <div style={{ position: 'relative' }}>
      <input
        type="text"
        value={value}
        onClick={onClick}
        placeholder="Select Date"
        readOnly
        className='datePicker'
        style={{ paddingRight: '30px', fontSize:'15px', width:'auto'}} // Adjust the padding to accommodate the icon
      />
      <span
        style={{fontSize:'15px' , position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)', cursor: 'pointer' }}
        onClick={onClick}
      >
        <Calendar size={20} color="#737E86" />
      </span>
    </div>
  );

function DatePickerAnc({defValue="", setTime}) {
  const [endDate, setEndDate] = useState(null);
  useEffect(() => {
    if (defValue && defValue != "" && defValue != "0000-00-00 00:00:00" && defValue != '1970-01-01') { 
      var defaultDate  = new Date(defValue);
      changeDateHandler(defaultDate);
    }
  }, [defValue])

  const changeDateHandler = (date) => {
      setEndDate(date);
      setTime(date);
  }
  return (
    <DatePicker
        selected={endDate}
        onChange={(date) => changeDateHandler(date)}
        dateFormat="d MMMM yyyy"
        customInput={<CustomDatePickerInput />} 
    />
  )
}

export default DatePickerAnc