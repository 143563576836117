import React from 'react' ;import { useState, useEffect} from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Cookies from 'universal-cookie'; 
import axios from 'axios' ;
import PdDetails from './PdDetails';
const cookies = new Cookies();
const MySwal = withReactContent(Swal)

function PdDetailsAdd() {
  const [inputs, setInputs] = useState({});
  const handleSubmit = (event) => {
    event.preventDefault();
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    var path = process.env.REACT_APP_PATH_DESTINATION + "/addNewPD" ;   
    const config = {headers:{['x-access-token']: sessionToken}};
    axios.post(path, {data: inputs} , config)
    .then(function (response) {
      if (response.data.status == '200')
      MySwal.fire({
        title: <strong>Success!</strong>,
        html: <p>{response.data.message}</p>,
        icon: 'success'
      }).then(() => {
        window.location.reload(false);
      })
    else 
      MySwal.fire({
        title: <strong>Failed!</strong>,
        html: <p>{response.data.message}</p>,
        icon: 'error'
      })
    })
    .catch(function (error) {
        console.log(error);
        MySwal.fire({
          title: <strong>Server Error!</strong>,
          html: <p>Something in the server went wrong. Please contact administrator</p>,
          icon: 'error'
        })
    });
  }
  var dataa = {
    title: "Add New Personal Dashboard",
    params : false
  };
  var defaultSelect = {
    label: "Choose User",
    value : "0"
};
  return (
    <>
      <PdDetails handleSubmit={handleSubmit} data={dataa} inputs={inputs} setInputs={setInputs} defaultSelect={defaultSelect} />
    </>
  )
}

export default PdDetailsAdd