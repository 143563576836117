import React from 'react'

function FormatDate({date}) {
  const formatDate = (date) => {
    const createdAt = new Date(date);
    const hours = createdAt.getHours().toString().padStart(2, '0');
    const minutes = createdAt.getMinutes().toString().padStart(2, '0');
    const day = createdAt.getDate().toString().padStart(2, '0');
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[createdAt.getMonth()];
    const year = createdAt.getFullYear();
    return `${hours}.${minutes} on ${day} ${month} ${year}`;
  };
  return (
    String(formatDate(date))
  )
}

export default FormatDate