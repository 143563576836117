import React , {useState, useEffect} from 'react'
import CommentListSingle from './CommentListSingle';
import Cookies from 'universal-cookie';
import useOrientation from '../../../general/useOrientation';
const cookies = new Cookies();
function CommentList({ data ,isExpanded,handleImageClick,handlePdfClick}) {
  const sessionData = cookies.get('sessionData', { path: '/' })
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait') { isThisPortrait = true;}
  // Check if data.comments is an array and not empty
  if (!Array.isArray(data.comment) || data.comment.length === 0) {
    return <></>;
  }

  if (orientation === 'landscape')
  return (
    <>
      {data.comment.map((comment) => (
        <div key={"Comment"+comment.id}>
          {comment.action === "Closed" ? (
              <CommentListSingle comment={comment} star={data.rating} isExpanded={isExpanded} handleImageClick={handleImageClick} handlePdfClick={handlePdfClick}/>
          ) : (
              sessionData.int_ext == 2 && comment.action === "Extended" && (data.displayType == 0 || data.displayType == 1) ? (
                  null // Show nothing
              ) : (
                  <CommentListSingle comment={comment} isExpanded={isExpanded} handleImageClick={handleImageClick} handlePdfClick={handlePdfClick}/>
              )
          )}
        </div>
        ))}
    </>

  );

  if (orientation === 'portrait')
  return (
    <>
      {data.comment.map((comment) => (
        <div key={"Comment"+comment.id}>
          {comment.action === "Closed" ? (
              <CommentListSingle comment={comment} star={data.rating} isExpanded={isExpanded} handleImageClick={handleImageClick} handlePdfClick={handlePdfClick}/>
          ) : (
              sessionData.int_ext == 2 && comment.action === "Extended" && (data.displayType == 0 || data.displayType == 1) ? (
                  null // Show nothing
              ) : (
                  <CommentListSingle comment={comment} isExpanded={isExpanded} handleImageClick={handleImageClick} handlePdfClick={handlePdfClick}/>
              )
          )}
        </div>
        ))}
    </>

  );
}

export default CommentList;
