import React, { useEffect, useState } from 'react'
import HeaderPage from '../headerPage/HeaderPage';
import FilterSearch from '../filter/FilterSearch';
import { useNavigate } from 'react-router-dom';
import DocTabbing from './DocTabbing';
import DocTableParent from './DocTableParent';
import Cookies from 'universal-cookie';
import useOrientation from '../../general/useOrientation';
import { isMobile } from 'react-device-detect';

const cookies = new Cookies();
function DocumentListParent({changeNavState}) {
  var sessionData = cookies.get('sessionData', { path: '/' })
  const orientation = useOrientation();
  const navigate = useNavigate();
  const [linkData, setLinkData] = useState(sessionData.int_ext == "1"? 'docListToMe' : 'docListByMe' );
  const [filterData, setFilterData] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [selectedTab, setSelectedTab] = useState(sessionData.int_ext == "1"? 'Documents To Me' : 'Documents By Me' );
  const buttonText = "Request Document";
  useEffect(() => {
    changeNavState('Document');
  }, [])

  
  useEffect(() => {
    const intervalId = setInterval(() => {
      setRefresh(!refresh);
    }, 60000); // 60 seconds in milliseconds
  
    // Clear the initial timeout to prevent the initial refresh
    const initialTimeoutId = setTimeout(() => {
      clearInterval(intervalId);
    }, 60000);
  
    // Cleanup function to clear the interval and initial timeout when the component unmounts
    return () => {
      clearInterval(intervalId);
      clearTimeout(initialTimeoutId);
    };
  }, []); // Empty dependency array for the initial setup
  
  
  const buttonActionNewDocLib = (() => {
    navigate('/addDocLib');
  })
  
  const buttonAction = (() => {
    navigate('/requestDocument');
  })

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    if (tab == "Documents By Me"){
      setLinkData('docListByMe');
    } else if (tab == "Documents To Me"){
      setLinkData('docListToMe');
    } else if (tab == "Documents Library"){
      setLinkData('docLibraryList');
    }
  };

  const handleRefreshClick = () => {
    // just change the value to tell components to refresh
    setRefresh(!refresh); 
  };

  const updateFilterData = (val) => {
    console.log("update filter data value ", val)
    setFilterData(val); 
  };

  if (orientation === 'landscape')
  return (
  <>

{sessionData.int_ext !== "1" ? (
  <HeaderPage 
    title1={"Document"}
    link1={"/documentList"}
    title2={"Document Lists"}
    buttonText={buttonText}
    buttonAction={buttonAction}
    loadingState={isLoading}
  />
) : (sessionData.int_ext === "1" && sessionData.apps_roles_id < 4) ? (
  <HeaderPage 
    title1={"Document"}
    link1={"/documentList"}
    title2={"Document Lists"}
    buttonText={"New Document Library"}
    buttonAction={buttonActionNewDocLib}
    loadingState={isLoading}
  />
) : (
  <HeaderPage 
    title1={"Document"}
    link1={"/documentList"}
    title2={"Document Lists"}
    loadingState={isLoading}
  />
)}


    <DocTabbing 
      selectedTab={selectedTab}
      handleTabClick={handleTabClick}
    />
     <div style={{position:'relative'}}>
      <div style={{zIndex:2 ,position: 'relative', width:'78%'}}>
        <FilterSearch filterData={filterData} setData={updateFilterData} setFilterData={setFilterData} handleRefreshClick={handleRefreshClick} tiketOrDoc="req_doc" bymeToMe={linkData}/>
      </div>
      <div style={{zIndex:1, position: 'relative', marginTop: '-6.2vw', marginLeft:'-1.2vw'}}>
        <DocTableParent filterData={filterData} link={linkData} refresh={refresh} setLoading={setIsLoading}/>
      </div>
     </div>
  </>
  )

  if (orientation === 'portrait')
  return (
  <>

{sessionData.int_ext !== "1" ? (
  <HeaderPage 
    title1={"Document"}
    link1={"/documentList"}
    title2={"Document Lists"}
    buttonText={buttonText}
    buttonAction={buttonAction}
    loadingState={isLoading}
  />
) : (sessionData.int_ext === "1" && sessionData.apps_roles_id < 4) ? (
  <HeaderPage 
    title1={"Document"}
    link1={"/documentList"}
    title2={"Document Lists"}
    buttonText={"New Document Library"}
    buttonAction={buttonActionNewDocLib}
    loadingState={isLoading}
  />
) : (
  <HeaderPage 
    title1={"Document"}
    link1={"/documentList"}
    title2={"Document Lists"}
    loadingState={isLoading}
  />
)}
    <DocTabbing 
      selectedTab={selectedTab}
      handleTabClick={handleTabClick}
    />
     <div style={{position:'relative'}}>
      <div style={{zIndex:2 ,position: 'relative', width:'78%'}}>
        <FilterSearch filterData={filterData} setData={updateFilterData} setFilterData={setFilterData} handleRefreshClick={handleRefreshClick} tiketOrDoc="req_doc" bymeToMe={linkData}/>
      </div>
      <div style={{zIndex:1, position: 'relative', marginTop: '-12vh', marginLeft:'-1.2vw'}}>
        <DocTableParent filterData={filterData} link={linkData} refresh={refresh} setLoading={setIsLoading}/>
      </div>
     </div>
  </>
  )
}

export default DocumentListParent