import React, { useState, useEffect } from 'react';
import HeaderPage from '../../headerPage/HeaderPage';
import {useNavigate, useParams} from "react-router-dom"
import TabbingConfig from './TabbingConfig';
import FilterSearch from '../../filter/FilterSearch';
import ConfigTableParent from './ConfigTableParent';
import useOrientation from '../../../general/useOrientation';

function ConfigList({changeNavState}) {
  const navigate = useNavigate();
  const { byTo,id,name } = useParams();
  const [imagePopup, setImagePopup] = useState("");
  const [filterData, setFilterData] = useState("");
  const [isExpanded, setIsExpanded] = useState(0);
  const [linkData, setLinkData] = useState('ticketExtPrtList');
  const [isLoading, setIsLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [selectedTab, setSelectedTab] = useState("Ext Ticket Parent");
  const [clickLink, setClickLink] = useState("/configuration/ticket/parent/2/");
  const [parentOrCat, setParentOrCat] = useState("parent");
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait') { isThisPortrait = true;}
  const [addNewLink, setAddNewLink] = useState("/configuration/add/parent/2/");

  useEffect(() => {
    changeNavState('Configurations');
  }, [])

  const buttonAction = (() => {
    navigate('/createTicket');
  })
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      setRefresh(!refresh);
    }, 60000); // 60 seconds in milliseconds
  
    // Clear the initial timeout to prevent the initial refresh
    const initialTimeoutId = setTimeout(() => {
      clearInterval(intervalId);
    }, 60000);
  
    // Cleanup function to clear the interval and initial timeout when the component unmounts
    return () => {
      clearInterval(intervalId);
      clearTimeout(initialTimeoutId);
    };
  }, []); // Empty dependency array for the initial setup
  
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    if (tab == "Ext Ticket Parent"){
      setParentOrCat('parent');
      setClickLink('/configuration/ticket/parent/2/');
      setLinkData('ticketExtPrtList');
      setAddNewLink('/configuration/add/parent/2');
    } else if (tab == "Ext Ticket Category"){
      setParentOrCat('cat');
      setClickLink('/configuration/ticket/cat/2/');
      setLinkData('ticketExtCatList');
      setAddNewLink('/configuration/add/cat/2');
    } else if (tab == "Doc Parent"){
      setParentOrCat('parent');
      setClickLink('/configuration/ticket/parent/3/');
      setLinkData('ticketDocPrtList');
      setAddNewLink('/configuration/add/parent/3');
    } else if (tab == "Doc Category"){
      setParentOrCat('cat');
      setClickLink('/configuration/ticket/cat/3/');
      setLinkData('ticketDocCatList');
      setAddNewLink('/configuration/add/cat/3');
    } else if (tab == "Int Category"){
      setParentOrCat('cat');
      setClickLink('/configuration/ticket/cat/1/');
      setLinkData('ticketIntCatList');
      setAddNewLink('/configuration/add/cat/1');
    } else if (tab == "Department Access"){
      setParentOrCat('deptaccess');
      setClickLink('/configuration/ticket/deptaccess/');
      setLinkData('ticketDeptAccessList');
      setAddNewLink('/configuration/add/deptaccess');
    }
  };

  const redirectLink = () => {
    navigate(addNewLink);
  }

  const handleRefreshClick = () => {
    // just change the value to tell components to refresh
    setRefresh(!refresh); 
  };
  
  const updateFilterData = (val) => {
    console.log("update filter data value ", val)
    setFilterData(val); 
  };

  const handleImageClick = (val) => {
    if (isExpanded == 0){
      setIsExpanded(val);
    } else {
      setIsExpanded(0);
      setImagePopup("");
    }
  };
  return (
  <>
     <div className={isExpanded == 0 ? '' : 'popup-overlay'}  onClick={() => handleImageClick(0)}>

     </div>
     {imagePopup && (
       <img
       src={process.env.REACT_APP_IMG_DESTINATION + imagePopup}
       alt="Image Popup"
       className="expanded"
       style={{ height: "3vw", marginTop: '2.2vw', position: 'absolute' }}
     />
     )}
     <HeaderPage 
        title1={"Configurations"}
        link1={"/configuration"}
        title2={"Ticket"}
        loadingState={isLoading}
        buttonText={"Create New"}
        buttonAction={redirectLink}
    />

    <TabbingConfig 
      selectedTab={selectedTab}
      handleTabClick={handleTabClick}
    />
     <div style={{position:'relative'}}>
      <div style={{zIndex:2 ,position: 'relative', width:'78%'}}>
        <FilterSearch init={id} name={name} data={filterData} setData={updateFilterData} handleRefreshClick={handleRefreshClick} tiketOrDoc="tiket" bymeToMe={linkData}/>
      </div>
      <div style={{zIndex:1, position: 'relative', marginTop: isThisPortrait?'-12vh':'-6.2vw', marginLeft:isThisPortrait?'-1.2vw':'-1.2vw', maxWidth:isThisPortrait?'100vw':''}}>
        <ConfigTableParent filterData={filterData} link={linkData} refresh={refresh} setLoading={setIsLoading}  imagePopup={imagePopup} setImagePopup={setImagePopup} isExpanded={isExpanded} setIsExpanded={setIsExpanded} parentOrCat={parentOrCat} clickLink={clickLink}/>
      </div>
     </div>
  </>
  )
}

export default ConfigList