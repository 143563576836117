import React, { useState, useEffect } from 'react';
import { Tooltip } from 'react-tooltip';
import useOrientation from '../../general/useOrientation';
function TicketCCs({ ccData }) {
  const [loading, setLoading] = useState(true);
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait') { isThisPortrait = true;}
  useEffect(() => {
    if (ccData) {
      // Data is available, set loading to false
      setLoading(false);
      //console.log("ccdata logging loading false",ccData);
    } else {
      //console.log("ccdata logging loading true",ccData);
    }
  }, [ccData]);

  return (
    <>
      {ccData.length > 0 && (
        <>
          <div style={{marginTop: '1vw'}}>
            <p style={{fontFamily: 'Blender Pro'}}>CC</p>
          </div>
          <div  style={{marginLeft: '1vw' }}>
            {loading ? (
              <p></p>
            ) : (
              ccData.map((cc) => (
              <a href="#" key={"cckey"+cc.id} className="no-hover" data-tooltip-id={"cc"+cc.id} data-tooltip-content={cc.display_name}>
                <img
                  key={cc.id}
                  src={process.env.REACT_APP_PUBLIC_DESTINATION + cc.path_foto}
                  alt={`Profile Picture of ${cc.display_name}`} 
                  className="brand-image img-circle"
                  style={{ height: isThisPortrait?'4vh':"3vw", width:isThisPortrait?'':"3vw" ,marginTop: '0.5vw', marginLeft: '-1vw' }}
                />
                <Tooltip id={"cc"+cc.id} place="bottom" type="dark" effect="solid" style={{marginTop:'0.5vw',marginLeft:'-0.5vw'}}/>
              </a>
              ))
            )}
          </div>
        </>
      )}
    </>
  );
}

export default TicketCCs;
