import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import NoPage from '../NoPage';
import useOrientation from '../../general/useOrientation';
const cookies = new Cookies();
function TabbingReport({selectedTab, handleTabClick}) {
  const orientation = useOrientation();
  var sessionData = cookies.get('sessionData', { path: '/' })
  if(sessionData.int_ext == "1" && orientation === 'landscape'){
    return (
      <div className='row' style={{ position:'relative', zIndex:'2',borderBottom: '1px solid #E8E9EB', width: '98%', marginTop: '3vw'}}>
        <div className={`tabbing ${selectedTab === 'Monthly Report' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Monthly Report')}>
          Monthly Report
        </div>
        <div className={`tabbing ${selectedTab === 'Summary Report' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Summary Report')}>
          Summary Report
        </div>
        <div className={`tabbing ${selectedTab === 'Tenant Report' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Tenant Report')}>
          Tenant Report
        </div>
      </div>
    )
  } else if (sessionData.int_ext == "1" && orientation === 'portrait'){
    return (
      <div className='row' style={{ position:'relative', zIndex:'2',borderBottom: '1px solid #E8E9EB', width: '98%', marginTop: '3vw', marginBottom: '2vh', marginLeft:'2vw'}}>
        <div className={`tabbingMobile ${selectedTab === 'Monthly Report' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Monthly Report')}>
          Monthly Report
        </div>
        <div className={`tabbingMobile ${selectedTab === 'Summary Report' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Summary Report')}>
          Summary Report
        </div>
        <div className={`tabbingMobile ${selectedTab === 'Tenant Report' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Tenant Report')}>
          Tenant Report
        </div>
      </div>
    )
  } else {
    return (
      <NoPage />
    )
  }
  
}

export default TabbingReport;
