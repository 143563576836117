import React from 'react'
import { useNavigate } from 'react-router-dom'
import useOrientation from '../../general/useOrientation'

function ConfigIcons({Icon, name, link}) {
    const navigate = useNavigate()
    const handleClick = () => {
        navigate(link)
    }
    const orientation = useOrientation();
    let isThisPortrait = false;
    if (orientation === 'portrait') { isThisPortrait = true;}

  if (orientation === 'landscape')
  return (
    <div className="col-md-2" style={{marginBottom:'1.2vw'}}>
        <div className="cursorPointer" onClick={handleClick} style={{fontSize:'1.2vw', width:'12.5vw', height:'12.5vw', borderRadius:'8px', border:'2px solid #E8E9EB', color: '#0296D2', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <Icon style={{width:'3.75vw', height:'3.75vw', color: '#0296D2'}} />
            <br/>
            {name}
        </div>
    </div>
  )

  if (orientation === 'portrait')
  return (
    <div className="" style={{marginBottom:'3vh', marginLeft:'5vw'}}>
        <div className="cursorPointer" onClick={handleClick} style={{fontSize:'15px', width:'15vh', height:'15vh', borderRadius:'8px', border:'2px solid #E8E9EB', color: '#0296D2', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <Icon style={{width:'5vh', height:'5vh', color: '#0296D2'}} />
            <br/>
            {name}
        </div>
    </div>
  )
}

export default ConfigIcons