import React, { useEffect, useState } from 'react'
import { Tooltip } from 'react-tooltip'; 
import {Star} from 'iconoir-react';
import TimeAgo from '../status/TimeAgo';
import FormatDate from '../../../general/FormatDate';
import AttachmentHandler from '../attachment/AttachmentHandler';
import useOrientation from '../../../general/useOrientation';
function CommentListSingle({comment,star,isExpanded,handleImageClick,handlePdfClick}) {
  const [marginAttachment, setMarginAttachment] = useState("");
  const [imgData, setImgData] = useState("");  
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait') { isThisPortrait = true;}

  useEffect(() => {
    if (comment.attachment){
      let attachmentCount = String(comment.attachment).split('|').length;
      let marginTop = `${6 + Math.floor((attachmentCount - 1) / 4) * 11}vw`;
      let marginTopM = `${18 + Math.floor((attachmentCount - 1) / 3) * 28}vw`;
      setMarginAttachment(isThisPortrait? marginTopM : marginTop);
    } else {
      setMarginAttachment("0");
    }
    setImgData(changeDataKonten(comment.konten))
  }, [comment])

  
  const changeDataKonten = (imgData) => {
    const searchString = '<img src="data:image'; 

    const modifiedKonten = String(imgData).replace(
      new RegExp(searchString, 'g'),
      `<img class="inlineImage" src="data:image`
    );

    return modifiedKonten;
  }

  if (orientation === 'landscape') 
  return (
    <div style={{borderBottom:'1px solid #E8E9EB', paddingBottom:'3vw' }}>
    <div style={{ marginLeft: '1.5vw', marginTop: '0.5vw', marginRight: '2vw'}}>
    <div style={{ marginTop: '0.5vw', position: 'relative' }} className='row'>
    {/* 
    <a href="#" className="no-hover" data-tooltip-id={"creatorComment"+comment.id} data-tooltip-content={comment.display_name}>
    */}
      <img
        src={process.env.REACT_APP_PUBLIC_DESTINATION + comment.path_foto}
        alt="Creator Profile Picture"
        className="brand-image img-circle"
        style={{ width: "2.5vw", height: "2.5vw", marginTop: '2.2vw', position: 'absolute' ,marginLeft:'0.5vw'}}
      />
    {/*
      <Tooltip id={"creatorComment"+comment.id} place="bottom" type="dark" effect="solid" style={{marginTop:'4.5vw', marginLeft:'1.5vw'}}/>
    </a>
     */}
      
      <div style={{ marginTop: '1.8vw', marginLeft: '4vw', width:'100%' }}>
        <h5 style={{ fontSize: '1.5vw', fontFamily: 'Blender Pro', marginRight:'3vw' }}>{comment.display_name}</h5>
        <p style={{ marginTop: '-1vw', fontSize: '1.1vw' }}>
          {`${comment.action} this ticket at `} <FormatDate date={comment.created_at}/> 
        </p>
        <div style={{ marginTop: '1vw', fontSize: '1.1vw', fontFamily: 'Blender Pro' }}>
        <div className="htmlUserInput" style={{fontSize:"1.3vw"}} dangerouslySetInnerHTML={{ __html: imgData }}></div>
        </div>

      </div>
      <TimeAgo
        time={comment.created_at}
        style={{  top: '2.2vw', left: '4.5vw' }}
      />
      <div className='row col-md-12' style={{marginLeft:'3vw', width:'100%', marginTop:'2vw'}}>
        {comment.attachment && String(comment.attachment).split('|').map((attachment, index) => (
          <AttachmentHandler
            key={"atchCom"+index} 
            id={index + 1} 
            isExpanded={isExpanded}
            source={`${process.env.REACT_APP_IMG_DESTINATION}${attachment}`}
            handleImageClick={handleImageClick}
            handlePdfClick={handlePdfClick}
            fileName={attachment}
          />
        ))}
      </div>
    </div>
    { star && (
          <>
          <div style={{float:'right' ,marginTop:'-4vw'}} className='countdownStarRating'>
          {star} <Star style={{fill:'CurrentColor', width:'1vw', marginTop:"-0.2vw", color:"#FFFFFF"}} />
          </div>
          </>
        )}
   </div>
   <div style={{marginTop:marginAttachment}} > &nbsp; </div>
   </div>
  )
  
  if (orientation === 'portrait') 
  return (
    <div style={{borderBottom:'1px solid #E8E9EB', paddingBottom:'2vh' , width:'95%', marginTop:'2vh'}}>
    <div style={{ marginLeft: '5vw', marginTop: '0.5vw', marginRight: '0vw'}}>
    <div style={{ marginTop: '0.5vw', position: 'relative' }} className='row'>
    {/* 
    <a href="#" className="no-hover" data-tooltip-id={"creatorComment"+comment.id} data-tooltip-content={comment.display_name}>
    */}
      <img
        src={process.env.REACT_APP_PUBLIC_DESTINATION + comment.path_foto}
        alt="Creator Profile Picture"
        className="brand-image img-circle"
        style={{ width: "4vh", height: "4vh", marginTop: '2.2vh', position: 'absolute' ,marginLeft:'0.5vw'}}
      />
    {/*
      <Tooltip id={"creatorComment"+comment.id} place="bottom" type="dark" effect="solid" style={{marginTop:'4.5vw', marginLeft:'1.5vw'}}/>
    </a>
     */}
      
      <div style={{ marginTop: '2vh', marginLeft: '10vw', width:'100%' }}>
        <h5 style={{ fontSize: '2vh', fontFamily: 'Blender Pro', marginRight:'3vw' }}>{comment.display_name}</h5>
        <p style={{ marginTop: '-2vh', fontSize: '2vh' }}>
          {`${comment.action} this ticket at `} <FormatDate date={comment.created_at}/> 
        </p>
        <div style={{ marginTop: '1vw', fontSize: '2vh', fontFamily: 'Blender Pro' }}>
        <div className="htmlUserInput" style={{fontSize:"2vh"}} dangerouslySetInnerHTML={{ __html: imgData }}></div>
        </div>

      </div>
      <div>
      <TimeAgo
        time={comment.created_at}
        style={{  top: '2.2vw', left: '4.5vw' }}
      />
      </div>
      <div className='row col-md-12' style={{marginLeft:isThisPortrait?'6vw':'3vw', width:'100%'}}>
        {comment.attachment && String(comment.attachment).split('|').map((attachment, index) => (
          <AttachmentHandler
            key={"atchCom"+index} 
            id={index + 1} 
            isExpanded={isExpanded}
            source={`${process.env.REACT_APP_IMG_DESTINATION}${attachment}`}
            handleImageClick={handleImageClick}
            handlePdfClick={handlePdfClick}
            fileName={attachment}
          />
        ))}
      </div>
    </div>
    { star && (
          <>
          <div style={{float:'right' ,marginTop:'-4vh'}} className='countdownStarRatingMobile'>
          {star} <Star style={{fill:'CurrentColor', width:'2vh', marginTop:"-0.2vw", color:"#FFFFFF"}} />
          </div>
          </>
        )}
   </div>
   <div style={{marginTop:marginAttachment}} > &nbsp; </div>
   </div>
  )
}

export default CommentListSingle