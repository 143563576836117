import React, { useState, useEffect }  from 'react';
import { motion, useAnimation } from 'framer-motion';
import { Search } from 'iconoir-react';
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
import useOrientation from '../../general/useOrientation';

const MySwal = withReactContent(Swal)
function FilterLevelTwo({filterVariable,SecondfilterExpand,animationVariants,data,dataID,setFilter}) {
  const [originalData, setOriginalData] = useState(data || []); 
  const [selectedDatetime, setSelectedDatetime] = useState('');
  const orientation = useOrientation();

  const handleDatetimeChange = (event) => {
    setSelectedDatetime(event.target.value);
  };

  const handleConfirmDatetime = () => {
    if (selectedDatetime && !isNaN(new Date(selectedDatetime))) {
      setFilter(selectedDatetime);
      //alert("datetime selected = " + selectedDatetime);
    } else {
      MySwal.fire({
        title: <strong>Error!</strong>,
        html: <p>Please choose a valid Date</p>
    })
    }
  };


  const filterInput = (event) => {
    const inputValue = event.target.value;
    if (inputValue === "") {
      setOriginalData(data);
    } else {
      const regex = new RegExp(inputValue, "i"); // "i" for case-insensitive matching
      const filteredItems = data.filter((item) => regex.test(item));
      setOriginalData(filteredItems);
    }
  };

  useEffect(() => {
    setOriginalData(data);
    console.log("data inside filter level two",data)
  }, [data]);
  
  const chooseFilter = (index) => {
    const originalIndex = data.indexOf(originalData[index]);
    setFilter(originalIndex);
  }
  if (orientation === 'landscape')
  return (
    <div style={{marginLeft: '15w'}} >
    {SecondfilterExpand && (
      <motion.div
        className=""
        style={{
          backgroundColor: 'white',
          position: 'absolute',
          marginTop: filterVariable.length > 0 ? '0vw':'2.5vw',
          marginLeft: '12.5vw',
          overflowY:'scroll',
          height: `auto`,
          maxHeight:'25vw',
          width: '25%',
          borderRadius: '8px',
          zIndex: 5,
          border: '0px solid black',
          boxShadow: '0px 4px 40px 0px #0000001F' 
        }}
        initial="hidden"
        animate={SecondfilterExpand ? 'visible' : 'hidden'}
        variants={animationVariants}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
      >
        { data.length > 7 && (
          <div className="">
              <div style={{ display: 'flex' }}>
                  <input
                      placeholder='Search'
                      className='inputSearch'
                      type='text'
                      style={{ width: '95%', marginTop: '1vw', marginLeft: '0.5vw' }}
                      onChange={filterInput}
                  />
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Search style={{ margin: '1vw 0vw 0vw -2.5vw' ,width:'1.5vw', heigth:'1.5vw', color:'#737E86'}} />
                  </div>
              </div>
          </div>
        )}

        {originalData && (
          originalData.length > 1 || (originalData.length === 1 && originalData[0] !== "") ? (
            originalData.map((item, index) => (
              <div key={item + index} className="outerTenantDiv">
                {item.toLowerCase() === "datetime" ? (
                  // Render a different div for "datetime" string
                  <div className="tenantTicketDiv" key={index}>
                      Select Date:<br/>
                      <input
                        style={{width:"10vw"}}
                        type="date"
                        id={`datePicker-${index}`}
                        value={selectedDatetime}
                        onChange={handleDatetimeChange}
                      />
                    <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '8px', marginTop: '0.5vw', border: '0px solid black', height: '2vw' }} className="buttonCreateNew" onClick={handleConfirmDatetime}>
                      Confirm
                    </button>
                  </div>
                ) : (
                  // Render the regular div for other items
                  <div
                    style={{}}
                    onClick={() => chooseFilter(index)}
                    className="tenantTicketDiv"
                    key={index}
                  >
                    {item}
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className="outerTenantDiv">
              <div className="tenantTicketDiv">
                No Data Available
              </div>
            </div>
          )
        )}

      </motion.div>
    )}
    </div>
  )
  if (orientation === 'portrait')
  return (
    <div style={{marginLeft: '0vw'}} >
    {SecondfilterExpand && (
      <motion.div
        className=""
        style={{
          backgroundColor: 'white',
          position: 'absolute',
          marginTop: filterVariable.length > 0 ? '0vw':'2.5vw',
          marginLeft: '32vw',
          overflowY:'scroll',
          height: `auto`,
          maxHeight:'120vw',
          width: '55vw',
          borderRadius: '8px',
          zIndex: 5,
          border: '0px solid black',
          boxShadow: '0px 4px 40px 0px #0000001F' 
        }}
        initial="hidden"
        animate={SecondfilterExpand ? 'visible' : 'hidden'}
        variants={animationVariants}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
      >
        <div style={{marginTop:'1vh'}}></div>
        { data.length > 7 && (
          <div className="">
            <div style={{ display: 'flex' }}>
              <input
                placeholder='Search'
                className='inputSearch'
                type='text'
                style={{ width: '90%', marginTop: '0vh', marginLeft: '4vw' , height:'4vh', fontSize:'2vh'}}
                onChange={filterInput}
              />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Search style={{ margin: '0vh 0vw 0vh -7vw' , heigth:'1.5vh', color:'#737E86'}} />
              </div>
            </div>
          </div>
        )}

        {originalData && (
          originalData.length > 1 || (originalData.length === 1 && originalData[0] !== "") ? (
            originalData.map((item, index) => (
              <div key={item + index} className="outerTenantDiv" style={{marginTop:'0vh'}}>
                {item.toLowerCase() === "datetime" ? (
                  // Render a different div for "datetime" string
                  <div className="tenantTicketDivMobile" key={index} style={{fontSize:'2vh',marginTop:'2vh'}}>
                      Select Date:<br/>
                      <input
                        style={{width:"90%", height:'4vh', marginTop:'1vh', marginBottom:'1vh'}}
                        type="date"
                        id={`datePicker-${index}`}
                        value={selectedDatetime}
                        onChange={handleDatetimeChange}
                      />
                    <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '8px', marginTop: '0.5vh', border: '0px solid black', height: '4vh', marginBottom:'2vh' }} className="buttonCreateNew" onClick={handleConfirmDatetime}>
                      Confirm
                    </button>
                  </div>
                ) : (
                  // Render the regular div for other items
                  <div
                    style={{fontSize:'2vh', display: 'flex', justifyContent: 'space-between'}}
                    onClick={() => chooseFilter(index)}
                    className="tenantTicketDivMobile"
                    key={index}
                  >
                    {item}
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className="outerTenantDiv">
              <div className="tenantTicketDivMobile" style={{fontSize:'2vh', display: 'flex', justifyContent: 'space-between',}}>
                No Data Available
              </div>
            </div>
          )
        )}
        <div style={{marginBottom:'1vh'}}></div>
      </motion.div>
    )}
    </div>
  )
}

export default FilterLevelTwo