import React from 'react'; import axios from 'axios' ;
import ContentHeader from '../../content/ContentHeader'; import { useState, useEffect} from 'react';
import Cookies from 'universal-cookie'; 
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
import BootstrapTable from 'react-bootstrap-table-next'
import {Search as NoirSearch } from 'iconoir-react'
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { useNavigate } from 'react-router-dom';
import useOrientation from '../../general/useOrientation';

const cookies = new Cookies();
const MySwal = withReactContent(Swal)
const { SearchBar } = Search;

function ReportTable({columnsSummary, columns,link, refresh, setLoading, clickLink, filterData}) {
  const navigate = useNavigate();
  const displayedRow = 12;
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait') { isThisPortrait = true;}
  const [dataa, setDataa] = useState([]);
  const [totlength, setTotLength] = useState(0);
  const [txtBL, setTxtBL] = useState('');
  const [currentPage, setCurrentPage] = useState(0); // Track the current page
  useEffect(() => {
    setLoading(true)
    if (filterData.length > 0){
      getFilteredData(link)
    } else {
      getData(link);
    }
  }, [link,filterData])

  useEffect(() => {
    if (filterData.length > 0){
      getFilteredData(link)
    } else {
      getData(link);
    }
  }, [refresh])

  useEffect(() => {
    let cpmone = parseInt(currentPage) - 1 
    let firstDigit = (parseInt(displayedRow) * parseInt(cpmone) )
    let secondDigit = parseInt(firstDigit) + parseInt(displayedRow)
    if (secondDigit > totlength) secondDigit = totlength
    let a = `${firstDigit+1} - ${secondDigit} of ${totlength} data`   ;
    setTxtBL(a);
    if (totlength === 0){
      setTxtBL();
    }
  }, [currentPage,totlength])
  
  const getData = (link) => {
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    axios.interceptors.request.use(config => {config.headers['x-access-token'] = sessionToken;return config;},error => {return Promise.reject(error);});
    axios.get(`${process.env.REACT_APP_PATH_DESTINATION}/${link}`) 
      .then(res => {
        console.log("Report Table",res.data.data);
        setDataa(res.data.data);
        setTotLength(res.data.data.length);
        setCurrentPage(1);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
            MySwal.fire({
            title: <strong>Error!</strong>,
            html: <p>{err}</p>
        })
        setLoading(false);
      })
  }

  const getFilteredData = (link) => {
    console.log("logging filter data before hit API",filterData)
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    axios.interceptors.request.use(config => {config.headers['x-access-token'] = sessionToken;return config;},error => {return Promise.reject(error);});
    axios.post(`${process.env.REACT_APP_PATH_DESTINATION}/${link}`, { data: filterData }) 
      .then(res => {
        console.log("getfiltered data logging",res.data.data);
        setDataa(res.data.data);
        setTotLength(res.data.data.length);
        setCurrentPage(1);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
            MySwal.fire({
            title: <strong>Error!</strong>,
            html: <p>{err}</p>
        })
        setLoading(false);
      })
  }
  const options = {
    sortIndicator: true,
    bordered: false,
    hideSizePerPage: true, // Hide the option to change the number of rows
    sizePerPage: displayedRow, // You can set your desired default value here
    page: 1,
    showTotal: false, // Hide the total number of rows
    sizePerPageList: [], // Remove the size per page options from the dropdown
    
  };
  const handlePageChange = (type, { page, sizePerPage }) => {
    setCurrentPage(type); 
  };
  const searchProps = {
    delay: 800,
    placeholder: isThisPortrait? "search..." : (link === "ticketListToMe" || link === "ticketListByMe")? "search ticket...":"search document...",
    className: "searchRight",
    zIndex : 5,
    position: 'relative',
    fontFamily: 'Blender Pro !important',
    width:isThisPortrait?'5vw':'inherit'
  };

  const tableRowEvents = {
    onClick: (e, row, rowIndex) => {
      //console.log('row',row); //got all the data u ever want here
      if (link === "getReport")
        navigate('/'+row.clickLink+'/'+row.TicketID);
    },
    onMouseEnter: (e, row, rowIndex) => {
      //console.log(`enter on row with index: ${rowIndex}`);
    }
  }

  return (
    <>
    <ContentHeader title=""/> 
        <section className="content">
        <div className="container-fluid">
            <div className="row"> 
              <div className="col-md-12">
                <ToolkitProvider
                  keyField="id"
                  data={dataa}
                  columns={link === "getReportSummary"? columnsSummary : columns}
                  bootstrap4={true}
                  search={searchProps}
                >
                  {props => (
                    <div>
                       <div style={{ width:isThisPortrait? '25vw':'200px', float:'right',zIndex:-1 }}>           
                      <table >
                        <tbody >
                        <tr >
                          <td>
                            <SearchBar {...props.searchProps} {...searchProps} />
                          </td>
                          </tr>
                        </tbody>
                      </table>
                      </div>
                      <div style={{ overflowX: 'auto' ,width:'100%'}}>
                      <BootstrapTable
                        {...props.baseProps}
                        responsive
                        striped 
                        hover
                        pagination={paginationFactory({ ...options, onPageChange: handlePageChange })}
                        classes="custom-table" 
                        rowClasses="custom-row"
                        rowEvents={ tableRowEvents }
                        style={{overflowX:'auto', width:'100%'}}
                      />
                      </div>
                    </div>
                  )}
                </ToolkitProvider>
                <div style={{marginTop:isThisPortrait?'1vh':'1vw', marginLeft:isThisPortrait?'3vw':'inherit'}}>{txtBL}</div>
              </div>
            </div>
        </div>
     </section>
    </>

  )
}

export default ReportTable