import React, { useState, useEffect } from 'react';

const TableGen = ({ element, defaultValue = [{colOne: '', colTwo: ''}] }) => {
  if (defaultValue === null || defaultValue === undefined) {
    defaultValue = [{colOne: '', colTwo: ''}];
  }
  const [rows, setRows] = useState(defaultValue.map(row => [row.colOne, row.colTwo]));
  const [tableData, setTableData] = useState([]);

  const addRow = (event) => {
    event.preventDefault();
    setRows([...rows, ['', '']]);
    handleTableChange();
  };
  
  const handleRowChange = (event, rowIndex, colIndex) => {
    const newRows = rows.map((row, index) => {
      if (index === rowIndex) {
        return [colIndex === 0 ? event.target.value : row[0], colIndex === 1 ? event.target.value : row[1]];
      } else {
        return row;
      }
    });
    setRows(newRows);
    handleTableChange();

  };
  

  useEffect(() => {
    const updatedTableData = rows.map(row => {
      return { "colOne": row[0], "colTwo": row[1] };
    });
    setTableData([rows[0], ...updatedTableData]); // add back the header row
    handleTableChange()
  }, [rows]);
  
  const handleTableChange = () => {
    const updatedTableData = [
      { "colOne": rows[0][0], "colTwo": rows[0][1] }, // add the header row
      ...rows.slice(1).map(row => {
        return { "colOne": row[0], "colTwo": row[1] };
      })
    ];
    const tableJson = JSON.stringify(updatedTableData, null, 2);
    setTableData(updatedTableData);
    const hiddenInput = document.getElementById(element.id);
    if (hiddenInput) {
      hiddenInput.value = tableJson;
      console.log("hidden input");
      console.log(hiddenInput.value);
    }
  };
  
  
  // check for empty array
  if (!defaultValue || defaultValue.length === 0 || (defaultValue.length === 1 && defaultValue[0].colOne === "" && defaultValue[0].colTwo === "")) {
    return <>
    <h3>{element.label}</h3>
    <table>
      <thead>
        <tr>
          <th><input type="text" placeholder="Header 1" onChange={(event) => handleRowChange(event, 0, 0)} /></th>
          <th><input type="text" placeholder="Header 2" onChange={(event) => handleRowChange(event, 0, 1)} /></th>
        </tr>
      </thead>
    </table>
    <input type="hidden" id={element.id} name={element.id} value={JSON.stringify(tableData)} />
  </>
  }
   else {
    return (
    <>
        <h3>{element.label}</h3>
        <table>
        <thead>
            <tr>
            <th>{rows[0][0]}</th>
            <th>{rows[0][1]}</th>
            </tr>
        </thead>
        <tbody>
            <tr style={{ display: 'none' }}>
            <td><input type="text" value={rows[0][0]} onChange={(event) => handleRowChange(event, 0, 0)} /></td>
            <td><input type="text" value={rows[0][1]} onChange={(event) => handleRowChange(event, 0, 1)} /></td>
            </tr>
            {rows.slice(1).map((row, rowIndex) => (
            <tr key={rowIndex}>
                <td><input type="text" value={row[0]} onChange={(event) => handleRowChange(event, rowIndex + 1, 0)} /></td>
                <td><input type="text" value={row[1]} onChange={(event) => handleRowChange(event, rowIndex + 1, 1)} /></td>
            </tr>
            ))}
        </tbody>

        </table>
        <button onClick={addRow}>Add Row</button>
        <input type="hidden" id={element.id} name={element.id} value={JSON.stringify(tableData)} />
    </>
    );
  }
};

export default TableGen;
