import React from 'react'
import ReportTable from './ReportTable'

function ReportTableParent({columnsSummary, columns, link, refresh, setLoading, isExpanded, setIsExpanded, imagePopup, setImagePopup,filterData}) {
    //if (link === "getReport" || link === "getReportSummary"){
        return (<ReportTable columnsSummary={columnsSummary} columns={columns} filterData={filterData} link={link} refresh={refresh} setLoading={setLoading} clickLink={"ticketDetail"}/>)
    //} else {
    //    return null
    //}
}

export default ReportTableParent