import React, { useEffect, useState } from 'react';
import ImageAttachment from './ImageAttachment';
import OtherAttachment from './OtherAttachment';

function AttachmentHandler({ id, isExpanded, source, handleImageClick, handlePdfClick,fileName,clearHandler ,isDraft="0"}) {
  const [isSourceImage, setIsSourceImage] = useState(false);
  const [fileExtension, setFileExtension] = useState('');
  const [attachmentType, setAttachmentType] = useState('');

  // Function to determine if the source is an image
  const isImage = (source) => {
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg'];
    const fileExtension = source.slice(((source.lastIndexOf(".") - 1) >>> 0) + 2);
    return imageExtensions.includes(`.${fileExtension.toLowerCase()}`);
  };


  useEffect(() => {
    if (source === null || source.trim() === '') {
      
      // Set attachment type to indicate no attachment
      setAttachmentType('none');
      setIsSourceImage(false); // Optional: Set isSourceImage to false
      setFileExtension(''); // Optional: Set file extension to an empty string
    } else { 
      // Get the file extension
      const newFileExtension = source.slice(((source.lastIndexOf(".") - 1) >>> 0) + 2);

      // Determine attachment type
      const allowedExtensions = ['.xls', '.xlsx', '.pdf', '.mp4', '.doc', '.docx'];
      
      if (isImage(source)) {
        setAttachmentType('image');
      } else if (allowedExtensions.includes(`.${newFileExtension.toLowerCase()}`)) {
        // Determine the type based on the allowed extensions
        if (newFileExtension.toLowerCase() === 'xls' || newFileExtension.toLowerCase() === 'xlsx') {
          setAttachmentType('excel');
        } else if (newFileExtension.toLowerCase() === 'pdf') {
          setAttachmentType('pdf');
        } else if (newFileExtension.toLowerCase() === 'mp4') {
          setAttachmentType('video');
        } else if (newFileExtension.toLowerCase() === 'doc' || newFileExtension.toLowerCase() === 'docx') {
          setAttachmentType('word');
        }
      } else {
        setAttachmentType('other');
      }

      setIsSourceImage(isImage(source));
      setFileExtension(newFileExtension);
    }
  }, [source]);

  return (
    attachmentType === 'none' ? null : (
      isSourceImage ? (
        <ImageAttachment
          id={id}
          isExpanded={isExpanded}
          source={source}
          handleImageClick={handleImageClick}
          fileName={fileName}
          clearHandler={clearHandler}
          isDraft={isDraft}
        />
      ) : (
        <OtherAttachment
          id={id}
          isExpanded={isExpanded}
          source={source}
          handlePdfClick={handlePdfClick}
          type={attachmentType}
          fileName={fileName}
          clearHandler={clearHandler}
          isDraft={isDraft}
        />
      )
    )
  );
  
}

export default AttachmentHandler;
