import React, { useEffect, useState } from 'react'
import HeaderPage from '../../headerPage/HeaderPage';
import Cookies from 'universal-cookie'; import axios from 'axios'
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
import { useParams } from 'react-router-dom';
import ConfigInputText from '../configHelper/ConfigInputText';
import ConfigDropdown from '../configHelper/ConfigDropdown';
import useOrientation from '../../../general/useOrientation';

const cookies = new Cookies();
const MySwal = withReactContent(Swal)
function ConfigViewTenantMgmt({addEdit, changeNavState}) {
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [entitas, setEntitas] = useState('');
  const [statusOperational, setStatusOperational] = useState('');
  const [namaTenant, setNamaTenant] = useState('');
  const [kodeTenant, setKodeTenant] = useState('');
  const [npwp, setNpwp] = useState('');
  const [telephone, setTelephone] = useState('');
  const [emailTenant, setEmailTenant] = useState('');
  const [websiteTenant, setWebsiteTenant] = useState('');
  const [alamatTenant, setAlamatTenant] = useState('');
  const [tenantDistrikId, setTenantDistrikId] = useState('0');
  const [statusOwnershipId, setStatusOwnershipId] = useState('');
  const [nationality, setNationality] = useState('');
  const [externalGroupId, setExternalGroupId] = useState('1');
  const [tenantBisnisTypeId, setTenantBisnisTypeId] = useState('');
  const [tenantStatusId, setTenantStatusId] = useState('0');
  const [blokId, setBlokId] = useState('');
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait') { isThisPortrait = true;}
  useEffect(() => {
    if (addEdit == "edit") {
      getData();
    }
  }, [addEdit])

  useEffect(() => {
    changeNavState('Configurations');
  }, [])

  const pickDropdownExtGroup = (val) => {
    setExternalGroupId(val.value);
  }

  const getData = () => {
    setIsLoading(true);
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    axios.interceptors.request.use(config => {config.headers['x-access-token'] = sessionToken;return config;},error => {return Promise.reject(error);});
    axios.get(`${process.env.REACT_APP_PATH_DESTINATION}/tenantMgmtView/${id}`) 
      .then(res => {
        console.log("logging configviewtenantmgmt",res.data.data);
        setEntitas(res.data.data.entitas);
        setStatusOperational(res.data.data.status_operasional);
        setNamaTenant(res.data.data.nama_tenant);
        setKodeTenant(res.data.data.kode_tenant);
        setNpwp(res.data.data.npwp);
        setTelephone(res.data.data.telephone);
        setEmailTenant(res.data.data.email_tenant);
        setWebsiteTenant(res.data.data.website_tenant);
        setAlamatTenant(res.data.data.alamat_tenant);
        setTenantDistrikId(res.data.data.tenant_distrik_id);
        setStatusOwnershipId(res.data.data.status_ownership_id);
        setNationality(res.data.data.nationality);
        setExternalGroupId(res.data.data.external_group_id);
        setTenantBisnisTypeId(res.data.data.tenant_bisnis_tipe_id);
        setTenantStatusId(res.data.data.tenant_status_id);
        setBlokId(res.data.data.blok_id);
        setIsLoading(false);
      })
      .catch(err => {
        console.log(err);
        MySwal.fire({
          title: <strong>Error!</strong>,
          html: <p>{err.response?.data?.message || "An error occurred. Please try relogging"}</p>
        })
        setIsLoading(false);
      })
  }

  const submitData = () => {
    //postData
    let data = {
      entitas : entitas,
      statusOperasional : statusOperational,
      namaTenant : namaTenant,
      kodeTenant : kodeTenant,
      npwp : npwp,
      telepon : telephone,
      emailTenant : emailTenant,
      websiteTenant : websiteTenant,
      alamatTenant : alamatTenant,
      tenantDistrikId : tenantDistrikId,
      statusOwnership : statusOwnershipId,
      nationality : nationality,
      externalGrup : externalGroupId,
      tenantBisnisTipe : tenantBisnisTypeId,
      tenantStatusId : tenantStatusId,
      blok : blokId,
      id : id
    }

    setIsLoading(true);
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    let apiLink = "";
    if (addEdit === "edit"){
      apiLink=`${process.env.REACT_APP_PATH_DESTINATION}/tenantMgmtEdit`
    } else if (addEdit === "add"){
      apiLink=`${process.env.REACT_APP_PATH_DESTINATION}/tenantmgmtAdd`
    }
    axios.interceptors.request.use(config => {config.headers['x-access-token'] = sessionToken;return config;},error => {return Promise.reject(error);});
    axios.post(apiLink,data) 
      .then(res => {
        console.log(res.data.data);
        setIsLoading(false);
        MySwal.fire({
          title: <strong>Success</strong>,
          html: <p>Data Edited</p>
        })
      })
      .catch(err => {
        console.log(err);
            MySwal.fire({
            title: <strong>Error!</strong>,
            html: <p>{err.response?.data?.message || "An error occurred. Please try relogging"}</p>
        })
        setIsLoading(false);
      })
  }

  return (
  <>
    <HeaderPage
        title1={"Configurations"}
        title2={"Tenant Management"}
        link1={"/configuration/tenant"}
        loadingState={isLoading}
    />

    <div style={{marginTop:'4vw', marginLeft:isThisPortrait?'5vw':'', marginRight:'1vw'}} className='row'>
      <div className='form-group'>
        <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
        <ConfigInputText name={"Nama PT"} data={namaTenant} setData={setNamaTenant}/>

        <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
        <ConfigInputText name={"Alamat PT"} data={alamatTenant} setData={setAlamatTenant}/>
        
        <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
        <ConfigInputText name={"Block PT"} data={blokId} setData={setBlokId}/>
        
        <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
        <ConfigInputText name={"Ownership"} data={statusOwnershipId} setData={setStatusOwnershipId}/>
        
        <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
        <ConfigDropdown defValue={externalGroupId} type={8} pickDropdown={pickDropdownExtGroup} title="Group Announcement" link={"/extGroup"} isMulti={false}/>
        
        <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
        <ConfigInputText name={"Entitas"} data={entitas} setData={setEntitas}/>
        
        <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
        <ConfigInputText name={"Jenis Industri"} data={tenantBisnisTypeId} setData={setTenantBisnisTypeId}/>
        
        <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
        <ConfigInputText name={"Negara Asal"} data={nationality} setData={setNationality}/>
        
        <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
        <ConfigInputText name={"Status Operational"} data={statusOperational} setData={setStatusOperational}/>
        
        <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
        <button type='submit' onClick={submitData} className='btn buttonBlue' style={{marginTop:'1vh'}}>
          Submit
        </button>
        <br/>
      </div>
    </div>    
  </>
  )
}

export default ConfigViewTenantMgmt