import React from 'react'; import { useState, useEffect} from 'react';
import { PageFlip } from 'iconoir-react';
function LatestActivity({ title, body, time, status }) {
  const maxBodyCharLength = 45;
  const [btnBgColor, setBtnBgColor] = useState('');
  const [btnTxtColor, setBtnTxtColor] = useState('');
  useEffect(() => {
      setStatus();
  }, [status])
  const setStatus = () => {
    if (status === 'Waiting'){
      setBtnBgColor('#FFF4E0');
      setBtnTxtColor('#A3711C');
    } else if (status === 'On Progress'){
      setBtnBgColor('#D6EBF6');
      setBtnTxtColor('#01648C');
    } else if (status === 'Forwarded'){
      setBtnBgColor('#E7E5F5');
      setBtnTxtColor('#403688');
    } else if (status === 'Resolved'){
      setBtnBgColor('#DCF5F3');
      setBtnTxtColor('#00786B');
    } else if (status === 'Closed'){
      setBtnBgColor('#E8E9EB');
      setBtnTxtColor('#737E86');
    } else if (status === 'Overdue'){
      setBtnBgColor('#FFDBD6');
      setBtnTxtColor('#A02411');
    }else {
      setBtnBgColor('#E8E9EB');
      setBtnTxtColor('#737E86');
    }
  }

  if (body && body.length > maxBodyCharLength) {
    // Trim content to 50 characters and add "..."
    body = body.substring(0, maxBodyCharLength) + "...";
  }

  return (
    <div className="row" style={{marginLeft:'0.6vw'}} >
      <div className="col-md-3">
      <p className='latestActivity' > <a style={{backgroundColor: '#FFFFFF', borderRadius: '0.85vw', padding:'0.2vw 0.2vw 0.2vw 0.2vw', fontFamily:'Blender Pro Book'}}><PageFlip color='#737E86' height='1.25vw' /></a> {title} &nbsp; </p>
      </div>
      <div className="col-md-5">
      <p className='latestActivity' style={{fontFamily:'Blender Pro Book'}}>{body} </p>
      </div>
      <div className="col-md-2">
      <p className='latestActivity' style={{fontFamily:'Blender Pro Book'}}>{time} </p>
      </div>
      <div className="col-md-2">
        <p className='latestActivity' style={{fontFamily:'Blender Pro Book'}}>
            <a style={{ backgroundColor: btnBgColor, color: btnTxtColor, borderRadius: '0.85vw', padding: '0.4vw' , fontSize: '0.9vw', fontFamily:'Blender Pro'}}>{status}</a> 
        </p>
      </div>
    </div>
  )
}

export default LatestActivity