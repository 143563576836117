import React from 'react'

function LoadingSpinner() {
  return (
    <img
        src="dist/img/loading.png"
        alt="Loading..."
        className="spinner"
        style={{
            position: 'relative',
            backgroundPosition: 'center',
            width: '20px', // Adjust the width according to your design
            height: '20px', // Adjust the height according to your design
        }}
    />
  )
}

export default LoadingSpinner