import React, { useState } from 'react'; import axios from 'axios' ;
import Cookies from 'universal-cookie'; 
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
import useOrientation from '../../../general/useOrientation';

const cookies = new Cookies();
const MySwal = withReactContent(Swal)
function SwitchOnOff({setLoading,initialActive,data,link}) {
  const [active, setActive] = useState(initialActive); 
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait') { isThisPortrait = true;}
  
  const changeData = () => {
    //alert(`${process.env.REACT_APP_PATH_DESTINATION}/${link}/active/${data}`);
    setLoading(true);
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    axios.interceptors.request.use(config => {config.headers['x-access-token'] = sessionToken;return config;},error => {return Promise.reject(error);});
    axios.get(`${process.env.REACT_APP_PATH_DESTINATION}/${link}/active/${data}`) 
      .then(res => {
        console.log(res.data.data);
        setActive(1 - active)
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
            MySwal.fire({
            title: <strong>Error!</strong>,
            html: <p>{err.response?.data?.message || "An error occurred. Please try relogging"}</p>
        })
        setLoading(false);
      })
  }
  return (
    <img 
        src={active == 1 ? 
            process.env.REACT_APP_SELF_PATH_DESTINATION+"/SwitchOn.png" : 
            process.env.REACT_APP_SELF_PATH_DESTINATION+"/SwitchOff.png"} 
        alt="Switch On Off" 
        style={{height:isThisPortrait?"2vh":"2vw"}}
        onClick={changeData}
    />
  )
}

export default SwitchOnOff