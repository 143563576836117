import React from 'react' ;import { useState, useEffect} from 'react';
import ContentHeader from '../../content/ContentHeader'
import BackButton from '../../content/BackButton'
import Select from 'react-select'
import Cookies from 'universal-cookie'; 
import axios from 'axios' ;
const cookies = new Cookies();

function PdDetails({handleSubmit, data, inputs, setInputs, defaultSelect}) {

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }

  const handleSelect = (val) => {
    setInputs(values => ({...values, ["user_id"]: val}))
  }

  const [dataa, setDataa] = useState([]);
  var temp = [];
  useEffect(() => {
    getData();
  }, [])
  
  const getData = () => {
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    var path = process.env.REACT_APP_PORTAL_DESTINATION + "api/getAllUserForApproval";   
    const config = {headers:{['x-access-token']: sessionToken}};
    axios.get(path , config)
    .then(function (res) {
        for (var i= 0; i < res.data.data.length; i++){
            if (res.data.data[i].id != 0){
                var temp2 = {value: res.data.data[i].id , label: res.data.data[i].fullname};
                temp.push(temp2);
            } 
        }
        setDataa(temp);
    })
    .catch(function (error) {
        console.log(error);
    });
  } 
  return (
    <>
      <BackButton link="../pdashSetting"/>
      <ContentHeader title={data.title}/>
      <section className="content">
        <div className="container-fluid">
          <div className="row"> 
          <form onSubmit={handleSubmit}>
            <div className="card-body">
              <div className="form-group">
                <label> Personal Dashboard User </label>
                <Select
                  name="approvers"
                  options={dataa}
                  onChange={(choice) => handleSelect(choice.value)}
                  defaultValue={defaultSelect}
                />
              </div>
              <div className="form-group">
                <label>Power BI Link</label>
                <input type="text" value={inputs.pbi_link || ""} onChange={handleChange} name="pbi_link" className="form-control" placeholder="Enter Power BI Link" />
              </div>
              <button type="submit" className="btn btn-primary">Submit</button>
            </div>
          </form>
          </div>
        </div>
      </section>
    </>
  )
}

export default PdDetails