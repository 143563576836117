import React from 'react'
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal)

class docDashDetails extends React.Component {
    
    static detailsFormatter = (cell, row, rowIndex, formatExtraData) => {
        //console.log(row);
        let tblString = "<b> No. " + String(row.doc_number) + " - " + String(row.doc_name) + "</b><br/><br/>\
        <table border ='1' cellpadding='5' style='margin-left: auto;margin-right: auto;'>";

        tblString = this.tableFormatter(row,tblString);

        return <>
        <button title="details" 
        onClick={() => MySwal.fire({
            title: <strong>Approval Details </strong>,
            html:tblString,
            width: '800px'
            })
        } 
        className="btn btn-warning">
        <i className="fa fa-eye"></i>
        </button>
        </>
    }

    static detailsFormatterInside = (data, tblString) => {
        tblString = "<h3><b> "+String(data.doc_name)+" <br/> "+String(data.doc_number)+"</b></h3><table border ='1' cellpadding='5' style='margin-right: auto;'>";
        tblString = this.tableFormatter(data,tblString);
        return tblString;
    }

    static tableFormatter(data, tblString) {

        let stts = String(data.approval_status);
        let by = String(data.approved_by_fullname); by = by.split(","); 
        let at = String(data.approved_at); at = at.split(","); 
        
        if(at[0])
        for (var i=0; i < at.length; i++){
            let a = new Date(at[i]);
            let b = String(a);
            b = b.substring(0,24);
            at[i] = b;
        }
        
        //console.log("data.approve_notes logging");
        //console.log(data.approve_notes);
        var apvArr = [];
        try {
            apvArr = data.approve_notes.split('|');
        } catch {

        }
        
        tblString +="<thead>\
                <tr> \
                    <th>Titles</th>\
                    <th>Name</th> \
                    <th>Timestamp</th> \
                    <th>Status</th> \
                    <th>Notes</th> \
                </tr>\
            </thead>\
            <tbody>"
        if (by[0])
        for (var i = 0; i< by.length; i++){
            tblString += 
            "<tr>\
                <td>"+data["ad_"+(i+1)+"_title"]+"</td>\
                <td>"+by[i]+"</td>\
                <td>"+at[i]+"</td>";
            if (stts == "2" && i+1 == by.length){
                tblString += "<td><p style='color:red'>Rejected</p></td>";
                tblString += "<td>"+data.reject_reason+"</td></tr>";
            } else if (stts == "3" && i+1 == by.length){
                tblString += "<td><p style='color:red'>Cancelled</p></td>";
                tblString += "<td>"+data.reject_reason+"</td></tr>";
            } else if (by[i]){
                tblString += "<td><p style='color:green'>Approved</p></td>";       
                try {
                    if (apvArr[i] && apvArr[i] != null && apvArr[i] != undefined && apvArr[i] != 'undefined') {
                        tblString += "<td>"+apvArr[i]+"</td></tr>";
                    } else {
                        tblString += "<td>None</td></tr>";
                    }
                } catch (error) {
                    tblString += "<td>None</td></tr>";
                }
            } else {
                tblString += "<td><p style='color:green'></p></td>";
                tblString += "<td></td></tr>";
            }
        }

        if(stts == "0"){
            //additonal row for waiting if not completed yet
            let abc = 1;
            tblString += 
            "<tr>\
                <td>"+data["ad_"+data.current_level+"_title"]+"</td>\
                <td>"+data.apv_fullname+"</td>\
                <td>-</td>\
                <td><p style='color:grey'>Waiting</p></td>\
            </tr>"
        }

        tblString +="</tbody></table> <br>";

        return tblString;
    }
}

export default docDashDetails;