import React from 'react';
import { useState, useEffect } from 'react';
import 'react-form-builder2/dist/app.css';
import { useParams } from 'react-router-dom';
import Cookies from 'universal-cookie'; 
import axios from 'axios' ;
import ContentHeader from '../../content/ContentHeader';
import FormToHtmlRules from './FormToHtmlRules';
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
import BackButton from '../../content/BackButton';
const MySwal = withReactContent(Swal)
const cookies = new Cookies();

function FormRules() {
  const { id, docName } = useParams();
  const [formData, setformData] = useState([]);
  const [dataReady, setDataReady] = useState(false);
  const [dataValue, setDataValue] = useState([]);
  const [rulesValue, setRulesValue] = useState([]);

  useEffect(() => {
    getData();
  }, [])

  const getData = () => {
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    var path = process.env.REACT_APP_PATH_DESTINATION + "/viewDocMasterDemo/" + id;   
    const config = {headers:{['x-access-token']: sessionToken}};
    axios.get(path , config)
      .then(res => {
        //console.log("res.data.data.doc_autofill");
        //console.log(res.data.data.doc_autofill);
        if (res.data.data.doc_autofill && String(res.data.data.doc_autofill) != "{}") {
          setDataValue(res.data.data.doc_autofill);
        }
        if (res.data.data.doc_rules && String(res.data.data.doc_rules) != "{}") {
          setRulesValue(res.data.data.doc_rules);
        }
        if (res.data.data.doc_content && String(res.data.data.doc_content) != "{}") {
          console.log("enter if");
          setformData(res.data.data.doc_content);
          setDataReady(true);
        } else {
          console.log("enter else");
          setDataReady(true);
        }
        Swal.close()
      })
      .catch(err => {
        console.log(err);
        Swal.close()
      })
  }    


  const onSubmit = (event) => {
    event.preventDefault();
  
    const form = event.currentTarget;
    const requestBody = new FormData();
  
    const colMd6Elements = form.getElementsByClassName('col-md-6');
  
    for (let i = 0; i < colMd6Elements.length; i++) {
      const selectElement = colMd6Elements[i].querySelector('select');
      if (selectElement) {
        const selectName = selectElement.getAttribute('name');
        if (selectName.startsWith('select_')) {
          const selectValue = selectElement.value;
          requestBody.append(selectName, selectValue);
        }
      }
    }
    requestBody.append('docId', id);
    
    // Log the form data
    for (const [key, value] of requestBody.entries()) {
      console.log(`${key}: ${value}`);
    }

    var sessionToken = cookies.get('sessionToken', { path: '/' })
    var path = process.env.REACT_APP_PATH_DESTINATION + "/SubmitRulesDoc" ;   
    const config = {headers:{['x-access-token']: sessionToken}};
    axios.post(path ,requestBody, config)
    .then(function (response) {
      if (response.data.status == '200')
        MySwal.fire({
          title: <strong>Success!</strong>,
          html: <p>{response.data.message}</p>,
          icon: 'success'
        }).then(() => {
          window.location.reload(true);
        })
      else 
        MySwal.fire({
          title: <strong>Failed!</strong>,
          html: <p>{response.data.message}</p>,
          icon: 'error'
        }).then(() => {
          window.location.reload(true);
        })
    })
    .catch(function (error) {
        console.log(error);
    });
  };
  
  


  if (!dataReady) {
    return <ContentHeader title="Loading Attachment..."/>;
  } else {
    //console.log(dataValue);
    return (
      <>
      <BackButton link="../../documentList"/>
      <ContentHeader title={"Rules "+docName}/>
        <section className="content">
          <div className="container-fluid">
            <div className=""> 
              <form onSubmit={(event) => onSubmit(event)} encType="multipart/form-data">
                <FormToHtmlRules formData={formData} dataValue={dataValue} rulesValue={rulesValue}/>
                <button className="btn btn-primary">Submit</button>
              </form>
              <br/>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default FormRules;