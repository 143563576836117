import React, { useEffect, useState } from 'react';

function CountDown({ data ,status}) {
  const [countdown, setCountdown] = useState('...loading...');

  useEffect(() => {
    if (status == "Closed"){
        setCountdown(`0D 00:00:00`);
    } else {
        const interval = setInterval(() => {
          //console.log("data due at = ", data)
          const currentTime = new Date();
          const timeDifference = new Date(data) - currentTime;
    
          const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
          const hours = String(Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, '0');
          const minutes = String(Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
          const seconds = String(Math.floor((timeDifference % (1000 * 60)) / 1000)).padStart(2, '0');
    
          setCountdown(`${days}D ${hours}:${minutes}:${seconds}`);
        }, 1000);
        return () => clearInterval(interval);
    }
  }, [data]);

  return <>{countdown}</>

}

export default CountDown;
