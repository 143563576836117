import React, { useEffect, useState } from 'react'
import {Cancel} from 'iconoir-react'
import useOrientation from '../../general/useOrientation';
function FaqView({data,closePopup}) {
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait') { isThisPortrait = true;}
  return (
    <div className={isThisPortrait?"popupContentMobile":"popupContent"} style={{width: isThisPortrait?'350px':'640px', marginTop:isThisPortrait?"":'auto',height:isThisPortrait?'85vh':''}}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <p style={{fontSize:'20px'}}><b>{data.title}</b></p>
        <Cancel onClick={closePopup} className="cursorPointer" style={{marginTop:'-20px'}}/>
      </div>
      <div style={{marginTop:'0.5vw'}}>
        <img 
            style={{maxWidth:'100%', maxHeight:'100%', overflow:'scroll'}}
            src={process.env.REACT_APP_FAQ_BG_DESTINATION + data.content}
        />
      </div>
    </div>
  )
}

export default FaqView