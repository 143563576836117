import React from 'react';
import { useState, useRef, useEffect } from 'react';

class Builder extends React.Component {

  static renderHeader = (element) => {
    return <h3 dangerouslySetInnerHTML={{ __html: element.content }}></h3>;
  };
  
  static renderTextInput = (element, initialValue) => {
    //console.log("textinput required  = " + element.required);
    return (
      <div className="form-group">
        <label htmlFor={element.field_name} dangerouslySetInnerHTML={{ __html: element.label }}></label>
        <input
          type="text"
          className="form-control"
          id={element.field_name}
          name={element.field_name}
          defaultValue={initialValue || ''}
          required={element.required}
        />
      </div>
    );
  };
  
  static renderCheckboxes = (element, initialValues) => {
    const handleCheckboxChange = () => {
      const newState = {};
  
      // Update the input value based on the checkbox change
      element.options.forEach((option) => {
        const checkbox = document.getElementById(option.key);
        newState[option.key] = checkbox.checked ? 'ok' : '';
      });
  
      // Update the input text
      let inputValue = '';
      element.options.forEach((option) => {
        inputValue += newState[option.key];
      });
      document.getElementById(element.label + 'txt').value = inputValue;
  
      // Update the checkbox states
      element.options.forEach((option) => {
        document.getElementById(option.key).checked = newState[option.key] === 'ok';
      });
    };
  
    return (
      <>
        <label htmlFor={element.field_name} dangerouslySetInnerHTML={{ __html: element.label }}></label>
        <input type="text" id={element.label + 'txt'} style={{ height: '1px', width: '1px', opacity: 0, border: 'none', outline: 'none', padding: 0, margin: 0 }} required={element.required} />
        <br />
        <div className={element.inline ? 'form-check form-check-inline form-group' : 'form-group'}>
          {element.options.map((option) => (
            <div key={option.key}>
              <input
                type="checkbox"
                id={option.key}
                name={option.key}
                value={option.value}
                defaultChecked={initialValues && initialValues.hasOwnProperty(option.key)}
                onChange={handleCheckboxChange} // Attach the event handler here
              />
              <label htmlFor={option.key}>
                {' '}
                &nbsp; {option.text} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </label>
            </div>
          ))}
        </div>
      </>
    );
  };
  

  static renderLabel = (element) => {
    return <label dangerouslySetInnerHTML={{ __html: element.content }}></label>;
  };
  
  static renderParagraph = (element) => {
    return <p dangerouslySetInnerHTML={{ __html: element.content }}></p>;
  };
  
  static renderLineBreak = () => {
    return <br />;
  };

  static renderDropdown = (element, initialValue = '') => {
    return (
      <div className="form-group">
        <label htmlFor={element.field_name} dangerouslySetInnerHTML={{ __html: element.label }}></label>
        <select id={element.field_name} name={element.field_name} className="form-control" defaultValue={initialValue} required={element.required}>
          {element.options.map((option) => (
            <option key={option.key} value={option.value}>
              {option.text}
            </option>
          ))}
        </select>
      </div>
    );
  };
  

  static renderTags = (element, initialValue = '') => {
    return (
      <div className="form-group">
        <label htmlFor={element.field_name} dangerouslySetInnerHTML={{ __html: element.label }}></label>
        <input required={element.required} type="text" className="form-control" id={element.field_name} name={element.field_name} list={`${element.field_name}-list`} defaultValue={initialValue} />
        <datalist id={`${element.field_name}-list`}>
          {element.options.map((option) => (
            <option key={option.key} value={option.value}>
              {option.text}
            </option>
          ))}
        </datalist>
      </div>
    );
  };
  
  static renderRadioButtons = (element, initialValue = '') => {
    const elementId = `radiobuttons_${element.field_name}`;
  
    return (
      <>
      <label dangerouslySetInnerHTML={{ __html: element.label }}></label>
      <br/>
      <div className={element.inline ? 'form-check form-check-inline form-group' : 'form-group'}>
        {element.options.map((option) => {
          const id = `${elementId}_${option.key}`;
          const checked = option.value === initialValue;
  
          return (
            <div className="form-check" key={option.key}>
              <input
                className="form-check-input"
                type="radio"
                name={element.field_name}
                id={id}
                value={option.value}
                defaultChecked={checked}
                required={element.required}
              />
              <label className="form-check-label" htmlFor={id}>
                {option.text}
              </label>
            </div>
          );
        })}
      </div>
      </>
    );
  };
  
  static renderEmailInput = (element, initialValue) => {
    return (
      <div className="form-group">
        <label htmlFor={element.field_name} dangerouslySetInnerHTML={{ __html: element.label }}></label>
        <input
          type="email"
          className="form-control"
          id={element.field_name}
          name={element.field_name}
          defaultValue={initialValue}
          required={element.required}
        />
      </div>
    );
  };
  
  static renderNumberInput = (element, initialValue) => {
    const [value, setValue] = useState(initialValue.toLocaleString());

    const handleInputChange = (event) => {
      const rawValue = event.target.value.replace(/\./g, '');
      const numericValue = rawValue.replace(/[^0-9]/g, ''); // Remove non-numeric characters
      const formattedValue = numericValue !== '' ? numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '';
      setValue(formattedValue);
    };

    return (
      <div className="form-group">
        <label htmlFor={element.field_name} dangerouslySetInnerHTML={{ __html: element.label }}></label>
        <input
          type="text"
          className="form-control"
          id={element.field_name}
          name={element.field_name}
          value={value}
          onChange={handleInputChange}
          required={element.required}
        />
      </div>
    );
  };

  static renderPhoneNumber = (element, initialValue) => {
    return (
      <div className="form-group">
        <label htmlFor={element.field_name} dangerouslySetInnerHTML={{ __html: element.label }}></label>
        <input type="tel" className="form-control" id={element.field_name} name={element.field_name} defaultValue={initialValue} required={element.required} />
      </div>
    );
  };  

  static renderTextArea = (element, initialValue) => {
    return (
      <div className="form-group">
         <label htmlFor={element.field_name} dangerouslySetInnerHTML={{ __html: element.label }}></label>
        <textarea className="form-control" id={element.field_name} name={element.field_name} defaultValue={initialValue} required={element.required}/>
      </div>
    );
  };  

  static renderImage = (element, fileName, fileLoc, dataValueParsed, onReupload, onFileUpload) => {
    const handleFileChange = (event) => {
      onFileUpload(event);
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          document.getElementById(`${element.field_name}-preview`).src = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    };
  
    const imageStyle =
      element.width && element.width !== '0' && element.height && element.height !== '0'
        ? { width: element.width+ 'px', height: element.height + 'px' }
        : {};
    return (
      <div className="form-group">
        <input
          type="file"
          accept="image/*"
          className="form-control"
          id={element.field_name}
          name={element.field_name}
          onChange={handleFileChange}
          style={{ display: 'none' }}
          required={element.required}
        />
        <label htmlFor={element.field_name}>
          <img id={`${element.field_name}-preview`} alt="Preview" src="/no_image.JPG" style={imageStyle} />
        </label>
        <input
          type="hidden"
          name="elementNames"
          value={element.field_name}
        />
      </div>
    );
  };

  static renderRating = (element, initialValue) => {
    const handleStarClick = (event) => {
      const rating = event.target.dataset.rating;
      const input = document.getElementById(`${element.field_name}-value`);
      if (input) {
        input.value = rating;
      }
      const stars = document.querySelectorAll(`.${element.field_name}-star`);
      stars.forEach((star) => {
        if (star.dataset.rating <= rating) {
          star.style.color = 'gold';
        } else {
          star.style.color = 'lightgray';
        }
      });
    };
  
    return (
      <div className="form-group">
        <label htmlFor={element.field_name} dangerouslySetInnerHTML={{ __html: element.label }}></label>
        <div>
          {[1, 2, 3, 4, 5].map((rating) => (
            <span
              key={rating}
              className={`${element.field_name}-star`}
              data-rating={rating}
              onClick={handleStarClick}
              style={{ fontSize: '24px', color: rating <= initialValue ? 'gold' : 'lightgray', cursor: 'pointer' }}
            >
              ★
            </span>
          ))}
        </div>
        <input type="hidden" required={element.required} id={`${element.field_name}-value`} name={element.field_name} value={initialValue} />
      </div>
    );
  };

  static renderDatePicker = (element, initialValue) => {
    return (
      <>
      <style>
        {`
          .my-input::before {
            content: none !important;
          }
        `}
      </style>
      <div className="form-group">
      <label htmlFor={element.field_name} dangerouslySetInnerHTML={{ __html: element.label }}></label>
        <input required={element.required} type="date" className="form-control my-input" id={element.field_name} name={element.field_name} defaultValue={initialValue} />
      </div>
      </>
    );
  };

  static Signature = ({ label, name, width, height, initialValue }) => {
    const [isDrawing, setIsDrawing] = useState(false);
    const [lastX, setLastX] = useState(0);
    const [lastY, setLastY] = useState(0);
    const canvasRef = useRef(null);
    const inputRef = useRef(null);
    const clearRef = useRef(null); // Add clearRef
    
    const handleMouseDown = (e) => {
      setIsDrawing(true);
      setLastX(e.nativeEvent.offsetX);
      setLastY(e.nativeEvent.offsetY);
    };
  
    const handleMouseMove = (e) => {
      if (!isDrawing) return;
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      ctx.beginPath();
      ctx.moveTo(lastX, lastY);
      ctx.lineTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
      ctx.stroke();
      setLastX(e.nativeEvent.offsetX);
      setLastY(e.nativeEvent.offsetY);
    };
  
    const handleMouseUp = () => {
      setIsDrawing(false);
      const canvas = canvasRef.current;
      const input = inputRef.current;
      input.value = canvas.toDataURL();
    };
  
    const handleMouseOut = () => {
      setIsDrawing(false);
    };
  
    const handleClear = (event) => { // Define the clear button handler
      event.preventDefault();
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      const input = inputRef.current;
      input.value = ""; // Set the input value to empty string
    };
  
    useEffect(() => { // Set the initial value when component mounts
      const canvas = canvasRef.current;
      const input = inputRef.current;
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.onload = () => {
        ctx.drawImage(img, 0, 0);
        input.value = canvas.toDataURL();
      };
      img.src = initialValue;
    }, [initialValue]);
  
    return (
      <div className="form-group">
        <canvas
          ref={canvasRef}
          width={width}
          height={height}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseOut={handleMouseOut}
          style={{ border: "1px solid black" }}
        ></canvas>
        <br />
        <label dangerouslySetInnerHTML={{ __html: label }}>
        </label> <button className="btn btn-secondary btn-sm" onClick={handleClear} ref={clearRef}>Clear</button> {/*Add the clear button*/}
        <input ref={inputRef} type="hidden" name={name} />
      </div>
    );
  };
  


  static renderHyperLink = (element) => {
    return (
      <div className="form-group">
        <a href={element.href} dangerouslySetInnerHTML={{ __html: element.content }}></a>
      </div>
    );
  };


  static renderDownload = (element, fileName, fileLoc,dataValueParsed, onReupload, onFileUpload) => {
    const [fileNames, setFileNames] = useState({});
    const handleFileUploadLocal = (event) => {
      if (event.target.files.length === 0) {
        // File selection canceled, preserve previous file
        return;
      } else {
        //console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaddadadasda");
        onFileUpload(event);// this goes to parent
        setFileNames((prevFileNames) => ({
          ...prevFileNames,
          [event.target.name]: event.target.files[0].name,
        }));
      }
    }

    return (
      <div className="form-group">
        <label dangerouslySetInnerHTML={{ __html: element.label }}></label>
        <br/>
        {fileNames[element.field_name] && (
          <p>Selected file: {fileNames[element.field_name]}</p>
        )}
        <label className="btn btn-secondary btn-sm">
        Select File
        <input
          type="file"
          name={element.field_name}
          onChange={handleFileUploadLocal}
          accept="image/*,.txt,.pdf"
          required={element.required}
          style={{ width: '1px', height: '1px', opacity: 0, position: 'absolute', overflow: 'hidden' }}
        />
        <input
          type="hidden"
          name="elementNames"
          value={element.field_name}
        />
      </label>
      
      </div>
    );      
  };

  static renderRange = (element, initialValue) => {
    let defaultValue = element.default_value;
    if (initialValue || initialValue === 0 || initialValue === '') {
      defaultValue = initialValue;
    }
    return (
      <div className="form-group">
        <label dangerouslySetInnerHTML={{ __html: element.label }}></label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: '8px' }}>{element.min_label}</span>
          <input
            type="range"
            name={element.field_name}
            min={element.min_value}
            max={element.max_value}
            step={element.step}
            defaultValue={defaultValue}
            style={{ flex: 1 }}
            required={element.required}
          />
          <span style={{ marginLeft: '8px' }}>{element.max_label}</span>
        </div>
      </div>
    );
  };
  
}

export default Builder;
