import React from 'react';
import { Timer } from 'iconoir-react';

function TimeStatus({
  createdAt = '-',
  acceptedAt = '-',
  doneAt = '-',
  closedAt = '-',
}) {
  const formatDate = (dateString) => {
    if (dateString && dateString !== '-') {
      const date = new Date(dateString);
      const hours = `0${date.getHours()}`.slice(-2);
      const minutes = `0${date.getMinutes()}`.slice(-2);
      const day = `0${date.getDate()}`.slice(-2);
      const month = `0${date.getMonth() + 1}`.slice(-2);
      const year = date.getFullYear().toString().slice(-2);

      return `${hours}:${minutes} ${day}/${month}/${year}`;
    } else {
      return '-';
    }
  };

  return (
    <div
      style={{
        marginTop: '2.5vw',
        overflowX: 'visible',
        backgroundColor: '#F5F5F6',
        marginRight: '-2vw',
        marginLeft: '-2vw',
        padding: '0.8vw 0vw 0.8vw 0vw',
      }}
    >
      <div
        className='row'
        style={{
          marginRight: '2vw',
          marginLeft: '2vw',
          verticalAlign: 'Center',
        }}
      >
        <div className='col-md-3 ticketTimeTexts'>
          <Timer style={{ height: '1.1vw', marginTop: '-0.3vw' }} /> Created:{' '}
          {formatDate(createdAt)}
        </div>
        <div className='col-md-3 ticketTimeTexts'>
          <Timer style={{ height: '1.1vw', marginTop: '-0.3vw' }} /> Accepted:{' '}
          {formatDate(acceptedAt)}
        </div>
        <div className='col-md-3 ticketTimeTexts'>
          <Timer style={{ height: '1.1vw', marginTop: '-0.3vw' }} /> Resolved:{' '}
          {formatDate(doneAt)}
        </div>
        <div className='col-md-3 ticketTimeTexts'>
          <Timer style={{ height: '1.1vw', marginTop: '-0.3vw' }} /> Closed:{' '}
          {formatDate(closedAt)}
        </div>
      </div>
    </div>
  );
}

export default TimeStatus;
