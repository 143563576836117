import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import useOrientation from '../../general/useOrientation';
const cookies = new Cookies();

function DocTabbing({selectedTab, handleTabClick}) {
  var sessionData = cookies.get('sessionData', { path: '/' })
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait') { isThisPortrait = true;}
  if(sessionData.int_ext == "1" && sessionData.apps_roles_id < 4 ){
    if (orientation === 'landscape')
    return (
      <div className='row' style={{ position:'relative', zIndex:'2',borderBottom: '1px solid #E8E9EB', width: '98%', marginTop: '3vw'}}>
        <div className={`tabbing ${selectedTab === 'Documents To Me' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Documents To Me')}>
          Document To Me
        </div>
        <div className={`tabbing ${selectedTab === 'Documents Library' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Documents Library')}>
          Document Library
        </div>
      </div>
    )
    else 
    return (
      <div className='row' style={{ position:'relative', zIndex:'2',borderBottom: '1px solid #E8E9EB', width: '98%', marginTop: '1.5vh',  marginBottom: '2vh', marginLeft:'2vw'}}>
        <div className={`tabbingMobile ${selectedTab === 'Documents To Me' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Documents To Me')}>
          Document To Me
        </div>
        <div className={`tabbingMobile ${selectedTab === 'Documents Library' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Documents Library')}>
          Document Library
        </div>
      </div>
    )
  } else if (sessionData.int_ext == "1" && sessionData.apps_roles_id == 4 ){
    if (orientation === 'landscape')
    return (
      <div className='row' style={{ position:'relative', zIndex:'2',borderBottom: '1px solid #E8E9EB', width: '98%', marginTop: '3vw'}}>
        <div className={`tabbing ${selectedTab === 'Documents To Me' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Documents To Me')}>
          Document To Me
        </div>
      </div>
    )
    else
    return (
      <div className='row' style={{ position:'relative', zIndex:'2',borderBottom: '1px solid #E8E9EB', width: '98%', marginTop: '1.5vh',  marginBottom: '2vh', marginLeft:'2vw'}}>
        <div className={`tabbingMobile ${selectedTab === 'Documents To Me' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Documents To Me')}>
          Document To Me
        </div>
      </div>
    )
  } else {
    if (orientation === 'landscape')
    return (
      <div className='row' style={{ position:'relative', zIndex:'2',borderBottom: '1px solid #E8E9EB', width: '98%', marginTop: '3vw'}}>
        <div className={`tabbing ${selectedTab === 'Documents By Me' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Documents By Me')}>
          Document By Me
        </div>
        <div className={`tabbing ${selectedTab === 'Documents Library' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Documents Library')}>
          Document Library
        </div>
      </div>
    )
    else
    return (
      <div className='row' style={{ position:'relative', zIndex:'2',borderBottom: '1px solid #E8E9EB', width: '98%', marginTop: '1.5vh', marginBottom: '2vh', marginLeft:'2vw'}}>
        <div className={`tabbingMobile ${selectedTab === 'Documents By Me' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Documents By Me')}>
          Document By Me
        </div>
        <div className={`tabbingMobile ${selectedTab === 'Documents Library' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Documents Library')}>
          Document Library
        </div>
      </div>
    )
  }
  
}

export default DocTabbing;