import Menubar from './Menubar';
import Header from './Header';
import { Outlet } from "react-router-dom";
import Footer from './Footer';

export default function Navbar({navState,changeNavState}) {


  //console.log("NAVBAR start logging")
  return (
    <>
    <Header />
    <Menubar navState={navState} changeNavState={changeNavState}/>
    <div className="content-wrapper contentWrapperBG" >
    <Outlet />
    </div>
    <Footer />
    </>
  )
}