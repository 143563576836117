import React, { useState, useEffect } from 'react';
import HeaderPage from '../headerPage/HeaderPage';
import {useNavigate, useParams} from "react-router-dom"
import FilterSearch from '../filter/FilterSearch';
import ReportTableParent from './ReportTableParent';
import TabbingReport from './TabbingReport';
import Cookies from 'universal-cookie'; 
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
import axios from 'axios' 
import useOrientation from '../../general/useOrientation';

const cookies = new Cookies();
const MySwal = withReactContent(Swal)
function Reports({sum, changeNavState}) {
  const navigate = useNavigate();
  const orientation = useOrientation();
  const { byTo,id,name } = useParams();
  const [imagePopup, setImagePopup] = useState("");
  const [filterData, setFilterData] = useState("");
  const [isExpanded, setIsExpanded] = useState(0);
  const [linkData, setLinkData] = useState(sum == true ? 'getReportSummary':'getReport');
  const [isLoading, setIsLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [selectedTab, setSelectedTab] = useState(sum == true ? 'Summary Report':'Monthly Report');
  const buttonText = "Download Report"

  useEffect(() => {
    changeNavState('Report');
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRefresh(!refresh);
    }, 60000); // 60 seconds in milliseconds
  
    // Clear the initial timeout to prevent the initial refresh
    const initialTimeoutId = setTimeout(() => {
      clearInterval(intervalId);
    }, 60000);
  
    // Cleanup function to clear the interval and initial timeout when the component unmounts
    return () => {
      clearInterval(intervalId);
      clearTimeout(initialTimeoutId);
    };
  }, []); // Empty dependency array for the initial setup
  
    
  const dateFormatter = (data) => {
    var datee = new Date(data);

    if (!data) {
      return '';
    }

    // Check if the date is valid
    if (isNaN(datee.getTime())) {
      // If not valid, return an empty string
      return data;
    }
    const year = datee.getFullYear().toString().slice(-2); // Get the last 2 digits of the year
    const month = String(datee.getMonth() + 1).padStart(2, '0'); // Get the month (0-indexed) and pad with 0 if needed
    const day = String(datee.getDate()).padStart(2, '0'); // Get the day and pad with 0 if needed
    const hours = String(datee.getHours()).padStart(2, '0'); // Get the hours and pad with 0 if needed
    const minutes = String(datee.getMinutes()).padStart(2, '0'); // Get the minutes and pad with 0 if needed

    return <>{`${day}/${month}/${year} at ${hours}:${minutes}`}</>
  }

  function stripTags(desc) {
    // Remove content inside angle brackets
    const strippedContent = desc.replace(/<[^>]*>/g, '').trim();
  
    // Check if the stripped content is empty
    return strippedContent;
  }
        
  const titleDescFormatter = (data,row) => {
    var dataa = "";
    if (row.tiket_referral_id != null){
      dataa = "[REF] ";
    }
    dataa += data ;

    dataa = stripTags (dataa);

    if (dataa.length > 35) {
      dataa = dataa.substring(0, 35) + " ...";
    }
    return dataa
  }
  let colHide = [
    {
      text: 'No ticket',
      hidden: false,
      no: "0",
    },
    {
      text: 'No Ticket Forward',
      hidden:false,
      no:"1",
    },
    {
      text: 'Nama Creator Tiket',
      hidden:false,
      no:"2",
    },
    {
      text: 'Nama Forwarder Ticket',
      hidden:false,
      no:"3",
    },
    {
      text: 'Tipe Tiket',
      hidden:false,
      no:"4",
    },
    {
      text: 'Nama Department / Tenant',
      hidden:false,
      no:"5",
    },
    {
      text: 'Subyek',
      hidden:false,
      no:"6",
    },
    {
      text: 'Detail Permasalahan',
      hidden:false,
      no:"7",
    },
    {
      text: 'Detail Permasalahan Forward',
      hidden:false,
      no:"8",
    },
    {
      text: 'Nama Kategori',
      hidden:false,
      no:"9",
    },
    {
      text: 'Nama Kategori Forward',
      hidden:false,
      no:"10",
    },
    {
      text: 'PIC Accept',
      hidden:false,
      no:"11",
    },
    {
      text: 'Tanggal Create Ticket',
      hidden:false,
      no:"12",
    },
    {
      text: 'Tanggal Accept',
      hidden:false,
      no:"13",
    },
    {
      text: 'Tanggal Forward',
      hidden:false,
      no:"14",
    },
    {
      text: 'Tanggal Done',
      hidden:false,
      no:"15",
    },
    {
      text: 'Tanggal Resolve',
      hidden:false,
      no:"16",
    },
    {
      text: 'Tanggal Close',
      hidden:false,
      no:"17",
    },
    {
      text: 'Keterangan (Status)',
      hidden:false,
      no:"18",
    },
    {
      text: 'Waktu SLA',
      hidden:false,
      no:"19",
    },
    {
      text: 'Extend',
      hidden:false,
      no:"20",
    },
    {
      text: 'Waktu Response (Accept Tiket)',
      hidden:false,
      no:"21",
    },
    {
      text: 'Waktu Penyelesaian Tiket (Resolve Tiket)',
      hidden:false,
      no:"22",
    },
    {
      text: 'Waktu Response Forward (Accept Forward)',
      hidden:false,
      no:"23",
    },
    {
      text: 'Waktu Penyelesaian Tiket Forward (Done Tiket)',
      hidden:true,
      no: "24" ,
    },
    {
      text: 'Rating',
      hidden :false,
      no:"25" ,
    },

  ]
  const columns = [
    {
      text: 'No',
      dataField: 'index',
      text: '#',
      formatter: (cell, row, rowIndex, columnIndex) => {
        return rowIndex + 1; // Adjust to 0-based or 1-based numbering as needed
      },
      headerStyle: { whiteSpace: 'nowrap' },
      style: { whiteSpace: 'nowrap' },
      sort: true 
    },
    {
      text: 'No ticket',
      dataField: 'No Ticket',
      sort: true,
      hidden: colHide[0].hidden,
      headerStyle: { whiteSpace: 'nowrap' },
      style: { whiteSpace: 'nowrap' }
    },
    {
      text: 'No Ticket Forward',
      dataField: 'No Ticket Forward',
      sort:true,
      hidden: colHide[1].hidden,
      headerStyle: { whiteSpace: 'nowrap' },
      style: { whiteSpace: 'nowrap' }
    },
    {
      text: 'Nama Creator Tiket',
      dataField: 'Nama Creator Tiket',
      sort:true,
      hidden: colHide[2].hidden,
      headerStyle: { whiteSpace: 'nowrap' },
      style: { whiteSpace: 'nowrap' }
    },
    {
      text: 'Nama Forwarder Ticket',
      dataField: 'Nama Forwarder Ticket',
      sort:true,
      hidden: colHide[3].hidden,
      headerStyle: { whiteSpace: 'nowrap' },
      style: { whiteSpace: 'nowrap' }
    },
    {
      text: 'Tipe Tiket',
      dataField: 'Tipe Tiket',
      sort:true,
      hidden: colHide[4].hidden,
      headerStyle: { whiteSpace: 'nowrap' },
      style: { whiteSpace: 'nowrap' }
    },
    {
      text: 'Nama Department / Tenant',
      dataField: 'Nama Department / Tenant',
      sort:true,
      hidden: colHide[5].hidden ,
      headerStyle: { whiteSpace: 'nowrap' },
      style: { whiteSpace: 'nowrap' }
    },
    {
      text: 'Subyek',
      dataField: 'Subyek',
      sort:true,
      formatter:titleDescFormatter,
      hidden: colHide[6].hidden ,
      headerStyle: { whiteSpace: 'nowrap' },
      style: { whiteSpace: 'nowrap' }
    },
    {
      text: 'Detail Permasalahan',
      dataField: 'Detail Permasalahan',
      sort:true,
      formatter:titleDescFormatter,
      hidden: colHide[7].hidden ,
      headerStyle: { whiteSpace: 'nowrap' },
      style: { whiteSpace: 'nowrap' }
    },
    {
      text: 'Detail Permasalahan Forward',
      dataField: 'Detail Permasalahan Forward',
      sort:true,
      formatter:titleDescFormatter,
      hidden: colHide[8].hidden ,
      headerStyle: { whiteSpace: 'nowrap' },
      style: { whiteSpace: 'nowrap' }
    },
    {
      text: 'Nama Kategori',
      dataField: 'Nama Kategori',
      sort:true,
      hidden: colHide[9].hidden ,
      headerStyle: { whiteSpace: 'nowrap' },
      style: { whiteSpace: 'nowrap' }
    },
    {
      text: 'Nama Kategori Forward',
      dataField: 'Nama Kategori Forward',
      sort:true,
      hidden: colHide[10].hidden ,
      headerStyle: { whiteSpace: 'nowrap' },
      style: { whiteSpace: 'nowrap' }
    },
    {
      text: 'PIC Accept',
      dataField: 'PIC Accept',
      sort:true,
      hidden: colHide[11].hidden ,
      headerStyle: { whiteSpace: 'nowrap' },
      style: { whiteSpace: 'nowrap' }
    },
    {
      text: 'Tanggal Create Ticket',
      dataField: 'Tanggal Create Ticket',
      sort:true,
      formatter:dateFormatter,
      hidden: colHide[12].hidden ,
      headerStyle: { whiteSpace: 'nowrap' },
      style: { whiteSpace: 'nowrap' }
    },
    {
      text: 'Tanggal Accept',
      dataField: 'Tanggal Accept',
      sort:true,
      formatter:dateFormatter,
      hidden: colHide[12].hidden ,
      headerStyle: { whiteSpace: 'nowrap' },
      style: { whiteSpace: 'nowrap' }
    },
    {
      text: 'Tanggal Forward',
      dataField: 'Tanggal Forward',
      sort:true,
      formatter:dateFormatter,
      hidden: colHide[13].hidden ,
      headerStyle: { whiteSpace: 'nowrap' },
      style: { whiteSpace: 'nowrap' }
    },
    {
      text: 'Tanggal Done',
      dataField: 'Tanggal Done',
      sort:true,
      formatter:dateFormatter,
      hidden: colHide[14].hidden ,
      headerStyle: { whiteSpace: 'nowrap' },
      style: { whiteSpace: 'nowrap' }
    },
    {
      text: 'Tanggal Resolve',
      dataField: 'Tanggal Resolve',
      sort:true,
      formatter:dateFormatter,
      hidden: colHide[15].hidden ,
      headerStyle: { whiteSpace: 'nowrap' },
      style: { whiteSpace: 'nowrap' }
    },
    {
      text: 'Tanggal Close',
      dataField: 'Tanggal Close',
      sort:true,
      formatter:dateFormatter,
      hidden: colHide[16].hidden ,
      headerStyle: { whiteSpace: 'nowrap' },
      style: { whiteSpace: 'nowrap' }
    },
    {
      text: 'Keterangan (Status)',
      dataField: 'Keterangan (Status)',
      sort:true,
      hidden: colHide[17].hidden ,
      headerStyle: { whiteSpace: 'nowrap' },
      style: { whiteSpace: 'nowrap' }
    },
    {
      text: 'Waktu SLA',
      dataField: 'Waktu SLA',
      sort:true,
      formatter:dateFormatter,
      hidden: colHide[18].hidden ,
      headerStyle: { whiteSpace: 'nowrap' },
      style: { whiteSpace: 'nowrap' }
    },
    {
      text: 'Extend',
      dataField: 'Extend',
      sort:true,
      formatter:dateFormatter,
      hidden: colHide[19].hidden ,
      headerStyle: { whiteSpace: 'nowrap' },
      style: { whiteSpace: 'nowrap' }
    },
    {
      text: 'Waktu Response (Accept Tiket)',
      dataField: 'WaktuResponseCalc',
      sort:true,
      hidden: colHide[20].hidden ,
      headerStyle: { whiteSpace: 'nowrap' },
      style: { whiteSpace: 'nowrap' }
    },
    {
      text: 'Waktu Penyelesaian Tiket (Resolve Tiket)',
      dataField: 'WaktuPenyelesaianTiketCalc',
      sort:true,
      hidden: colHide[21].hidden ,
      headerStyle: { whiteSpace: 'nowrap' },
      style: { whiteSpace: 'nowrap' }
    },
    {
      text: 'Waktu Response Forward (Accept Forward)',
      dataField: 'WaktuResponseForwardCalc',
      sort:true,
      hidden: colHide[22].hidden ,
      headerStyle: { whiteSpace: 'nowrap' },
      style: { whiteSpace: 'nowrap' }
    },
    {
      text: 'Waktu Penyelesaian Tiket Forward (Done Tiket)',
      dataField: 'WaktuPenyelesaianTiketForwardCalc',
      sort:true,
      hidden: colHide[23].hidden ,
      headerStyle: { whiteSpace: 'nowrap' },
      style: { whiteSpace: 'nowrap' }
    },
    {
      text: 'Rating',
      dataField: 'Rating',
      sort:true,
      hidden: colHide[24].hidden ,
      headerStyle: { whiteSpace: 'nowrap' },
      style: { whiteSpace: 'nowrap' }
    },
  ];

  const columnsSummary = [
    {
      text: 'No',
      dataField: 'index',
      text: '#',
      formatter: (cell, row, rowIndex, columnIndex) => {
        return rowIndex + 1; // Adjust to 0-based or 1-based numbering as needed
      },
      headerStyle: { whiteSpace: 'nowrap' },
      style: { whiteSpace: 'nowrap' },
      sort: true 
    },
    {
      text: 'Dept / Issue',
      dataField: 'dept_issue',
      sort: true,
      headerStyle: { whiteSpace: 'nowrap' },
      style: { whiteSpace: 'nowrap' }
    },
    {
      text: 'Kategori',
      dataField: 'kategori_name',
      sort:true,
      headerStyle: { whiteSpace: 'nowrap' },
      style: { whiteSpace: 'nowrap' }
    },
    {
      text: 'Tipe',
      dataField: 'int_ext',
      sort:true,
      headerStyle: { whiteSpace: 'nowrap' },
      style: { whiteSpace: 'nowrap' }
    },
    {
      text: 'Total',
      dataField: 'total',
      sort:true,
      headerStyle: { whiteSpace: 'nowrap' },
      style: { whiteSpace: 'nowrap' }
    },
  ];

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    if (tab == "Monthly Report"){
      setLinkData('getReport');
    } else if (tab == "Summary Report"){
      setLinkData('getReportSummary');
    } else if (tab == "Tenant Report"){
      setLinkData('getReportTenant');
    } 
  };

  const handleRefreshClick = () => {
    // just change the value to tell components to refresh
    setRefresh(!refresh); 
  };
  
  const updateFilterData = (val) => {
    console.log("update filter data value ", val)
    setFilterData(val); 
  };

  const buttonAction = (type) => {
    setIsLoading(true);
    let linkExport = "";
    switch(selectedTab){
      case "Monthly Report": linkExport = "exportExcel"; break;
      case "Summary Report": linkExport = "exportExcelSummary"; break;
      case "Tenant Report": linkExport = "exportExcel"; break;
    }

    if (linkExport === ""){
      MySwal.fire({
          title: <strong>Something Went Wrong!</strong>,
          html: <p>Export link is not valid</p>
      })
      return;
    }

    var sessionToken = cookies.get('sessionToken', { path: '/' })
    axios.interceptors.request.use(config => {config.headers['x-access-token'] = sessionToken;return config;},error => {return Promise.reject(error);});
    axios.post(`${process.env.REACT_APP_PATH_DESTINATION}/${linkExport}`, { data: filterData }) 
      .then(res => {
        console.log("export excel log",res.data)
        window.location.href = process.env.REACT_APP_PUBLIC_DESTINATION + res.data;
        setIsLoading(false);
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
            MySwal.fire({
            title: <strong>Error!</strong>,
            html: <p>{err}</p>
        })
      })
  }

  const handleImageClick = (val) => {
    if (isExpanded == 0){
      setIsExpanded(val);
    } else {
      setIsExpanded(0);
      setImagePopup("");
    }
  };

  if (orientation === 'landscape')
  return (
  <>
     {isLoading && (
        <div className='popup-overlay-transparent'>

        </div>
     )}

     <div className={isExpanded == 0 ? '' : 'popup-overlay'}  onClick={() => handleImageClick(0)}>

     </div>
     {imagePopup && (
       <img
       src={process.env.REACT_APP_IMG_DESTINATION + imagePopup}
       alt="Image Popup"
       className="expanded"
       style={{ height: "3vw", marginTop: '2.2vw', position: 'absolute' }}
     />
     )}
    <HeaderPage 
        title1={"Report"}
        link1={"/reports"}
        title2={
          linkData === "getReport" ? "Monthly" :
          linkData === "getReportSummary" ? "Summary" :
          "Tenant"
        }
        buttonText={buttonText}
        buttonAction={buttonAction}
        loadingState={isLoading}
    />

    <TabbingReport 
      selectedTab={selectedTab}
      handleTabClick={handleTabClick}
    />
     <div style={{position:'relative'}}>
      <div style={{zIndex:2 ,position: 'relative', width:'78%'}}>
        <FilterSearch init={id} name="reportDateTime" data={filterData} setData={updateFilterData} handleRefreshClick={handleRefreshClick} tiketOrDoc="tiket" bymeToMe={linkData}/>
      </div>
      <div style={{zIndex:1, position: 'relative', marginTop: '-6.2vw', marginLeft:'-1.2vw'}}>
        <ReportTableParent columnsSummary={columnsSummary} columns={columns} filterData={filterData} link={linkData} refresh={refresh} setLoading={setIsLoading}  imagePopup={imagePopup} setImagePopup={setImagePopup} isExpanded={isExpanded} setIsExpanded={setIsExpanded}/>
      </div>
     </div>
  </>
  )

  if (orientation === 'portrait')
  return (
  <>
     {isLoading && (
        <div className='popup-overlay-transparent'>

        </div>
     )}

     <div className={isExpanded == 0 ? '' : 'popup-overlay'}  onClick={() => handleImageClick(0)}>

     </div>
     {imagePopup && (
       <img
       src={process.env.REACT_APP_IMG_DESTINATION + imagePopup}
       alt="Image Popup"
       className="expanded"
       style={{ height: "3vw", marginTop: '2.2vw', position: 'absolute' }}
     />
     )}
    <HeaderPage 
        title1={"Report"}
        link1={"/reports"}
        title2={
          linkData === "getReport" ? "Monthly" :
          linkData === "getReportSummary" ? "Summary" :
          "Tenant"
        }
        buttonText={buttonText}
        buttonAction={buttonAction}
        loadingState={isLoading}
    />

    <TabbingReport 
      selectedTab={selectedTab}
      handleTabClick={handleTabClick}
    />
     <div style={{position:'relative'}}>
      <div style={{zIndex:2 ,position: 'relative', width:'78%'}}>
        <FilterSearch init={id} name="reportDateTime" data={filterData} setData={updateFilterData} handleRefreshClick={handleRefreshClick} tiketOrDoc="tiket" bymeToMe={linkData}/>
      </div>
      <div style={{zIndex:1, position: 'relative', marginTop: '-12vh', marginLeft:'-1.2vw'}}>
        <ReportTableParent columnsSummary={columnsSummary} columns={columns} filterData={filterData} link={linkData} refresh={refresh} setLoading={setIsLoading}  imagePopup={imagePopup} setImagePopup={setImagePopup} isExpanded={isExpanded} setIsExpanded={setIsExpanded}/>
      </div>
     </div>
  </>
  )
}

export default Reports