import React from 'react'; import { useState, useEffect} from 'react';
import ContentHeader from '../content/ContentHeader';
import axios from 'axios' 
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

function DashboardThree() {

  var data = cookies.get('sessionData', { path: '/' })
  var widthiFrame = "960px";
  var heightiFrame = "597.6px";
  const [dataa, setDataa] = useState([]);
  const [sumDrafting, setsumDrafting] = useState([0]);
  const [sumPending, setsumPending] = useState([0]);
  const [pbiLink, setpbiLink] = useState("");
  const [sumApproved, setSumApproved] = useState([0]);
  const [sumRejected, setSumRejected] = useState([0]);
  const [sumOnProgress, setsumOnProgress] = useState([0]);
  const [sumAll, setsumAll] = useState([0]);
  const navigate = useNavigate();
  useEffect(() => {
    getData();
  }, [])  

  const getData = () => {
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    const config = {headers:{['x-access-token']: sessionToken}};

    //checkPersonalDashboard
    var dbpath = process.env.REACT_APP_PATH_DESTINATION + "/checkPersonalDashboard";   
    axios.get(dbpath , config)
      .then(respd => { 
        //console.log("res.data.data pdb logging")
        //console.log(res.data.status + "200");
        if (respd.data.status == "300"){
          //personal dashboard not detected, redirecting
          navigate(`../dashboard`);
        } else {
          //personal dashboard detected, no need to redirect
          var path = process.env.REACT_APP_PATH_DESTINATION + "/overviewDoc/" + data.user_id;   
          axios.get(path , config).then(res => {
            
            setpbiLink(respd.data.data.pbi_link);
            setDataa(res.data.data);
            setsumPending(res.data.data.sum_pending);
            setsumOnProgress(res.data.data.sum_on_progress_s);
            setSumApproved(res.data.data.sum_approved_s);
            setSumRejected(res.data.data.sum_rejected_s);
            setsumAll(res.data.data.sum_rejected_p);
            console.log("respd.data.data");
            console.log(res.data.data);
          })
          .catch(err => {
            console.log(err);
          })
        }
      })
  }   

  const box =  {
    display: 'inline-block',
    padding: '5px',
    border: '0px solid black',
    borderRadius: '5px',
    textDecoration: 'none',
    color: 'black',
    margin:'8px',
    width:'18%',
    backgroundColor: 'white',
    color: '#118DFF',
    boxShadow: 'rgb(208,208,208) 3px 3px 0px 0px'
  }

  const bgBox = {
    backgroundColor: '#E6E6E6',
    width: widthiFrame
  }

  return (
    <>
      <ContentHeader title="Dashboard"/> 
      <section className="content">
  <div className="container-fluid">
    <div id="accordion" style={bgBox} >
      <a className="nav-link text-center" style={box} href="/dashboard/1/all/All">
        <b>Waiting Approval</b> <br/> 
        <span className="badge badge-danger">{sumPending}</span>
      </a>

      <a className="nav-link text-center" data-toggle=""style={box} href="/dashboard/2/all/All">
        <b>On Progress</b> <br/> 
        <span className="badge badge-danger">{sumOnProgress}</span>
      </a>

      <a className="nav-link text-center" style={box} href="/dashboard/3/all/All">
        <b>Approved</b> <br/> 
        <span className="badge badge-info ml-1">{sumApproved}</span>
      </a>
      
      <a className="nav-link text-center" style={box} href="/dashboard/4/all/All">
        <b>Rejected</b> <br/> 
        <span className="badge badge-info ml-1">{sumRejected}</span>
      </a>

      <a className="nav-link text-center" style={box} href="/dashboard/8/all/0">
        <b>View All</b>
        <br/> 
        <span className="badge badge-info ml-1">{sumAll}</span>
      </a>

      <br/>
      <iframe title="Report Section" width={widthiFrame} height={heightiFrame} src={pbiLink} frameborder="0" allowfullscreen="true"></iframe>
    </div>
  </div>
</section>
    </>
  );
}

export default DashboardThree;
