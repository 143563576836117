import React, { useEffect, useState } from 'react'
import HeaderPage from '../../headerPage/HeaderPage'
import { useParams } from 'react-router-dom';
import ConfigInputText from '../configHelper/ConfigInputText';
import Cookies from 'universal-cookie'; import axios from 'axios'
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
import useOrientation from '../../../general/useOrientation';

const cookies = new Cookies();
const MySwal = withReactContent(Swal)
function ConfigAncView({addEdit, changeNavState}) {
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait') { isThisPortrait = true;}
  const { id } = useParams();
  const [ancCategory, setAncCategory] = useState('');
  const getDataLink=`/ticketAncListView/${id}`
  const editLink=`/ticketAncEdit`
  const addLink=`/ticketAncAdd`

  useEffect(() => {
    if (addEdit == "edit") {
      getData();
    }
  }, [addEdit])

  useEffect(() => {
    changeNavState('Configurations');
  }, [])
  
const getData = () => {
    
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    axios.interceptors.request.use(config => {config.headers['x-access-token'] = sessionToken;return config;},error => {return Promise.reject(error);});
    axios.get(process.env.REACT_APP_PATH_DESTINATION + getDataLink) 
      .then(res => {
        console.log("getdata ancview",res.data.data);
        setAncCategory(res.data.data.kategori_name);
      })
      .catch(err => {
        console.log(err);
        MySwal.fire({
          title: <strong>Error!</strong>,
          html: <p>{err.response?.data?.message || "An error occurred. Please try relogging"}</p>
        })
      })
  }

const submitData = () => {
    //postData
    let data = {
      ancCategory : ancCategory,
      id : id
    }

    var sessionToken = cookies.get('sessionToken', { path: '/' })
    let apiLink = "";
    if (addEdit === "edit"){
      apiLink = editLink
    } else if (addEdit === "add"){
      apiLink = addLink
    }
    axios.interceptors.request.use(config => {config.headers['x-access-token'] = sessionToken;return config;},error => {return Promise.reject(error);});
    axios.post(process.env.REACT_APP_PATH_DESTINATION + apiLink,data) 
      .then(res => {
        console.log(res.data.data);
        MySwal.fire({
          title: <strong>Success</strong>,
          html: <p>Data Edited</p>
        })
      })
      .catch(err => {
        console.log(err);
            MySwal.fire({
            title: <strong>Error!</strong>,
            html: <p>{err.response?.data?.message || "An error occurred. Please try relogging"}</p>
        })
      })
  }

  return (
  <>
    <HeaderPage 
        title1={"Configurations"}
        link1={"/configuration"}
        title2={"Configurations Announcement"}
    />
    <div style={{marginTop:'4vw',  marginLeft:isThisPortrait?'5vw':'', marginRight:'1vw'}} className='row'>
        <div className='form-group'>
        <ConfigInputText name={"Announcement Category"} data={ancCategory} setData={setAncCategory}/>
        <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>   
        <button type='submit' onClick={submitData} className='btn buttonBlue' style={{marginTop:'1vh'}}>
        Submit
        </button>
        </div>
    </div>    
  </> 
  )
}

export default ConfigAncView