import React from 'react';
import 'react-form-builder2/dist/app.css';
import TableGen from './TableGen';
import BuilderRO from './BuilderRO';

function FormToHtmlReadOnly({ formData, dataValue, onFileUpload, onReupload }  ) {
  //console.log("dataValue logging")
  //console.log(dataValue);  
  const formDataParsed = JSON.parse(formData);
  //console.log("datavalue logging");console.log(dataValue);
  const dataValueParsed = dataValue && dataValue.length > 0 ? JSON.parse(dataValue) : [];

  const formElements = formDataParsed.map((element) => (
    <div key={element.id}>
      {(() => {
        switch (element.element) {
          case 'Header':
            return BuilderRO.renderHeader(element)
          case 'TextInput':
            return BuilderRO.renderTextInput(element, dataValueParsed[element.field_name] || '');
          case 'Checkboxes':
            return BuilderRO.renderCheckboxes(element, dataValueParsed);
          case 'Label':
            return BuilderRO.renderLabel(element);
          case 'Paragraph':
            return BuilderRO.renderParagraph(element);
          case 'LineBreak':
            return BuilderRO.renderLineBreak();
          case 'Dropdown':
            return BuilderRO.renderDropdown(element, dataValueParsed[element.field_name] || '');
          case 'Tags':
            return BuilderRO.renderTags(element, dataValueParsed[element.field_name] || '');
          case 'RadioButtons':
            return BuilderRO.renderRadioButtons(element, dataValueParsed[element.field_name] || '');
          case 'EmailInput':
            return BuilderRO.renderEmailInput(element, dataValueParsed[element.field_name] || '');
          case 'NumberInput':
            return BuilderRO.renderNumberInput(element, dataValueParsed[element.field_name] || '');
          case 'PhoneNumber':
            return BuilderRO.renderPhoneNumber(element, dataValueParsed[element.field_name] || '');
          case 'TextArea':
            return BuilderRO.renderTextArea(element, dataValueParsed[element.field_name] || '');
          case 'Image':
          case 'Camera':
            return BuilderRO.renderImage(element, dataValueParsed[element.field_name] || '', dataValueParsed['loc_'+element.field_name] || '', dataValueParsed);
          case 'Rating':
            return BuilderRO.renderRating(element, dataValueParsed[element.field_name] || '');
          case 'DatePicker':
            return BuilderRO.renderDatePicker(element, dataValueParsed[element.field_name] || '');
          case 'Signature':
            return <BuilderRO.Signature label={element.label} name={element.id} width={400} heigth={200} initialValue={dataValueParsed[element.id]}/>;
          case 'HyperLink':
            return BuilderRO.renderHyperLink(element);
          case 'Download':
          case 'FileUpload':
            if (typeof dataValueParsed[element.field_name] !== "string") 
              dataValueParsed[element.field_name] = ""; // Set it to an empty string
            return BuilderRO.renderDownload(element, dataValueParsed[element.field_name] || '', dataValueParsed['loc_'+element.field_name] || '', dataValueParsed || '');
          case 'Range':
            return BuilderRO.renderRange(element, dataValueParsed[element.field_name] || '');
          case 'TwoColumnRow':
          case 'ThreeColumnRow' :
          case 'MultiColumnRow' :
            return <TableGen element={element} defaultValue={dataValueParsed[element.id]} />
          default:
            return null;
        }
      })()}
    </div>
  ));
  return (<>{formElements}</>);
  
  
}

export default FormToHtmlReadOnly;