import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Cookies from 'universal-cookie';
import {OpenNewWindow} from 'iconoir-react'
import { useNavigate } from 'react-router-dom';
const cookies = new Cookies();

const ReferralShow = ({title,value,label,isMulti=false}) => {
  const navigate = useNavigate();
  const [options, setOptions] = useState();
  useEffect(() => {
    setOptions([{
      value: value,
      label: label,
    },]);
  }, [value])

  const goToDetail = () => {
    const url = "/ticketDetail/" + value;
    window.open(url, '_blank');
    //navigate("/ticketDetail/" + value)
  }

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: '8px',
      padding: '-2vw -5vw -2vw -5vw',
      fontSize: '1vw',
      width: '95%',
      height:"1.75vw",
      border: '0px',
      backgroundColor: '#F5F5F6',
      color: '#FFFFFF',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#0296D2' : '#fff', // Change the color here
      color: state.isSelected ? '#fff' : '#404041',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#404041',
      fontFamily : 'Blender Pro',
      fontSize:'1vw'
    }),
    menu: (provided) => ({
      ...provided,
      width: '95%',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#D6EBF6', 
      borderRadius: '8px'
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#01648C', 
      fontFamily: 'Blender Pro',
      fontSize:'1vw'
    }),
  };
  const DropdownIndicator = () => null;
  return (
    <>
    <div style={{marginTop: '1vw'}}>
        <p style={{fontFamily: 'Blender Pro'}}>{title}</p>
    </div>
    <div style={{marginTop:'0.5vw', position: 'relative' }} >
      <Select
        isMulti={isMulti}
        id="dropdown"
        value={options}
        options={options}
        placeholder="Select"
        styles={customStyles}
        isDisabled={true}
        components={{
          DropdownIndicator,
        }}
      />
      <OpenNewWindow
        className='cursorPointer'
        onClick={()=>goToDetail()}
        style={{
            position: 'absolute',
            marginTop: '-1.5vw',
            right: 0,
            marginRight:"2vw",
            transform: 'translateY(-50%)',
            width:"1.5vw",
            color:"#0296D2"
        }}
       />
       </div>
       </>
  );
};

export default ReferralShow;
