import React, { useEffect, useState } from 'react';

function SwitchOnOff({ isSwitchOn, click }) {
  const [imageSrc, setImageSrc] = useState('');

  useEffect(() => {
    // Use the isSwitchOn prop to determine the image source
    const newImageSrc = isSwitchOn ? '/SwitchOn.png' : '/SwitchOff.png';
    setImageSrc(newImageSrc);
  }, [isSwitchOn]);

  return (
    <img
      src={imageSrc}
      style={{ height: '20px' }}
      onClick={click}
    />
  );
}

export default SwitchOnOff;
