import React from 'react'

function HeaderApp({imageSrc, title,desc,link}) {
    const redirect = () => {
        window.open(link, '_blank');
    };
  return (
    <div onClick={redirect} className="outerTenantDiv" style={{height:'auto', paddingTop:'1vw', paddingBottom:'1vw',borderBottom:'1px solid #E8E9EB', marginTop:'0px'}}>
        <div>
            <img src={imageSrc} style={{height:'32px', marginLeft:'20px'}}/>
        </div>
        <div >
            <p style={{marginTop:'-30px',marginLeft:'65px',marginRight:'2vw', fontSize:'1.2vw', fontFamily:'Blender Pro', fontWeight:'500'}}><b>{title}</b></p>
            <p  style={{marginTop:'-30px',marginLeft:'65px',marginRight:'2vw', marginTop:'-1vw',fontSize:'1.1vw', fontFamily:'Blender Pro', color:'#404041'}}> {desc} </p>
        </div>
    </div>
  )
}

export default HeaderApp