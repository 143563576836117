import React, { useEffect, useState } from 'react'
import HeaderPage from '../../headerPage/HeaderPage';
import Cookies from 'universal-cookie'; import axios from 'axios'
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
import { useParams } from 'react-router-dom';
import useOrientation from '../../../general/useOrientation';

const cookies = new Cookies();
const MySwal = withReactContent(Swal)
function ConfigViewParent({addEdit, changeNavState}) {
  const [isLoading, setIsLoading] = useState(false);
  const { intExtDoc,id } = useParams();
  const [inputValue, setInputValue] = useState('');
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait') { isThisPortrait = true;}
  useEffect(() => {
    if (addEdit == "edit") {
      getData();
    }
  }, [addEdit])

  useEffect(() => {
    changeNavState('Configurations');
  }, [])

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const getData = () => {
    setIsLoading(true);
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    axios.interceptors.request.use(config => {config.headers['x-access-token'] = sessionToken;return config;},error => {return Promise.reject(error);});
    axios.get(`${process.env.REACT_APP_PATH_DESTINATION}/ticketParentView/${intExtDoc}/${id}`) 
      .then(res => {
        console.log(res.data.data);
        setInputValue(res.data.data.parent_name)
        setIsLoading(false);
      })
      .catch(err => {
        console.log(err);
        MySwal.fire({
          title: <strong>Error!</strong>,
          html: <p>{err.response?.data?.message || "An error occurred. Please try relogging"}</p>
        })
        setIsLoading(false);
      })
  }

  const submitData = () => {
    //postData
    let data = {
      type : intExtDoc,
      name : inputValue,
      id : id
    }

    setIsLoading(true);
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    let apiLink = "";
    if (addEdit === "edit"){
      apiLink=`${process.env.REACT_APP_PATH_DESTINATION}/ticketParentEdit`
    } else if (addEdit === "add"){
      apiLink=`${process.env.REACT_APP_PATH_DESTINATION}/ticketParentAdd`
    }
    axios.interceptors.request.use(config => {config.headers['x-access-token'] = sessionToken;return config;},error => {return Promise.reject(error);});
    axios.post(apiLink,data) 
      .then(res => {
        console.log(res.data.data);
        setIsLoading(false);
        MySwal.fire({
          title: <strong>Success</strong>,
          html: <p>Data Edited</p>
        })
      })
      .catch(err => {
        console.log(err);
            MySwal.fire({
            title: <strong>Error!</strong>,
            html: <p>{err.response?.data?.message || "An error occurred. Please try relogging"}</p>
        })
        setIsLoading(false);
      })
  }

  return (
  <>
    <HeaderPage
        title1={"Configurations"}
        title2={"Ticket Parent"}
        link1={"/configuration/ticket"}
        loadingState={isLoading}
    />

    <div style={{marginTop:'4vw', marginLeft:isThisPortrait?'5vw':'', marginRight:'1vw'}} className='row'>
      <div className='form-group'>
        <label htmlFor='inputField'>{addEdit == "add"? "Add" : "Edit"} Parent Category Name:</label>
        <input
          type='text'
          className='form-control'
          id='inputField'
          placeholder='Enter something'
          style={{ width: '25vw' }}
          value={inputValue}
          onChange={handleChange}
        />
        <button type='submit' onClick={submitData} className='btn buttonBlue' style={{marginTop:'1vh'}}>
          Submit
        </button>
      </div>
    </div>    
  </>
  )
}

export default ConfigViewParent