import React, { useState, useEffect } from 'react';
import HeaderPage from '../../headerPage/HeaderPage';
import {useNavigate, useParams} from "react-router-dom"
import FilterSearch from '../../filter/FilterSearch';
import TabbingTenantConfig from './TabbingTenantConfig';
import ConfigTenantTable from './ConfigTenantTable';
import useOrientation from '../../../general/useOrientation';

function ConfigTenantList() {
  const navigate = useNavigate();
  const { byTo,id,name } = useParams();
  const [imagePopup, setImagePopup] = useState("");
  const [isExpanded, setIsExpanded] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [filterData, setFilterData] = useState("");
  const [linkData, setLinkData] = useState('tenantMgmtList');
  const [selectedTab, setSelectedTab] = useState("Tenant Management");
  const [refresh, setRefresh] = useState(true);
  const [clickLink, setClickLink] = useState("/configuration/edit/tenantMgmt/");
  const [parentOrCat, setParentOrCat] = useState("parent");
  const [addNewLink, setAddNewLink] = useState("/configuration/add/tenantMgmt");
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait') { isThisPortrait = true;}
  const redirectLink = () => {
    navigate(addNewLink);
  }
      
  const updateFilterData = (val) => {
    console.log("update filter data value ", val)
    setFilterData(val); 
  };

  const handleRefreshClick = () => {
    // just change the value to tell components to refresh
    setRefresh(!refresh); 
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    if (tab == "Tenant Management"){
      setParentOrCat('parent');
      setClickLink('/configuration/edit/tenantMgmt/');
      setLinkData('tenantMgmtList');
      setAddNewLink('/configuration/add/tenantMgmt');
    } else if (tab == "Tenant User Management"){
      setParentOrCat('cat');
      setClickLink('/configuration/edit/tenantUser/');
      setLinkData('tenantUserList');
      setAddNewLink('/configuration/add/tenantUser');
    } 
  };

  return (
    <>
    <HeaderPage 
        title1={"Configurations"}
        link1={"/configuration"}
        title2={"Tenant"}
        loadingState={isLoading}
        buttonText={"Create New"}
        buttonAction={redirectLink}
    />

    <TabbingTenantConfig 
      selectedTab={selectedTab}
      handleTabClick={handleTabClick}
    />

    <div style={{position:'relative'}}>
      <div style={{zIndex:2 ,position: 'relative', width:'78%'}}>
        <FilterSearch init={id} name={name} data={filterData} setData={updateFilterData} handleRefreshClick={handleRefreshClick} tiketOrDoc="tiket" bymeToMe={linkData}/>
      </div>
      <div style={{zIndex:1, position: 'relative', marginTop: isThisPortrait?'-12vh':'-6.2vw', marginLeft:isThisPortrait?'-1.2vw':'-1.2vw', maxWidth:isThisPortrait?'100vw':''}}>
        <ConfigTenantTable filterData={filterData} link={linkData} refresh={refresh} setLoading={setIsLoading}  imagePopup={imagePopup} setImagePopup={setImagePopup} isExpanded={isExpanded} setIsExpanded={setIsExpanded} parentOrCat={parentOrCat} clickLink={clickLink}/>
      </div>
     </div>
    </>
  )
}

export default ConfigTenantList