import React from 'react'
import TicketTable from './TicketTable'
import TicketTableDraft from './TicketTableDraft'
import TicketFiles from './TicketFiles'

function TicketTableParent({link, refresh, setLoading, isExpanded, setIsExpanded, imagePopup, setImagePopup,filterData}) {
    if (link === "ticketListToMe" || link === "ticketListByMe"){
        return (<TicketTable filterData={filterData} link={link} refresh={refresh} setLoading={setLoading} clickLink={"ticketDetail"}/>)
    } else if (link === "ticketListByMeDraft"){
        return (<TicketTableDraft link={link} refresh={refresh} setLoading={setLoading} clickLink={"ticketDetail"}/>)
    } else if (link === "fileList"){
        return (<TicketFiles link={link} refresh={refresh} setLoading={setLoading} clickLink={"ticketDetail"} imagePopup={imagePopup} setImagePopup={setImagePopup} isExpanded={isExpanded} setIsExpanded={setIsExpanded}/>)
    } else {
        return (<TicketTable filterData={filterData} link={link} refresh={refresh} setLoading={setLoading} clickLink={"ticketDetail"}/>)
    }
}
export default TicketTableParent