import React from 'react'

function ConfigInputText({name,data,setData}) {
  const handleChange = (e) => {
    setData(e.target.value);
  };

  return (
  <>
    <label>{name}</label>
    <input
        type='text'
        className='form-control'
        placeholder={'Enter ' + name} 
        style={{ width: '71vw' }}
        value={data}
        onChange={handleChange}
    />
  </>
  )
}

export default ConfigInputText