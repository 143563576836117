import React, { useEffect, useState } from 'react'
import HeaderPage from '../../headerPage/HeaderPage';
import Cookies from 'universal-cookie'; import axios from 'axios'
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
import { useParams } from 'react-router-dom';
import ConfigDropdown from '../configHelper/ConfigDropdown';
import ConfigInputText from '../configHelper/ConfigInputText';
import useOrientation from '../../../general/useOrientation';

const cookies = new Cookies();
const MySwal = withReactContent(Swal)
function ConfigViewTenantUser({addEdit, changeNavState}) {
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [tenantId, setTenantId] = useState('1');
  const [displayName, setDisplayName] = useState('');
  const [deptPic, setDeptPic] = useState('');
  const [tipePic, setTipePic] = useState('');
  const [email, setEmail] = useState('');
  const [nohp, setNohp] = useState('');
  const [passwordUser, setPasswordUser] = useState('');
  const [tenantBlock, setTenantBlock] = useState('');
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait') { isThisPortrait = true;}
  useEffect(() => {
    if (addEdit == "edit") {
      getData();
    }
  }, [addEdit])

  useEffect(() => {
    changeNavState('Configurations');
  }, [])

  const pickDropdownTenantId = (val) => {
    setTenantId(val.value);
  }

  const getData = () => {
    setIsLoading(true);
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    axios.interceptors.request.use(config => {config.headers['x-access-token'] = sessionToken;return config;},error => {return Promise.reject(error);});
    axios.get(`${process.env.REACT_APP_PATH_DESTINATION}/tenantUserView/${id}`) 
      .then(res => {
        console.log(res.data.data);
        setTenantId(res.data.data.tenant_id)
        setDisplayName(res.data.data.display_name)
        setDeptPic(res.data.data.dept_pic)
        setTipePic(res.data.data.tipe_pic)
        setEmail(res.data.data.email)
        setNohp(res.data.data.no_hp)
        setTenantBlock(res.data.data.tenant_blok)
        setIsLoading(false);
      })
      .catch(err => {
        console.log(err);
        MySwal.fire({
          title: <strong>Error!</strong>,
          html: <p>{err.response?.data?.message || "An error occurred. Please try relogging"}</p>
        })
        setIsLoading(false);
      })
  }

  const submitData = () => {
    //postData
    let data = {
      tenantId : tenantId,
      tenantBlok : tenantBlock,
      namaUser : displayName,
      email : email,
      nik : '-',
      noHp : nohp,
      password : passwordUser,
      deptPic : deptPic,
      tipePic : tipePic,
      role : '1',
      position: '1',
      id: id
    }

    setIsLoading(true);
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    let apiLink = "";
    if (addEdit === "edit"){
      apiLink=`${process.env.REACT_APP_PATH_DESTINATION}/tenantUserEdit`
    } else if (addEdit === "add"){
      apiLink=`${process.env.REACT_APP_PATH_DESTINATION}/tenantUserAdd`
    }
    axios.interceptors.request.use(config => {config.headers['x-access-token'] = sessionToken;return config;},error => {return Promise.reject(error);});
    axios.post(apiLink,data) 
      .then(res => {
        console.log(res.data.data);
        setIsLoading(false);
        MySwal.fire({
          title: <strong>Success</strong>,
          html: <p>Data Edited</p>
        })
      })
      .catch(err => {
        console.log(err);
            MySwal.fire({
            title: <strong>Error!</strong>,
            html: <p>{err.response?.data?.message || "An error occurred. Please try relogging"}</p>
        })
        setIsLoading(false);
      })
  }

  return (
  <>
    <HeaderPage
        title1={"Configurations"}
        title2={"Tenant User"}
        link1={"/configuration/tenant"}
        loadingState={isLoading}
    />

    <div style={{marginTop:'4vw', marginLeft:isThisPortrait?'5vw':'', marginRight:'1vw'}} className='row'>
      <div className='form-group'>
        <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
        <ConfigDropdown defValue={tenantId} type={9} pickDropdown={pickDropdownTenantId} title="Tenant Name" link={"/tenantMgmtList"} isMulti={false}/>
        
        <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
        <ConfigInputText name={"Nama PIC"} data={displayName} setData={setDisplayName}/>
        
        <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
        <ConfigInputText name={"PIC Ext Position"} data={deptPic} setData={setDeptPic}/>
        
        <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
        <ConfigInputText name={"PIC For Dept"} data={tipePic} setData={setTipePic}/>
        
        <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
        <ConfigInputText name={"Email PIC"} data={email} setData={setEmail}/>
        
        <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
        <ConfigInputText name={"No HP PIC"} data={nohp} setData={setNohp}/>
        
        <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
        <ConfigInputText name={"Password (required for add | for edit = set new pass for tenant user - leave empty if dont want to change pass)"} data={passwordUser} setData={setPasswordUser}/>
        
        <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
        <ConfigInputText name={"Tenant Block (Jika Ada)"} data={tenantBlock} setData={setTenantBlock}/>
        
        <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
        <button type='submit' onClick={submitData} className='btn buttonBlue' style={{marginTop:'1vh'}}>
          Submit
        </button>
        <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
      </div>
    </div>    
  </>
  )
}

export default ConfigViewTenantUser