import React from 'react'
import { useEffect,useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import HeaderPage from '../headerPage/HeaderPage';
import Cookies from 'universal-cookie';
import AnnouncementCreate from './create/AnnouncementCreate';
import AnnouncementTable from './AnnouncementTable';
import AncView from './AncView';
import useOrientation from '../../general/useOrientation';

const cookies = new Cookies();
function AnnouncementListParent({changeNavState}) {
  var sessionData = cookies.get('sessionData', { path: '/' })
  const [isLoading, setIsLoading] = useState(true);
  const [showOverlay, setShowOverlay] = useState(false);
  const [addAnc, setAddAnc] = useState(false);
  const { search } = useParams();  
  const navigate = useNavigate();
  const [viewAnc, setViewAnc] = useState(false);
  const [editAnc, setEditAnc] = useState(false);
  const [ancData, setAncData] = useState({});
  const buttonText = "Create Announcement";
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait') { isThisPortrait = true;}
  useEffect(() => {
      changeNavState('Announcement');
  }, [])
  const addAnnouncement = (() => {
    setShowOverlay(true);
    setAddAnc(true);
  })
  
  const closePopup = (() => {
    setShowOverlay(false);
    setAddAnc(false);
    setViewAnc(false);
    setEditAnc(false);
  })
  const allowCreateAnnouncement = () => {
    if (sessionData.apps_roles_id == 1 || sessionData.apps_roles_id == 2 || sessionData.apps_roles_id == 3) {
      return true;
    } else {
      return false;
    }
  }
  const viewAnnouncement = (data) => {
    setViewAnc(true);
    setAncData(data);
    setShowOverlay(true);
  };

  const editAnnouncement = (data) => {
    setEditAnc(true);
    setAncData(data);
    setShowOverlay(true);
  };

  const showAncCreate = () => {
    setShowOverlay(true);
  }

  const hideAncCreate = () => {
    setShowOverlay(false);
  }

  return (
   <>
    <div onClick={closePopup} className={showOverlay ? 'divOverlay' : ''}> </div>
    {addAnc && (
      <AnnouncementCreate show={showAncCreate} hide={hideAncCreate} closePopup={closePopup} />
    )}
    {viewAnc && (
      <AncView data={ancData} closePopup={closePopup} />
    )}
    {editAnc && (
      <AnnouncementCreate data={ancData} isEdit={true} closePopup={closePopup} />
    )}

    {allowCreateAnnouncement() ? (
      <HeaderPage
        title1={"Announcement"}
        title2={"List"}
        buttonText={buttonText}
        buttonAction={addAnnouncement}
        loadingState={isLoading}
      />
    ) : (
      <HeaderPage
        title1={"Announcement"}
        title2={"List"}
        loadingState={isLoading}
      />
    )}
    <div style={{marginTop:isThisPortrait?'-5vh':'-2vw'}}>
      <AnnouncementTable edit={editAnnouncement} search={search} setLoading={setIsLoading} setAnc={viewAnnouncement}/>
    </div>
   </>
  )
}

export default AnnouncementListParent