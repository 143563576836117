import { useParams } from 'react-router-dom';
import React from 'react' ;import { useState, useEffect} from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Cookies from 'universal-cookie'; 
import axios from 'axios' ;
import PdDetails from './PdDetails';
import ContentHeader from '../../content/ContentHeader';
const cookies = new Cookies();
const MySwal = withReactContent(Swal)
var defaultSelect = null;

function PdDetailsEdit() {
    const { id } = useParams();
    const [inputs, setInputs] = useState({});
    useEffect(() => {
        getData();
    }, [])
    const getData = () => {
        var sessionToken = cookies.get('sessionToken', { path: '/' })
        var path = process.env.REACT_APP_PATH_DESTINATION + "/getPDEditData/" + id;   
        const config = {headers:{['x-access-token']: sessionToken}};
        axios.get(path , config)
        .then(function (res) {
            console.log(res);
            setInputs(values => ({...values, ["pbi_link"]: res.data.data.pbi_link}))
            defaultSelect = {    
                label: res.data.data.fullname,
                value : res.data.data.user_id
            }
            setInputs(values => ({...values, ["user_id"]: res.data.data.user_id}))
            setInputs(values => ({...values, ["id"]: id}))
        })
        .catch(function (error) {
            console.log(error);
        });
    } 
    const handleSubmit = (event) => {
        event.preventDefault();
        var sessionToken = cookies.get('sessionToken', { path: '/' })
        var path = process.env.REACT_APP_PATH_DESTINATION + "/editPD" ;   
        const config = {headers:{['x-access-token']: sessionToken}};
        axios.post(path, {data: inputs} , config)
        .then(function (response) {
            if (response.data.status == '200')
            MySwal.fire({
                title: <strong>Success!</strong>,
                html: <p>{response.data.message}</p>,
                icon: 'success'
            })
            else 
            MySwal.fire({
                title: <strong>Failed!</strong>,
                html: <p>{response.data.message}</p>,
                icon: 'error'
            })
        })
        .catch(function (error) {
            console.log(error);
            MySwal.fire({
                title: <strong>Server Error!</strong>,
                html: <p>Something in the server went wrong. Please contact administrator</p>,
                icon: 'error'
              })
        });
    }
    var dataa = {
        title: "Edit Personal Dashboard",
        params : false
    };

    return defaultSelect ?  (
        <>
            <PdDetails handleSubmit={handleSubmit} data={dataa} inputs={inputs} setInputs={setInputs} defaultSelect={defaultSelect} />
        </>
    ) : <ContentHeader title="Loading..." />
}

export default PdDetailsEdit