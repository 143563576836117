import React, { useEffect, useState } from 'react'
import {Cancel,User,Calendar,Label} from 'iconoir-react'
import FormatDate from '../../general/FormatDate'
import useOrientation from '../../general/useOrientation';
function AncView({data,closePopup}) {
  const [content, setContent] = useState("");
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait') { isThisPortrait = true;}
  useEffect(() => {
      const searchString = '<img src="data:image'; 
      const modifiedKonten = String(data.konten).replace(
        new RegExp(searchString, 'g'),
        `<img class="inlineImageAnc" src="data:image`
      );
      setContent( modifiedKonten);
  }, [data])
  return (
    <div className={isThisPortrait?"popupContentMobile":"popupContent"} style={{width: isThisPortrait?'95vw':'640px', marginTop:'auto', height:isThisPortrait?'80vh':''}}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <p style={{fontSize:'20px',fontFamily:'Blender Pro' }}><b>Announcement</b></p>
        <Cancel onClick={closePopup} className="cursorPointer" style={{marginTop:'-20px'}}/>
      </div>
      <div>
        {isThisPortrait && (
          <><User style={{height:'12px'}}/> {data.internal == 1? "Internal" : "External"} &nbsp; </>
        )}
        {!isThisPortrait && (
          <><User style={{height:'15px'}}/> Target {data.internal == 1? "Internal" : "External"} &nbsp; &nbsp; &nbsp;</>
        )}

        {isThisPortrait && (
          <><Calendar style={{height:'12px'}}/> <FormatDate date={data.created_at} /> &nbsp;</>
        )}
        {!isThisPortrait && (
          <><Calendar style={{height:'15px'}}/> Publish Date: <FormatDate date={data.created_at} /> &nbsp; &nbsp; &nbsp;</>
        )}

        {isThisPortrait && (
          <><Label style={{height:'12px'}}/> <span className='blueBgClass'>{data.kategori_name}</span></>
        )}
        {!isThisPortrait && (
          <><Label style={{height:'15px'}}/> Category <span className='blueBgClass'>{data.kategori_name}</span> </>
        )}

      </div>
      <div style={{marginTop:'0.5vw'}}><p style={{ marginTop:'1.5vw', marginBottom:'1.5vw',fontSize: '20px',fontFamily:'Blender Pro' }}><b>{data.title}</b></p></div>
       <div className="htmlUserInput" style={{fontFamily:'Blender Pro', fontSize:'15px',marginTop:'1vh' }} dangerouslySetInnerHTML={{ __html: content }}></div>
    </div>
  )
}

export default AncView