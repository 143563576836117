import React , {useState} from 'react'
import { useEffect } from 'react';
import ReactQuill from 'react-quill'
import {WarningCircle} from 'iconoir-react'
import CommentUploadComp from './CommentUploadComp';
import TicketDropdownShow from '../TicketDropdownShow';
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
import useOrientation from '../../../general/useOrientation';

const MySwal = withReactContent(Swal);
function CommentAdd({handleFileChange,action, cancelHandler, sendHandler, setContent}) {
  const [desc, setDesc] = useState('');
  const [title, setTitle] = useState('');
  const [commentEmpty, setCommentEmpty] = useState(false);
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait') { isThisPortrait = true;}
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['link', 'image'],
    ],
  };

  function isDescEmpty(desc) {
    // Remove content inside angle brackets
    const strippedContent = desc.replace(/<[^>]*>/g, '').trim();
  
    // Check if the stripped content is empty
    return strippedContent === '';
  }

  const handleDescChange = (value) => {
    if (desc == '' || isDescEmpty(desc)){
      setCommentEmpty(true);
    } else {
      setCommentEmpty(false);
    }
    setDesc(value);
    setContent(value);
  }
  
  // Set a maximum size limit for 'desc' including base64-encoded images (in kilobytes)
  const maxDescSizeKB = process.env.REACT_APP_MAX_INLINE_IMAGE_SIZE; // Adjust the limit according to your requirements

  // Function to estimate the size of a base64 string in kilobytes
  const getBase64Size = (base64String) => {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
    const byteSize = base64.length / 4 * 3 - padding.length;
    return byteSize / 1024; // Convert to kilobytes
  };

  const sendComment = () => {
    if (desc == '' || isDescEmpty(desc)){
      setCommentEmpty(true);
      return;
    } else {
      setCommentEmpty(false);
    }
    const descSizeKB = getBase64Size(desc);
    if (descSizeKB > maxDescSizeKB) {
      MySwal.fire({
          title: <strong>Image is too big!</strong>,
          html: <p>Image inside in the body text is too big. Please reduce the image size or use attach file. Max image size is : {maxDescSizeKB} kb </p>
      })
      return;
    } else {
      sendHandler()
    }
  }

  useEffect(() => {
    if(action ==="Forwarded"){
      setTitle("Reason to Forward")
    } else if(action ==="Extended"){
      setTitle("Reason to Extend")
    } else {
      setTitle("Description");
    } 
  }, [action])

  if (orientation == "landscape")
  return (
    <>
    <div style={{marginLeft:"4vw", marginTop:'2vw', marginBottom:'5vw'}}>
      {action == "Accepted" && (
        <>
        <TicketDropdownShow title="" value="0" label={"Accept: Anda akan melakukan support dan ticket tidak dapat di-forward"} />
        <br/>
        </>
      )}
      <p className="txtCreateTicket">{title}</p>
      <div>
        <ReactQuill
          value={desc}
          onChange={handleDescChange}
          modules={modules}
          className='inputText'
          style={{minHeight:'10vw'}}
        />
      </div>
      <br/>
         
      <CommentUploadComp action={action} onChangeStatus={handleFileChange}/>
      {commentEmpty && (
        <div style={{marginTop:'2vw'}}>
          <p style={{fontSize:'1.2vw'}}>
          <WarningCircle width="16px" color="red" />
            &nbsp;&nbsp;&nbsp;
            <font color="red">Please Submit a Description</font>
          </p>
        </div>
      )}

      <div style={{float:'right', marginTop:'1vw'}}>
        <a className="draftClass" onClick={cancelHandler}>
          Cancel
        </a>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <button className="btn btnSubmitTicket" type="button" onClick={sendComment}>
          Send
        </button>
      </div>
    </div>
    </>
  )

  if (orientation == "portrait")
  return (
    <>
    <div style={{marginLeft:"4vw", marginTop:'2vh', marginBottom:'5vh'}}>
      {action == "Accepted" && (
        <>
        <TicketDropdownShow title="" value="0" label={"Accept: Anda akan melakukan support"} />
        <br/>
        </>
      )}
      <p className="txtCreateTicketMobile">{title}</p>
      <div>
        <ReactQuill
          value={desc}
          onChange={handleDescChange}
          modules={modules}
          className='inputText'
          style={{minHeight:'15vh'}}
        />
      </div>
      <br/>
         
      <CommentUploadComp action={action} onChangeStatus={handleFileChange}/>
      {commentEmpty && (
        <div style={{marginTop:'2vw'}}>
          <p style={{fontSize:'15px'}}>
          <WarningCircle width="16px" color="red" />
            &nbsp;&nbsp;&nbsp;
            <font color="red">Please Submit a Description</font>
          </p>
        </div>
      )}

      <div style={{float:'right', marginTop:'1.5vh', marginBottom:'3vh'}}>
        <a className="draftClassM" onClick={cancelHandler}>
          Cancel
        </a>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <button className="btn btnSubmitTicket" type="button" onClick={sendComment}>
          Send
        </button>
      </div>
    </div>
    </>
  )
}

export default CommentAdd