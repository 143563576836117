import React, { useEffect, useState } from 'react';
import useOrientation from '../../../general/useOrientation';

function StatusImage({ displayType, type, status_overdue }) {
  const [dynamicUrlLink, setDynamicUrlLink] = useState('');
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait') { isThisPortrait = true;}
  var mrgntop = "0vw"
  useEffect(() => {
    let urlLink = '';
    if (type === 'Waiting') {
      urlLink = 'Waiting';
    } else if (type === 'On Progress' && status_overdue === 'overdue' && displayType != "1" && displayType != "0") {
      urlLink = 'Overdue';
      type = 'Overdue';
    } else if (type === 'On Progress') {
      urlLink = 'OnProgress';
    } else if (type === 'Resolved') {
      urlLink = 'Resolved';
    } else if (type === 'Forwarded' && displayType == "1") {
      urlLink = 'OnProgress';
    } else if (type === 'Forwarded') {
      urlLink = 'Forwarded';
    } else if (type === 'Closed') {
      urlLink = 'Closed';
    } else if (type === 'Extend') {
      urlLink = 'OnProgress';
    } else if (type === 'Overdue' && displayType == "1") {
      urlLink = 'OnProgress';
    } else if (type === 'Overdue') {
      urlLink = 'Overdue';
    } else if (type === 'Done') {
      urlLink = 'Done';
    }

    const newDynamicUrlLink = '/dist/img/status/' + urlLink + '.png';
    setDynamicUrlLink(newDynamicUrlLink);
  }, [type, status_overdue]);

  if (orientation == 'landscape')
  return <img src={dynamicUrlLink} alt={"Status Image"}/>;

  if (orientation == 'portrait')
  return <img src={dynamicUrlLink} alt={"Status Image"} style={{height:'3.5vh'}}/>;
}

export default StatusImage;
