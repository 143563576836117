import React, { useState, useRef, useEffect } from 'react';
import Cookies from 'universal-cookie'; import axios from 'axios'
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
import { isBrowser, isMobile } from 'react-device-detect';
import useOrientation from '../../general/useOrientation';

const cookies = new Cookies();
const MySwal = withReactContent(Swal)
function ProfileEdit({ changeNavState }) {
  const orientation = useOrientation();
  const [displayName, setDisplayName] = useState('');
  const [picPath, setPicPath] = useState('');
  const hiddenFileInput = useRef(null);
  const uploadedFilesRef = useRef([]);
  const nameRef = useRef("");
  const changesRef = useRef("");

  const sessionData = cookies.get('sessionData', { path: '/' })
  console.log(sessionData);
  useEffect(() => {
    getData();
    changeNavState('Configurations');
  }, [])

  const setDisplayNameFunction = (name) => {
    // Your logic to set the displayName
    setDisplayName(name);
    changesRef.current = "txt"
    nameRef.current = name
    updateData();
  };

  const getData = () => {
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    axios.interceptors.request.use(config => {config.headers['x-access-token'] = sessionToken;return config;},error => {return Promise.reject(error);});
    axios.get(`${process.env.REACT_APP_PATH_DESTINATION}/getPathPhoto`) 
      .then(res => {
        //console.log("abcdefghiiiiii",res.data.data);
        setDisplayName(res.data.data.display_name);
        nameRef.current = res.data.data.display_name;
        setPicPath(res.data.data.path_foto);
      })
      .catch(err => {
        console.log(err);
        MySwal.fire({
          title: <strong>Error!</strong>,
          html: <p>{err.response?.data?.message || "An error occurred. Please try relogging"}</p>
        })
      })
  }

  
  const updateData = () => {
    // Create a FormData object
    const formData = new FormData();
    formData.append('displayName', nameRef.current);
    formData.append('files',uploadedFilesRef.current);

    var sessionToken = cookies.get('sessionToken', { path: '/' })
    const config = {headers:{['x-access-token']: sessionToken}};
    var apiPath = process.env.REACT_APP_PATH_DESTINATION + "/editProfilePicture";   
    axios.post( apiPath, formData, config)
    .then(res => {
      if (res.data.status == "200"){
        if ( changesRef.current === "img"){
          getData();
        }
      } else {
        console.log("Error update profile");
      }
    })
  }

// Callback function to handle file and metadata on change status
const handleFileChange = (file) => {
  uploadedFilesRef.current = file
  changesRef.current = "img"
  updateData();
};

  // Custom function to open the file dialog when the button is clicked
  const handleButtonClick = () => {
    hiddenFileInput.current.click();
  };

  if (orientation === 'landscape')
  return (
    <div style={{ textAlign: 'center' }}>
      <img
        src={process.env.REACT_APP_PUBLIC_DESTINATION + picPath}
        alt="User Image"
        className="brand-image img-circle"
        style={{ height: "8vw",width: "8vw", marginTop: '0vw' }}
      />
      <br/>
      <p>User Avatar</p>
      <button type="button" className="btn btn-primary" onClick={handleButtonClick} style={{backgroundColor: '#0296D2', borderRadius: '8px', fontFamily:'Blender Pro', height:'2.8vw', marginTop:'0vw'}}>
        Upload New 
      </button>
      
      
      <br/><br/>
      <table style={{ textAlign: 'left' , width:'100%', marginLeft:'30vw'}}>
        <tbody>
          <tr><td>
            <p className="txtCreateTicket" style={{ fontSize: '1.2vw', marginBottom:'1vw' }}>Display Name</p>
          </td></tr><tr><td>
            <input
              type="text"
              value={displayName}
              onChange={(e) => setDisplayNameFunction(e.target.value)}
              placeholder="Enter Display Name"
              style={{ marginTop: '-3vw', width: '30%',marginBottom:'1vw' }}
              className='inputText'
            />
          </td></tr>
          {sessionData.int_ext === "1" && (
          <>
            <tr><td>
              <p className="txtCreateTicketThin" style={{ fontSize: '1.2vw', marginBottom:'1vw' }}>Employee Name</p>
            </td></tr><tr><td>
              <input
                type="text"
                value={sessionData.fullname}
                style={{ marginTop: '-3vw', width: '30%',marginBottom:'1vw' }}
                className='inputTextThin'
              />
            </td></tr>
            <tr><td>
              <p className="txtCreateTicketThin" style={{ fontSize: '1.2vw', marginBottom:'1vw' }}>Department</p>
            </td></tr>
            {sessionData.department.map((department, index) => (<>
              <tr><td>
                <input
                  type="text"
                  value={department.nama_department}
                  style={{ marginTop: '-3vw', width: '30%',marginBottom:'1vw' }}
                  className='inputTextThin'
                />
              </td></tr> </>
            ))}
            
            <tr><td>
              <p className="txtCreateTicketThin" style={{ fontSize: '1.2vw', marginBottom:'1vw' }}>Location</p>
            </td></tr><tr><td>
              <input
                type="text"
                value={sessionData.location_name}
                style={{ marginTop: '-3vw', width: '30%',marginBottom:'1vw' }}
                className='inputTextThin'
              />
            </td></tr>
            
          </>
          )}
        </tbody>
      </table>

      
      
      <input
        ref={hiddenFileInput}
        type="file"
        style={{ display: 'none' }}
        accept="image/*"
        onChange={(e) => {
          const selectedFile = e.target.files[0];
          if (selectedFile && selectedFile.type.startsWith('image/')) {
            // Manually handle file upload when the file input changes
            handleFileChange(selectedFile);
          } else {
            // Handle case where the selected file is not an image
            console.error('Please select a valid image file.');
          }
        }}
      />
      
    </div>
  );

  if (orientation === 'portrait')
  return (
    <div style={{ textAlign: 'center', marginTop:'7vh'}}>
      <img
        src={process.env.REACT_APP_PUBLIC_DESTINATION + picPath}
        alt="User Image"
        className="brand-image img-circle"
        style={{ height: "20vw", width: "20vw", marginTop: '2vw' }}
      />
      <br/>
      <p>User Avatar</p>
      <button type="button" className="btn btn-primary" onClick={handleButtonClick} style={{backgroundColor: '#0296D2', borderRadius: '8px', fontFamily:'Blender Pro', height:'6vw', marginTop:'2vw', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 'auto'}}>
        <span style={{ fontSize: '3vw' }}>Upload New</span>
      </button>


      
      <br/><br/>
      <div style={{ textAlign: 'left', width: '80%', marginLeft: '18%' }}>
        <p className="txtCreateTicket" style={{ fontSize: '4vw', marginBottom:'2vw' }}>Display Name</p>
        <input
          type="text"
          value={displayName}
          onChange={(e) => setDisplayNameFunction(e.target.value)}
          placeholder="Enter Display Name"
          style={{ marginTop: '-1vw', width: '80%', marginBottom:'2vw', fontSize: '4vw' }}
          className='inputText'
        />
        {sessionData.int_ext === "1" && (
          <>
            <p className="txtCreateTicketThin" style={{ fontSize: '4vw', marginBottom:'2vw' }}>Employee Name</p>
            <input
              type="text"
              value={sessionData.fullname}
              style={{ marginTop: '-1vw', width: '80%', marginBottom:'2vw', fontSize: '4vw' }}
              className='inputTextThin'
            />
            <p className="txtCreateTicketThin" style={{ fontSize: '4vw', marginBottom:'2vw' }}>Department</p>
            {sessionData.department.map((department, index) => (
              <input
                key={index}
                type="text"
                value={department.nama_department}
                style={{ marginTop: '-1vw', width: '80%', marginBottom:'2vw', fontSize: '4vw' }}
                className='inputTextThin'
              />
            ))}
            <p className="txtCreateTicketThin" style={{ fontSize: '4vw', marginBottom:'2vw' }}>Location</p>
            <input
              type="text"
              value={sessionData.location_name}
              style={{ marginTop: '-1vw', width: '80%', marginBottom:'2vw', fontSize: '4vw' }}
              className='inputTextThin'
            />
          </>
        )}
      </div>

      <input
        ref={hiddenFileInput}
        type="file"
        style={{ display: 'none' }}
        accept="image/*"
        onChange={(e) => {
          const selectedFile = e.target.files[0];
          if (selectedFile && selectedFile.type.startsWith('image/')) {
            // Manually handle file upload when the file input changes
            handleFileChange(selectedFile);
          } else {
            // Handle case where the selected file is not an image
            console.error('Please select a valid image file.');
          }
        }}
      />
    </div>
  );
}

export default ProfileEdit;
