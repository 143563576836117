import React, { useState, useEffect } from 'react';
import HeaderPage from '../headerPage/HeaderPage'
import {useNavigate, useParams} from "react-router-dom"
import StatusTicketDetail from './status/StatusTicketDetail';
import { Tooltip } from 'react-tooltip';
import { CheckCircle,ArrowEmailForward,ReplyToMessage,ArrowTrCircle,Timer,Cancel, OpenNewWindow} from 'iconoir-react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import TimeAgo from './status/TimeAgo';
import TicketDropdownShow from './TicketDropdownShow';
import Cookies from 'universal-cookie';
import axios from 'axios' 
import TicketCCs from './TicketCCs';
import AttachmentHandler from './attachment/AttachmentHandler';
import CommentList from './comment/CommentList';
import FormatDate from '../../general/FormatDate';
import CommentAdd from './comment/CommentAdd';
import ForwardAddHandler from './forward/ForwardAddHandler';
import ForwardDisplay from './forward/ForwardDisplay';
import ExtendPick from './extendClose/ExtendPick';
import ClosePick from './extendClose/ClosePick';
import ReferralShow from './status/ReferralShow';
import useOrientation from '../../general/useOrientation';
import MobileDetailTabbing from './mobile/MobileDetailTabbing';
const cookies = new Cookies();
const MySwal = withReactContent(Swal)
function TicketDetails({changeNavState}) {
  const debugButton = false;
  const sessionData = cookies.get('sessionData', { path: '/' })
  const navigate = useNavigate(); 
  const [isExpanded, setIsExpanded] = useState(0);
  const { id } = useParams();    
  const [data, setData] = useState({});
  const [commentContent, setCommentContent] = useState("");
  const [actionComment, setActionComment] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [dueAt, setDueAt] = useState("");
  const [marginAttachment, setMarginAttachment] = useState("");
  const [marginAttachmentM, setMarginAttachmentM] = useState("");
  const [deptFwd, setDeptFwd] = useState('');
  const [catFwd, setCatFwd] = useState('');
  const [extendTime, setExtendTime] = useState('');
  const [closeRating, setCloseRating] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [imgSrc, setImgSrc] = useState(process.env.REACT_APP_PUBLIC_DESTINATION + data.path_foto);
  const [selectedTab, setSelectedTab] = useState( 'Conversation' );
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait') { isThisPortrait = true;}

  useEffect(() => {
    changeNavState('Ticket');
    getData();
  }, [id])

  const handleImageError = () => {
    // If the main image fails to load, set the alternative image
    setImgSrc(process.env.REACT_APP_PUBLIC_DESTINATION + '/dist/img/pp/default1.png');
  };

  const changeDataKonten = (imgData) => {
    const searchString = '<img src="data:image'; 

    const modifiedKonten = String(imgData).replace(
      new RegExp(searchString, 'g'),
      `<img class="inlineImage" src="data:image`
    );

    return modifiedKonten;
  }
  const ChangeActionComment = (value) => {
    if (value === actionComment)
      setActionComment("");
    else 
      setActionComment(value);
  }

  const hideComment = () => {
    setActionComment("");
    setUploadedFiles([]);
  }

  const setCommentValue = (value) => {
    setCommentContent(value);
  }

  const commentHandler = () => {
    switch (actionComment) {
      case "Accepted":
        ticketAccept();
        break;
      case "Replied":
        sendComment();
        break;
      case "Resolved":
        ticketResolve();
        break;
      case "Done":
        ticketDone();
        break;
      case "Forwarded":
        ticketForward();
        break;
      case "Extended":
        ticketExtend();
        break;
      case "Closed":
        ticketClose();
        break;
      default:
        MySwal.fire({
          title: <strong>Failed!</strong>,
          html: <p>Error Action Comment</p>,
          icon: 'error'
        })
    }
  }

  const PickDropdownDept = (val) => {
    setDeptFwd(val.value);
  }

  const PickDropdownCat = (val) => {
    //console.log(val.label);
    setCatFwd(val.value)
  }

  // Callback function to handle file and metadata on change status
  const handleFileChange = (meta, file, status) => {
    if (status === 'done') {
      // File has been successfully uploaded
      setUploadedFiles((prevFiles) => [...prevFiles, { meta, file }]);
    } else if (status === 'removed') {
      // File has been removed
      setUploadedFiles((prevFiles) =>
        prevFiles.filter((uploadedFile) => uploadedFile.file !== file)
      );
    }
    // Add more conditions based on your requirements
    console.log("upload files logging",uploadedFiles);
  };

  const PickExtendDate = (val) => {
    val = new Date(val)  
    const year = val.getFullYear();
    const month = val.getMonth() + 1;
    const day = val.getDate();

    // Constructing the yyyy-mm-dd format
    const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
    setExtendTime(formattedDate);
  };

  const PickRating = (val) => {
    setCloseRating(val);
  }

  const createReferralHandler = (value) => {
    //alert("create ref val = " + value);
    navigate("/CreateTicketReferral/" + value)
  }
  
  const checkUploadNeeded = () => {
    if (actionComment == "Replied" || actionComment == "Accepted" || actionComment == "Resolved"|| actionComment == "Done"){
      return true;
    } else {
      return false;
    } 
  }

  const sendComment = () => {
    // Create a FormData object
    const formData = new FormData();
    formData.append('tiket_id', data.id);
    formData.append('komen', commentContent);
    formData.append('action', actionComment);
    if (checkUploadNeeded()){
      // Append the uploaded files to the FormData object
      uploadedFiles.forEach((file) => {
        formData.append('files',file.file); // Use the appropriate field name for your API
      });
    } else {
        //no attachment detail needed
    }

    var sessionToken = cookies.get('sessionToken', { path: '/' })
    const config = {headers:{['x-access-token']: sessionToken}};
    var apiPath = process.env.REACT_APP_PATH_DESTINATION + "/createKomen";   
    axios.post( apiPath, formData, config)
    .then(res => {
      //console.log(res.data) 
      if (res.data.status == "200"){
        //alert("Success");
        getData();

      } else {
        console.log("Error comment");
        //alert("status " + res.data.status);
      }
    })
  }

  const ticketAccept = () => { 
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    const config = {headers:{['x-access-token']: sessionToken}};
    var apiPath = process.env.REACT_APP_PATH_DESTINATION + "/onProgress";
    const postData = {
      tiket_id: data.id, 
    };
    axios.post( apiPath, postData, config)
    .then(res => {
      //console.log(res.data) 
      if (res.data.status == "200"){
        if (data.forwarded = 1){
          //forwardAccept - forward accept, if forwarded then record the accept forward
          var apiPath2 = process.env.REACT_APP_PATH_DESTINATION + "/forwardAccept";
          axios.post( apiPath2, postData, config)
          .then(res => {
            if (res.data.status == "200"){
              //getData();
              sendComment();
            } else {
              MySwal.fire({
                title: <strong>Failed!</strong>,
                html: <p>Error Ticket Accept</p>,
                icon: 'error'
              })
            }
          })
        } else {
          getData();
        }
      } else {
        console.log("Error Accept")
      }
    })
  }

  const ticketForward = () => { 
    //TOFO: forward comment is abit diferent. it writes to fwd_konten and fwd_by in ticket table
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    const config = {headers:{['x-access-token']: sessionToken}};
    var postData = {};
    var apiPath = "";
    if (data.int_or_ext_id === 1 || data.priority_id_manual == 2){
      apiPath = process.env.REACT_APP_PATH_DESTINATION + "/forwardInt";   
      postData = {
        tiket_id: data.id, 
        deptMgmt: deptFwd,
        intCatId : catFwd,
        komen: commentContent,
      };
    } else if (data.int_or_ext_id === 2){
      apiPath = process.env.REACT_APP_PATH_DESTINATION + "/forwardExt";   
      postData = {
        tiket_id: data.id, 
        komen: commentContent,
      };
    } else {
      alert("internal / external error");
    }
    axios.post( apiPath, postData, config)
    .then(res => {
      console.log(res.data) 
      if (res.data.status == "200"){
        getData();
      } else {
        console.log("Error forward");
        //alert("status " + res.data.status);
      }
    })
  }

  const ticketDone = () => { 
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    const config = {headers:{['x-access-token']: sessionToken}};
    var apiPath = process.env.REACT_APP_PATH_DESTINATION + "/done";   
    const postData = {
      tiket_id: data.id, 
    };
    axios.post( apiPath, postData, config)
    .then(res => {
      console.log(res.data) 
      if (res.data.status == "200"){
        sendComment();
      } else {
        MySwal.fire({
          title: <strong>Failed!</strong>,
          html: <p>Error Done Ticket</p>,
          icon: 'error'
        })
      }
    })
  }

  const ticketResolve = () => { 
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    const config = {headers:{['x-access-token']: sessionToken}};
    var apiPath = process.env.REACT_APP_PATH_DESTINATION + "/resolved";   
    const postData = {
      tiket_id: data.id, 
    };
    axios.post( apiPath, postData, config)
    .then(res => {
      console.log(res.data) 
      if (res.data.status == "200"){
        sendComment();
      } else {
        MySwal.fire({
          title: <strong>Failed!</strong>,
          html: <p>Error Resolve Ticket</p>,
          icon: 'error'
        })
      }
    })
  }

  const ticketExtend = () => { 
    //alert(""+extendTime);
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    const config = {headers:{['x-access-token']: sessionToken}};
    var apiPath = process.env.REACT_APP_PATH_DESTINATION + "/extend";   
    var postData = {}
    if (data.int_or_ext_id == "1"){
      postData = {
        tiket_id: data.id, 
        extendedTo: `${extendTime}`
      };
    } else {
      postData = {
        tiket_id: data.id, 
        extendedTo: `${extendTime}`
      };
    }
    axios.post( apiPath, postData, config)
    .then(res => {
      console.log(res.data) 
      if (res.data.status == "200"){
        sendComment();
      } else {
        MySwal.fire({
          title: <strong>Failed!</strong>,
          html: <p>Error Extend Ticket</p>,
          icon: 'error'
        })
      }
    })
  }

  const ticketClose = () => { 
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    const config = {headers:{['x-access-token']: sessionToken}};
    var apiPath = process.env.REACT_APP_PATH_DESTINATION + "/closed" ;   
    const postData = {
      tiket_id: data.id, 
      rating : closeRating,
    };
    axios.post( apiPath, postData, config)
    .then(res => {
      console.log(res.data) 
      if (res.data.status == "200"){
        sendComment();
      } else {
        MySwal.fire({
          title: <strong>Failed!</strong>,
          html: <p>Error Close Ticket</p>,
          icon: 'error'
        })
      }
    })
  }

  const handleImageClick = (val) => {
    if (isExpanded == 0){
      setIsExpanded(val);
    } else {
      setIsExpanded(0);
    }
  };
  const handlePdfClick = (link) => {
   //alert(link)
    window.open(link, '_blank');
  };

  const formatDate = (date) => {
    if (date){
      const createdAt = new Date(date);
      const hours = createdAt.getHours().toString().padStart(2, '0');
      const minutes = createdAt.getMinutes().toString().padStart(2, '0');
      const day = createdAt.getDate().toString().padStart(2, '0');
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const month = monthNames[createdAt.getMonth()];
      const year = createdAt.getFullYear();
      return `${hours}.${minutes} on ${day} ${month} ${year}`;
    } else {
      return '-'
    }
    
  };

  const getData = () => { 
    setRefreshData(!refreshData);
    setUploadedFiles([]);
    setActionComment("");
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    const config = {headers:{['x-access-token']: sessionToken}};
    var dbpath = process.env.REACT_APP_PATH_DESTINATION + "/ticketDetail/" + id;   
    axios.get( dbpath, config)
      .then(res => { 
        console.log("res.data.data ticket detail logging")
        console.log(res.data.data);
        if (res.data.status == "200"){
          res.data.data.konten = changeDataKonten(res.data.data.konten);
          setData(res.data.data);
          setImgSrc(process.env.REACT_APP_PUBLIC_DESTINATION + res.data.data.path_foto);
          // Convert and set the created date manually
          const formattedDate = <FormatDate date={res.data.data.created_at} />
          //formatDate(res.data.data.created_at);
          setCreatedDate(formattedDate);

          const dueDate = formatDate(res.data.data.due_at);
          //console.log("res.data.data.due_at = " + res.data.data.due_at);
          setDueAt(dueDate);

          //calc height
          if (res.data.data.attachment){
            let attachmentCount = String(res.data.data.attachment).split('|').length;
            let marginTop = `${11 + Math.floor((attachmentCount - 1) / 4) * 11}vw`;
            let marginTopM = `${28 + Math.floor((attachmentCount - 1) / 3) * 28}vw`;
            setMarginAttachment(marginTop);
            setMarginAttachmentM(marginTopM);
          } else {
            setMarginAttachment("0");
            setMarginAttachmentM("0");
          }
          
        } else {
          //maybe error log / alert here somehow
        }
      })
  }

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };


if (orientation === 'landscape')
return (
<>
<HeaderPage 
  title1={"Ticket List"}
  link1={"/ticketList"}
  leftArrow={true}
  ticketNumber={data.tiket_number}
/>         
<div className="headerPageMarginTop">
  <section className="content" style={{ marginLeft: "3vw", position: 'relative' }}>
    <div className="container-fluid" style={{ position: 'relative' }}>
      <div className='row mx-auto text-center' style={{}}>
      <div className="col-md-8 text-left h-100" style={{ width: '55% !important', marginLeft:'-3vw'}}>
          <div className='borderWithRadius' style={{ paddingBottom:'4vw', borderBottom:'0px solid black', height:'auto'}}>
           <div style={{ marginLeft: '2vw', marginTop: '1.5vw', marginRight: '2vw' }}>
            <StatusTicketDetail dueAt={dueAt} typeString={data.status_name} extended ={data.extended} countdown={data.countdown_time} data={data} displayType={data.displayType} star={data.rating} refresh={refreshData}/>
            <div style={{ marginTop: '0.5vw', position: 'relative' }} className='row'>
            <a href="#" className="no-hover" data-tooltip-id={"creatorTicket"} data-tooltip-content={data.display_name}>
              <img
                src={imgSrc}
                alt="Creator Image"
                className="brand-image img-circle"
                style={{ height: "3vw", width:"3vw", marginTop: '2.2vw', position: 'absolute' }}
                onError={handleImageError}
              />
              <Tooltip id={"creatorTicket"} place="bottom" type="dark" effect="solid" style={{marginTop:'4.5vw', marginLeft:'1.5vw'}}/>
            </a>
              <div style={{ marginTop: '1.8vw', marginLeft: '4vw', width:'100%' , marginBottom:'5vw'}}>
                <div className="col-md-11" style={{marginLeft:'-0.5vw',paddingBottom:'1vw'}}>
                  <h5 style={{ fontSize: '2vw', fontFamily: 'Blender Pro', marginRight:'3vw' }}>{data.title}</h5>                  
                </div>
                <div className="col-md-1"></div>
                <p style={{ marginTop: '-1vw', fontSize: '1.1vw' }}>
                  {data.display_name} created this ticket at {createdDate}
                </p>
                <div style={{ marginTop: '2vw', fontSize: '1.1vw', fontFamily: 'Blender Pro' }}>
                <div className="htmlUserInput" style={{fontSize:"1.3vw"}} dangerouslySetInnerHTML={{ __html: data.konten }}></div>
                </div>
                <div className={isExpanded == 0 ? '' : 'divOverlay'}  onClick={() => handleImageClick(0)}>

                </div>
                <div className='row' style={{ marginTop: '3vw', }}>
                  {data.attachment && String(data.attachment).split('|').map((attachment, index) => (
                    <AttachmentHandler
                      key={"atch"+index} 
                      id={index + 1} 
                      isExpanded={isExpanded}
                      source={`${process.env.REACT_APP_IMG_DESTINATION}${attachment}`}
                      handleImageClick={handleImageClick}
                      handlePdfClick={handlePdfClick}
                      fileName={attachment}
                    />
                  ))}
                </div>
                  
                <div style={{marginTop: marginAttachment, marginLeft:'-4vw'}} >
                  <div style={{marginLeft:'4vw'}}>
                    {data && (data.displayType == 2 || data.displayType == 3) && data.forwarded == 1 && (
                      <ForwardDisplay data={data}/>
                    )}
                    {(data.displayType == 2) && (data.status_id == 1 ) && (
                      <button className={`btn btn-primary ${actionComment == 'Accepted' ? 'buttonBlueSelected' : 'buttonBlue'}`} onClick={() => ChangeActionComment("Accepted")}> <CheckCircle style={{height: '1.1vw', marginTop:'-0.1vw'}} />  Accept</button>
                    )}
                    {((data.displayType == 3) && (data.status_id == 6)) && (
                      <button className={`btn btn-primary ${actionComment == 'Accepted' ? 'buttonBlueSelected' : 'buttonBlue'}`} onClick={() => ChangeActionComment("Accepted")}> <CheckCircle style={{height: '1.1vw', marginTop:'-0.1vw'}} />  Accept</button>
                    )}
                    {data && data.displayType == 2 && data.status_id == 1 && (
                      <button className={`btn btn-primary ${actionComment == 'Forwarded' ? 'buttonBlueSelected' : 'buttonBlue'}`} onClick={() => ChangeActionComment("Forwarded")}><ArrowEmailForward style={{height: '1.1vw', marginTop:'-0.1vw'}} />  Forward</button>
                    )}
                    {data && data.status_id != 4 && (data.displayType == 1 || data.displayType == 2 || data.displayType == 3) && (
                      <button className={`btn btn-primary ${actionComment == 'Replied' ? 'buttonBlueSelected' : 'buttonBlue'}`} onClick={() => ChangeActionComment("Replied")} ><ReplyToMessage style={{height: '1.1vw', marginTop:'-0.1vw'}} /> Reply</button>
                    )}
                    {data && ((data.accept_by == sessionData.user_id ) ) && ((data.displayType == 2 && data.forwarded != 1) || (data.displayType == 3 && data.forwarded == 1)) && data.status_id == 2 && data.extended == 0 && (
                      <button className={`btn btn-primary ${actionComment == 'Extended' ? 'buttonBlueSelected' : 'buttonBlue'}`} onClick={() => ChangeActionComment("Extended")}><Timer style={{height: '1.1vw', marginTop:'-0.1vw'}} />Extend</button>
                    )}
                    {data && ((data.fwd_by == sessionData.user_id && data.forwarded == 1) || (data.accept_by == sessionData.user_id && data.forwarded != 1) ) && data.displayType == 2 && ((data.status_id == 2 && data.forwarded != 1)|| (data.status_id == 5 && data.forwarded == 1)) && (
                      <button className={`btn btn-primary ${actionComment == 'Resolved' ? 'buttonBlueSelected' : 'buttonBlue'}`} onClick={() => ChangeActionComment("Resolved")}><CheckCircle style={{height: '1.1vw', marginTop:'-0.1vw'}} /> Resolve</button>
                    )}
                    {data && data.displayType == 3 && (data.status_id == 2) && data.forwarded == 1 && (
                      <button className={`btn btn-primary ${actionComment == 'Done' ? 'buttonBlueSelected' : 'buttonBlue'}`} onClick={() => ChangeActionComment("Done")}><CheckCircle style={{height: '1.1vw', marginTop:'-0.1vw'}} /> Done</button>
                    )}
                    {data && (data.created_by == sessionData.user_id || data.created_by == sessionData.tenant_user_id_ext ) && data.status_id == 3 && (
                      <button className={`btn btn-primary ${actionComment == 'Closed' ? 'buttonBlueSelected' : 'buttonBlue'}`} onClick={() => ChangeActionComment("Closed")}> <Cancel style={{height: '1.1vw', marginTop:'-0.1vw'}} />Close</button>
                    )}
                    {data && ((data.fwd_by == sessionData.user_id && data.forwarded == 1) || (data.accept_by == sessionData.user_id && data.forwarded != 1) ) && data.displayType == 2 && (data.status_id == 2) && data.forwarded != 1 && (
                      <button onClick={() => createReferralHandler(data.id)} className="btn btn-primary buttonGreen" > <ArrowTrCircle style={{height: '1.1vw', marginTop:'-0.1vw'}}  /> Create Referral</button> 
                    )}
                    
                  </div>
                  {debugButton && (
                    <div style={{marginLeft:'4vw'}}>
                      DebugButton <br/>
                      <button className={`btn btn-primary ${actionComment == 'Accepted' ? 'buttonBlueSelected' : 'buttonBlue'}`} onClick={() => ChangeActionComment("Accepted")}> <CheckCircle style={{height: '1.1vw', marginTop:'-0.1vw'}} />  Accept</button>
                      <button className={`btn btn-primary ${actionComment == 'Forwarded' ? 'buttonBlueSelected' : 'buttonBlue'}`} onClick={() => ChangeActionComment("Forwarded")}><ArrowEmailForward style={{height: '1.1vw', marginTop:'-0.1vw'}} />  Forward</button>
                      <button className={`btn btn-primary ${actionComment == 'Replied' ? 'buttonBlueSelected' : 'buttonBlue'}`} onClick={() => ChangeActionComment("Replied")} ><ReplyToMessage style={{height: '1.1vw', marginTop:'-0.1vw'}} /> Reply</button>
                      <button className="btn btn-primary buttonGreen" onClick={() => createReferralHandler(data.id)} > <ArrowTrCircle style={{height: '1.1vw', marginTop:'-0.1vw'}}  /> Create Referral</button> 
                      <button className={`btn btn-primary ${actionComment == 'Resolved' ? 'buttonBlueSelected' : 'buttonBlue'}`} onClick={() => ChangeActionComment("Resolved")}><CheckCircle style={{height: '1.1vw', marginTop:'-0.1vw'}} /> Resolve</button>
                      <button className={`btn btn-primary ${actionComment == 'Done' ? 'buttonBlueSelected' : 'buttonBlue'}`} onClick={() => ChangeActionComment("Done")}><CheckCircle style={{height: '1.1vw', marginTop:'-0.1vw'}} /> Done</button>
                      <button className={`btn btn-primary ${actionComment == 'Extended' ? 'buttonBlueSelected' : 'buttonBlue'}`} onClick={() => ChangeActionComment("Extended")}><Timer style={{height: '1.1vw', marginTop:'-0.1vw'}} />Extend</button>
                    <button className={`btn btn-primary ${actionComment == 'Closed' ? 'buttonBlueSelected' : 'buttonBlue'}`} onClick={() => ChangeActionComment("Closed")}> <Cancel style={{height: '1.1vw', marginTop:'-0.1vw'}} />Close</button>
                    </div>
                  )}

                  { actionComment !== "" && (
                    actionComment === "Forwarded" ? (
                      // Render something else for "Forwarded" case
                      <ForwardAddHandler setDeptFwd={PickDropdownDept} setCatFwd={PickDropdownCat} int_or_ext_id={data.int_or_ext_id} action={actionComment} cancelHandler={hideComment} sendHandler={commentHandler} setContent={setCommentValue} priorityId={data.priority_id_manual} />
                    ) : actionComment === "Extended" ? (
                      // Render something else for "Extended" case
                      <ExtendPick setExtendTime={PickExtendDate} int_or_ext_id={data.int_or_ext_id} action={actionComment} cancelHandler={hideComment} sendHandler={commentHandler} setContent={setCommentValue} />
                    ) : actionComment === "Closed" ? (
                      // Render something else for "Extended" case
                      <ClosePick setRating={PickRating} int_or_ext_id={data.int_or_ext_id} action={actionComment} cancelHandler={hideComment} sendHandler={commentHandler} setContent={setCommentValue} pic_name={data.pic_display_name}/>
                    ) : (
                      // Render the default CommentAdd component
                      <>
                        <CommentAdd handleFileChange={handleFileChange} action={actionComment} cancelHandler={hideComment} sendHandler={commentHandler} setContent={setCommentValue} />
                      </>
                    )
                  )}
                  <CommentList data={data} isExpanded={isExpanded} handleImageClick={handleImageClick} handlePdfClick={handlePdfClick}/>
                </div>
              </div>
              <div style={{marginTop:'-2vh'}}>
              <TimeAgo
                time={data.created_at}
                style={{  top: '2.2vw', left: '4.5vw' }}
              /></div>
            </div>
            
           </div>
           
          </div>
      </div>
        
        
        <div className="col-md-1 h-100">
        <div style={{ marginLeft: '1.5vw', position: 'absolute', left: 0, top: '-2.01vw', bottom: 0, borderLeft: '1px solid #E8E9EB', height: '110vh' }}></div>
        </div>

        <div className="col-md-3 text-left h-100" style={{ width: '100%', position: 'relative' }}>
          <div style={{ marginLeft: '-3vw' }}>
          {(data.int_or_ext_id == 1 && data.forwarded == 1) ? (
            <>
              <TicketDropdownShow title="Initial Department" value={data.dept_mgmt_id} label={data.dept_name} />
              <div style={{ marginTop: '1.5vw', marginBottom: '1.5vw' }}> </div>
              <TicketDropdownShow title="Initial Category" value={data.tiket_int_cat_id} label={data.kategori_name} />
              <div style={{ marginTop: '1.5vw', marginBottom: '1.5vw' }}> </div>
              <TicketDropdownShow title="Forward Department" value={data.fwd_dept_mgmt_id} label={data.dept_name_fwd} />
              <div style={{ marginTop: '1.5vw', marginBottom: '1.5vw' }}> </div>
              <TicketDropdownShow title="Forward Category" value={data.fwd_tiket_int_cat_id} label={data.kategori_name_fwd} />
            </>
          ) : (data.int_or_ext_id == 1 && data.forwarded != 1) ? (
            <>
              <TicketDropdownShow title="Department" value={data.dept_mgmt_id} label={data.dept_name} />
              <div style={{ marginTop: '1.5vw', marginBottom: '1.5vw' }}> </div>
              <TicketDropdownShow title="Category" value={data.tiket_int_cat_id} label={data.kategori_name} />
            </>
          ) : (data.int_or_ext_id == 2 && data.forwarded == 1) ? (
            <>
              <TicketDropdownShow title="Issue" value={data.parent_name} label={data.parent_name} />
              <div style={{ marginTop: '1.5vw', marginBottom: '1.5vw' }}> </div>
              <TicketDropdownShow title="Category" value={data.ext_kategory_name} label={data.ext_kategory_name} />
            </>
          ) : (data.int_or_ext_id == 2 && data.forwarded != 1) ? (
            <>
              <TicketDropdownShow title="Issue" value={data.parent_name} label={data.parent_name} />
              <div style={{ marginTop: '1.5vw', marginBottom: '1.5vw' }}> </div>
              <TicketDropdownShow title="Category" value={data.ext_kategory_name} label={data.ext_kategory_name} />
            </>
          ) : null}

            <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
            {data.cc_data && (<TicketCCs ccData={data.cc_data} />)} 
            {data.tiket_referral_id && data.ref_tiket_number && (
             <div style={{marginTop:'1.5vw'}} >
              <ReferralShow title="Ticket Referral" value={data.tiket_referral_id} label={data.ref_tiket_number} />
             </div>
            )}
          </div>
          <div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
      
    </>
  )

    
if (orientation === 'portrait')
return (
<div style={{maxWidth:'96%'}}>
<HeaderPage 
  title1={"Ticket List"}
  link1={"/ticketList"}
  leftArrow={true}
  ticketNumber={data.tiket_number}
/>         
<div style={{marginTop:'2vh'}}>
  <section className="content" style={{ position: 'relative' }}>
    <div className="container-fluid" style={{ position: 'relative' }}>
      <div className='row mx-auto text-center' style={{}}>
      <div className="col-md-11 text-left h-100" style={{ marginLeft:'-3vw'}}>
          <div className='' style={{ paddingBottom:'4vw', borderBottom:'0px solid black', height:'auto'}}>
           <div style={{ marginLeft: '2vw', marginTop: '1.5vw'}}>
            <StatusTicketDetail dueAt={dueAt} typeString={data.status_name} extended ={data.extended} countdown={data.countdown_time} data={data} displayType={data.displayType} star={data.rating} refresh={refreshData}/>
            <MobileDetailTabbing 
              selectedTab={selectedTab}
              handleTabClick={handleTabClick}
            />

            {selectedTab == 'Conversation' && (
            <div style={{ marginTop: '1vh', position: 'relative', marginLeft:'0vw' }} >
            <a href="#" className="no-hover"  data-tooltip-id={"creatorTicketM"} data-tooltip-content={data.display_name} >
              <img
                src={imgSrc}
                alt="Creator Image"
                className="brand-image img-circle"
                style={{ height: "4vh", width:"4vh", marginTop: '0vh', position: 'absolute' }}
                onError={handleImageError}
              />
               <Tooltip id={"creatorTicketM"} place="bottom" type="dark" effect="solid" style={{marginTop:'3vh', marginLeft:'3vw'}}/>
            </a>
              <div style={{ marginTop: '2vh', marginLeft: '0vh' }}>
                <div className="col-md-12" style={{marginLeft:'4vh',paddingBottom:'1vh', marginTop:'3vh'}}>
                  <h5 style={{ fontSize: '2.5vh', fontFamily: 'Blender Pro' , width:'80%', marginTop:'3vh'}}>{data.title}</h5>                  
                </div>
                
                <div style={{ marginLeft:'5vh',marginTop: '2vh', fontSize: '15px', fontFamily: 'Blender Pro' }}>
                <div className="htmlUserInput" style={{fontSize:"2vh"}} dangerouslySetInnerHTML={{ __html: data.konten }}></div>
                </div>
                <div className={isExpanded == 0 ? '' : 'divOverlay'}  onClick={() => handleImageClick(0)}>

                </div>
                <div className='row' style={{ marginTop: '3vw', marginLeft:'8vw'}}>
                  {data.attachment && String(data.attachment).split('|').map((attachment, index) => (
                    <AttachmentHandler
                      key={"atch"+index} 
                      id={index + 1} 
                      isExpanded={isExpanded}
                      source={`${process.env.REACT_APP_IMG_DESTINATION}${attachment}`}
                      handleImageClick={handleImageClick}
                      handlePdfClick={handlePdfClick}
                      fileName={attachment}
                    />
                  ))}
                </div>
                 
                <div style={{marginTop: marginAttachmentM, marginLeft:'-3.5vw', width:'112.5%'}} >
                 {data.status_id !== 4 && (
                  <div style={{marginLeft:'4vw',borderTop:'2px solid #F5F5F6', borderBottom:'2px solid #F5F5F6', padding:'0vh 0vw 2vh 0vw'}}>
                    {data && (data.displayType == 2 || data.displayType == 3) && data.forwarded == 1 && (
                      <ForwardDisplay data={data}/>
                    )}
                    {(data.displayType == 2) && (data.status_id == 1 ) && (
                      <button style={{marginRight:'2vw', marginTop:'2vh'}} className={`btn btn-primary ${actionComment == 'Accepted' ? 'buttonBlueSelected' : 'buttonBlue'}`} onClick={() => ChangeActionComment("Accepted")}> <CheckCircle style={{height: '2vh', marginTop:'-0.1vw'}} />  Accept</button>
                    )}
                    {((data.displayType == 3) && (data.status_id == 6)) && (
                      <button style={{marginRight:'2vw', marginTop:'2vh'}} className={`btn btn-primary ${actionComment == 'Accepted' ? 'buttonBlueSelected' : 'buttonBlue'}`} onClick={() => ChangeActionComment("Accepted")}> <CheckCircle style={{height: '12vh', marginTop:'-0.1vw'}} />  Accept</button>
                    )}
                    {data && data.displayType == 2 && data.status_id == 1 && (
                      <button style={{marginRight:'2vw', marginTop:'2vh'}} className={`btn btn-primary ${actionComment == 'Forwarded' ? 'buttonBlueSelected' : 'buttonBlue'}`} onClick={() => ChangeActionComment("Forwarded")}><ArrowEmailForward style={{height: '2vh', marginTop:'-0.1vw'}} />  Forward</button>
                    )}
                    {data && data.status_id != 4 && (data.displayType == 1 || data.displayType == 2 || data.displayType == 3) && (
                      <button style={{marginRight:'2vw', marginTop:'2vh'}} className={`btn btn-primary ${actionComment == 'Replied' ? 'buttonBlueSelected' : 'buttonBlue'}`} onClick={() => ChangeActionComment("Replied")} ><ReplyToMessage style={{height: '2vh', marginTop:'-0.1vw'}} /> Reply</button>
                    )}
                    {data && ((data.accept_by == sessionData.user_id ) ) && ((data.displayType == 2 && data.forwarded != 1) || (data.displayType == 3 && data.forwarded == 1)) && data.status_id == 2 && data.extended == 0 && (
                      <button style={{marginRight:'2vw', marginTop:'2vh'}} className={`btn btn-primary ${actionComment == 'Extended' ? 'buttonBlueSelected' : 'buttonBlue'}`} onClick={() => ChangeActionComment("Extended")}><Timer style={{height: '2vh', marginTop:'-0.1vw'}} />Extend</button>
                    )}
                    {data && ((data.fwd_by == sessionData.user_id && data.forwarded == 1) || (data.accept_by == sessionData.user_id && data.forwarded != 1) ) && data.displayType == 2 && ((data.status_id == 2 && data.forwarded != 1)|| (data.status_id == 5 && data.forwarded == 1)) && (
                      <button style={{marginRight:'2vw', marginTop:'2vh'}} className={`btn btn-primary ${actionComment == 'Resolved' ? 'buttonBlueSelected' : 'buttonBlue'}`} onClick={() => ChangeActionComment("Resolved")}><CheckCircle style={{height: '2vh', marginTop:'-0.1vw'}} /> Resolve</button>
                    )}
                    {data && data.displayType == 3 && (data.status_id == 2) && data.forwarded == 1 && (
                      <button style={{marginRight:'2vw', marginTop:'2vh'}} className={`btn btn-primary ${actionComment == 'Done' ? 'buttonBlueSelected' : 'buttonBlue'}`} onClick={() => ChangeActionComment("Done")}><CheckCircle style={{height: '2vh', marginTop:'-0.1vw'}} /> Done</button>
                    )}
                    {data && (data.created_by == sessionData.user_id || data.created_by == sessionData.tenant_user_id_ext ) && data.status_id == 3 && (
                      <button style={{marginRight:'2vw', marginTop:'2vh'}} className={`btn btn-primary ${actionComment == 'Closed' ? 'buttonBlueSelected' : 'buttonBlue'}`} onClick={() => ChangeActionComment("Closed")}> <Cancel style={{height: '2vh', marginTop:'-0.1vw'}} />Close</button>
                    )}
                    {data && ((data.fwd_by == sessionData.user_id && data.forwarded == 1) || (data.accept_by == sessionData.user_id && data.forwarded != 1) ) && data.displayType == 2 && (data.status_id == 2) && data.forwarded != 1 && (
                      <button style={{marginRight:'2vw', marginTop:'2vh'}} onClick={() => createReferralHandler(data.id)} className="btn btn-primary buttonGreen" > <ArrowTrCircle style={{height: '2vh', marginTop:'-0.1vw'}}  /> Create Referral</button> 
                    )}
                    
                  </div>
                 )}

                 {data.status_id == 4 && (
                  <div style={{marginLeft:'4vw',borderBottom:'2px solid #F5F5F6' ,padding:'1vh 0vw 1vh 0vw'}}>

                  </div>
                 )}

                  {debugButton && (
                    <div style={{marginLeft:'4vw'}}>
                      DebugButton <br/>
                      <button className={`btn btn-primary ${actionComment == 'Accepted' ? 'buttonBlueSelected' : 'buttonBlue'}`} onClick={() => ChangeActionComment("Accepted")}> <CheckCircle style={{height: '2vh', marginTop:'-0.1vw'}} />  Accept</button>
                      <button className={`btn btn-primary ${actionComment == 'Forwarded' ? 'buttonBlueSelected' : 'buttonBlue'}`} onClick={() => ChangeActionComment("Forwarded")}><ArrowEmailForward style={{height: '2vh', marginTop:'-0.1vw'}} />  Forward</button>
                      <button className={`btn btn-primary ${actionComment == 'Replied' ? 'buttonBlueSelected' : 'buttonBlue'}`} onClick={() => ChangeActionComment("Replied")} ><ReplyToMessage style={{height: '2vh', marginTop:'-0.1vw'}} /> Reply</button>
                      <button className="btn btn-primary buttonGreen" onClick={() => createReferralHandler(data.id)} > <ArrowTrCircle style={{height: '2vh', marginTop:'-0.1vw'}}  /> Create Referral</button> 
                      <button className={`btn btn-primary ${actionComment == 'Resolved' ? 'buttonBlueSelected' : 'buttonBlue'}`} onClick={() => ChangeActionComment("Resolved")}><CheckCircle style={{height: '2vh', marginTop:'-0.1vw'}} /> Resolve</button>
                      <button className={`btn btn-primary ${actionComment == 'Done' ? 'buttonBlueSelected' : 'buttonBlue'}`} onClick={() => ChangeActionComment("Done")}><CheckCircle style={{height: '2vh', marginTop:'-0.1vw'}} /> Done</button>
                      <button className={`btn btn-primary ${actionComment == 'Extended' ? 'buttonBlueSelected' : 'buttonBlue'}`} onClick={() => ChangeActionComment("Extended")}><Timer style={{height: '2vh', marginTop:'-0.1vw'}} />Extend</button>
                      <button className={`btn btn-primary ${actionComment == 'Closed' ? 'buttonBlueSelected' : 'buttonBlue'}`} onClick={() => ChangeActionComment("Closed")}> <Cancel style={{height: '2vh', marginTop:'-0.1vw'}} />Close</button>
                    </div>
                  )}

                  { actionComment !== "" && (
                    actionComment === "Forwarded" ? (
                      // Render something else for "Forwarded" case
                      <ForwardAddHandler setDeptFwd={PickDropdownDept} setCatFwd={PickDropdownCat} int_or_ext_id={data.int_or_ext_id} action={actionComment} cancelHandler={hideComment} sendHandler={commentHandler} setContent={setCommentValue} priorityId={data.priority_id_manual} />
                    ) : actionComment === "Extended" ? (
                      // Render something else for "Extended" case
                      <ExtendPick setExtendTime={PickExtendDate} int_or_ext_id={data.int_or_ext_id} action={actionComment} cancelHandler={hideComment} sendHandler={commentHandler} setContent={setCommentValue} />
                    ) : actionComment === "Closed" ? (
                      // Render something else for "Extended" case
                      <ClosePick setRating={PickRating} int_or_ext_id={data.int_or_ext_id} action={actionComment} cancelHandler={hideComment} sendHandler={commentHandler} setContent={setCommentValue} pic_name={data.pic_display_name}/>
                    ) : (
                      // Render the default CommentAdd component
                      <>
                        <CommentAdd handleFileChange={handleFileChange} action={actionComment} cancelHandler={hideComment} sendHandler={commentHandler} setContent={setCommentValue} />
                      </>
                    )
                  )}
                  <br/><br/>
                  <div style={{width:'100%', marginTop:'-2vh'}}>
                  <CommentList data={data} isExpanded={isExpanded} handleImageClick={handleImageClick} handlePdfClick={handlePdfClick}/>
                  </div>
                </div>
              </div>
              <div style={{  marginRight:'-5vw !important'}}>
              <TimeAgo
                time={data.created_at}
                right={'-8vw'}
              />
              </div>
            </div>

            )}

            {selectedTab == 'Details' && (
              <div style={{ marginLeft: '1vw', width:'108%', marginTop:'2vh' }}>
              {(data.int_or_ext_id == 1 && data.forwarded == 1) ? (
                <>
                  <TicketDropdownShow title="Initial Department" value={data.dept_mgmt_id} label={data.dept_name} />
                  <div style={{ marginTop: '1vh', marginBottom: '1vh' }}> </div>
                  <TicketDropdownShow title="Initial Category" value={data.tiket_int_cat_id} label={data.kategori_name} />
                  <div style={{ marginTop: '1vh', marginBottom: '1vh' }}> </div>
                  <TicketDropdownShow title="Forward Department" value={data.fwd_dept_mgmt_id} label={data.dept_name_fwd} />
                  <div style={{ marginTop: '1vh', marginBottom: '1vh' }}> </div>
                  <TicketDropdownShow title="Forward Category" value={data.fwd_tiket_int_cat_id} label={data.kategori_name_fwd} />
                </>
              ) : (data.int_or_ext_id == 1 && data.forwarded != 1) ? (
                <>
                  <TicketDropdownShow title="Department" value={data.dept_mgmt_id} label={data.dept_name} />
                  <div style={{ marginTop: '1vh', marginBottom: '1vh' }}> </div>
                  <TicketDropdownShow title="Category" value={data.tiket_int_cat_id} label={data.kategori_name} />
                </>
              ) : (data.int_or_ext_id == 2 && data.forwarded == 1) ? (
                <>
                  <TicketDropdownShow title="Issue" value={data.parent_name} label={data.parent_name} />
                  <div style={{ marginTop: '1vh', marginBottom: '1vh' }}> </div>
                  <TicketDropdownShow title="Category" value={data.ext_kategory_name} label={data.ext_kategory_name} />
                </>
              ) : (data.int_or_ext_id == 2 && data.forwarded != 1) ? (
                <>
                  <TicketDropdownShow title="Issue" value={data.parent_name} label={data.parent_name} />
                  <div style={{ marginTop: '1vh', marginBottom: '1vh' }}> </div>
                  <TicketDropdownShow title="Category" value={data.ext_kategory_name} label={data.ext_kategory_name} />
                </>
              ) : null}
    
                <div style={{marginTop:'1vh', marginBottom:'1vh' }}> </div>
                {data.cc_data && (<TicketCCs ccData={data.cc_data} />)} 
                {data.tiket_referral_id && data.ref_tiket_number && (
                 <div style={{marginTop:'1vh'}} >
                  <ReferralShow title="Ticket Referral" value={data.tiket_referral_id} label={data.ref_tiket_number} />
                 </div>
                )}

                <TicketDropdownShow title="Created At" value={data.created_at} label={formatDate(data.created_at)} />
                <div style={{ marginTop: '1vh', marginBottom: '1vh' }}> </div>
                <TicketDropdownShow title="Accepted At" value={data.accepted_at} label={formatDate(data.accepted_at)} />
                <div style={{ marginTop: '1vh', marginBottom: '1vh' }}> </div>
                <TicketDropdownShow title="Done At" value={data.resolved_at} label={formatDate(data.resolved_at)} />
                <div style={{ marginTop: '1vh', marginBottom: '1vh' }}> </div>
                <TicketDropdownShow title="Closed At" value={data.closed_at} label={formatDate(data.closed_at)} />
              </div>
            )}
           </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
    </div>
  )
}

export default TicketDetails