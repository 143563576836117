import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PdfEditor() {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  function handleDrag(event) {
    event.preventDefault();
    setPosition({ x: event.clientX, y: event.clientY });
  }

  function handleSubmit(event) {
    console.log(`Submit button clicked at x=${position.x}, y=${position.y}`);
  }

  return (
    <>
      <Document file="./existing.pdf">
        <Page 
        pageNumber={1} 
        renderTextLayer={false} 
        renderMode={'svg'} 
        renderAnnotationLayer={false} 
        />
      </Document>
      <img
      src="approve.png"
      alt="draggable"
      draggable="true"
      onDrag={handleDrag}
      style={{ position: 'absolute', left: position.x, top: position.y }}
    />
      <button onClick={handleSubmit}>Submit</button>
    </>
  );
}
export default PdfEditor