import React from 'react';
import { useState, useEffect } from 'react';
import 'react-form-builder2/dist/app.css';
import { useParams } from 'react-router-dom';
import Cookies from 'universal-cookie'; 
import axios from 'axios' ;
import ContentHeader from '../../content/ContentHeader';
import FormToHtmlDouble from './FormToHtmlDouble';
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal)
const cookies = new Cookies();

function FormSubmit() {
  const { id, deptId, docName, dadID } = useParams();
  const [formData, setformData] = useState([]);
  const [dataReady, setDataReady] = useState(false);
  const [dataValue, setDataValue] = useState([]);
  const [fileObjects, setFileObjects] = useState([]);
  const [rulesValue, setRulesValue] = useState([]);
  const [lockedDoc, setLockedDoc] = useState(false);

  useEffect(() => {
    getData();
  }, [])

  const getData = () => {
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    var path = process.env.REACT_APP_PATH_DESTINATION + "/viewDocMasterDemoDAD/" + dadID;   
    const config = {headers:{['x-access-token']: sessionToken}};
    axios.get(path , config)
      .then(res => {
        //console.log("res.data.data.doc_autofill");
        //console.log(res.data.data.doc_autofill);
        if (res.data.data.doc_autofill && String(res.data.data.doc_autofill) != "{}") {
          setDataValue(res.data.data.doc_autofill);
        }
        if (res.data.data.doc_rules && String(res.data.data.doc_rules) != "{}") {
          setRulesValue(res.data.data.doc_rules);
        }
        if (res.data.data.doc_content && String(res.data.data.doc_content) != "{}") {
          console.log("enter if");
          setformData(res.data.data.doc_content);
          setDataReady(true);
        } else {
          console.log("enter else");
          setDataReady(true);
        }

        //calculation for locked user ID
        const docLockedToUserId = res.data.data.doc_locked_to_user_id;
        var data = cookies.get('sessionData', { path: '/' })
        const userId = data.user_id;

        if (docLockedToUserId == "0") {
          setLockedDoc(false); // unlock doc, no locked ID
        } else {
          const numbersArray = docLockedToUserId.split(",");
          const isUserIdIncluded = numbersArray.includes(userId);
        
          if (isUserIdIncluded) {
            setLockedDoc(false); // unlock doc
          } else {
            setLockedDoc(true); // lock doc, numbers not included
          }
        }
        Swal.close()
      })
      .catch(err => {
        console.log(err);
        Swal.close()
      })
  }    

  const onReupload = (event, fieldName) => {
    event.preventDefault();
    Swal.fire({
      title: 'Clearing Data...',
      didOpen: () => {
        Swal.showLoading()
      },
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false
    })
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    var path = process.env.REACT_APP_PATH_DESTINATION + "/reuploadDocMasterDemo/";
    var data = {
      id: id,
      fieldName: fieldName
    }   
    const config = {headers:{['x-access-token']: sessionToken}};
    axios.post(path , data={data}, config)
      .then(res => {
        console.log(res.data);
        getData();
      })
      .catch(err => {
        console.log(err);
        getData();
      })
  }

  const onSubmit = (event) => {
    event.preventDefault();
    Swal.fire({
      title: 'Uploading to Server',
      didOpen: () => {
        Swal.showLoading()
      },
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false
    })
    const form = event.target;
    
    const requestBody = new FormData(form);
    requestBody.append('dadID', dadID);
    requestBody.append('deptId', deptId);
    if (!fileObjects) {
      console.log('File input is empty');
    } else {
      for (const fileObject of fileObjects) {
        //const elementNames = requestBody.getAll('elementNames');
        
        //requestBody.append('elementNames', fileObject.elementName);
        requestBody.append('file', fileObject.file);
        requestBody.append(fileObject.elementName, fileObject.file.name);
      }
    }
    for (const element of form.elements) {
      if (element.name && element.type === 'file') {
        //do nothing, alr handled above
      } else if (element.name == "elementNames" ) {
        const elementNames = new Set(requestBody.getAll('elementNames')); // Convert to a Set
        elementNames.add(element.value); // Add element value to the Set
        requestBody.set('elementNames', Array.from(elementNames).join(',')); // Convert back to an array and join as a single value
      } else if (element.name) {
        if (element.type === 'radio') {
          if (element.checked) {
            requestBody.set(element.name, element.value);
          }
        } else if (element.type === 'checkbox') {
          if (element.checked) {
            if (!requestBody.has(element.name)) {
              requestBody.set(element.name, [element.value]);
            } else {
              const values = requestBody.getAll(element.name);
              values.push(element.value);
              requestBody.set(element.name, values);
            }
          }
        } else {
          console.log("name = " + element.name + " value = " + element.value);
          requestBody.set(element.name, element.value);
        }
      }
    }

    //delete dupes 
    try{
      const elementNames = requestBody.get('elementNames');
      const uniqueElementNames = [...new Set(elementNames.split(','))];
      requestBody.set('elementNames', uniqueElementNames.join(','));  
    } catch {
      //do nothing, eleemnt just doesnt exist
    }
    
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    var path = process.env.REACT_APP_PATH_DESTINATION + "/SubmitValueDoc" ;   
    const config = {headers:{['x-access-token']: sessionToken}};
    axios.post(path ,requestBody, config)
    .then(function (response) {
      if (response.data.status == '200')
        MySwal.fire({
          title: <strong>Success!</strong>,
          html: <p>{response.data.message}</p>,
          icon: 'success'
        }).then(() => {
          window.location.replace("/");
        })
      else 
        MySwal.fire({
          title: <strong>Failed!</strong>,
          html: <p>{response.data.message}</p>,
          icon: 'error'
        }).then(() => {
          window.location.reload(true);
        })
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const handleFileUpload = (event) => {
    const newFiles = event.target.files;
    const elementName = event.target.name;
    const newFileObjects = Array.from(newFiles).map(file => ({ file, elementName }));
  
    setFileObjects(prevFileObjects => {
      const index = prevFileObjects.findIndex(fileObject => fileObject.elementName === elementName);
      if (index !== -1) {
        prevFileObjects[index] = newFileObjects[0];
        return [...prevFileObjects];
      } else {
        return [...prevFileObjects, ...newFileObjects];
      }
    });
  };

  if (!dataReady) {
    return <ContentHeader title="Loading Attachment..."/>;
  } else {
    //console.log(dataValue);
    return (
      <>
        <ContentHeader title={"Create " + docName} />
        <section className="content">
          <div className="container-fluid">
            <div className="">
              <form onSubmit={(event) => onSubmit(event)} encType="multipart/form-data">
                <FormToHtmlDouble levelFiller={"0"} formData={formData} dataValue={dataValue} rulesValue={rulesValue} onFileUpload={handleFileUpload} onReupload={onReupload} />
                {!lockedDoc && <button className="btn btn-primary">Submit</button>}
              </form>
              <br />
            </div>
          </div>
        </section>
      </>
    );    
  }
}

export default FormSubmit;