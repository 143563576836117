import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Cookies from 'universal-cookie';
import axios from 'axios' 
import useOrientation from '../../general/useOrientation';
const cookies = new Cookies();

const TicketDropdownShow = ({title,value,label,isMulti=false}) => {
  const [options, setOptions] = useState();
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait')
    isThisPortrait = true;
  useEffect(() => {
    setOptions([{
      value: value,
      label: label,
    },]);
  }, [value,label])

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: '8px',
      padding: '-2vw -5vw -2vw -5vw',
      fontSize: '15px',
      width: isThisPortrait? '100%': '95%',
      border: '0px',
      backgroundColor: '#F5F5F6',
      color: '#FFFFFF',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#0296D2' : '#fff', // Change the color here
      color: state.isSelected ? '#fff' : '#404041',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#404041',
      fontFamily : 'Blender Pro',
      fontSize:'15px'
    }),
    menu: (provided) => ({
      ...provided,
      width: isThisPortrait? '100%':'95%',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#D6EBF6', 
      borderRadius: '8px'
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#01648C', 
      fontFamily: 'Blender Pro',
      fontSize:'15px'
    }),
  };
  const DropdownIndicator = () => null;
  return (
    <>
    {title && (
      <div style={{marginTop: '1vw'}}>
        <p style={{fontFamily: 'Blender Pro', fontSize:'15px'}}>{title}</p>
      </div>
    )}
    <div style={{marginTop:'0.5vw'}}>
      <Select
        isMulti={isMulti}
        id="dropdown"
        value={options}
        options={options}
        placeholder="Select"
        styles={customStyles}
        isDisabled={true}
        components={{
          DropdownIndicator,
        }}
      />

       </div>
       </>
  );
};

export default TicketDropdownShow;
