import React from 'react' ; import axios from 'axios' ;
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
import { useParams } from 'react-router-dom';
import DocDashList from './DocDashList';
import docDashDetails from './docDashDetails';
import Cookies from 'universal-cookie'; 
import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';
const cookies = new Cookies();
const MySwal = withReactContent(Swal)
function DocDashMaster() {
    const { type} = useParams();
    const { deptIDChosen } = useParams();
    const { deptNameChosen } = useParams();
    const [backCode, setbackCode] = useState("0");
    useEffect(() => {
        switch(parseInt(type)){
            case 1:
                setbackCode("1"); break;
            case 2:
            case 6:
                setbackCode("4"); break;
            case 3:
            case 7:
                setbackCode("2"); break;
            case 4:
                setbackCode("3"); break;
            case 5:
                setbackCode("6"); break;
            case 8:
                setbackCode("5"); break;
        }
    })
    const handleAction = (data, link) => {
        const sessionToken = cookies.get('sessionToken', { path: '/' })
        const config = { headers: {['x-access-token']: sessionToken } }
        if (link === 'docApprove') {
            handleDocApprove(data, config)
        } else {
            handleOtherAction(data, link, config)
        }
    }
      
      const handleDocApprove = (data, config) => {
        const path = process.env.REACT_APP_PATH_DESTINATION + '/docCheck'
      
        axios.post(path, { data }, config)
          .then(response => {
            console.log(response.data);
            if (response.data.status === '200') {
              showWarningModal(response.data, data, config)
            } else {
              handleOtherAction(data, 'docApprove', config)
            }
          })
          .catch(error => {
            console.log(error);
            showErrorModal()
          })
      }
      
      const handleOtherAction = (data, link, config) => {
        const path = process.env.REACT_APP_PATH_DESTINATION + '/' + link
        axios.post(path, { data }, config)
          .then(response => {
            if (response.data.status === '200') {
              showSuccessModal(response.data.message)
            } else {
              showErrorModal(response.data.message)
            }
          })
          .catch(error => {
            console.log(error);
            showErrorModal()
          })
      }
      
      const showWarningModal = (response, dataa, config) => {
        console.log("showWarningModal")
        let tableData = '';
        let message = response.message;
        let data2 = response.data;
        console.log(response)
        for (let i = 0; i < data2.length; i++) {
          const updatedAt = new Date(data2[i].updated_at).toLocaleString('en-US', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
          });
          tableData += `<tr>
                          <td style="text-align:left;">${data2[i].doc_name}</td>
                          <td style="text-align:left;">${updatedAt}</td>
                        </tr>`;
        }
        const swalHTML = `
        <div>
            <p style="text-align:left;">${message}</p>
            <table style="width: 700px;">
            <thead>
                <tr>
                <th style="text-align:left;">Document Name</th>
                <th style="text-align:left;">Last Update</th>
                </tr>
            </thead>
            <tbody>
                ${tableData}
            </tbody>
            </table>
        </div>
        `;
      
        MySwal.fire({
            title: '<strong>Warning</strong>',
            html: swalHTML,
            icon: 'warning',
            width:'800px',
            showCancelButton: true,
            cancelButtonText: 'Back',
            confirmButtonText: 'Proceed'
          }).then(result => {
            if (result.isConfirmed) {
              // Handle the "Proceed" button click
              handleOtherAction(dataa, 'docApprove', config)  
            } else {
              MySwal.close()
            }
          })
      }
      

      const showSuccessModal = (message) => {
        MySwal.fire({
          title: '<strong>Success!</strong>',
          html: '<p>' + message + '</p>',
          icon: 'success'
        }).then(() => {
          window.location.reload(true)
        })
      }
      
      const showErrorModal = (message) => {
        MySwal.fire({
          title: '<strong>Error!</strong>',
          html: '<p>' + (message || 'Something went wrong. Please contact the administrator.') + '</p>',
          icon: 'error'
        })
      }

    const approveFormatter = (cell, row, rowIndex, formatExtraData)=> {
        return <>
        <button title="Approve" onClick={() => handleAction(row,"docApprove")} className="btn btn-success">
        <i className="fa fa-check"></i>
        </button>
        </>
    }
       
    const docFormatter = (cell, row, rowIndex, formatExtraData) => {
        return <Link to={"/showDoc/" + row.id + "/" + backCode}>
                    <button title="Show Docs" className="btn btn-info">
                        <i className="fa fa-file"></i>
                    </button>
                </Link>
    }

    const cancelFormatter = (cell, row, rowIndex, formatExtraData) => {
        return <>
        <button title="Reject" onClick={() => 
            MySwal.fire({
                title: <strong>Enter Cancel Reason</strong>,
                html: <input type="text" id="reject_reason" className="swal2-input" placeholder="Enter Cancel Reason"></input>,
                confirmButtonText: 'Submit',
                focusConfirm: false,
                preConfirm: () => {
                    const reject_reason = Swal.getPopup().querySelector('#reject_reason').value
                    if (!reject_reason ) {
                    Swal.showValidationMessage(`Please enter cancel reason`)
                    }
                    return { reject_reason: reject_reason }
                }
            }).then((result) => {
                row.reject_reason = result.value.reject_reason
                handleAction(row,"docCancel")
            })
        } 
        className="btn btn-danger">
        <i className="fa fa-times"></i>
        </button>
        </>
    }

    const rejectFormatter = (cell, row, rowIndex, formatExtraData)=> {
        return <>
        <button title="Reject" onClick={() => 
            MySwal.fire({
                title: <strong>Enter Reject Reason</strong>,
                html: <input type="text" id="reject_reason" className="swal2-input" placeholder="Enter Reject Reason"></input>,
                confirmButtonText: 'Submit',
                focusConfirm: false,
                preConfirm: () => {
                    const reject_reason = Swal.getPopup().querySelector('#reject_reason').value
                    if (!reject_reason ) {
                    Swal.showValidationMessage(`Please enter reject reason`)
                    }
                    return { reject_reason: reject_reason }
                }
            }).then((result) => {
                row.reject_reason = result.value.reject_reason
                handleAction(row,"docReject")
            })
        } 
        className="btn btn-danger">
        <i className="fa fa-times"></i>
        </button>
        </>
    }
 

    const ApprovalToFormatter = (data,row) => {
        if (data == "Everyone in BeFa") 
            return "-"
        else 
            return data
    }
    
    const dateFormatter = (data,row) => {
        let a = new Date(data);
        let b = String(a);
        b = b.substring(0,24);
        return b;
    }

    const statusFormatter = (data,row) => {
        var dataSession = cookies.get('sessionData', { path: '/' })
        if (data == "1") 
          return (<p style={{color:'green'}}>Approved</p>)
        else if (data == "2") 
          return (<p style={{color:'red'}}>Rejected</p>)
        else if (data == "3") 
          return (<p style={{color:'red'}}>Cancelled</p>)
        else if (data == "0") {
            if(row.approval_to == dataSession.user_id){
                return (<p style={{color:'red'}}>Waiting Approval</p>)
            } else {
                return (<p style={{color:'blue'}}>On Progress</p>)
            }
        }
        else
          return data 
    }

    const columnsPending = [
        {
            text: 'No.',
            dataField: 'sl.no',
            formatter: (cell, row, rowIndex, formatExtraData) => {
            return rowIndex + 1;
            },
            sort: true,
        },
        {
            text: 'Document Name',
            dataField: 'doc_name',
            sort:true
        },
        {
            text: 'Document Number',
            dataField: 'doc_number',
            sort:true
        },
        {
            text: 'Approval To',
            dataField: 'apv_fullname',
            sort:true,
            formatter: ApprovalToFormatter
        },
        {
            text: 'Requested By',
            dataField: 'req_fullname',
            sort:true
        },
        {
            text: 'Last Update',
            dataField: 'created_at',
            sort:true,
            formatter: dateFormatter
        },
        {
            text: 'Doc',
            dataField: 'approved_at',
            sort:true,
            formatter: docFormatter
        },
        {
            text: 'Details',
            dataField: 'approved_by',
            sort:true,
            formatter: docDashDetails.detailsFormatter
        },
        {
            text: 'department_id',
            dataField: 'department_id',
            sort:true,
            hidden: true
        }
    ];

    const columnsStandard = [
        {
            text: 'No.',
            dataField: 'sl.no',
            formatter: (cell, row, rowIndex, formatExtraData) => {
            return rowIndex + 1;
            },
            sort: true,
        },
        {
            text: 'Document Name',
            dataField: 'doc_name',
            sort:true
        },
        {
            text: 'Document Number',
            dataField: 'doc_number',
            sort:true
        },
        {
            text: 'Approval To',
            dataField: 'apv_fullname',
            sort:true,
            formatter: ApprovalToFormatter
        },
        {
            text: 'Requested By',
            dataField: 'req_fullname',
            sort:true
        },
        {
            text: 'Last Update',
            dataField: 'created_at',
            sort:true,
            formatter: dateFormatter
        },
        {
            text: 'Doc',
            dataField: 'approved_at',
            sort:true,
            formatter: docFormatter
        },
        {
            text: 'Details',
            dataField: 'approved_by',
            sort:true,
            formatter: docDashDetails.detailsFormatter
        },
        {
            text: 'department_id',
            dataField: 'department_id',
            sort:true,
            hidden: true
        }
    ];

    const columnsCancel = [
        {
            text: 'No.',
            dataField: 'sl.no',
            formatter: (cell, row, rowIndex, formatExtraData) => {
            return rowIndex + 1;
            },
            sort: true,
        },
        {
            text: 'Document Name',
            dataField: 'doc_name',
            sort:true
        },
        {
            text: 'Document Number',
            dataField: 'doc_number',
            sort:true
        },
        {
            text: 'Approval To',
            dataField: 'apv_fullname',
            sort:true,
            formatter: ApprovalToFormatter
        },
        {
            text: 'Requested By',
            dataField: 'req_fullname',
            sort:true
        },
        {
            text: 'Last Update',
            dataField: 'created_at',
            sort:true,
            formatter: dateFormatter
        },
        {
            text: 'Doc',
            dataField: 'approved_at',
            sort:true,
            formatter: docFormatter
        },
        {
            text: 'Details',
            dataField: 'approved_by',
            sort:true,
            formatter: docDashDetails.detailsFormatter
        },
        {
            text: 'Cancel',
            dataField: 'current_level',
            sort:true,
            formatter: cancelFormatter
        },
        {
            text: 'department_id',
            dataField: 'department_id',
            sort:true,
            hidden: true
        }
    ];
        
    const columnsApproved = [
        {
            text: 'No.',
            dataField: 'sl.no',
            formatter: (cell, row, rowIndex, formatExtraData) => {
            return rowIndex + 1;
            },
            sort: true,
        },
        {
            text: 'Document Name',
            dataField: 'doc_name',
            sort:true
        },
        {
            text: 'Document Number',
            dataField: 'doc_number',
            sort:true
        },
        {
            text: 'Document Status',
            dataField: 'approval_status',
            sort:true,
            formatter: statusFormatter
        },
        {
            text: 'Requested By',
            dataField: 'req_fullname',
            sort:true
        },
        {
            text: 'Last Update',
            dataField: 'created_at',
            sort:true,
            formatter: dateFormatter
        },
        {
            text: 'Doc',
            dataField: 'approved_at',
            sort:true,
            formatter: docFormatter
        },
        {
            text: 'Details',
            dataField: 'approved_by',
            sort:true,
            formatter: docDashDetails.detailsFormatter
        },
        {
            text: 'department_id',
            dataField: 'department_id',
            sort:true,
            hidden: true
        }
    ];
    
    const columnsRejected = [
        {
            text: 'No.',
            dataField: 'sl.no',
            formatter: (cell, row, rowIndex, formatExtraData) => {
            return rowIndex + 1;
            },
            sort: true,
        },
        {
            text: 'Document Name',
            dataField: 'doc_name',
            sort:true
        },
        {
            text: 'Document Number',
            dataField: 'doc_number',
            sort:true
        },
        {
            text: 'Document Status',
            dataField: 'approval_status',
            sort:true,
            formatter: statusFormatter
        },
        {
            text: 'Reject Reason',
            dataField: 'reject_reason',
            sort:true
        },
        {
            text: 'Requested By',
            dataField: 'req_fullname',
            sort:true
        },
        {
            text: 'Last Update',
            dataField: 'created_at',
            sort:true,
            formatter: dateFormatter
        },
        {
            text: 'Doc',
            dataField: 'approved_at',
            sort:true,
            formatter: docFormatter
        },
        {
            text: 'Details',
            dataField: 'approved_by',
            sort:true,
            formatter: docDashDetails.detailsFormatter
        },
        {
            text: 'department_id',
            dataField: 'department_id',
            sort:true,
            hidden: true
        }
    ];    
    
    const columnsAll = [
        {
            text: 'No.',
            dataField: 'sl.no',
            formatter: (cell, row, rowIndex, formatExtraData) => {
            return rowIndex + 1;
            },
            sort: true,
        },
        {
            text: 'Document Name',
            dataField: 'doc_name',
            sort:true
        },
        {
            text: 'Document Number',
            dataField: 'doc_number',
            sort:true
        },
        {
            text: 'Document Status',
            dataField: 'approval_status',
            sort:true,
            formatter: statusFormatter
        },
        {
            text: 'Requested By',
            dataField: 'req_fullname',
            sort:true
        },
        {
            text: 'Last Update',
            dataField: 'created_at',
            sort:true,
            formatter: dateFormatter
        },
        {
            text: 'Doc',
            dataField: 'approved_at',
            sort:true,
            formatter: docFormatter
        },
        {
            text: 'Details',
            dataField: 'approved_by',
            sort:true,
            formatter: docDashDetails.detailsFormatter
        },
        {
            text: 'department_id',
            dataField: 'department_id',
            sort:true,
            hidden: true
        }
    ];
    var cols; var txt = "";
    switch(parseInt(type)){
        case 1:
            txt = "Pending"; cols = columnsPending;  break;
        case 2:
        case 6:
            txt = "On Progress"; cols = columnsStandard; break;
        case 3:
        case 7:
            txt = "Accepted"; cols = columnsApproved; break;
        case 4:
            txt = "Rejected"; cols = columnsRejected; break;
        case 5:
            txt = "Requested"; cols = columnsCancel; break;
        case 8:
            txt = "All"; cols = columnsAll; break;
    }
    return (
        <DocDashList type={type} deptIDChosen={deptIDChosen} deptNameChosen={deptNameChosen} statusText={txt} columns={cols}/>
    )
}

export default DocDashMaster