import Cookies from 'universal-cookie';
import ContentHeader from '../content/ContentHeader'
const cookies = new Cookies();

const Settings = () => {
  var data = cookies.get('sessionData', { path: '/' })
  console.log(data);
  return (
  <>
    <ContentHeader title="Dashboard"/> 
    <section className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <h3> 
              User ID : {data.user_id} <br/>
              Username : {data.username} <br/>
              Fullname : {data.fullname} <br/>
              Location ID : {data.location_id} <br/>
              Location Name : {data.location_name} <br/>
              Apps Role ID : {data.apps_roles_id} <br/>
              Role Name : {data.role_name} <br/>
              <br/>
              Department : <br/><br/>
              {data.department.map((dept) => (
                <p>
                  department_id : {dept.department_id} <br/>
                  kode_department : {dept.kode_department} <br/>
                  nama_department : {dept.nama_department} <br/>
                  department_roles_id : {dept.department_roles_id} <br/>
                  department_roles_name : {dept.department_roles_name} <br/>
                </p>
              ))}
             </h3>
          </div>
        </div>
      </div>
    </section>
  </>
  )
  };
  
  export default Settings;