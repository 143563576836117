import React, { useState, useEffect }  from 'react';
import { NavArrowRight } from 'iconoir-react';
import useOrientation from '../../general/useOrientation';

function FilterLevelOne({currentState, setCurrentState,state,text,changeLevelTwo,setSecondLevel,enabled=true}) {
  // Conditionally assign the class based on the current state
  const outerDivClass = currentState === state ? 'outerTenantDiv outerClickedClass' : 'outerTenantDiv';
  const outerDivClassMobile = currentState === state ? 'outerTenantDiv outerClickedClassMobile' : 'outerTenantDiv';
  const handleClick = () => {
    changeLevelTwo(text);
    setSecondLevel(true);
    setCurrentState(state);
  };
  const orientation = useOrientation();
  if (orientation === 'landscape')
  return (
    <div style={{marginTop:'0.5vw'}} className={outerDivClass} onClick={handleClick}  >
      <div style={{ width:'9vw',display: 'flex', justifyContent: 'space-between',marginRight:'15px', padding:'',border:'0px solid black'}}className="tenantTicketDiv" >
        {text} <NavArrowRight className="tenantTicketIcon" /> 
      </div>
    </div>
  )
  if (orientation === 'portrait')
  return (
    <div style={{marginTop:'0vh', marginBottom:'0vh'}} className={outerDivClassMobile} onClick={handleClick}  >
      <div style={{ fontSize:'2vh', width:'30vw',display: 'flex', justifyContent: 'space-between',marginRight:'15px', padding:'',border:'0px solid black'}}className="tenantTicketDivMobile" >
        {text} <NavArrowRight style={{height:'2vh', marginTop:'0.2vh'}} className="tenantTicketIcon" /> 
      </div>
    </div>
  )
}

export default FilterLevelOne