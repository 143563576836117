import React from 'react'

function DashboardCard({data}) {
  return (
    <div className="col-lg-3 col-6">
        <div className={`small-box ` + data.class } style={{backgroundColor: data.style_bgcolor, color: data.style_color}}>
            <div className="inner">
                <h3>{data.sum} </h3>
                <p><b>{data.statusText}</b></p>
            </div>
            <div className="icon" >
                <i className={`fa ` + data.icon} /> 
            </div>
                <a href={data.href} className="small-box-footer"> 
                {data.gotext}
                <i className="fas fa-arrow-circle-right" />
            </a>
        </div>
    </div>
  )
}

export default DashboardCard