import React, { useRef } from 'react'; import axios from 'axios' ;
import ContentHeader from '../../content/ContentHeader'; import { useState, useEffect} from 'react';
import Cookies from 'universal-cookie'; 
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
import BootstrapTable from 'react-bootstrap-table-next'
import {Search as NoirSearch,User, Label, EditPencil } from 'iconoir-react'
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import NoPage from '../NoPage';
import { useNavigate } from 'react-router-dom';
import useOrientation from '../../general/useOrientation';

const cookies = new Cookies();
const MySwal = withReactContent(Swal)
const { SearchBar } = Search;


const bigDateFormatter = (data) => {
  var datee = new Date(data);
  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const dayOfWeek = daysOfWeek[datee.getDay()]; // Get the day of the week
  const year = datee.getFullYear().toString().slice(-2); // Get the last 2 digits of the year
  const month = String(datee.getMonth() + 1).padStart(2, '0'); // Get the month (0-indexed) and pad with 0 if needed
  const day = String(datee.getDate()).padStart(2, '0'); // Get the day and pad with 0 if needed
  const hours = String(datee.getHours()).padStart(2, '0'); // Get the hours and pad with 0 if needed
  const minutes = String(datee.getMinutes()).padStart(2, '0'); // Get the minutes and pad with 0 if needed

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight:'25px',
      paddingTop:'20px',
      paddingBottom:'20px'
    }}>
      <p style={{ textAlign: 'center', margin: 0 }}>
        {dayOfWeek}
      </p>
      <h1 style={{ margin: 0 }}>
        {day}
      </h1>
    </div>
  );
}




function AnnouncementTable({ edit, search, setLoading,setAnc}) {
  var sessionData = cookies.get('sessionData', { path: '/' })
  const clickedColumnIndex = useRef();
  const navigate = useNavigate();
  const displayedRow = 12;
  const [dataa, setDataa] = useState([]);
  const [totlength, setTotLength] = useState(0);
  const [txtBL, setTxtBL] = useState('');
  const [currentPage, setCurrentPage] = useState(0); // Track the current page
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait') { isThisPortrait = true;}
  
  const iconsFormatter = (data,row) => {
    if (isThisPortrait === "landscape")
    return <>
    <div>
      <User style={{color:"#737E86"}}/> &nbsp;<span style={{color:"#737E86"}} className='whiteBgClass'>{data == 1? "Internal" : "External"}</span>
    </div>
    <div style={{marginTop:'0.5vw'}}>
      <Label style={{color:"#737E86"}}/> &nbsp;<span className='blueBgClass'>{ row.kategori_name }</span>
    </div>
    </>
    if (isThisPortrait === "portrait")
    return <>
  
    </>
  }
  
const titleContentFormatter = (data,row) => {
  var content = row.konten.replace(/<[^>]*>/g, '').trim();
   
  if (content.length > 100 && !isThisPortrait) {
    content = content.substring(0, 85) + " ...";
  }   

  if (content.length > 35 && isThisPortrait) {
    content = content.substring(0, 45) + " ...";
  }
  return <div>
    <h4 style={{fontFamily:'Blender Pro Book'}}><b>{data}</b></h4>
    <p style={{color:"#737E86",marginTop:'-10px',fontFamily:'Blender Pro Book'}}>{content}</p>
  </div>
}

  const [columns, setColumns] = useState([
    {
      text: 'Title',
      dataField: 'created_at',
      sort: true,
      headerClasses: 'd-none',
      formatter: bigDateFormatter,
      center: true,
    },
    {
      text: 'internal',
      dataField: 'internal',
      sort: true,
      headerClasses: 'd-none',
      center: true,
      formatter: iconsFormatter,
    },
    {
      text: 'title',
      dataField: 'title',
      sort: true,
      headerClasses: 'd-none',
      center: true,
      formatter: titleContentFormatter,
    },
  ]);
  useEffect(() => {
    setLoading(true);
    getData();
  }, [search])

  const actionFormatter = (data, row) => {
    return (
      <>
        <div
          className='buttonBlue'
          style={{
            padding: '5px 15px 5px 15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width:'auto'
          }}
          onClick={() => edit(row)}
        >
          <EditPencil /> &nbsp;<span>{isThisPortrait? 'Edit':'Edit Content'}</span>
        </div>
      </>
    );
  };
  useEffect(() => {
    let cpmone = parseInt(currentPage) - 1 
    let firstDigit = (parseInt(displayedRow) * parseInt(cpmone) )
    let secondDigit = parseInt(firstDigit) + parseInt(displayedRow)
    if (secondDigit > totlength) secondDigit = totlength
    let a = `${firstDigit+1} - ${secondDigit} of ${totlength} data`   ;
    setTxtBL(a);
    if (totlength === 0){
      setTxtBL();
    }
  }, [currentPage,totlength])
  
  
  useEffect(() => {
    // Check if sessionData.apps_roles_id is smaller than 4
    if (sessionData.apps_roles_id == 1 || sessionData.apps_roles_id == 2 || sessionData.apps_roles_id == 3) {
      // Add the last column only if the condition is met
      setColumns(prevColumns => [
        ...prevColumns,
        {
          text: 'kategori_name',
          dataField: 'kategori_name',
          sort: true,
          headerClasses: 'd-none',
          center: true,
          formatter: actionFormatter,
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
                clickedColumnIndex.current = columnIndex;
            },
        },
        },
      ]);
    }
  }, [sessionData.apps_roles_id]);

  const getData = () => {
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    
    axios.interceptors.request.use(config => {config.headers['x-access-token'] = sessionToken;return config;},error => {return Promise.reject(error);});
    axios.get(`${process.env.REACT_APP_PATH_DESTINATION}/announcementList`) 
      .then(res => {
        console.log("res.data.data announcement logging");
        console.log(res.data.data);
        setDataa(res.data.data);
        setTotLength(res.data.data.length);
        setCurrentPage(1);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
            MySwal.fire({
            title: <strong>Error!</strong>,
            html: <p>{err}</p>
        })
        setLoading(false);
      })
  }
  const options = {
    sortIndicator: true,
    bordered: false,
    hideSizePerPage: true, // Hide the option to change the number of rows
    sizePerPage: displayedRow, // You can set your desired default value here
    page: 1,
    showTotal: false, // Hide the total number of rows
    sizePerPageList: [] // Remove the size per page options from the dropdown
  };
  const handlePageChange = (type, { page, sizePerPage }) => {
    setCurrentPage(type); 
  };
  const searchProps = {
    delay: 800,
    placeholder:isThisPortrait? "search..." :"search announcement...",
    className: "searchRight",
    zIndex : 5,
    position: 'relative',
    height: '4px',
    fontFamily: 'Blender Pro !important',
    defaultSearch: search
  };


  const tableRowEvents = {
    onClick: (e, row, rowIndex) => {
      //console.log('row',row); //got all the data u ever want here
      if (clickedColumnIndex.current === 3) {
        // Do nothing
      } else {
        // Navigate to another page
        setAnc(row);
      }
      // Reset clickedColumnIndex
      clickedColumnIndex.current = undefined;
    },
    onMouseEnter: (e, row, rowIndex) => {
      //console.log(`enter on row with index: ${rowIndex}`);
    }
  }

  return (
    <>
    <ContentHeader title=""/> 
        <section className="content">
        <div className="container-fluid">
            <div className="row"> 
              <div className="col-md-12">
                <ToolkitProvider
                  keyField="id"
                  data={dataa}
                  columns={columns}
                  bootstrap4={true}
                  search={searchProps}
                >
                  {props => (
                    <div >
                      <div style={{ width:isThisPortrait? '25vw':'200px', float:'right',zIndex:-1 }}>        
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <SearchBar {...props.searchProps} {...searchProps} />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      </div>
                      <BootstrapTable
                        {...props.baseProps}
                        striped 
                        hover
                        pagination={paginationFactory({ ...options, onPageChange: handlePageChange })}
                        classes="custom-table" 
                        rowClasses="custom-row"
                        rowEvents={ tableRowEvents }
                      />
                    </div>
                  )}
                </ToolkitProvider>
                <div style={{marginTop:'1vw', marginLeft:isThisPortrait?'2vw':''}}>{txtBL}</div>
              </div>
            </div>
        </div>
     </section>
    </>

  )
}

export default AnnouncementTable