import React from 'react';
import 'react-form-builder2/dist/app.css';
import BuilderRO from './BuilderRO';
import TableGen from './TableGen';

function FormToHtmlRules({ formData, dataValue , rulesValue}  ) {
  try {rulesValue = JSON.parse(rulesValue)} catch {rulesValue = {}};

  const renderNoneApprover = (index) => {
    return <select
    className="form-control"
    id={"select_" + index}
    name={"select_" + index}
    defaultValue={rulesValue["select_" + index]} // Set the selected value based on rulesValue
  >
    <option value="99">None</option>
  </select>;
  };

  const renderSelectApprover = (index) => {
    return <select
    className="form-control"
    id={"select_" + index}
    name={"select_" + index}
    defaultValue={rulesValue["select_" + index]} // Set the selected value based on rulesValue
  >
  <option value="0">User Creator</option>
  <option value="1">Level 1</option>
  <option value="2">Level 2</option>
  <option value="3">Level 3</option>
  <option value="4">Level 4</option>
  <option value="5">Level 5</option>
  <option value="6">Level 6</option>
  <option value="7">Level 7</option>
  <option value="8">Level 8</option>
  <option value="9">Level 9</option>
  <option value="10">Level 10</option>
  <option value="11">Level 11</option>
  <option value="12">Level 12</option>
  <option value="13">Level 13</option>
  <option value="14">Level 14</option>
  <option value="15">Level 15</option>
  <option value="99">None</option>

  </select>;
  };

  try {
    const formDataParsed = JSON.parse(formData);
    //console.log("datavalue logging");console.log(dataValue);
    const dataValueParsed = dataValue && dataValue.length > 0 ? JSON.parse(dataValue) : [];
    const formElements = formDataParsed.map((element, index) => (
      <div className="row" key={element.id + index + "row"}>
      <div className="col-md-6" key={element.id}>
        {(() => {
          switch (element.element) {
            case 'Header':
              return BuilderRO.renderHeader(element);
            case 'TextInput':
              return BuilderRO.renderTextInput(element, dataValueParsed[element.field_name] || '');
            case 'Checkboxes':
              return BuilderRO.renderCheckboxes(element, dataValueParsed);
            case 'Label':
              return BuilderRO.renderLabel(element);
            case 'Paragraph':
              return BuilderRO.renderParagraph(element);
            case 'LineBreak':
              return BuilderRO.renderLineBreak();
            case 'Dropdown':
              return BuilderRO.renderDropdown(element, dataValueParsed[element.field_name] || '');
            case 'Tags':
              return BuilderRO.renderTags(element, dataValueParsed[element.field_name] || '');
            case 'RadioButtons':
              return BuilderRO.renderRadioButtons(element, dataValueParsed[element.field_name] || '');
            case 'EmailInput':
              return BuilderRO.renderEmailInput(element, dataValueParsed[element.field_name] || '');
            case 'NumberInput':
              return BuilderRO.renderNumberInput(element, dataValueParsed[element.field_name] || '');
            case 'PhoneNumber':
              return BuilderRO.renderPhoneNumber(element, dataValueParsed[element.field_name] || '');
            case 'TextArea':
              return BuilderRO.renderTextArea(element, dataValueParsed[element.field_name] || '');
            case 'Image':
            case 'Camera':
              return BuilderRO.renderImage(element, dataValueParsed[element.field_name] || '', dataValueParsed['loc_'+element.field_name] || '', dataValueParsed);
            case 'Rating':
              return BuilderRO.renderRating(element, dataValueParsed[element.field_name] || '');
            case 'DatePicker':
              return BuilderRO.renderDatePicker(element, dataValueParsed[element.field_name] || '');
            case 'Signature':
              return <BuilderRO.Signature label={element.label} name={element.id} width={400} heigth={200} initialValue={dataValueParsed[element.id]}/>;
            case 'HyperLink':
              return BuilderRO.renderHyperLink(element);
            case 'Download':
            case 'FileUpload':
              if (typeof dataValueParsed[element.field_name] !== "string") 
                dataValueParsed[element.field_name] = ""; // Set it to an empty string
              return BuilderRO.renderDownload(element, dataValueParsed[element.field_name] || '', dataValueParsed['loc_'+element.field_name] || '', dataValueParsed);
            case 'Range':
              return BuilderRO.renderRange(element, dataValueParsed[element.field_name] || '');
            case 'TwoColumnRow':
            case 'ThreeColumnRow' :
            case 'MultiColumnRow' :
              return <TableGen element={element} defaultValue={dataValueParsed[element.id]} />
            default:
              return null;
          }
        })()}
      </div>

      <div className="col-md-6" key={element.id + "_" + index}>
        <div className="form-group">
          <label> Field {index} </label>
          {(() => {
          switch (element.element) {
            case 'Header':
            case 'Label':
            case 'Paragraph':
            case 'LineBreak':
            case 'Tags':
            case 'HyperLink':
            case 'TwoColumnRow':
            case 'ThreeColumnRow' :
            case 'MultiColumnRow' :
              return renderNoneApprover(index);
            default:
              return renderSelectApprover(index);
          }
        })()}
        </div>
      </div>

      </div>

    ));
    return (<>{formElements}</>);
  }
  catch {
    return (<>Form empty. Please add the form first <br/><br/></>);
  }
  
}

export default FormToHtmlRules;