import React from 'react'; import { useState, useEffect} from 'react';
import axios from 'axios' 
import Cookies from 'universal-cookie';
import UrgentTicket from './UrgentTicket';
import { BrowserView, MobileView } from 'react-device-detect';
import useOrientation from '../../general/useOrientation';
const cookies = new Cookies();
function UrgentTicketParent() {
  const initialButton = cookies.get('activeButton') || 'OnProgress';
  const initialDeptID = cookies.get('deptID') || 0;
  const [activeButton, setActiveButton] = useState(initialButton);
  const [headData, setHeadData] = useState({});
  const [deptID, setDeptID] = useState(initialDeptID);
  const [deptData, setDeptData] = useState({});
  const [data, setData] = useState({});
  const orientation = useOrientation();
  const handleButtonClick = (buttonType) => {
    if (activeButton === buttonType) return;

    setActiveButton(buttonType);
    cookies.set('activeButton', buttonType, { path: '/' }); // Update cookie with the new activeButton value
    updateDataByButton(buttonType);
  };

  const updateDataByButton = (buttonType) => {
    if (buttonType === 'OnProgress') {
      setData(headData.onProg);
    } else if (buttonType === 'Waiting') {
      setData(headData.waiting);
    } else if (buttonType === 'Overdue') {
      setData(headData.over);
    }
  };

  useEffect(() => {
    getData();
  }, [deptID, deptData])

  useEffect(() => {
    getDeptTickets();
  }, [])

  const getData = () => {
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    var path = process.env.REACT_APP_PATH_DESTINATION + "/urgentTicketToMeInt/"+deptID;   
    const config = {headers:{['x-access-token']: sessionToken}};
    axios.get(path, config)
    .then(res => {
      console.log("res.data.data urgent ticket int");
      console.log(res.data.data);
      if (res.data.status = "200"){
        setHeadData(res.data.data);
        //console.log("on prog",res.data.data);
        if (activeButton == 'OnProgress'){
          setData(res.data.data.onProg);
        } else if (activeButton == 'Waiting'){
          setData(res.data.data.waiting);
        } else if (activeButton == 'Overdue'){
          setData(res.data.data.over);
        }
      }
    })
    .catch(err => {
      console.log(err);
    })
  }  

  const getDeptTickets = () => {
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    var path = process.env.REACT_APP_PATH_DESTINATION + "/getDeptTickets";   
    const config = {headers:{['x-access-token']: sessionToken}};
    axios.get(path, config)
    .then(res => {
      console.log("res.data.data getDeptTickets int");
      console.log(res.data.data);
      if (res.data.status = "200"){
        setDeptData(res.data.data);
      }
    })
    .catch(err => {
      console.log(err);
    })
  }  


  const handleDropdownChange = (event) => {
    const selectedDeptID = event.target.value;
    setDeptID(selectedDeptID); // Update state
    cookies.set('deptID', selectedDeptID, { path: '/' }); // Store deptID in cookie
  };
  
  if (orientation === 'landscape')
  return (
  <>
  <>
    <div className='borderWithRadius'>
      <div className="row dashboardMarginRight" style={{marginTop:"1.3vw", marginRight:'1vw', marginBottom:'1.3vw', marginLeft:'1.6vw'}}>
        <p style={{fontFamily:'Blender Pro Book', fontSize:'1.5vw'}}><b>Urgent Tickets & Requests</b></p>
        <p style={{marginTop:"-0.6vw", textAlign:'left',fontFamily:'Blender Pro Book'}}>Closest due time or high priority Tickets & Requests</p>
        
        {deptData && deptData.length > 1 && (<><br/><br/>
          <select className='dropdownUrgent' id="dropdown" value={deptID} onChange={handleDropdownChange}>
            <option value="0"><b>All</b></option> {/* Default option */}
            {deptData.map(dept => (
              <option key={dept.id} value={dept.id}>
                <b>{dept.dept_name}</b>
              </option>
            ))}
          </select></>
        )}
        <br/>
        <div className='col-md-12' style={{marginLeft:'-1.8vw'}}>
            <div className="button-container" style={{marginBottom:'-0.8vw'}}>
                <div
                    style={{width:"6vw", fontFamily:'Blender Pro'}}
                    className={`byMeButton ${activeButton === 'OnProgress' ? 'byMeColorBlue' : 'byMeColorGray'}`}
                    onClick={() => handleButtonClick('OnProgress')}
                >
                    On Progress
                </div>
                <div
                    className={`byMeButton ${activeButton === 'Waiting' ? 'byMeColorBlue' : 'byMeColorGray'}`}
                    onClick={() => handleButtonClick('Waiting')}
                    style={{ marginLeft: '-0.8vw' ,width:"4vw", fontFamily:'Blender Pro'}}
                >
                    Waiting
                </div>
                <div
                    className={`byMeButton ${activeButton === 'Overdue' ? 'byMeColorBlue' : 'byMeColorGray'}`}
                    onClick={() => handleButtonClick('Overdue')}
                    style={{ marginLeft: '-0.8vw',width:"4vw", fontFamily:'Blender Pro' }}
                >
                    Overdue
                </div>
            </div>
        </div>
      </div>
      {data && data.length > 0 ? (
        data.map((item, index) => (
          <UrgentTicket key={'UT' + index} path_foto={item.path_foto} title={item.title} deadline={item.due_at} id={item.id} name={item.display_name}/>
        ))
      ) : (
        <p style={{marginTop:'2vw', fontFamily:'Blender Pro Book'}}>No urgent tickets found.</p>
      )}
      <div style={{marginBottom:'0.2vw'}}>&nbsp;</div>
    </div>
  </>
  </>
  )
  if (orientation === 'portrait')
  return (
    <>
    <div className='borderWithRadius'>
        <p style={{ textAlign:'left', fontFamily:'Blender Pro Book', fontSize:'2.5vh', marginLeft:'4vw', marginTop:'2vh'}}><b>Urgent Tickets & Requests</b></p>
        <p style={{ textAlign:'left', fontFamily:'Blender Pro Book', marginLeft:'4vw', marginTop:'-2vh', marginBottom:'2.5vh'}}>Closest due time or high priority Tickets & Requests</p>
        <div className="row dashboardMarginRight" style={{marginTop:"1.3vh", marginRight:'1vw', marginBottom:'1.3vh', marginLeft:'1.6vw'}}>
          
          <div className='col-md-12' style={{marginLeft:'-3.5vw'}}>
              <div className="button-container" style={{marginBottom:'0vh', marginTop:'1vh'}}>
                  <div
                      style={{width:"25vw", fontFamily:'Blender Pro'}}
                      className={`byMeButtonMobile ${activeButton === 'OnProgress' ? 'byMeColorBlue' : 'byMeColorGray'}`}
                      onClick={() => handleButtonClick('OnProgress')}
                  >
                      On Progress
                  </div>
                  <div
                      className={`byMeButtonMobile ${activeButton === 'Waiting' ? 'byMeColorBlue' : 'byMeColorGray'}`}
                      onClick={() => handleButtonClick('Waiting')}
                      style={{ marginLeft: '0vw', fontFamily:'Blender Pro',width:"18vw"}}
                  >
                      Waiting
                  </div>
                  <div
                      className={`byMeButtonMobile ${activeButton === 'Overdue' ? 'byMeColorBlue' : 'byMeColorGray'}`}
                      onClick={() => handleButtonClick('Overdue')}
                      style={{ marginLeft: '0vw', fontFamily:'Blender Pro',width:"18vw" }}
                  >
                      Overdue
                  </div>
              </div>
          </div>
        </div>
        {data && data.length > 0 ? (
          data.map((item, index) => (
            <UrgentTicket key={'UT' + index} path_foto={item.path_foto} title={item.title} deadline={item.due_at} id={item.id} name={item.display_name}/>
          ))
        ) : (
          <p style={{marginTop:'2vw', fontFamily:'Blender Pro Book'}}>No urgent tickets found.</p>
        )}
        <div style={{marginBottom:'0.2vw'}}>&nbsp;</div>
      </div>
    </>
    )
}

export default UrgentTicketParent