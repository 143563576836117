import React, { useState, useEffect, useRef } from 'react';
import HeaderPage from '../../headerPage/HeaderPage';
import {useNavigate, useParams} from "react-router-dom"
import FilterSearch from '../../filter/FilterSearch';
import SwitchOnOff from '../configTicket/SwitchOnOff';
import ConfigSingleTable from './ConfigSingleTable';

function ConfigAnnouncement({linkData, clickLink, changeNavState}) {
  const clickedColumnIndex = useRef();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    changeNavState('Configurations');
  }, [])

  const addAnnouncementCat = (() => {
    navigate('/configuration/createConfigAnnouncement');
  })

  const activeFormatter = (data,row) => {
    return <SwitchOnOff setLoading={setIsLoading} link={linkData} initialActive={data} data={row.id}/>
  }
  
  const columns = [
    {
      text: '#',
      dataField: 'index',
      sort: true,
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: (column, colIndex) => {
        return { width: '80px' }; // Adjust the width of the index column
      },
    },
    {
      text: 'Name',
      dataField: 'kategori_name',
      sort: true
    },
    {
      text: 'Status',
      dataField: 'active',
      sort:true,
      formatter:activeFormatter,
      events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
              clickedColumnIndex.current = columnIndex;
          },
      },
    }
  ];

  return (
  <>
     <HeaderPage 
        title1={"Configurations"}
        link1={"/configuration"}
        title2={"Announcement"}
        loadingState={isLoading}
        buttonText={"Create New"}
        buttonAction={addAnnouncementCat}
    />
      <div style={{zIndex:1, marginTop: '-5vh', marginLeft:'-1.2vw'}}>
        <ConfigSingleTable clickedColumnIndex={clickedColumnIndex} columns={columns} getDataLink={linkData} refresh={refresh} setLoading={setIsLoading} clickLink={clickLink}/>
      </div>
  </>
  )
}

export default ConfigAnnouncement