import React, { useState } from 'react';
import {NavArrowLeft, Copy} from 'iconoir-react'
import { useNavigate } from 'react-router-dom';
import useOrientation from '../../general/useOrientation';

function HeaderPage({ title1, link1, title2, link2, buttonText, buttonAction, loadingState = false ,leftArrow=false, ticketNumber}) {
  const [copySuccess, setCopySuccess] = useState(false);
  const navigate = useNavigate();
  const orientation = useOrientation();
  const handleCopyClick = () => {
    // Create a text area element to hold the text to be copied to clipboard
    const textArea = document.createElement('textarea');
    textArea.value = ticketNumber;
    document.body.appendChild(textArea);

    // Select the text in the text area and copy it to clipboard
    textArea.select();
    document.execCommand('copy');

    // Clean up
    document.body.removeChild(textArea);

    // Set copy success state
    setCopySuccess(true);
  };
  const handleClickLink = (link) =>{
    navigate(link);
  }
  if (orientation === 'landscape')
  return (
    <div>
      <div style={{ marginTop: '2.82vw', borderBottom: '1px solid #E8E9EB', position: 'absolute', left: '-5px', right: '0' }}></div>
      <p style={{ marginTop: '1.5vw', fontSize: '1vw' }}>
        <a className={link1 ? 'cursorPointer' : ''} onClick={() => handleClickLink(link1)}>{leftArrow && ( <NavArrowLeft style={{marginTop: '-0.2vw', color:'#000000',height:'1vw' }}/> )} {title1}</a>&nbsp; &nbsp;
        {title2 && (
          <>
            /&nbsp;&nbsp;
            <b style={{ color: '#404041' }}>{title2}</b>
          </>
        )}

        {ticketNumber && (
          <>
            /&nbsp;&nbsp;
            <span className='smallText'>
            
            {ticketNumber}
            {/*
            <Copy style={{ marginLeft: '8px' , height:'16px'}} className="cursorPointer" onClick={handleCopyClick}/>
            */}
          </span>
          </>
        )}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {loadingState && (
          <img
          src="/dist/img/loading.png"
          alt="Loading..."
          className="spinner"
          style={{
            position: 'relative',
            backgroundPosition: 'center',
            width: '20px', // Adjust the width according to your design
            height: '20px', // Adjust the height according to your design
          }}
        />
        )}
       
      </p>
      {buttonText && (
      <div style={{float:'right' , marginRight:'2vw' , marginTop:'-3.2vw'}}>
          <button onClick={buttonAction} className='btn btn-primary ' style={{backgroundColor: '#0296D2', borderRadius: '8px', fontFamily:'Blender Pro', height:'2.8vw'}}> {buttonText} </button>
        </div>
      )}
    </div>
  );
  if (orientation === 'portrait')
  return (
  <div style={{marginTop:'10vh', paddingBottom:buttonText? '0vh':'2vh', alignItems:'center', borderBottom: '1px solid #E8E9EB', left: '-5px'}}>
    
    <div style={{marginLeft:'6vw',}}>
      
      <p style={{ marginTop:'-1vh', fontSize: '2vh' }}>
        <a className={link1 ? 'cursorPointer' : ''} onClick={() => handleClickLink(link1)}>{leftArrow && ( <NavArrowLeft style={{marginTop: '-0.2vh', color:'#000000',height:'2vh', marginLeft:'-2vw' }}/> )} {title1}</a>&nbsp; &nbsp;
        {title2 && (
          <>
            /&nbsp;&nbsp;
            <b style={{ color: '#404041' }}>{title2}</b>
          </>
        )}

        {ticketNumber && (
          <>
            /&nbsp;&nbsp;
            <span className='smallText' style={{fontSize:'2vh'}}>
            
            {ticketNumber}
          </span>
          </>
        )}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {loadingState && (
          <img
          src="/dist/img/loading.png"
          alt="Loading..."
          className="spinner"
          style={{
            position: 'relative',
            backgroundPosition: 'center',
            width: '20px', // Adjust the width according to your design
            height: '20px', // Adjust the height according to your design
          }}
        />
        )}
       
      </p>
      {buttonText && (
      <div style={{ float: 'right', marginRight: '2vw', marginTop: '-5.5vh' }}>
        <button onClick={buttonAction} className='btn btn-primary' style={{ backgroundColor: '#0296D2', borderRadius: '8px', fontFamily: 'Blender Pro', height: '4vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {buttonText}
        </button>
      </div>
      )}
    </div>
  </div>
  );
}


export default HeaderPage;
