import React from 'react'; import axios from 'axios' ;
import ContentHeader from '../../content/ContentHeader'; import { useState, useEffect} from 'react';
import Cookies from 'universal-cookie'; 
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
import BootstrapTable from 'react-bootstrap-table-next'
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BackButton from '../../content/BackButton';
const cookies = new Cookies();
const MySwal = withReactContent(Swal)
const { SearchBar } = Search;

function DocDashList({type, deptIDChosen, deptNameChosen, statusText, columns}) {
  if (deptNameChosen == 0) deptNameChosen = '\u00A0';
  const [dataa, setDataa] = useState([]);
    useEffect(() => {
      getData();
    }, [])
    
    const getData = () => {
        var sessionToken = cookies.get('sessionToken', { path: '/' })
        var path = process.env.REACT_APP_PATH_DESTINATION + "/docDashboardDetail" ;   
        const config = {headers:{['x-access-token']: sessionToken}};
        axios.post(path, {"type":type , "deptIDChosen":deptIDChosen} , config)
        .then(res => {
          setDataa(res.data.data);
          console.log(res.data.data);
        })
        .catch(err => {
          console.log(err);
        })
    }   
      
    const searchProps = {
        delay: 800,
        placeholder: "search table...",
        className: "searchRight"
      };
      const searchPropsContent = {
        delay: 800,
        placeholder: "search content...",
        className: "searchRight"
      };
  return (
    <>
        <BackButton link="/" />
        <ContentHeader title={statusText + ` Document ` + deptNameChosen}/>
        <section className="content">
        <div className="container-fluid">
            <div className="row"> 
              <div className="col-md-12">
              
                <ToolkitProvider
                  keyField="id"
                  data={dataa}
                  columns={columns}
                  bootstrap4={true}
                  search={searchProps}
                >
                  {props => (
                    <div>
                      <div style={{ /*width: '400px',*/ float:'right'}}>
                      <table>
                        <tbody>
                        <tr>
                          <td><i className="fa fa-search" aria-hidden="true"></i></td>
                          <td><SearchBar {...props.searchProps} {...searchProps} /></td>
                          <td>&nbsp;</td>
                          {/*<td><i className="fa fa-search" aria-hidden="true"></i></td>
                          <td><SearchBar {...props.searchPropsContent} {...searchPropsContent} /></td>*/}
                        </tr>
                        </tbody>
                      </table>
                      </div>
                      
                      <BootstrapTable
                        {...props.baseProps}
                        striped 
                        hover
                        pagination={paginationFactory({
                          sizePerPage: 25 
                        })}

                      />
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default DocDashList