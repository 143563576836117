import React, { useEffect, useState } from 'react';

function ConfigInputTime({ name, data, setData, time }) {
  const [divBy, setDivBy] = useState(0);
  const handleChange = (e) => {
    setData(e.target.value * divBy);
  };

  useEffect(() => {
    switch (time) {
      case 'm':
        setDivBy(60);
        break;
      case 'h':
        setDivBy(3600);
        break;
      case 'd':
        setDivBy(86400);
        break;
    }
  }, [time]);

  const processedData = parseInt(data) / divBy;

  return (
    <>
      <label>{name}</label>
      <input
        type='text'
        className='form-control'
        placeholder={'Enter ' + name}
        style={{ width: '71vw' }}
        value={processedData}
        onChange={handleChange}
      />
    </>
  );
}

export default ConfigInputTime;
