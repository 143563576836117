import React, { useEffect, useState } from 'react'
import {MoreVert, Download, Edit} from 'iconoir-react';
import Cookies from 'universal-cookie'; 
import { useNavigate } from 'react-router-dom';
import useOrientation from '../../general/useOrientation';
function ImageDocLibrary({data, order}) {
  const cookies = new Cookies();
  const sessionData = cookies.get('sessionData', { path: '/' })
  const navigate = useNavigate(); 

  const [updatedAt, setUpdatedAt] = useState({});
  const [popup, setPopup] = useState(false);
  const [nameShortened, setNameShortened] = useState(0);
  const orientation = useOrientation();
  const CHARACTER_LIMIT = 20;
  useEffect(() => {
    setUpdatedAt(data.updated_at);
    const originalName = data.name || '';
    // Limit the name to the constant character limit
    const shortenedName = originalName.length > CHARACTER_LIMIT
        ? originalName.slice(0, CHARACTER_LIMIT) + '...'
        : originalName;

    // Set the shortened name in the state
    setNameShortened(shortenedName);
  }, [data])

  const formatDate = (date) => {
    const createdAt = new Date(date);
    const day = createdAt.getDate().toString().padStart(2, '0');
    const month = (createdAt.getMonth() + 1).toString().padStart(2, '0'); // Adjust month by +1
    const year = createdAt.getFullYear();
    return `${day}/${month}/${year}`;
  };


  const handlePopupclick = () => {
    setPopup(!popup)
  }

  const handleEdit = (id) => {
    navigate("/editDocLib/"+id);
  }
  

  const handleDownload = () => {
    setPopup(false)
    const downloadLink = process.env.REACT_APP_IMG_DESTINATION + data.attachment;
    // Fetch the file as a blob
    fetch(downloadLink)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a URL for the blob
        const blobUrl = URL.createObjectURL(blob);
  
        // Create a virtual link element
        const linkElement = document.createElement('a');
  
        // Set the href attribute to the blob URL
        linkElement.href = blobUrl;
  
        // Set the download attribute with the desired file name
        linkElement.download = data.attachment;
  
        // Append the link to the document
        document.body.appendChild(linkElement);
  
        // Trigger a click on the link to start the download
        linkElement.click();
  
        // Remove the link from the document
        document.body.removeChild(linkElement);
  
        // Revoke the blob URL to free up resources
        URL.revokeObjectURL(blobUrl);
      })
      .catch((error) => {
        console.error('Error downloading the file:', error);
      });
}

  if (data && data != undefined && orientation === 'landscape')
  return (
  <div style={{ position: 'relative', width: '90%', margin: 'auto' }}>
    <div style={{ position: 'relative' }}>
      <img
          src={process.env.REACT_APP_PUBLIC_DESTINATION + "DLC.png"}
          alt={`Attachment`} 
          style={{
            width: '100%',
            weight:'12vw',
            objectFit: 'cover', 
            position: 'absolute',
            borderRadius: '8px',
            border: '1px solid #E8E9EB', 
          }}
      />
      <img
          src={process.env.REACT_APP_IMG_DESTINATION + data.cover}
          alt={`Attachment`} 
          style={{
            width:'60%',
            marginTop:'1.25vw',
            marginLeft:'3vw',
            height:'10vw',
            objectFit: 'cover', 
            position: 'absolute',
            borderRadius: '8px',
            zIndex:'3'
          }}
      />
      <div style={{
        position: 'absolute',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        backgroundColor: 'white',
        width: '100%',
        height: '4vw',
        border: '1px solid #E8E9EB',
        zIndex: '5',
        marginTop:'9vw',
        display: 'flex',  
        alignItems: 'center', 
        justifyContent: 'space-between',
      }}>
        <div style={{ marginLeft: '1.2vw' }}>
          <p style={{ fontFamily: 'Blender Pro', fontSize: '1vw' }}> <b>{nameShortened}</b></p>
          <p style={{ fontFamily: 'Blender Pro', fontSize: '0.75vw', marginTop: '-1vw' }}> Uploaded by CS BeFa on {formatDate(updatedAt)}</p>
        </div>
        <div style={{ position: 'absolute', right: '0.5vw', top: '40%', transform: 'translateY(-50%)' }}>
          <MoreVert onClick={handlePopupclick} className="cursorPointer" style={{ height: '1.15vw'}} />
        </div>
      </div>
      {popup && (
        <>
        <div onClick={handlePopupclick} className="popup-overlay-doclib">
        </div>
        <div style={{
          borderRadius:'8px ',
          position:'absolute', 
          width: '50%',
          backgroundColor: 'white ',
          zIndex: '7',
          marginTop:'10vw',
          marginLeft: order == 3 ? '6vw' : '16.5vw' ,
          boxShadow: '0px 4px 40px rgba(0, 0, 0, 0.12)'
          
        }}>
          <div onClick={handleDownload} style={{ height:'3vw', display: 'flex', alignItems: 'center'}} className="row docLib" >
            <Download className= "docLib" style={{marginLeft:'15%', height:'1.5vw'}}/>
            <p style={{marginLeft: '5%', fontFamily:"Blender Pro", fontSize:'1vw'}}>Download</p>
          </div>
          {}
          <div onClick={() => handleEdit(data.id)} style={{ height:'3vw', display: 'flex', alignItems: 'center'}} className="row docLib" >
            <Edit className= "docLib" style={{marginLeft:'15%', height:'1.5vw'}}/>
            <p style={{marginLeft: '5%', fontFamily:"Blender Pro", fontSize:'1vw'}}>Edit </p>
          </div>
        </div>
        </>
      )}
    </div>
  </div>
  )
  else if (data && data != undefined && orientation === 'portrait')
  return (
  <div style={{ position: 'relative', width: '42vw', marginLeft:'3vw', marginTop:'2vh', marginRight:'3vw'}}>
    <div style={{ position: 'relative' }}>
      <img
          src={process.env.REACT_APP_PUBLIC_DESTINATION + "DLC.png"}
          alt={`Attachment`} 
          style={{
            width: '100%',
            height:'15vh',
            objectFit: 'cover', 
            position: 'absolute',
            borderRadius: '8px',
            border: '1px solid #E8E9EB', 
          }}
      />
      <img
          src={process.env.REACT_APP_IMG_DESTINATION + data.cover}
          alt={`Attachment`} 
          style={{
            width:'80%',
            marginTop:'2vh',
            marginLeft:'10%',
            height:'11vh',
            objectFit: 'cover', 
            position: 'absolute',
            borderRadius: '8px',
            zIndex:'3'
          }}
      />
      <div style={{
        position: 'absolute',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        backgroundColor: 'white',
        width: '100%',
        height: '4vh',
        border: '1px solid #E8E9EB',
        zIndex: '5',
        marginTop:'11vh',
        display: 'flex',  
        alignItems: 'center', 
        justifyContent: 'space-between',
      }}>
        <div style={{ marginLeft: '1.2vw' }}>
          <p style={{ fontFamily: 'Blender Pro', fontSize: '15px' }}> <b>{nameShortened}</b></p>
        </div>
        <div style={{ position: 'absolute', right: '0.5vw', top: '50%', transform: 'translateY(-50%)' }}>
          <MoreVert onClick={handlePopupclick} className="cursorPointer" style={{ height: '1.8vh'}} />
        </div>
      </div>
      {popup && (
        <>
        <div onClick={handlePopupclick} className="popup-overlay-doclib">
        </div>
        <div style={{
          borderRadius:'8px ',
          position:'absolute', 
          width: '35vw',
          height: '9vh',
          backgroundColor: 'white ',
          zIndex: '7',
          marginTop:'0px',
          marginLeft: order == 1 ? '6vw' : '16.5vw' ,
          boxShadow: '0px 4px 40px rgba(0, 0, 0, 0.12)'
          
        }}>
          <div onClick={handleDownload} style={{ marginTop:'1vh', height:'3vh', display: 'flex', alignItems: 'center'}} className="row docLib" >
            <Download className= "docLib" style={{marginLeft:'15%', height:'2vh'}}/>
            <p style={{marginLeft: '5%', fontFamily:"Blender Pro", fontSize:'18px'}}>Download</p>
          </div>
          {}
          <div onClick={() => handleEdit(data.id)} style={{ marginTop:'1vh', height:'3vh', display: 'flex', alignItems: 'center'}} className="row docLib" >
            <Edit className= "docLib" style={{marginLeft:'15%', height:'2vh'}}/>
            <p style={{marginLeft: '5%', fontFamily:"Blender Pro", fontSize:'18px'}}>Edit </p>
          </div>
        </div>
        </>
      )}
    </div>
  </div>
  )
  else return <></>
}
export default ImageDocLibrary