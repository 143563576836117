import React, { useState } from 'react';
import { Star } from 'iconoir-react';
import CommentAdd from '../comment/CommentAdd';

function ClosePick({ setRating, int_or_ext_id, action, cancelHandler, sendHandler, setContent, pic_name }) {
  const [selectedRating, setSelectedRating] = useState(0);
  const [hoveredRating, setHoveredRating] = useState(null);

  const handleRatingClick = (rating) => {
    setSelectedRating(rating);
    setRating(rating);
  };
  
  const handleRatingHover = (rating) => {
    setHoveredRating(rating);
  };

  const handleRatingLeave = () => {
    setHoveredRating(null);
  };

  return (
    <div>
      <div style={{ marginLeft: '4vw' }}>
        <p className="txtCreateTicket" style={{ marginTop: '2vw', fontSize:'15px' }}>
          What's your rating for {pic_name}'s support?
        </p>
        <div>
        {[1, 2, 3, 4, 5].map((rating) => (
        <span
          key={rating}
          onClick={() => handleRatingClick(rating)}
          onMouseEnter={() => handleRatingHover(rating)}
          onMouseLeave={handleRatingLeave}
        >
          <img
            src={
              rating <= (hoveredRating || selectedRating)
                ? "/ratingActive.svg"
                : "/ratingInactive.svg"
            }
            alt={`Rating ${rating}`}
            style={{ width: '32px', height: '32px', cursor: 'pointer', marginRight:'8px' }}
          />
        </span>
      ))}
        </div>
      </div>
      <CommentAdd action={action} cancelHandler={cancelHandler} sendHandler={sendHandler} setContent={setContent} />
    </div>
  );
}

export default ClosePick;
