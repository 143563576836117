import React from 'react'; import { useState, useEffect} from 'react';
import { Tooltip } from 'react-tooltip'; 
import { useNavigate } from 'react-router-dom';
import { BrowserView,MobileView } from 'react-device-detect';
import useOrientation from '../../general/useOrientation';
const maxCharacter= 35;
function UrgentTicket({ title, deadline ,path_foto, id, name}) {
  const navigate = useNavigate();
  const orientation = useOrientation();
  const [dueText, setDueText] = useState('');
  const [idLink, setIdLink] = useState('');
  const [truncatedTitle, setTruncatedTitle] = useState('');
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const redirectToTicketDetail = () => {
    navigate('/ticketDetail/'+idLink);
  }

  useEffect(() => {
    if (title.length > maxCharacter) {
      // If the title is longer than the maximum allowed characters, truncate it and add '...'
      const truncated = title.substring(0, maxCharacter) + '...';
      setTruncatedTitle(truncated);
    } else {
      setTruncatedTitle(title);
    }
  }, [title]);

  useEffect(() => {
    setIdLink(id);
  }, [id]);

  useEffect(() => {

    let a = new Date(deadline);

    // Get the date and time components
    const hours = a.getHours();
    const minutes = a.getMinutes();
    const day = a.getDate();
    const month = monthNames[a.getMonth()];
    const year = a.getFullYear();
    
    setDueText(`Due at ${hours}:${minutes} on ${day} ${month} ${year}`);

  }, [deadline])

  if (orientation === 'landscape')
  return (
  <>
  <>
    <div className="row align-items-center" style={{ marginLeft: '0.9vw', marginBottom:'1vw' }}>
      <div className="col-md-2">
        <a href="#" className="no-hover" data-tooltip-id={"urgentToMe"+id} data-tooltip-content={name}>
          <img
            src={process.env.REACT_APP_PUBLIC_DESTINATION + path_foto}
            alt="Profile Picture"
            className="brand-image img-circle"
            style={{ height: "3vw", width:'3vw' }} 
          />
          <Tooltip id={"urgentToMe"+id} place="left" type="dark" effect="solid" style={{marginTop:'', marginLeft:'',}}/>
        </a>
      </div>
      <div className="col-md-10 cursorPointer" style={{ textAlign: "left" }} onClick={redirectToTicketDetail}>
        <p style={{ fontSize: "1vw", fontFamily:'Blender Pro Book' }}><b>{truncatedTitle}</b></p>
        <p style={{ marginTop: '-1vw', fontSize: "0.9vw", color: '#FF553B', fontFamily:'Blender Pro' }}><b>{dueText}</b></p>
      </div>
    </div>
  </>
  
  </>
  );
  if (orientation === 'portrait')
  return(
    <>
    <div className="row align-items-center" style={{ marginLeft: '0.9vw', marginBottom:'2vh', marginTop:'-0.5vh' }}>
      <div style={{marginLeft:'4vw'}}>
        <a href="#" className="no-hover">
          <img
            src={process.env.REACT_APP_PUBLIC_DESTINATION + path_foto}
            alt="Profile Picture"
            className="brand-image img-circle"
            style={{ height: "5vh", width:'5vh' }} 
          />
        </a>
      </div>
      <div className="cursorPointer" style={{ textAlign: "left", marginLeft:'3vw'}} onClick={redirectToTicketDetail}>
        <p style={{ marginTop: '-0.5vh', fontSize: "2vh", fontFamily:'Blender Pro Book' }}><b>{truncatedTitle}</b></p>
        <p style={{ marginTop: '-2vh', fontSize: "1.8vh", color: '#FF553B', fontFamily:'Blender Pro' }}><b>{dueText}</b></p>
      </div>
    </div>
  </>
  )
}

export default UrgentTicket