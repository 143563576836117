import React, { useState, useEffect }  from 'react';
import { Refresh, Cancel } from 'iconoir-react';
import { motion, useAnimation } from 'framer-motion';
import FilterLevelOne from './FilterLevelOne';
import FilterLevelTwo from './FilterLevelTwo';
import Cookies from 'universal-cookie'; 
import axios from 'axios' ;
import useOrientation from '../../general/useOrientation';

const cookies = new Cookies();
function FilterSearch({init="",name="", handleRefreshClick,tiketOrDoc,bymeToMe,setData}) {
  const [filterExpand, setFilterExpand] = useState(false);
  const [secondFilterExpand, setSecondFilterExpand] = useState(false);
  const [secondLevelData, setSecondLevelData] = useState();
  const [secondLevelDataID, setSecondLevelDataID] = useState();
  const [currentState, setCurrentState] = useState(0);
  const [filterKeys, setFilterKeys] = useState([]);
  const [filterData, setFilterData] = useState();
  const [filterAble, setFilterAble] = useState(false);
  const [firstTime, setFirstTime] = useState(true);
  const [filterVariable, setFilterVariable] = useState([]);
  const orientation = useOrientation();
  useEffect(() => {
    setCurrentState();
    setFilterExpand(false); // Make the image bold when clicked
    setSecondFilterExpand(false)
    //alert("init = " + init);
    //alert("name = " + name);
    //alert("firstTime = " + firstTime);
    if (firstTime && (init !== "" || name !== "")){
      //alert("enter")
      const fetchData = async () => {
        // Introduce 1-second delay
        await new Promise(resolve => setTimeout(resolve, 100));
    
        if (init !== "" && name !== "") {
    
          let newFilterVarData = {
            "key": "Status",
            "data": name,
            "dataID": init
          };
    
          await setFilterVariable(prevFilterVariable => {
            const uniqueFilterVariableKeys = new Set(prevFilterVariable.map(item => item.key));
    
            if (!uniqueFilterVariableKeys.has(newFilterVarData.key)) {
              const updatedArray = [...prevFilterVariable, newFilterVarData];
              setData(updatedArray);
              return updatedArray;
            } else {
              const updatedFilterVariable = prevFilterVariable.map(item =>
                item.key === newFilterVarData.key ? newFilterVarData : item
              );
              setData(updatedFilterVariable);
              return updatedFilterVariable;
            }
          });
    
          console.log("Filter Variable", filterVariable);
          setFilterExpand(false);
          setSecondFilterExpand(false);
          setCurrentState();
          controls.start({ opacity: filterExpand ? 0 : 1 });
        } else if (name === "reportDateTime"){
          console.log("reportDateTime");
          const currentDate = new Date();
          const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-01`;
              
          let newFilterVarData = {
            "key": "Start Date",
            "data": formattedDate,
            "dataID": ""
          };
    
          await setFilterVariable(prevFilterVariable => {
            const uniqueFilterVariableKeys = new Set(prevFilterVariable.map(item => item.key));
    
            if (!uniqueFilterVariableKeys.has(newFilterVarData.key)) {
              const updatedArray = [...prevFilterVariable, newFilterVarData];
              setData(updatedArray);
              return updatedArray;
            } else {
              const updatedFilterVariable = prevFilterVariable.map(item =>
                item.key === newFilterVarData.key ? newFilterVarData : item
              );
              setData(updatedFilterVariable);
              return updatedFilterVariable;
            }
          });
    
          console.log("Filter Variable", filterVariable);
          setFilterExpand(false);
          setSecondFilterExpand(false);
          setCurrentState();
          controls.start({ opacity: filterExpand ? 0 : 1 });
          
        }
    
        // Now, the handleRefreshClick will be called only after the asynchronous processes are completed.
        //handleRefreshClick();
        setFirstTime(false);
      };
    
      fetchData();
    
    } else {
      getData();
    }
  }, [handleRefreshClick,tiketOrDoc,bymeToMe]);

  useEffect(() => {
    //setData([]);
    //setFilterVariable([]);
    for (let i = 0 ; i<filterVariable.length; i++){removeFilterIndex(i) }
  }, [tiketOrDoc,bymeToMe]);

  useEffect(() => {
    //if status from dashboard then dont retain the filter status
    if(name === "reportDateTime") // because only init === "" is from dashboard
      setFirstTime(true);
  }, [tiketOrDoc,init, name, bymeToMe]);
  
  useEffect(() => {
    setFirstTime(true);
  }, []);

  const handleModifyLevelTwo = (val) => {
    //api here
    console.log("val = " + val);
    console.log("filterData[val] = " ,filterData[val]);
    //this is temp data
    if(val == 'Category' || val == 'Category Int'|| val == 'Category Ext'|| val == 'Category Doc'|| val == 'Creator' || val == 'PIC' || val == 'Status') {
      setSecondLevelData(filterData[val].split(';')) ;
      setSecondLevelDataID(filterData[val+"ID"].split(';'));
    } else {
      setSecondLevelData(filterData[val].split(';'));
      setSecondLevelDataID([]);
    }
    
    setSecondFilterExpand(true);
  };

  const setSecondLevel = (boolVal) => {
    setSecondFilterExpand(boolVal);
    //alert("set bool = "+ boolVal);
  }

  const removeFilterIndex = (idx) => {
    setFilterVariable(prevFilterVariable => {
      const updatedFilterVariable = [...prevFilterVariable];
      updatedFilterVariable.splice(idx, 1); // Remove the item at the specified index
      setData(updatedFilterVariable);
      return updatedFilterVariable;
    });
  };

  const removeAllFilterIndexes = () => {
    setFilterVariable(prevFilterVariable => {
      const updatedFilterVariable = [];
      setData(updatedFilterVariable);
      return updatedFilterVariable;
    });
  };
  
  const secondLevelPicked = (index) => {
    let newFilterVarData = {}
    if (secondLevelData[0].toLowerCase() === "datetime" ){
      newFilterVarData = {
        "key": filterKeys[currentState],
        "data": index,
        "dataID": ""
      };
    } else {
      // Create a new object with the data to be pushed
      newFilterVarData = {
        "key": filterKeys[currentState],
        "data": secondLevelData[index],
        "dataID": secondLevelDataID[index]
      };
    }

    // Use setFilterVariable to push the new object to the array
    setFilterVariable(prevFilterVariable => {
      // Use a Set to store unique keys and filter out duplicates
      const uniqueFilterVariableKeys = new Set(prevFilterVariable.map(item => item.key));

      // Check if the new key already exists in the set
      if (!uniqueFilterVariableKeys.has(newFilterVarData.key)) {
        // If it's a unique key, add the new object to the array
        const updatedArray = [...prevFilterVariable, newFilterVarData];
        setData(updatedArray);
        return updatedArray;
      } else {
        // If the key already exists, update the existing object or handle it as needed
        const updatedFilterVariable = prevFilterVariable.map(item => 
          item.key === newFilterVarData.key ? newFilterVarData : item
        );
        setData(updatedFilterVariable);
        return updatedFilterVariable;
      }
    });

    console.log("Filter Variable", filterVariable);
    setFilterExpand(false); // Make the image bold when clicked
    setSecondFilterExpand(false);
    setCurrentState();
    controls.start({ opacity: filterExpand ? 0 : 1 });
  }

  const controls = useAnimation();
  const handleClick = () => {
    //alert('hi'); // Display the alert when the image is clicked
    setFilterExpand(!filterExpand); // Make the image bold when clicked
    setSecondFilterExpand(false);
    controls.start({ opacity: filterExpand ? 0 : 1 });
    if (!filterExpand) {setCurrentState()}
  };
  const animationVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0 },
  };

  const imageStyle = {
    height: '2.5vw',
  };

  const getData = () => { 
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    const config = {headers:{['x-access-token']: sessionToken}};

    let linkAPI = "";
    if (bymeToMe == "ticketListToMe"){
      linkAPI = "/filterTicketToMe"
    } else if (bymeToMe == "ticketListByMe"){
      linkAPI = "/filterTicketByMe"
    } else if (bymeToMe == "docListByMe"){
      linkAPI = "/filterDocByMe"
    } else if (bymeToMe == "docListToMe"){
      linkAPI = "/filterDocToMe"
    } else if (bymeToMe == "getReport"){
      linkAPI = "/filterReportMonthly"
    } else if (bymeToMe == "getReportSummary"){
      linkAPI = "/filterReportSummary"
    } else if (bymeToMe == "getReportTenant"){
      linkAPI = "/filterReportTenant"
    } else {
      linkAPI = "/filterTicketToMe" 
    }

    if (linkAPI != ""){
      setFilterAble(true);
      var dbpath = process.env.REACT_APP_PATH_DESTINATION + linkAPI;   
      axios.get( dbpath, config)
        .then(res => { 
          console.log("res.data.data filter logging")
          console.log(res.data.data);
          if (res.data.status == "200"){
            setFilterKeys([]);
            for (let i=0;i< res.data.data.keys.length; i ++){
              const currentKey = res.data.data.keys[i];
              if (currentKey && !currentKey.endsWith("ID")) {
                setFilterKeys(prevFilterKeys => [...prevFilterKeys, currentKey]);
              }
            }
            setFilterData(res.data.data.data)
          } else {
            //maybe error log / alert here somehow
          }
        })
    } else {
      setFilterAble(false);
      setFilterKeys([]);
    }
  }
  const [screenMargin, setScreenMargin] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      let x = window.innerHeight - 600;
      if (x < 0)
      setScreenMargin(x/1.7);
      else 
      setScreenMargin(x/10);
    };

    // Initial check on component mount
    handleResize();

    // Attach event listener for scroll
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  if (orientation === 'landscape')
  return (
    <div className='row' style={{ marginTop: '1vw', marginBottom:'0.5vw' }}>
      <div style={{  zIndex:'3', position: 'relative', width: '2.5vw', height: '2.5vw', border: '1px solid #E8E9EB', padding: '0.5vw', borderRadius:'8px' }}>
        <Refresh className='cursorPointer' onClick={handleRefreshClick} style={{  width: '1.5vw', height: '1.5vw', color:'#737E86', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
      </div>
      &nbsp;&nbsp;&nbsp;
      {filterAble && (
        <div>
          <a style={{ cursor: 'pointer', zIndex:'5' }} onClick={handleClick}>
            <img
              style={imageStyle}
              src={filterExpand ? '/dist/img/tableFilterBold.png' : '/dist/img/tableFilter.png'}
              alt="Table Filter"
            />
          </a>
          <div style={{maxWidth:'50vw',marginLeft:'8vw', marginTop:'-2.5vw'}} className='row'>
          {filterVariable && filterVariable.map((item, index) => (
            <div style={{marginLeft:'0.5vw',marginBottom:'0.5vw'}} className="filter-selected"  key={index}>
              {item.key} - {item.data} 
              <Cancel className='cursorPointer' onClick={() => removeFilterIndex(index)} style={{  width: '1.2vw', height: '1.2vw', color:'#737E86', top: '50%', left: '50%',marginLeft:'0.5vw'}} />
              </div>
          ))}
          </div>
          {filterExpand && (
            <>
            <motion.div
              style={{
                backgroundColor: 'white',
                position: 'absolute',
                marginTop: filterVariable.length > 0 ? '0vw':'2.5vw',
                height: `auto`,
                width: '12vw',
                borderRadius: '8px',
                zIndex: 5,
                border: '0px solid black',
                boxShadow: '0px 4px 40px 0px #0000001F' 
              }}
              initial="hidden"
              animate={filterExpand ? 'visible' : 'hidden'}
              variants={animationVariants}
              transition={{ duration: 0.5, ease: 'easeInOut' }}
            >
  
            {filterKeys.map((key, index) => (
              <FilterLevelOne
                key={index}
                currentState={currentState}
                setCurrentState={setCurrentState}
                state={index}
                text={key}
                changeLevelTwo={handleModifyLevelTwo}
                setSecondLevel={setSecondLevel}
              />
            ))}
  
            </motion.div>
            <FilterLevelTwo 
              setSecondFilterExpand={setSecondFilterExpand} 
              SecondfilterExpand={secondFilterExpand} 
              animationVariants={animationVariants}
              data={secondLevelData}
              dataID={secondLevelDataID}
              filterVariable={filterVariable}
              setFilter={secondLevelPicked}
            />
            </>
          )}
        </div>
      )}
    </div>
  );
  if (orientation === 'portrait')
  return (
    <div className='row' style={{ marginTop: '1vw', marginBottom:screenMargin +'px' }}>
      <div style={{  marginLeft:'5vw',zIndex:'3', position: 'relative', border: '1px solid #E8E9EB', width: '4vh', height: '4vh', padding: '0.5vh', borderRadius:'8px' }}>
        <Refresh className='cursorPointer' onClick={handleRefreshClick} style={{  width: '2.8vh', height: '2.8vh', color:'#737E86', position: 'absolute' }} />
      </div>
      &nbsp;&nbsp;&nbsp;
      {filterAble && (
        <div>
          <a style={{ cursor: 'pointer', zIndex:'5' }} onClick={handleClick}>
            <img
              style={{height:'4vh'}}
              src={filterExpand ? '/dist/img/tableFilterBold.png' : '/dist/img/tableFilter.png'}
              alt="Table Filter"
            />
          </a>
          <div style={{width:'30vw',height:'4vh', marginLeft:'13.5vh', marginTop:'-4vh'}} className='row'>
          {filterVariable && filterVariable.length > 0 && (
            <div style={{width:'30vw', marginTop:'0vh'}} className="filter-selected-mobile">
              {filterVariable.length} filter applied 
              <Cancel className='cursorPointer' onClick={() => removeAllFilterIndexes()} style={{ height: '20px', color:'#737E86', top: '50%', left: '50%',marginLeft:'0.5vw', align:'right'}} />
            </div>
          )}
          </div>
          
          {filterExpand && (
            <>
            <motion.div
              style={{
                backgroundColor: 'white',
                position: 'absolute',
                marginTop: filterVariable.length > 0 ? '0vw':'2.5vw',
                height: `auto`,
                width: '36vw',
                borderRadius: '8px',
                zIndex: 5,
                border: '0px solid black',
                boxShadow: '0px 4px 40px 0px #0000001F' ,
                marginLeft:'-5vw'
              }}
              initial="hidden"
              animate={filterExpand ? 'visible' : 'hidden'}
              variants={animationVariants}
              transition={{ duration: 0.5, ease: 'easeInOut' }}
            >
  
            {filterKeys.map((key, index) => (
              <FilterLevelOne
                key={index}
                currentState={currentState}
                setCurrentState={setCurrentState}
                state={index}
                text={key}
                changeLevelTwo={handleModifyLevelTwo}
                setSecondLevel={setSecondLevel}
              />
            ))}
  
            </motion.div>
            <FilterLevelTwo 
              setSecondFilterExpand={setSecondFilterExpand} 
              SecondfilterExpand={secondFilterExpand} 
              animationVariants={animationVariants}
              data={secondLevelData}
              dataID={secondLevelDataID}
              filterVariable={filterVariable}
              setFilter={secondLevelPicked}
            />
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default FilterSearch;
