import React, { useEffect, useState } from 'react'
import HeaderPage from '../../headerPage/HeaderPage';
import Cookies from 'universal-cookie'; import axios from 'axios'
import Swal from 'sweetalert2'; import withReactContent from 'sweetalert2-react-content';
import { useParams } from 'react-router-dom';
import ConfigInputText from '../configHelper/ConfigInputText';
import ConfigDropdown from '../configHelper/ConfigDropdown';
import ConfigInputTime from '../configHelper/ConfigInputTime';
import useOrientation from '../../../general/useOrientation';

const cookies = new Cookies();
const MySwal = withReactContent(Swal)
function ConfigViewCat({addEdit, changeNavState}) {
  const [isLoading, setIsLoading] = useState(false);
  const {intExtDoc, id} = useParams();
  const [catName, setCatName] = useState('');
  const [catCode, setCatCode] = useState('');
  const [slaWaiting, setSlaWaiting] = useState(0);
  const [slaOnProgress, setSlaOnProgress] = useState(0);
  const [slaAutoClose, setSlaAutoClose] = useState(0);
  const [dept, setDept] = useState('');
  const [parent, setParent] = useState('');
  const [pic, setPic] = useState('');
  const [pelaksana, setPelaksana] = useState('');
  const [priority, setPriority] = useState(1);
  const [deptFwd, setDeptFwd] = useState('');
  const [slaWaitingFwd, setSlaWaitingFwd] = useState(0);
  const [slaOnProgressFwd, setSlaOnProgressFwd] = useState(0);
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait') { isThisPortrait = true;}
  useEffect(() => {
    if (addEdit == "edit"){
      getData();
    }
  }, [])

  useEffect(() => {
    changeNavState('Configurations');
  }, [])

  
  const changePriority = () => {
    if (priority === 1){
      setPriority(2);
    } else {
      setPriority(1);
    }
  }

  const getData = () => {
    setIsLoading(true);
    var sessionToken = cookies.get('sessionToken', { path: '/' })
    axios.interceptors.request.use(config => {config.headers['x-access-token'] = sessionToken;return config;},error => {return Promise.reject(error);});
    axios.get(`${process.env.REACT_APP_PATH_DESTINATION}/ticketCatView/${intExtDoc}/${id}`) 
      .then(res => {
        //console.log("abcdefghiiiiii",res.data.data);
        setCatName(res.data.data.kategori_name);
        setCatCode(res.data.data.kode_kategori);
        setSlaWaiting(res.data.data.sla_waiting);
        setSlaOnProgress(res.data.data.sla_on_progress);
        setDept(res.data.data.dept_mgmt_id);
        setPelaksana(res.data.data.penaggung_jawab);

        if (intExtDoc == "1") {
          setSlaAutoClose(res.data.data.sla_resolve);
          setPic(res.data.data.pic_pelaksana);
        } else if (intExtDoc == "2" || intExtDoc == "3") {
          setSlaAutoClose(res.data.data.sla_resolved);
          setPic(res.data.data.pic);
          //new
          setPriority(res.data.data.priority_id);
          setDeptFwd(res.data.data.fwd_dept_mgmt_id);
          setSlaWaitingFwd(res.data.data.sla_waiting_fwd);
          setSlaOnProgressFwd(res.data.data.sla_on_progress_fwd);
          setParent(intExtDoc == "2"? res.data.data.ext_cat_parent_id : res.data.data.doc_cat_parent_id);
        }


        //setInputValue(res.data.data.parent_name)
        setIsLoading(false);
      })
      .catch(err => {
        console.log(err);
        MySwal.fire({
          title: <strong>Error!</strong>,
          html: <p>{err.response?.data?.message || "An error occurred. Please try relogging"}</p>
        })
        setIsLoading(false);
      })
  }
  
  const pickDropdownDept = (val) => {
    setDept(val.value);
  }

  const pickDropdownParent = (val) => {
    setParent(val.value);
  }

  const pickDropdownDeptFwd = (val) => {
    setDeptFwd(val.value);
  }

  const pickDropdownPelaksana = (val) => {
    let tmp = "";
    for(let i = 0 ; i < val.length; i++){
      tmp += val[i].value;
      if (i < val.length - 1) {
        tmp += ",";
      }
    }
    setPelaksana(tmp);
  }

  const pickDropdownPic = (val) => {
    let tmp = "";
    for(let i = 0 ; i < val.length; i++){
      tmp += val[i].value;
      if (i < val.length - 1) {
        tmp += ",";
      }
    }
    setPic(tmp);
  }
  
  const submitData = () => {
    let apiLink = "";
    if (addEdit === "edit"){
      apiLink=`${process.env.REACT_APP_PATH_DESTINATION}/ticketCatEdit`
    } else if (addEdit === "add"){
      apiLink=`${process.env.REACT_APP_PATH_DESTINATION}/ticketCatAdd`
    }

    setIsLoading(true);

    //postData
    let data = {
      type : intExtDoc,
      id : id,
      catName : catName,
      catCode : catCode,
      slaWaiting : slaWaiting,
      slaOnProgress : slaOnProgress,
      slaAutoClose : slaAutoClose,
      dept : dept,
      pelaksana : pelaksana,
      pic : pic,
      parent : parent,
      priority : priority,
      deptFwd : priority == "2"? null : deptFwd,
      slaWaitingFwd : priority == "2"? slaWaiting : slaWaitingFwd,
      slaOnProgressFwd : priority == "2"?slaOnProgress : slaOnProgressFwd
    }

    console.log("postdata logging ", data);

    var sessionToken = cookies.get('sessionToken', { path: '/' })
    axios.interceptors.request.use(config => {config.headers['x-access-token'] = sessionToken;return config;},error => {return Promise.reject(error);});
    axios.post(apiLink,data) 
      .then(res => {
        console.log(res.data.data);
        setIsLoading(false);
        MySwal.fire({
          title: <strong>Success</strong>,
          html: <p>Data Edited</p>
        })
      })
      .catch(err => {
        console.log(err);
            MySwal.fire({
            title: <strong>Error!</strong>,
            html: <p>{err.response?.data?.message || "An error occurred. Please try relogging"}</p>
        })
        setIsLoading(false);
      })
      
  }

  return (
  <>
    <HeaderPage
        title1={"Configurations"}
        title2={"Ticket Category"}
        link1={"/configuration/ticket"}
        loadingState={isLoading}
    />

    <div style={{marginTop:'4vw', marginLeft:isThisPortrait?'5vw':'', marginRight:'1vw'}} className='row'>
      <div className='form-group'>
        {(intExtDoc == "2" || intExtDoc == "3") && (
          <>
          <ConfigDropdown defValue={parent} type={4} pickDropdown={pickDropdownParent} title="Parent Category" link={'/getParent/' + intExtDoc} />
          <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
          </>
        )}
        <ConfigInputText name={"Category Name"} data={catName} setData={setCatName}/>
        <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
        <ConfigDropdown defValue={dept} type={1} pickDropdown={pickDropdownDept} title="Department" link='/deptMgmtAll' />
        <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
        <ConfigDropdown defValue={pelaksana} type={3} pickDropdown={pickDropdownPelaksana} title="Penanggung Jawab" link={"/getAllUser"} isMulti={true}/>
        <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
        <ConfigDropdown defValue={pic} type={3} pickDropdown={pickDropdownPic} title="PIC" link={"/getAllUser"} isMulti={true}/>
        <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
        <ConfigInputText name={"Category Code"} data={catCode} setData={setCatCode}/>
        <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
        <ConfigInputTime time={"m"} name={"First Response Duration (in minute) "} data={slaWaiting} setData={setSlaWaiting}/>
        <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
        <ConfigInputTime time={"d"} name={"On Progress Duration (in day)"} data={slaOnProgress} setData={setSlaOnProgress}/>
        <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
        <ConfigInputTime time={"d"} name={"Auto Close Duration (in day)"} data={slaAutoClose} setData={setSlaAutoClose}/>
        <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
        {(intExtDoc == "2" || intExtDoc == "3") && (
          <>
          <label>Emergency &nbsp;</label> <img 
            src={priority == 1 ? 
                process.env.REACT_APP_SELF_PATH_DESTINATION+"/SwitchOn.png" : 
                process.env.REACT_APP_SELF_PATH_DESTINATION+"/SwitchOff.png"} 
            alt="Switch On Off" 
            style={{height:"2vh"}}
            onClick={changePriority}
          />
          <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
          {priority == 1 && (
            <>
              <ConfigDropdown defValue={deptFwd} type={1} pickDropdown={pickDropdownDeptFwd} title="Department Forward" link='/deptMgmtAll' />
              <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
              <ConfigInputTime time={"m"} name={"First Response Forward (in minute) "} data={slaWaitingFwd} setData={setSlaWaitingFwd}/>
              <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
              <ConfigInputTime time={"d"} name={"On Progress Forward (in day)"} data={slaOnProgressFwd} setData={setSlaOnProgressFwd}/>
              <div style={{marginTop:'1.5vw', marginBottom:'1.5vw' }}> </div>
            </>
          )}
          </>
        )}
        <button type='submit' onClick={submitData} className='btn buttonBlue' style={{marginTop:'1vh'}}>
          Submit
        </button>
      </div>
    </div> 
  </>
  )
}

export default ConfigViewCat