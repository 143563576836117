// Complete code with @material-ui/core
import React from "react";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const tabContent = [
  {
    label: "Item One",
    content: <p>This is item one.</p>,
  },
  {
    label: "Item Two",
    content: <p>This is item two.</p>,
  },
  {
    label: "Item Three",
    content: <p>This is item three.</p>,
  },
];

function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        {tabContent.map((tab) => (
          <Tab key={tab.label} label={tab.label} />
        ))}
      </Tabs>
      {tabContent[value].content}
    </Box>
  );
}

export default BasicTabs