import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import useOrientation from '../../../general/useOrientation';
const cookies = new Cookies();
function TabbingConfig({selectedTab, handleTabClick}) {
  const orientation = useOrientation();
  let isThisPortrait = false;
  if (orientation === 'portrait') { isThisPortrait = true;}
  var sessionData = cookies.get('sessionData', { path: '/' })
  
  if (orientation === 'landscape') {
    if(parseInt(sessionData.int_ext) === 1 && parseInt(sessionData.apps_roles_id) === 3){
      return (
        <div className='row' style={{ position:'relative', zIndex:'2',borderBottom: '1px solid #E8E9EB', width: '98%', marginTop: '3vw'}}>
          <div className={`tabbingBig ${selectedTab === 'Ext Ticket Parent' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Ext Ticket Parent')}>
            Ext Ticket Parent
          </div>
          <div className={`tabbingBig ${selectedTab === 'Ext Ticket Category' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Ext Ticket Category')}>
            Ext Ticket Category
          </div>
          <div className={`tabbing ${selectedTab === 'Doc Parent' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Doc Parent')}>
            Doc Parent
          </div>
          <div className={`tabbing ${selectedTab === 'Doc Category' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Doc Category')}>
            Doc Category
          </div>
        </div>
      )
    } else if (parseInt(sessionData.int_ext) === 1 && parseInt(sessionData.apps_roles_id) < 3) {
      return (
        <div className='row' style={{ position:'relative', zIndex:'2',borderBottom: '1px solid #E8E9EB', width: '98%', marginTop: '3vw'}}>
          <div className={`tabbingBig ${selectedTab === 'Ext Ticket Parent' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Ext Ticket Parent')}>
            Ext Ticket Parent
          </div>
          <div className={`tabbingBig ${selectedTab === 'Ext Ticket Category' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Ext Ticket Category')}>
            Ext Ticket Category
          </div>
          <div className={`tabbing ${selectedTab === 'Doc Parent' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Doc Parent')}>
            Doc Parent
          </div>
          <div className={`tabbing ${selectedTab === 'Doc Category' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Doc Category')}>
            Doc Category
          </div>
          <div className={`tabbing ${selectedTab === 'Int Category' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Int Category')}>
            Int Category
          </div>
          <div className={`tabbingBig ${selectedTab === 'Department Access' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Department Access')}>
            Department Access
          </div>
        </div>
      )
    }
  }
  
  if (orientation === 'portrait') {
    if(parseInt(sessionData.int_ext) === 1 && parseInt(sessionData.apps_roles_id) === 3){
      return (
        <div className='row' style={{ position:'relative', zIndex:'2',borderBottom: '1px solid #E8E9EB', width: '98%', marginTop: '3vw', marginBottom: '2vh', marginLeft:'2vw'}}>
          <div className={`tabbingMobile ${selectedTab === 'Ext Ticket Parent' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Ext Ticket Parent')}>
            Ext Ticket Parent
          </div>
          <div className={`tabbingMobile ${selectedTab === 'Ext Ticket Category' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Ext Ticket Category')}>
            Ext Ticket Category
          </div>
          <div className={`tabbingMobile ${selectedTab === 'Doc Parent' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Doc Parent')}>
            Doc Parent
          </div>
          <div className={`tabbingMobile ${selectedTab === 'Doc Category' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Doc Category')}>
            Doc Category
          </div>
        </div>
      )
    } else if (parseInt(sessionData.int_ext) === 1 && parseInt(sessionData.apps_roles_id) < 3) {
      return (
        <div className='row' style={{ position:'relative', zIndex:'2',borderBottom: '1px solid #E8E9EB', width: '98%', marginTop: '3vw', marginBottom: '2vh', marginLeft:'2vw'}}>
          <div className={`tabbingMobile ${selectedTab === 'Ext Ticket Parent' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Ext Ticket Parent')}>
            Ext Ticket Parent
          </div>
          <div className={`tabbingMobile ${selectedTab === 'Ext Ticket Category' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Ext Ticket Category')}>
            Ext Ticket Category
          </div>
          <div className={`tabbingMobile ${selectedTab === 'Doc Parent' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Doc Parent')}>
            Doc Parent
          </div>
          <div className={`tabbingMobile ${selectedTab === 'Doc Category' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Doc Category')}>
            Doc Category
          </div>
          <div className={`tabbingMobile ${selectedTab === 'Int Category' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Int Category')}>
            Int Category
          </div>
          <div className={`tabbingMobile ${selectedTab === 'Department Access' ? 'tabChosen' : ''}`} onClick={() => handleTabClick('Department Access')}>
            Department Access
          </div>
        </div>
      )
    }
  }
}

export default TabbingConfig;
