import React from 'react';

const OverlaySubmit = () => {
  return (
    <div className="popup-overlay-transparent">
      
    </div>
  );
};

export default OverlaySubmit;
