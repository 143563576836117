import React, { useState,useEffect  } from 'react';
import {useNavigate, Link} from "react-router-dom"
import { Plus, PageFlip, Page} from 'iconoir-react';
import { motion, useAnimation } from 'framer-motion';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import useOrientation from '../general/useOrientation';

function NavbarHeader({ isPopup = false }) {
  const navigate = useNavigate();
  const orientation = useOrientation();
  const [currentTime, setCurrentTime] = useState(getCurrentTime());
  const [isToggleOn, setToggle] = useState(false);
  const [textButton, setTextButton] = useState(false);
  const controls = useAnimation();

  const newTicketPopupClick = () => {
    navigate('/createTicket');
  };

  const newDocPopupClick = () => {
    navigate('/requestDocument');
  };
  
  function getCurrentTime() {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  useEffect(() => {
    if (isPopup){
      setTextButton("Create New");
    } else {
      setTextButton("Create Ticket");
    }

    // Interval for 1 second
    const intervalId1 = setInterval(() => {
      setCurrentTime(getCurrentTime());
    }, 1000);
  }, [])
  
  const IconComponent = isPopup ? Plus : PageFlip;

  const createNewClick = () => {

    if (isPopup){
      setToggle(!isToggleOn);
      controls.start({ opacity: isToggleOn ? 0 : 1 });
    } else {
      navigate('/createTicket');
    }

  };

  const animationVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0 },
  };

  if (orientation === 'landscape')
  return (
    <>
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img
          src="/dist/img/logo.png"
          alt="BeFa Logo"
          className=""
          style={{ opacity: '1', height: '7vw', marginTop: '2.5vw', marginLeft: 'auto', marginRight: 'auto' }}
        />
        <br />
      </div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <button
          className="buttonCreateNew"
          onClick={createNewClick}
          style={{
            opacity: '1',
            borderRadius: '8px',
            height: '3.5vw',
            width: '80%',
            marginTop: '2vw',
            marginBottom: '2vw',
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontFamily: 'Blender Pro Book, sans-serif',
          }}
        >
          <IconComponent style={{ alignSelf: 'center' }} /> &nbsp; {textButton}
        </button>

        {isToggleOn && (
          <motion.div
            style={{
              backgroundColor: 'white',
              position: 'absolute',
              marginTop: '6.5vw',
              height: '7vw',
              width: '80%',
              borderRadius: '8px',
              zIndex: 5,
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'
            }}
            initial="hidden"
            animate={isToggleOn ? 'visible' : 'hidden'}
            variants={animationVariants}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
            onClick={() => setToggle(false)} // Close the div when clicked
          >
            <Link to="/createTicket"> {/* Use Link component with the desired route */}
              <div className='outerTenantDiv'>
                <div className="tenantTicketDiv">
                  <PageFlip className="tenantTicketIcon" /> New Ticket
                </div>
              </div>
            </Link>
            <Link to="/requestDocument"> {/* Use Link component with the desired route */}
              <div className='outerTenantDiv'>
                <div className="tenantTicketDiv">
                  <Page className="tenantTicketIcon" /> Request Document
                </div>
              </div>
            </Link>
          </motion.div>
        )}
      </div>
    </>
    
    </>
  );
  if (orientation === 'portrait')
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop:'1.5vh' }}>
        <p style={{ fontFamily:'Blender Pro Book', fontSize: "4vh", color: "#737E86" }}> {currentTime}</p>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <button
          className="buttonCreateNew"
          onClick={createNewClick}
          style={{
            opacity: '1',
            borderRadius: '8px',
            height: '5vh',
            width: '80%',
            marginTop: '1vh',
            marginBottom: '1vh',
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontFamily: 'Blender Pro Book, sans-serif',
          }}
        >
          <IconComponent style={{ alignSelf: 'center' }} /> &nbsp; {textButton}
        </button>

        {isToggleOn && (
          <motion.div
            style={{
              backgroundColor: 'white',
              position: 'absolute',
              marginTop: '6.5vw',
              height: '7vw',
              width: '80%',
              borderRadius: '8px',
              zIndex: 5,
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'
            }}
            initial="hidden"
            animate={isToggleOn ? 'visible' : 'hidden'}
            variants={animationVariants}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
            onClick={() => setToggle(false)} // Close the div when clicked
          >
            <Link to="/createTicket"> {/* Use Link component with the desired route */}
              <div className='outerTenantDiv'>
                <div className="tenantTicketDiv">
                  <PageFlip className="tenantTicketIcon" /> New Ticket
                </div>
              </div>
            </Link>
            <Link to="/requestDocument"> {/* Use Link component with the desired route */}
              <div className='outerTenantDiv'>
                <div className="tenantTicketDiv">
                  <Page className="tenantTicketIcon" /> Request Document
                </div>
              </div>
            </Link>
          </motion.div>
        )}
      </div>
    </>
  )
}

export default NavbarHeader;
